import React from 'react'
import _ from 'lodash'

import Typography from '@mui/material/Typography'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'

import { isMediaWidthMoreThan, convertDateToThaiString } from 'src/utils/lib'
import { Column, Row } from 'src/components/Layout/Styled'
import { StyledCard, StyledLabelIcon, StyledRowFooter } from './Styled'
import ContainedButton from './ContainedButton'
import Divider from '@mui/material/Divider'
import { formatAnnounceDate } from '../event'

const ClassCard = ({ data, index }) => {
  const isSm = isMediaWidthMoreThan('sm')

  const dateRange = `เวลา : ${_.get(data, 'startTime', '')} - ${_.get(
    data,
    'startEnd',
    ''
  )} น.`

  const fontWeight = isSm ? 'h6' : 'h5'
  const fontSize = isSm ? '28px' : '20px'
  const iconSize = isSm ? '24px' : '20px'
  const name = `ประกาศรายชื่อผู้มีสิทธิ์สอบวันที่ ${convertDateToThaiString(
    _.get(data, 'examDate', '')
  )}`

  return (
    <StyledCard index={index + 1}>
      <Row id="button" sx={{ justifyContent: 'space-between' }}>
        <Column sx={{ width: 'auto', gap: '18px' }}>
          <Typography
            variant={fontWeight}
            sx={{
              fontSize: fontSize,
            }}
          >
            {name}
          </Typography>
          <StyledRowFooter>
            <StyledLabelIcon>
              <CampaignOutlinedIcon
                color="text.secondary"
                fontSize={iconSize}
              />
              <Typography
                maxWidth={200}
                variant="body1"
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {formatAnnounceDate(data)}
              </Typography>
            </StyledLabelIcon>
            <StyledLabelIcon>
              <TimerOutlinedIcon color="text.secondary" fontSize={iconSize} />
              <Typography
                maxWidth={185}
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {dateRange}
              </Typography>
            </StyledLabelIcon>
            <StyledLabelIcon>
              <BusinessOutlinedIcon
                color="text.secondary"
                fontSize={iconSize}
              />
              <Typography
                maxWidth={185}
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {`สนามสอบ : ${_.get(data, 'examinationField', '-')}`}
              </Typography>
            </StyledLabelIcon>
            {!_.isEmpty(_.get(data, 'examinationRegion')) &&
              _.get(data, 'examinationRegion') !== '-' && (
                <StyledLabelIcon>
                  <MapOutlinedIcon color="text.secondary" fontSize={iconSize} />
                  <Typography
                    maxWidth={185}
                    sx={{
                      fontSize: '20px',
                      color: '#333333',
                    }}
                  >
                    {`ภูมิภาค : ${_.get(data, 'examinationRegion', '-')}`}
                  </Typography>
                </StyledLabelIcon>
              )}
            <StyledLabelIcon>
              <LocationOnOutlinedIcon
                color="text.secondary"
                fontSize={iconSize}
              />
              <Typography
                maxWidth={185}
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {`สถานที่ : ${_.get(data, 'examinationLocation', '-')}`}
              </Typography>
            </StyledLabelIcon>
          </StyledRowFooter>
        </Column>
        <ContainedButton data={data} />
      </Row>
      <Divider />
    </StyledCard>
  )
}

export default ClassCard
