import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { StyledContainer } from '../../../components/Layout/Styled'
import Breadcrumb from '../../../components/Breadcrumb'
import Template from '../../../components/Template'

import Header from '../Header'

import { listTableHead } from './model'
import {
  CertContainer,
  CertDetailContainer,
  StyledCardBoxPreview,
  StyledTable,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
} from './Styled'
import { fetchDataRegistration, LayoutPreview, onDownload } from './events'

const Grades = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()

  const data = useSelector((state) => state.registration.data, shallowEqual)
  const rowData = _.get(data, 'courseResult', [])
  const eCertification = _.get(data, 'eCertificationVersion', '')
  const eCertExpireDate = _.get(data, 'expireCertDate', '')
  const isCanDownloadCert = _.get(data, 'isCanDownloadCert', false)
  const classLearnerUuid = _.get(data, 'classLearnerUuid', '')
  const eCertText = isCanDownloadCert ? 'ดาวน์โหลดประกาศนียบัตร' : 'หมดอายุแล้ว'

  useEffect(() => {
    dispatch(fetchDataRegistration({ uuid }))
  }, [uuid])

  const listBreadcrumbs = [
    {
      isLink: true,
      color: 'text.secondary',
      label: 'คลาสของฉัน',
      link: '/my-class',
    },
    {
      isLink: true,
      color: 'text.secondary',
      label: 'คลาส',
      link: `/my-class/registration-detail/${uuid}?classLearnerUuid=${classLearnerUuid}`,
    },
    {
      isLink: false,
      color: 'primary',
      label: 'ผลการเรียน',
    },
  ]

  return (
    <Template>
      <StyledContainer>
        <Breadcrumb list={listBreadcrumbs} />
        <Header isGrade />
        {!_.isEmpty(eCertification) && (
          <CertContainer>
            <StyledCardBoxPreview>
              <LayoutPreview version={eCertification} />
            </StyledCardBoxPreview>
            <CertDetailContainer>
              <Typography variant="h6" color="primary">
                ยินดีด้วย! คุณได้รับประกาศนียบัตร
              </Typography>
              <Button
                startIcon={<CloudDownloadIcon />}
                variant="contained"
                disabled={!isCanDownloadCert}
                onClick={() => dispatch(onDownload(uuid))}
              >
                {eCertText}
              </Button>
              {eCertExpireDate && (
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ margin: '0px auto' }}
                >
                  หมดอายุ: {eCertExpireDate}
                </Typography>
              )}
            </CertDetailContainer>
          </CertContainer>
        )}
        <StyledTable>
          <Box>
            <StyledTableHead>
              {listTableHead.map((column, i) => (
                <StyledTableCell
                  key={i}
                  isHead
                  width={column.width}
                  align={column.align}
                >
                  <Typography variant="body1b">{column.title}</Typography>
                </StyledTableCell>
              ))}
            </StyledTableHead>
            {rowData.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell width="25%" align="left" isLeft>
                  {row.name}
                </StyledTableCell>
                <StyledTableCell justifyContent="center" width="25%">
                  <Typography
                    variant="body2b"
                    color={
                      row.passResult === 'ผ่าน' || row.passResult === 'ทำแล้ว'
                        ? 'success.main'
                        : 'error.main'
                    }
                  >
                    {row.passResult}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell isResult width="50%">
                  <StyledTableCell justifyContent="center" width="50%">
                    {row.result}
                  </StyledTableCell>
                  <StyledTableCell justifyContent="end" width="50%">
                    {row.testCriteriaType}
                  </StyledTableCell>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </Box>
        </StyledTable>
      </StyledContainer>
    </Template>
  )
}

export default Grades
