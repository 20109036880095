import _ from 'lodash'

export const handleQueueing = (percent) => {
  const progress = document.querySelectorAll('.progress')
  const queue = _.ceil(percent / 5)
  if (progress && queue > 0 && queue <= 20) {
    const index = queue - 1
    Array(queue)
      .fill()
      .forEach((q, i) => {
        progress[i].classList.add('active')
        progress[i].classList.remove('current')
      })
    progress[index].classList.add('current')
  }
}
