import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import Divider from '@mui/material/Divider'
import TextareaAutosize from '@mui/material/TextareaAutosize'

import { handleCopyError } from './events'
import { StyledErrorDetail } from './Styled'

const ErrorDetail = ({ curl }) => {
  const [isShow, setShowError] = useState(false)
  const [isOpenSnackbar, setOpenSnackbar] = useState(false)

  return (
    <StyledErrorDetail>
      <Box>
        <Typography
          data-testid="btn-show"
          variant="helpertext"
          color="primary.light"
          onClick={() => setShowError(!isShow)}
        >
          {isShow ? 'ซ่อนรายละเอียด' : 'แสดงรายละเอียด'}
        </Typography>
        <Divider variant="middle" orientation="vertical" flexItem />
        <Typography
          data-testid="btn-copy"
          variant="helpertext"
          color="primary.light"
          onClick={() => handleCopyError(curl, setOpenSnackbar)}
        >
          คัดลอกรายละเอียด
        </Typography>
      </Box>
      {isShow && (
        <TextareaAutosize
          disabled
          defaultValue={curl}
          maxRows={5}
          style={{ resize: 'none' }}
        />
      )}
      <Snackbar
        data-testid="snackbar"
        open={isOpenSnackbar}
        autoHideDuration={3000}
        message="คัดลอกรายละเอียดแล้ว"
        onClose={() => setOpenSnackbar(false)}
      />
    </StyledErrorDetail>
  )
}

export default ErrorDetail
