import React, { useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Webcam from 'react-webcam'
import Cameraswitch from '@mui/icons-material/Cameraswitch'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { StyledCameraFrame, StyledCaptureButton } from './Styled'

const CameraFrame = ({ field, image, onCapture, onError }) => {
  const camRef = useRef(null)
  const isNotSm = isMediaWidthMoreThan('sm')
  const videoWidth = isNotSm ? 500 : 294
  const videoHeight = 369
  const [isFrontCam, setFrontCam] = useState(true)
  const facingMode = useMemo(
    () => (isFrontCam ? 'user' : 'environment'),
    [isFrontCam]
  )
  const videoConstraints = {
    width: videoWidth,
    height: videoHeight,
    facingMode,
  }
  const frameImg = field === 'idCardImage' ? 'id_card' : 'face'

  return (
    <>
      <Box sx={{ position: 'relative', height: 'auto' }}>
        {image ? (
          <img
            src={image}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <Webcam
            ref={camRef}
            width="100%"
            height={videoHeight}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMediaError={onError}
          />
        )}
        <StyledCameraFrame src={`/icon/e-learning/frame_cam_${frameImg}.svg`} />
      </Box>
      <StyledCaptureButton onClick={() => onCapture(camRef)}>
        <img src="/icon/e-learning/ic_camera.svg" />
      </StyledCaptureButton>
      <IconButton
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        color="primary"
        onClick={() => setFrontCam((prev) => !prev)}
      >
        <Cameraswitch />
      </IconButton>
    </>
  )
}

export default CameraFrame
