import { object, string, array } from 'yup'
import _ from 'lodash'
import {
  setExaminersFormError,
  setFormError,
  setReduxValue,
  setFormNextButtonDisabled,
} from '.'
import { checkIdCard, isValidateDate, saveDebounce } from 'src/utils/lib'

export async function handleFormInput(
  currentState,
  originalState,
  index,
  listenerApi
) {
  const otherDocConfig = listenerApi.getState().eExamination?.otherDocConfig
  let validateInputSchema = object().shape({
    prefix: string().required('กรุณาเลือกคำนำหน้าชื่อ'),
    firstNameTH: string().when('editable.isEditName', {
      is: true,
      then: string().required('กรุณากรอกชื่อผู้เข้าสอบ'),
    }),
    lastNameTH: string().when('editable.isEditSurname', {
      is: true,
      then: string().required('กรุณากรอกนามสกุลผู้เข้าสอบ'),
    }),
    idCardNo: string().when('editable.isEditIdCardNo', {
      is: 'true',
      then: string().required('เลขประจำตัวประชาชน'),
    }),
    mobileNo: string().when('editable.isEditMobile', {
      is: true,
      then: string().required('กรุณากรอกหมายเลขโทรศัพท์มือถือ'),
    }),
    birthDate: string().when('editable.isEditBirthDate', {
      is: true,
      then: string().required('กรุณาเลือกวัน เดือน ปี เกิด'),
    }),
    gender: string().when('prefix', {
      is: (e) => e?.length > 1,
      then: string().required('กรุณาเลือกเพศ'),
    }),
    zipCode: string().required('กรุณากรอกรหัสไปรษณีย์'),
    province: string().required('กรุณากรอกจังหวัด'),
    amphure: string().required('กรุณากรอกอำเภอ/เขต'),
    tambon: string().required('กรุณากรอกตำบล/แขวง'),
    address: string().required('กรุณากรอกรายละเอียดที่อยู่'),
    institution: string().required('กรุณากรอกสถานศึกษา'),
    qualification: string().required('กรุณาเลือกวุฒิการศึกษา'),
    branch: string().required('กรุณากรอกสาขา'),
    cardImage: object()
      .shape({
        cardImage: string().required('กรุณาอัปโหลดรูปหน้าบัตรประชาชน'),
      })
      .required('กรุณาอัปโหลดรูปหน้าบัตรประชาชน'),
    otherDocs: array().when([], {
      is: () => otherDocConfig && otherDocConfig?.config?.status === 'ACTIVE',
      then: array()
        .test('other-docs', 'กรุณาอัปโหลดเอกสาร', (val) => {
          return val?.length >= 1
        })
        .max(5, 'อัพโหลดได้สูงสุด 5 ไฟล์'),
      otherwise: array().nullable().min(0).max(5, 'อัพโหลดได้สูงสุด 5 ไฟล์'),
    }),
  })

  try {
    await validateInputSchema.validate(currentState, { abortEarly: false })
    if (originalState.formNextButtonDisabled[index]) {
      listenerApi.dispatch(
        setFormNextButtonDisabled({ index: index, value: false })
      )
    }
  } catch (e) {
    if (!originalState.formNextButtonDisabled[index]) {
      listenerApi.dispatch(
        setFormNextButtonDisabled({ index: index, value: true })
      )
    }
  }
}

export async function handleFormNextStep(
  currentState,
  originalState,
  index,
  listenerApi
) {
  let validateInputSchema = object().shape({
    firstNameTH: string().test(
      'firstNameTH-length-test',
      'กรอกได้ไม่เกิน 255 ตัวอักษร',
      (val) => {
        return val?.length <= 255
      }
    ),
    lastNameTH: string().test(
      'lastNameTH-length-test',
      'กรอกได้ไม่เกิน 255 ตัวอักษร',
      (val) => {
        return val?.length <= 255
      }
    ),
    idCardNo: string().when('editable.isEditIdCardNo', {
      is: true,
      then: string()
        .test('idCardNo-length-test', 'กรอกได้ไม่เกิน 13 ตัวอักษร', (val) => {
          return val?.length <= 13
        })
        .test(
          'idCardNo-test',
          'กรุณากรอกเลขประจำตัวประชาชนให้ถูกต้อง',
          (val) => {
            return checkIdCard(val)
          }
        ),
    }),
    mobileNo: string().when('editable.isEditMobile', {
      is: true,
      then: string()
        .test('mobileNo-length-test', 'กรอกได้ไม่เกิน 10 ตัวอักษร', (val) => {
          return val?.length <= 10
        })
        .test(
          'mobile-test',
          'กรุณากรอกหมายเลขโทรศัพท์มือถือให้ถูกต้อง',
          (val) => {
            return val?.length == 10 && /^[0-9]+$/.test(val)
          }
        ),
    }),
    birthDate: string().when('editable.isEditBirthDate', {
      is: true,
      then: string().test(
        'date-formt-test',
        'กรุณากรอกวันที่ให้ถูกต้อง',
        (val) => {
          return isValidateDate(val)
        }
      ),
    }),
    zipCode: string().test(
      'zipCode-length-test',
      'กรอกได้ไม่เกิน 5 ตัวอักษร',
      (val) => {
        return val?.length <= 5
      }
    ),
    address: string().test(
      'address-length-test',
      'กรอกได้ไม่เกิน 255 ตัวอักษร',
      (val) => {
        return val?.length <= 255
      }
    ),
    institution: string().test(
      'institution-length-test',
      'กรอกได้ไม่เกิน 255 ตัวอักษร',
      (val) => {
        return val?.length <= 255
      }
    ),
    between: string().test(
      'between-length-test',
      'กรอกได้ไม่เกิน 255 ตัวอักษร',
      (val) => {
        return val?.length <= 255
      }
    ),
    branch: string().test(
      'branch-length-test',
      'กรอกได้ไม่เกิน 255 ตัวอักษร',
      (val) => {
        return val?.length <= 255
      }
    ),
    gpa: string().test(
      'gpa-length-test',
      'กรอกได้ไม่เกิน 255 ตัวอักษร',
      (val) => {
        return val?.length <= 255
      }
    ),
  })
  try {
    await validateInputSchema.validate(currentState, { abortEarly: false })
    listenerApi.dispatch(setFormError({ index: index, value: [] }))
    if (originalState.examinersFormError[index]) {
      saveDebounce(() =>
        listenerApi.dispatch(
          setExaminersFormError({ index: index, value: false })
        )
      )
    }
  } catch (e) {
    listenerApi.dispatch(setFormError({ index: index, value: e.inner }))
    if (!originalState.examinersFormError[index]) {
      saveDebounce(() =>
        listenerApi.dispatch(
          setExaminersFormError({ index: index, value: true })
        )
      )
    }
  }
}

export function handleStep1Button(listenerApi) {
  const selectedRounds = listenerApi.getState().eExamination.selectedRounds
  const examinerLength = listenerApi.getState().eExamination.examiners.length
  if (selectedRounds.length > 0 && examinerLength > 0) {
    const { available } = _.minBy(selectedRounds, (round) => {
      return round.available
    })
    if (examinerLength <= available) {
      listenerApi.dispatch(
        setReduxValue({ index: 'nextButtonDisabled', value: false })
      )
    } else {
      listenerApi.dispatch(
        setReduxValue({ index: 'nextButtonDisabled', value: true })
      )
    }
  } else {
    listenerApi.dispatch(
      setReduxValue({ index: 'nextButtonDisabled', value: true })
    )
  }
}

export function handleStep2Button(listenerApi) {
  const formNextButtonDisabled =
    listenerApi.getState().eExamination.formNextButtonDisabled
  const errorCheck = _.findIndex(formNextButtonDisabled, (ele) => {
    return ele
  })
  if (errorCheck >= 0) {
    listenerApi.dispatch(
      setReduxValue({ index: 'nextButtonDisabled', value: true })
    )
  } else {
    listenerApi.dispatch(
      setReduxValue({ index: 'nextButtonDisabled', value: false })
    )
  }
}

export function handleStep3Button(listenerApi) {
  const consent = listenerApi.getState().eExamination.consent
  listenerApi.dispatch(
    setReduxValue({ index: 'nextButtonDisabled', value: !consent })
  )
}

export const manageOtherDocsName = (oldDocs, newDoc) => {
  // add number to name
  let myArray = []
  if (newDoc) {
    myArray = [...oldDocs, newDoc]
  } else {
    myArray = [...oldDocs]
  }
  const groupDocs = []
  const myArray_ = myArray.map((item) => {
    if (!groupDocs[item.documentName]) {
      groupDocs[item.documentName] = 0
    }
    return {
      ...item,
      type: item.documentName,
    }
  }, {})
  const newArr = myArray_.map((item) => {
    groupDocs[item.type] = groupDocs[item.type] + 1
    return {
      ...item,
      order: groupDocs[item.type],
    }
  })
  return newArr
}
