import _ from 'lodash'
import { postEnrollLearningPath } from 'src/services/eLearning/learningPath'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getDetailState } from '../events'

export const handleEnroll = () => async (dispatch) => {
  const { data } = getDetailState()
  const body = { eLearningLearningPath: { uuid: data.uuid, id: data.id } }
  dispatch(startLoading())
  const response = await dispatch(postEnrollLearningPath(body))
  dispatch(stopLoading())

  if (_.isEmpty(response.error)) {
    window.location.reload()
  }
}
