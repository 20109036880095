import axios from 'axios'
import _ from 'lodash'
import { credential } from 'src/utils/apiPath'

export const fetchCreateLiveness = async (setLoading, setLivenessData) => {
  const createSession = await axios.get('/queue/session')
  const sessionId = _.get(createSession, 'data', null)
  setLivenessData({ sessionId: sessionId })
  setLoading(false)
}

export const credentialProvider = async () => {
  const response = await axios.get(credential)
  return response.data
}