import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledInputHidden = styled('input')({ display: 'none' })

export const StyledInputDisplay = styled(Row)(({ theme, isAllow }) => ({
  border: '1px solid',
  borderColor: theme.palette?.text?.silver,
  background: isAllow ? 'transparent' : theme.palette?.text?.wildSand,
  gap: '19px',
  '& > .MuiButton-root': {
    width: 92,
    height: 40,
    background: theme.palette?.text?.wildSand,
    borderRight: '1px solid',
    borderColor: theme.palette?.text?.silver,
    boxShadow: 'none',
    color: isAllow
      ? theme.palette?.text?.primary
      : theme.palette?.action?.disabled,
    fontSize: '20px',
    fontWeight: 400,
  },
  '& > .MuiTypography-root': {
    maxWidth: 480,
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export const StyledColumn = styled(Column)(({ theme }) => ({
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    '& #info': { maxWidth: 232, textAlign: 'right' },
  },
}))
