import { WebStorageStateStore } from 'oidc-client-ts'

export const OIDC_METADATA = {
  issuer: window.__env__.AGENT_ONE_URL,
  jwks_uri: window.__env__.AGENT_ONE_URL + '/wks.json',
  authorization_endpoint: window.__env__.AGENT_ONE_URL + '/authorize',
  token_endpoint: window.__env__.REACT_APP_API_MIDDLEWARE_URL + '/oauth/token',
  userinfo_endpoint: window.__env__.AGENT_ONE_URL + '/userinfo',
  revocation_endpoint: window.__env__.AGENT_ONE_URL + '/oauth/revoke',
}

const AgentOneAccount = {
  authority: `${window.__env__.AGENT_ONE_URL}`,
  client_id: window.__env__.AGENT_ONE_CLIENT_ID,
  client_secret: window.__env__.AGENT_ONE_CLIENT_SECRET,
  response_type: 'code',
  useRefreshTokens: true,
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  automaticSilentRenew: false,
  scope: 'openid email offline_access',
  metadata: {
    ...OIDC_METADATA,
  },
}
export default AgentOneAccount
