import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/system/styled'

export const ResponsiveWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
  background: theme.palette?.common?.black,
}))

export const ReactPlayerStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  pointerEvents: 'none',
  iframe: { display: 'none !important' },
}

export const reactPlayerConfig = {
  youtube: {
    playerVars: {
      fs: 0,
      rel: 0,
      controls: 0,
      disablekb: 1,
      cc_load_policy: 1,
      iv_load_policy: 3,
    },
  },
  vimeo: {
    playerOptions: {
      byline: false,
      controls: false,
      keyboard: false,
    },
  },
}

export const StyledFloadtingButton = styled((props) => (
  <IconButton {...props} color="white" />
))(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 999,
  svg: {
    fontSize: '15rem',
    [theme.breakpoints.down('sm')]: { fontSize: '13rem' },
  },
}))
