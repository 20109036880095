import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledGroupBox = styled(Box)(({ theme }) => ({
  borderRadius: theme?.shape?.borderRadius,
  border: '1px solid',
  borderColor: theme.palette.text?.lightBlue,
  margin: theme.spacing(3),
  marginTop: 0,
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  '& > .MuiFormGroup-root': { width: '100%', gap: theme.spacing(0.5) },
  '& .MuiFormControlLabel-root': { padding: 0, margin: '0 5px' },
  // text, select, date input
  '&  > .MuiFormGroup-root > .MuiInputBase-root,  > .MuiFormGroup-root > .MuiBox-root':
    {
      width: 'auto',
      margin: theme.spacing(1),
      marginTop: 0,
    },
  // checkbox style
  '& > .MuiBox-root': {
    margin: theme.spacing(2),
    marginTop: 0,
    marginBottom: theme.spacing(1),
    '& .MuiCheckbox-root .MuiSvgIcon-root': { width: 20, height: 20 },
  },
}))

export const StyledFooter = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(3),
  marginTop: 'auto',
}))
