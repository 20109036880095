import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const manageProspectUpload = createSlice({
  name: 'manageProspectUpload',
  initialState,
  reducers: {
    setChangeFile: (state, { payload }) => {
      state.resultUpload.files = payload.files
      state.resultUpload.fileName = payload.fileName
    },
    setChangeUpload: (state, { payload }) => {
      state.resultUpload = {
        ...state.resultUpload,
        ...payload,
      }
    },
    setClearUpload: (state) => {
      state.resultUpload = initialState.resultUpload
    },
  },
})

export const { setChangeFile, setChangeUpload, setClearUpload } =
  manageProspectUpload.actions

export default manageProspectUpload.reducer
