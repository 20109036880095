import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import Close from '@mui/icons-material/Close'
import { dialogIcon } from '../model'
import { StyledContent } from '../Styled'
import { closeDialog } from '../../../redux/slices/alertDialog'

const Index = () => {
  const dialog = useSelector((state) => state.alertDialog)
  const dispatch = useDispatch()

  const { isClose, iconType, title, message, px } = dialog
  const checkTitle = title.split('\n').length > 1 ? true : false

  return (
    <Box>
      <Box
        sx={{
          height: 60,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {isClose && (
          <Close
            sx={{ mx: 3, mt: 2, cursor: 'pointer' }}
            data-testid="btn-close"
            color="action"
            onClick={() => dispatch(closeDialog())}
          />
        )}
      </Box>
      <StyledContent px={px}>
        {iconType && (
          <img
            alt="icon"
            src={dialogIcon[iconType] || '/icon/main_error.svg'}
          />
        )}
        {checkTitle && (
          <>
            {title.split('\n').map((item, index) => {
              return (
                <Box key={index}>
                  <Typography
                    variant="h5"
                    color="primary.main"
                    sx={{ mt: iconType && !index ? 3 : 0 }}
                  >
                    {item}
                  </Typography>
                </Box>
              )
            })}
          </>
        )}
        {!checkTitle && (
          <Typography
            variant="h5"
            color="primary.main"
            sx={{ mt: iconType ? 3 : 0, textAlign: 'center' }}
          >
            {title}
          </Typography>
        )}

        {message && (
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {message.map((item, index) => (
              <Typography
                key={index}
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: item,
                }}
              />
              // <Typography key={index} variant="body1">
              //   {item}
              // </Typography>
            ))}
          </Box>
        )}
      </StyledContent>
      {_.get(dialog, 'content', <></>)}
    </Box>
  )
}

export default Index
