import _ from 'lodash'
import { store } from 'src/App'
import { setFileFinished } from 'src/redux/slices/eLearning/learning'
import { getLearningState, handleUpdateLogPerLesson } from '../../events'

export const handleFinishFile = () => {
  const { selectedLesson } = getLearningState()
  const selectedFile = _.get(selectedLesson, 'selectedFile', 0)
  store.dispatch(setFileFinished(selectedFile))
  store.dispatch(handleUpdateLogPerLesson())
}
