import _ from 'lodash'
import { setDataList, setIsFetch } from 'src/redux/slices/eContent'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { postEContentFilter } from 'src/services/eContent'

export const fetchDataList = (props) => async (dispatch) => {
  dispatch(setIsFetch(true))
  dispatch(startLoading())
  const res = await dispatch(postEContentFilter(props))
  const data = _.get(res, 'payload.data', {
    result: [],
    totalCount: 0,
  })
  dispatch(setDataList(data))
  dispatch(stopLoading())
}
