import _ from 'lodash'
import { E_LEARNING_LEARNER_LOG_RESULT } from 'src/constants/eLearning'

export const handleModuleResult = (data) => {
  const result = _.get(data, 'result', '') || _.get(data, 'status', '')
  switch (result) {
    case E_LEARNING_LEARNER_LOG_RESULT.INPROGRESS:
      return { text: 'กำลังเรียน', color: 'primary.main' }
    case E_LEARNING_LEARNER_LOG_RESULT.COMPLETE:
    case E_LEARNING_LEARNER_LOG_RESULT.PASS:
      return { text: 'ผ่าน', color: 'success.main' }
    case E_LEARNING_LEARNER_LOG_RESULT.FAIL:
      return { text: 'ไม่ผ่าน', color: 'error.main' }
    default:
      return { text: 'ยังไม่ทำ', color: 'warning.main' }
  }
}
