import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { SlideContainer, StyledRowHeader, StyledTitleBox } from './Styled'
import ContentCard from '../ContentCard'
import { landingTitle } from '../model'
import './styled.css'
import { setFilterValue } from 'src/redux/slices/eContent'

const RecommendArticleRow = ({ isMobile, type }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { slickData } = useSelector(
    (state) => ({
      slickData: state.eContent.landing[type],
    }),
    shallowEqual
  )
  var settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    useTransform: false,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '20px',
        }}
      >
        <StyledTitleBox sx={{}}>
          <StyledRowHeader variant={isMobile ? 'h6' : 'h5'} sx={{ my: 2 }}>
            {landingTitle[type]}
          </StyledRowHeader>
        </StyledTitleBox>
        <Button
          variant="text"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            p: 0,
            gap: 1,
          }}
          onClick={() => {
            dispatch(setFilterValue({ key: 'type', value: [type] }))
            history.push(`/e-content/list/`)
          }}
        >
          <Typography color="primary.main">ดูทั้งหมด</Typography>
          <ArrowForwardIosIcon
            sx={{ fontSize: '20px', color: 'primary.main' }}
          />
        </Button>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />
      <SlideContainer>
        {slickData.length > 0 ? (
          <Slider {...settings}>
            {slickData?.map((item, index) => {
              return <ContentCard item={item} key={index} />
            })}
          </Slider>
        ) : (
          <Typography variant="body1" sx={{ textAlign: 'center', my: 10 }}>
            ไม่พบข้อมูล
          </Typography>
        )}
      </SlideContainer>
    </>
  )
}

export default RecommendArticleRow
