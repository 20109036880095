import React, { useEffect } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Preview from 'src/modules/ETesting'
import Testing from 'src/modules/ETesting/Testing'
import { MediaBoxStyled } from '../Styled'
import { fetchETesting } from './events'

const ETesting = ({ selectedLesson }) => {
  const uuid = _.get(selectedLesson, 'eTestingUuid', '')
  const moduleUuid = _.get(selectedLesson, 'uuid', '')
  const testRound = _.get(selectedLesson, 'testRound', '')
  const startTime = _.get(selectedLesson, 'startTime', '')
  const endTime = _.get(selectedLesson, 'endTime', '')
  const isInProgress = _.get(selectedLesson, 'isInProgress', false)
  const isSubmitted = _.get(selectedLesson, 'isSubmitted', false)
  const isReview = isSubmitted && !_.isEmpty(endTime)
  // isReview = true คือทำแล้วและไม่มีการเปลี่ยนแปลงโมดูลมาจากหลังบ้าน
  // ถ้าทำแล้ว แต่มีการเปลี่ยนแปลงที่หลังบ้าน endTime จะเป็น null
  const { isLessonLoading, eLearningCourseBatchLearner } = useSelector(
    (state) => ({
      isLessonLoading: state.eLearningLearning.isLessonLoading,
      eLearningCourseBatchLearner:
        state.eLearningLearning.eLearningCourseBatchLearner,
    }),
    shallowEqual
  )
  const payload = {
    uuid,
    moduleUuid,
    startTime: isReview
      ? dayjs().format('YYYY-MM-DD HH:mm:ss').toString()
      : startTime,
    eLearningCourseBatchLearner,
    isModule: true,
  }
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchETesting({ payload, isReview, testRound }))
  }, [moduleUuid, testRound])

  if (isLessonLoading) return <MediaBoxStyled />

  if (isReview) return <Testing isReview isModule />
  if (isInProgress) return <Testing isModule />
  return <Preview isModule />
}

export default ETesting
