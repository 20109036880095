export const path = {
  eTesting: '/e-testing',
  eEvaluation: '/e-evaluation',
  eCertification: '/e-certification',
  myClass: '/my-class',
  eLearning: '/e-learning',
  eLearningCourse: '/e-learning/course',
  eLearningVerification: '/e-learning/course/enroll/verification',
  myClassELearning: '/my-class/e-learning-registration-detail',
  learningPath: '/e-learning/learning-path',
}

export const jwtIgnore = ["/manage-content/login", "/location", "/queue"]