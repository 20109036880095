import axios from 'axios'

export const downloadFile = (link, fileName) => {
  axios({
    url: `${window.__env__.REACT_APP_FILE_URL}${link}`,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const href = URL.createObjectURL(response.data)
      const linkElement = document.createElement('a')
      linkElement.href = href
      linkElement.setAttribute('download', fileName)
      document.body.appendChild(linkElement)
      linkElement.click()
    })
    .catch((err) => console.log(err))
}

export const downloadAllFiles = async (data) => {
  const filteredData = await data.filter((item) => item.type === 'UploadFile')

  for (const element of filteredData) {
    downloadFile(element.link, element.name)
  }
}
