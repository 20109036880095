import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Typography from '@mui/material/Typography'
import { ColumnCard } from './Styled'
import { StyledAccordion, StyledAccordionSummary } from './Styled'
import { Column, Row } from 'src/components/Layout/Styled'
import { setCatalogSeeMore } from 'src/redux/slices/myProfile'
import CatalogList from './CatalogList'

const RedeemableCatalog = () => {
  const dispatch = useDispatch()
  const { isCatalogSeeMore, catalogData } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )
  return (
    <ColumnCard>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
        >
          <Typography variant="h6" color="primary.main">
            รายการของรางวัลที่แลกได้
          </Typography>
        </StyledAccordionSummary>

        {catalogData.length > 0 ? (
          <Column>
            <Row>
              <CatalogList />
            </Row>
            {catalogData.length > 3 && (
              <Row
                sx={{ width: 'fit-content', margin: 'auto', cursor: 'pointer' }}
                onClick={() => dispatch(setCatalogSeeMore())}
              >
                <Typography
                  variant="body1b"
                  color="primary.main"
                  sx={{
                    textDecoration: 'underline 1px !important',
                    WebkitTextDecorationStyle: 'solid !important',
                    WebkitTextDecorationThickness: '1px !important',
                    WebkitTextDecorationLine: 'underline !important',
                  }}
                >
                  {isCatalogSeeMore ? 'ย่อลง' : 'ดูเพิ่มเติม'}
                </Typography>
                {isCatalogSeeMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Row>
            )}
          </Column>
        ) : (
          <Row sx={{ justifyContent: 'center', py: 3 }}>
            <Typography variant="body1b">ยังไม่มีรายการที่แลกได้</Typography>
          </Row>
        )}
        <Typography variant="caption" sx={{ mt: 1, mx: 2, mb: 2 }}>
          หมายเหตุ: กรุณาติดต่อแอดมินประจำพื้นที่ของท่าน
          เพื่อแลกคะแนนสะสมเป็นของรางวัล
        </Typography>
      </StyledAccordion>
    </ColumnCard>
  )
}

export default RedeemableCatalog
