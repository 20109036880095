import { Box, Typography } from '@mui/material'
import styled from '@mui/system/styled'

export const StyledTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}))

export const StyledRowHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: '0px',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const SlideContainer = styled('div')(() => ({
  marginBottom: '32px',
}))
