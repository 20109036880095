import _ from 'lodash'
import {
  setFilterBody,
  setFilterValue,
} from 'src/redux/slices/eLearning/collection'

export const getFilter = (state, key, defaultValue) =>
  _.get(state.eLearningCollection.filter, key, defaultValue)

export const handleChangeSlider = (value) => (dispatch) => {
  dispatch(setFilterValue({ key: 'pathHourFrom', value: value[0] }))
  dispatch(setFilterValue({ key: 'pathHourTo', value: value[1] }))
}

export const handleChangeSort = (e) => (dispatch) => {
  const name = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  dispatch(setFilterBody({ key: name, value }))
}
