import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import PlayCircle from '@mui/icons-material/PlayCircle'
import PauseCircle from '@mui/icons-material/PauseCircle'
import {
  StyledImagePreview,
  StyledVideoPreview,
} from 'src/components/Preview/QuestionCard/Styled'
import { setVideoEnded } from 'src/redux/slices/eTesting'
import { handleHoverOnVideo } from './events'
import { VideoWrapperStyled } from './Styled'

const QuestionMedia = ({ question = { url: '' } }) => {
  const { mediaType, url, uuid } = question
  const controlRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [isEnded, setEnded] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    handleHoverOnVideo(controlRef)
  }, [])

  if (!url) return <></>

  if (mediaType == 'VIDEO') {
    return (
      <Box sx={{ position: 'relative' }}>
        {!isEnded && (
          <VideoWrapperStyled
            ref={controlRef}
            onClick={() => setPlaying(!playing)}
          >
            {playing ? <PauseCircle /> : <PlayCircle />}
          </VideoWrapperStyled>
        )}
        <StyledVideoPreview
          key={uuid}
          url={_.get(url.split('&'), 0, '')}
          playing={playing}
          onEnded={() => {
            dispatch(setVideoEnded(uuid))
            setEnded(true)
          }}
        />
      </Box>
    )
  } else {
    return (
      <StyledImagePreview src={`${window.__env__.REACT_APP_FILE_URL}${url}`} />
    )
  }
}

export default QuestionMedia
