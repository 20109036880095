import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'

import CameraOutdoor from '@mui/icons-material/CameraOutdoor'
import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import { Row } from 'src/components/Layout/Styled'
import { COURSE_TYPE_TH } from 'src/constants/eLearning'
import { convertDateRange, isMediaWidthMoreThan } from '../../../utils/lib'
import { StyledDetail, StyledDateTime, StyledTypography } from './Styled'
import { handleELearningDateRange } from './events'

const Header = ({ isGrade, isELearning, isGradeLearning }) => {
  const isWidthLg = isMediaWidthMoreThan('lg')

  let data

  if (isGradeLearning) {
    data = useSelector(
      (state) => state.eLearningRegistration.data,
      shallowEqual
    )
  } else {
    data = useSelector((state) => state.registration.data, shallowEqual)
  }
  let dateRange = convertDateRange(_.get(data, 'dateRangeMonthlyPlan', null))
  let className =
    _.get(data, 'nameForLearner', '') || _.get(data, 'englishName', '-')
  let trainingType = _.get(data, 'trainingPlatformName', '-')
  let hours = _.get(data, 'hours', '0')
  let minutes = _.get(data, 'minutes', 0)

  if (isELearning || isGradeLearning) {
    const eLearningCourse = _.get(
      data,
      'eLearningCourseBatchLearner.eLearningCourse',
      {}
    )
    dateRange = handleELearningDateRange(data)
    className = _.get(eLearningCourse, 'name', '')
    hours = _.get(eLearningCourse, 'totalHour', '')
    minutes = _.get(eLearningCourse, 'totalMinute', '')
    trainingType = COURSE_TYPE_TH[_.get(eLearningCourse, 'courseType', '')]
  }

  if (!minutes) minutes = null

  return (
    <>
      <Box>
        <Typography
          variant={isWidthLg ? 'h3' : 'h4'}
          color="text.primary"
          sx={{ wordWrap: 'break-word' }}
        >
          {isGrade ? 'ผลการเรียน' : className}
        </Typography>

        <StyledDetail>
          {isGrade && (
            <>
              <StyledTypography maxWidth={331} variant="body1b">
                {className}
              </StyledTypography>
              {<DividerResponsive />}
            </>
          )}
          <StyledDateTime>
            <Row sx={{ width: 'auto', gap: 1 }}>
              <InsertInvitationOutlined fontSize="small" />
              <Typography variant="body1">{dateRange}</Typography>
            </Row>
            <Divider orientation="vertical" flexItem />
            <Row sx={{ width: 'auto', gap: 1 }}>
              <TimerOutlined fontSize="small" />
              <Typography variant="body1">
                {hours} ชั่วโมง {minutes && `${minutes} นาที`}
              </Typography>
            </Row>
            {<DividerResponsive />}
          </StyledDateTime>
          <CameraOutdoor fontSize="small" />
          <StyledTypography maxWidth={305} variant="body1">
            {trainingType || '-'}
          </StyledTypography>
        </StyledDetail>
      </Box>
    </>
  )
}

export default Header

export const DividerResponsive = () => {
  const isNotSm = isMediaWidthMoreThan('sm')

  if (isNotSm) return <Divider orientation="vertical" flexItem />
}
