import { WebStorageStateStore } from 'oidc-client-ts'

const OneAccount = {
  authority: `${window.__env__.ONE_URL}`,
  client_id: window.__env__.ONE_CLIENT_ID,
  client_secret: window.__env__.ONE_CLIENT_SECRET,
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  automaticSilentRenew: false,
  scope: 'openid email',
}
export default OneAccount
