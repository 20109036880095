import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledContentHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '24px 24px 0px 24px',
}))

export const StyledBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid #CCCCCC',
  borderRadius: '2px',
  gap: theme.spacing(2),
  margin: `${theme.spacing(2)} ${theme.spacing(3)}`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

export const StyledTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-all',
}))