import { MODULE_TYPE } from 'src/constants/eLearning'

export const MODULE_ICON = {
  [MODULE_TYPE.E_TESTING]: 'ic_e_testing.svg',
  [MODULE_TYPE.E_EVALUATION]: 'ic_e_evaluation.svg',
  [MODULE_TYPE.EMBED_CONTENT]: 'ic_embed_content.svg',
  [MODULE_TYPE.HTML]: 'ic_embed_content.svg',
  [MODULE_TYPE.LINK]: 'ic_embed_content.svg',
  [MODULE_TYPE.UPLOAD_FILE]: 'ic_file.svg',
  [MODULE_TYPE.LINK_TO]: 'ic_link.svg',
}
