import React from 'react'
import ReactPlayer from 'react-player'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { VideoThumbnailBox } from './styled'

const Link = ({ data }) => {
  const theme = useTheme()
  return (
    <Box className="noPrint">
      {data.content.isHasDetail == 'INACTIVE' ? (
        <ReactPlayer
          controls={true}
          url={data.content.link}
          style={{ width: '100%', maxWidth: '100%', margin: 'auto' }}
        />
      ) : (
        <VideoThumbnailBox>
          <Box
            sx={{
              width: '50%',
              maxWidth: '50%',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                maxWidth: '100%',
              },
            }}
          >
            <ReactPlayer
              controls={true}
              url={data.content.link}
              style={{
                width: '100%',
                maxWidth: '100%',
              }}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              maxWidth: { sm: '100%', md: '50%' },
              wordBreak: 'break-word',
            }}
          >
            {data.content.detail}
          </Typography>
        </VideoThumbnailBox>
      )}
    </Box>
  )
}

export default Link
