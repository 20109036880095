import React from 'react'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { Column } from 'src/components/Layout/Styled'
import CameraDialog from 'src/components/CameraDialog'
import DialogCountdown from '../../components/DialogCountdown'
import { handleDetectFace, handleOpenCamera, handleVerifyFail } from './events'

const CameraContent = () => {
  const { isOpen, faceImage } = useSelector(
    (state) => state.eLearningLearning.camera
  )
  const dispatch = useDispatch()
  return (
    <CameraDialog
      isLiveness
      isOpen={isOpen}
      image={faceImage}
      onCapture={(livenessData) => dispatch(handleDetectFace(livenessData))}
      onOpenCamera={() => dispatch(handleOpenCamera())}
      onError={(error) => dispatch(handleVerifyFail(error))}
    >
      <Column gap={0.5} alignItems="center">
        <Typography variant="h6">ยืนยันตัวตนด้วยใบหน้าระหว่างเรียน</Typography>
        <Typography variant="body2" color="text.gray">
          กรุณายืนยันตัวภายใน
        </Typography>
        <DialogCountdown isCamera />
      </Column>
    </CameraDialog>
  )
}

export default CameraContent
