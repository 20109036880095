import { createSlice } from '@reduxjs/toolkit'
import { getAnnounceDetail } from 'src/services/eExamination'
import { initialState } from './model'
import _ from 'lodash'

const announceExaminerDetail = createSlice({
  name: 'announceExaminerDetail',
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnnounceDetail.fulfilled, (state, action) => {
        const { payload } = action
        const announceDetail = _.defaultTo(payload, {})
        state.detail = announceDetail
      })
      .addCase(getAnnounceDetail.pending, (state) => {
        state.detail = {}
      })
      .addCase(getAnnounceDetail.rejected, (state, action) => {
        state.detail = {}
        state.error = action.meta?.message
      })
  },
})

export const {
  resetData,
} = announceExaminerDetail.actions

export default announceExaminerDetail.reducer
