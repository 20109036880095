import React, { useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { COURSE_TYPE } from 'src/constants/eLearning'
import { Column } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import CircularContent from '../components/CircularContent'
import MediaDisplay from './MediaDisplay'
import QuestionContent from './QuestionContent'
import { handleHoverOnVideo, onKeyEsc } from './events'
import { StyledLeftContent } from './Styled'

const LeftPanel = () => {
  const leftRef = useRef(null)
  const dispatch = useDispatch()
  const { isLoading, isLessonLoading, courseType, isFullScreen } = useSelector(
    (state) => ({
      isLoading: state.eLearningLearning.isLoading,
      isLessonLoading: state.eLearningLearning.isLessonLoading,
      courseType: _.get(state.eLearningLearning.course, 'courseType', ''),
      isFullScreen: state.eLearningLearning.isFullScreen,
    }),
    shallowEqual
  )
  const isOic = courseType === COURSE_TYPE.OIC
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    handleHoverOnVideo(leftRef)

    document.addEventListener('keydown', (e) => dispatch(onKeyEsc(e)))
    return () => {
      document.removeEventListener('keydown', (e) => dispatch(onKeyEsc(e)))
    }
  }, [])

  return (
    <StyledLeftContent ref={leftRef} isFullScreen={isFullScreen}>
      <CircularContent isLoading={isLoading || isLessonLoading} />
      <Column sx={{ justifyContent: 'center' }}>
        <MediaDisplay />
        {isOic && isNotSm && <QuestionContent />}
      </Column>
    </StyledLeftContent>
  )
}

export default LeftPanel
