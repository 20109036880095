import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Template from 'src/components/Template'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { Row } from 'src/components/Layout/Styled'
import { StyledTypography } from 'src/components/CourseCard/Styled'
import { LikeIconButton } from '../../../components/LikeButton'
import {
  StyledHeaderImage,
  StyledTextBtn,
  StyledHeaderWrapper,
  StyledHeaderContainer,
  StyledTooltip,
} from './styled'
import { handleLikeCollection } from '../events'

const Header = () => {
  const dispatch = useDispatch()
  const { data, isLikeLoading } = useSelector(
    (state) => ({
      data: state.eLearningCollection.data,
      isLikeLoading: state.eLearningCollection.isLikeLoading,
    }),
    shallowEqual
  )
  const coverImage = _.get(data, 'coverImage', '')
  const isNotSm = isMediaWidthMoreThan('sm')
  const isLike = _.get(data, 'isLike', false)

  if (isNotSm) {
    return <TabletDesktopHeader data={data} coverImage={coverImage} />
  }

  return (
    <StyledHeaderWrapper>
      <Row sx={{ justifyContent: 'space-between' }}>
        <StyledTextBtn
          onClick={() => (window.location.href = '/e-learning/collection')}
        >
          กลับ
        </StyledTextBtn>
        <Typography variant="body1b">Collection</Typography>
        <LikeIconButton
          isLike={isLike}
          isLoading={isLikeLoading}
          onClick={() => dispatch(handleLikeCollection())}
        />
      </Row>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_FILE_URL}/get${coverImage}`}
        />
      )}
      <StyledTypography variant="h6">
        {_.get(data, 'nameForBanner', '')}
      </StyledTypography>
    </StyledHeaderWrapper>
  )
}

export default Header

export const TabletDesktopHeader = ({ data, coverImage }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const name = _.get(data, 'nameForBanner', '')

  return (
    <StyledHeaderWrapper>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_FILE_URL}/get${coverImage}`}
        />
      )}
      <Template noMinHeight>
        <StyledTextBtn
          onClick={() => (window.location.href = '/e-learning/collection')}
        >
          กลับ
        </StyledTextBtn>

        <StyledHeaderContainer>
          <Box>
            <Typography variant="h6" color="text.alabaster">
              Collection
            </Typography>
            <StyledTooltip title={name}>
              <StyledTypography variant={isLg ? 'h2' : 'h4'} color="text.white">
                {name}
              </StyledTypography>
            </StyledTooltip>
          </Box>
        </StyledHeaderContainer>
      </Template>
    </StyledHeaderWrapper>
  )
}
