import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getCourseBatchDetail } from 'src/services/eLearning/detail'
import { initialState } from './model'

const courseBatchDetail = createSlice({
  name: 'courseBatchDetail',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startLoadMore: (state) => {
      state.isLoadMore = true
    },
    stopLoadMore: (state) => {
      state.isLoadMore = false
    },
    setLoadMore: (state) => {
      const nextPage = state.filterBody.page + 1
      const maxPage = _.ceil(state.totalClass / 12)
      if (nextPage <= maxPage) state.filterBody.page = nextPage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseBatchDetail.fulfilled, (state, { payload }) => {
        state.detail = payload.data
      })
      .addCase(getCourseBatchDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startLoadMore,
  stopLoadMore,
  setFilter,
  setLoadMore,
} = courseBatchDetail.actions

export default courseBatchDetail.reducer
