import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledBackgroundLoading = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ theme, isLoading }) => ({
  display: isLoading ? 'flex' : 'none',
  backgroundColor: theme.palette?.other?.backdropOverlay,
  position: 'fixed',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
  '& .MuiCircularProgress-root': {
    color: theme.palette.text?.white,
    width: '96px !important',
    height: '96px !important',
  },
}))
