import React, { useEffect } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import Autocomplete from '../../../../components/Input/Autocomplete'

import { CardSection } from '../'
import { handleChangeCourse, handleFetchStatus } from '../events'

const Index = () => {
  const dispatch = useDispatch()

  const { optionCourse, course, prospects, isSuccess } = useSelector(
    (state) => state.manageProspectEnrollment,
    shallowEqual
  )

  const trainingPlan = _.get(course, 'trainingPlan.value', '')

  useEffect(() => {
    dispatch(handleFetchStatus(course, prospects))
  }, [trainingPlan])

  return (
    <CardSection
      section="1"
      labelSection="เลือกหลักสูตรที่จะสมัคร"
      labelCard="หลักสูตรที่เลือกสมัครเข้าอบรม"
      isViewCourse={true}
      course={course}
    >
      <Box sx={{ border: '1px solid #DBE4F1', borderRadius: '2px', mt: 3 }}>
        {!isSuccess && (
          <Box sx={{ m: 2 }}>
            <Autocomplete
              id="course"
              name="course"
              isHideSort={false}
              labelText="รหัสหลักสูตร - หลักสูตร"
              options={[...optionCourse]}
              defaultValue={_.get(course, 'course', null)}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField placeholder={'เลือกหลักสูตร'} {...params} />
              )}
              handleChange={(e, element) =>
                dispatch(
                  handleChangeCourse({
                    key: 'course',
                    value: element,
                  })
                )
              }
            />
            <Autocomplete
              id="trainingPlan"
              name="trainingPlan"
              isHideSort={false}
              labelText="วันที่อบรม"
              options={[..._.get(course, 'course.classDate', [])]}
              defaultValue={_.get(course, 'trainingPlan', null)}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField placeholder={'เลือกวันที่อบรม'} {...params} />
              )}
              handleChange={(e, element) =>
                dispatch(
                  handleChangeCourse({
                    key: 'trainingPlan',
                    value: element,
                  })
                )
              }
            />
          </Box>
        )}
        {isSuccess && (
          <Box sx={{ m: 2, display: 'flex', gap: 3, flexDirection: 'column' }}>
            <Box>
              <Typography variant="body1" color="text.secondary">
                รหัสหลักสูตร - หลักสูตร
              </Typography>
              <Typography variant="body1">
                {_.get(course, 'course.label', '-')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="text.secondary">
                วันที่อบรม
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {_.get(course, 'trainingPlan.label', '-')}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </CardSection>
  )
}

export default Index
