export const formatData = (field, isColor) => {
  let color = ''
  let text = ''
  switch (field) {
    case 'STUDY':
      text = 'มาเรียน'
      color = 'success.main'
      break
    case 'MISSING':
      text = 'ไม่มาเรียน'
      color = 'error.main'
      break
    case 'TIME_OUT':
      text = 'ไม่ได้เช็ค'
      color = 'action.disabled'
      break
    default:
      color = 'text.disabled'
      break
  }
  return isColor ? color : text
}
