import React from 'react'
import _ from 'lodash'
import Slider from 'react-slick'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ELearningCard from 'src/components/ELearningCard'
import { isMediaWidth, isMediaWidthMoreThan } from 'src/utils/lib'
import Template from 'src/components/Template'
import CourseCard from 'src/components/CourseCard'
import { SECTION_KEY } from 'src/components/CourseCard/model'
import { handleCourseValue } from './events'
import {
  Container,
  StyledButton,
  StyledNoContentBox,
  StyledRowHeader,
} from './styled'
import './styled.css'

const List = ({ courses }) => {
  const isMobile = isMediaWidth('xs')
  const isLaptop = isMediaWidthMoreThan('lg')
  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    useTransform: false,
    dotsClass: 'dotBar',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Container>
      <Template noMinHeight maxWidth={1280}>
        <Box sx={{ width: '100%', minHeight: '325px', mt: 2 }}>
          <Box
            sx={{
              minWidth: 342,
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '10px',
            }}
          >
            <StyledRowHeader
              variant={isMobile ? 'h6' : 'h5'}
              color="primary.main"
            >
              รายการหลักสูตร ({_.get(courses, 'length', 0)})
            </StyledRowHeader>
          </Box>
          {_.get(courses, 'length', 0) > 0 ? (
            isLaptop ? (
              <Box
                sx={{
                  minWidth: 342,
                  minHeight: isMobile ? 225 : 550,
                  paddingTop: '10px',
                }}
              >
                <Grid container spacing={4} sx={{ mb: 3, width: 'auto' }}>
                  {courses.map((course, index) => (
                    <Grid item key={index} md={6} lg={3}>
                      <DisplayCourseCard course={course} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Box sx={{ minWidth: 342, minHeight: isMobile ? 220 : 530 }}>
                <Slider {...settings}>
                  {courses.map((course, index) => (
                    <DisplayCourseCard key={index} course={course} />
                  ))}
                </Slider>
              </Box>
            )
          ) : (
            <StyledNoContentBox sx={{}}>
              <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <img width={95} height={78} src="/icon/ic_sleeping.svg" />
                <Typography
                  variant="h5"
                  sx={{
                    mx: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  ไม่พบข้อมูล
                </Typography>
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    window.location.href = `/e-learning/course/`
                  }}
                  sx={{ mx: 'auto' }}
                >
                  <Typography color="white" sx={{}}>
                    ดูทั้งหมด{' '}
                  </Typography>
                </StyledButton>
              </Box>
            </StyledNoContentBox>
          )}
        </Box>
      </Template>
    </Container>
  )
}

export default List

export const DisplayCourseCard = ({ course }) => {
  const type = course.type.toUpperCase().replace(/ /g, '_')
  const { courseData, sectionKey } = handleCourseValue(course)

  if (type === SECTION_KEY.LEARNING_PATH) {
    return <ELearningCard course={courseData} keySection={sectionKey} />
  }

  return <CourseCard course={courseData} keySection={sectionKey} />
}
