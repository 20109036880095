export const initialState = {
  isOpen: false,
  isConfirmation: false,
  isLoading: false,
  isCloseDialog: true,
  isHiddenFooter: false,
  isHiddenButton: false,
  fullWidth: true,
  disableButton: false,
  type: 'default',
  title: '',
  message: '',
  content: null,
  iconType: null,
  maxWidth: null,
  customWidth: null,
  handleConfirm: null,
  handleError: null,
  handleCancel: null,
  errorCurl: null,
  buttonLeft: {},
  buttonRight: {},
  buttonCenter: {},
  zIndex: 1299,
  px: null,
}
