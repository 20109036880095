export const initForm = {
  //GENERAL
  NEW_CLASS_AFTER_APPROVE: '',
  CAN_EDIT_PREVIOUS_PLAN: '',
  EDIT_TRAINING_PLAN_DATE_AHEAD: '',
  EDIT_CLASS_ONSITE_LOCATION_AFTER_APPROVE: '',
  EDIT_CLASS_BREAK_BUDGET_AFTER_APPROVE: '',
  //E_LEARNING
  EL_UPLOAD_MAX_FILE_SIZE: { size: '', sizeType: 'GB' },
  EL_BATCH_START_AFTER_CLOSE_REGISTER: '',
  EL_FACE_DETECTION_PERCENT: '',
  EL_RANDOM_VERIFY_IDENTITY_DURING_TRAINING: '',
  EL_COUNTDOWN_FACE_DETECTION: '',
  EL_COUNTDOWN_CLICK: '',
  EL_FACE_IMAGE_RETENTION_PERIOD: { year: '', month: '' },
  EL_ID_CARD_RETENTION_PERION: { year: '', month: '' },
  EL_TRAINING_HISTORY_RETENTION_PERIOD: { year: '', month: '' },
  EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION: '',
  //EExam
  CC_EMAIL_ANNOUNCEMENT_EXAMINATION: [], // ''mail1, mail2
  EXAMINATION_APPLICATION_FEE: '',
  EXAMINATION_UPLOAD_OTHER_DOCUMENT: { status: '', label: '' },
  //Terms
  consentBodyTextEN: '',
  consentBodyTextTH: '',
  TERMS_AND_CONDITIONS_EN: '',
  TERMS_AND_CONDITIONS_TH: '',
}

export const initialState = {
  tab: 'GENERAL',
  body: initForm,
  isInit: false,
  isLoading: false,
}
