import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledFooterWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  bottom: 0,
  background: theme.palette?.primary?.main,
  zIndex: 999,
}))

export const StyledContainer = styled(Row)(({ theme }) => ({
  gap: theme.spacing(5),
  padding: `${theme.spacing(3)} 0`,
  justifyContent: 'space-between',
  '#cover-image': { objectFit: 'cover', width: 139, minWidth: 139, height: 89 },
  '& > .MuiBox-root:first-of-type': { gap: theme.spacing(5) },
  '& > .MuiBox-root:last-of-type': { minWidth: 440 },
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(2),
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    '#cover-image': { width: 108, minWidth: 108, height: 74 },
    '& > .MuiBox-root:first-of-type': { gap: theme.spacing(2) },
    '& > .MuiBox-root:last-of-type': { minWidth: '40%' },
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    '& > .MuiBox-root:first-of-type': { display: 'none' },
    '& > .MuiBox-root:last-of-type': { minWidth: '100%' },
  },
}))
