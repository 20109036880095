import React, { useEffect } from 'react'
import _ from 'lodash'
import Template from '../../components/Template'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'

import Details from './Details'
import MySkill from './MySkill'
import ToDoList from './ToDoList'
import Calendar from './Calendar'
import Info from './Info'

import { BoxContent, BoxLabel, BoxGroup } from './Styled'
import { fetchData } from './events'
import RedeemableCatalog from './RedeemableCatalog'

export const LabelHeaderCard = ({ label, isIcon, handleClick }) => {
  return (
    <BoxLabel isIcon={isIcon}>
      <Typography variant="h6" color="primary.main">
        {label}
      </Typography>
      {isIcon ? (
        <HistoryTwoTone
          color="primary"
          sx={{ cursor: 'pointer', width: 20, height: 20 }}
          onClick={handleClick}
        />
      ) : (
        <ArrowForwardOutlined
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={handleClick}
        />
      )}
    </BoxLabel>
  )
}

const Index = () => {
  const [user] = useLocalStorage('user')
  const dispatch = useDispatch()
  const { bodyCalendar } = useSelector((state) => state.myProfile, shallowEqual)

  useEffect(() => {
    dispatch(fetchData(bodyCalendar))
  }, [bodyCalendar])

  return (
    <Template maxWidth={1440}>
      <BoxContent>
        <BoxGroup>
          <Details />
          <MySkill />
          {_.get(user, 'userType', 'STAFF') === 'AGENT' && (
            <RedeemableCatalog />
          )}
        </BoxGroup>
        <BoxGroup>
          {_.get(user, 'userType', 'STAFF') === 'AGENT' && <Info />}
          <ToDoList />
          <Calendar />
        </BoxGroup>
      </BoxContent>
    </Template>
  )
}

export default Index
