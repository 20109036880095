import Step from '@mui/material/Step'
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import styled from '@mui/system/styled'

export const StyledStep = styled(Step)(({ theme }) => ({
  '.MuiStepLabel-label': { marginTop: theme.spacing(1) },
  '.MuiStepConnector-root': {
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    '.MuiStepLabel-label': { fontSize: 14 },
    '.MuiStepConnector-root': {
      left: 'calc(-50% + 20px)',
      right: 'calc(50% + 20px)',
    },
  },
}))

export const StyledStepIcon = styled('div')(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 44,
  height: 44,
  position: 'relative',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette?.other?.divider,
  color: theme.palette?.text?.lightGray,
  ...(ownerState.inactive && {
    color: theme.palette?.text?.lightGray,
  }),
  ...(ownerState.active && {
    color: theme.palette?.primary?.main,
    background: 'transparent',
    border: '3px solid',
    borderColor: theme.palette?.primary?.main,
  }),
  ...(ownerState.completed && {
    color: theme.palette?.text?.white,
    background: theme.palette?.primary?.main,
  }),
  '& .done': { position: 'absolute', right: 0, top: 0 },
  [theme.breakpoints.down('sm')]: {
    width: 30,
    height: 30,
    '& .done': { right: -4, top: -4 },
  },
}))

export const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20.5,
    [theme.breakpoints.down('sm')]: { top: 12.5 },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette?.primary?.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette?.primary?.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette?.blue?.blueLine,
  },
}))
