import React from 'react'
import AdditionalInfoConsent from 'src/modules/Document/AdditionalInfo/Consent'
import AdditionalInfoConsentRegister from 'src/modules/Document/AdditionalInfo/ConsentAndRegister'
import { getQueryParams } from 'src/utils/lib'

const AdditionalInfoConsentPage = () => {
  const id = getQueryParams('id')

  return (
    <>{id ? <AdditionalInfoConsentRegister /> : <AdditionalInfoConsent />}</>
  )
}
export default AdditionalInfoConsentPage
