import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const ProgressContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '17px',
  alignItems: 'center',
  margin: '24px',
  height: '40px',
}))

export const ProgressDateBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
}))

export const ProgressCircle = styled(Box)(({ theme, isLatest }) => ({
  width: isLatest ? '11px' : '7px',
  height: isLatest ? '11px' : '7px',
  backgroundColor: isLatest
    ? theme.palette?.primary?.main
    : theme.palette?.grey?.grey400,
  borderRadius: '50%',
  marginLeft: isLatest && '2px',
}))

export const ProgressDivider = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '1px',
  height: '70px',
  zIndex: -1,
  left: '50%',
  right: '50%',
  bottom: 0,
  backgroundColor: theme.palette?.grey?.grey400,
}))

export const ProgressCircleContainer = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  width: '15px',
}))
