import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxContent = styled(Box)(({ theme, isColumn }) => ({
  display: 'flex',
  flexDirection: isColumn ? 'column' : 'row',
  gap: theme.spacing(4),
  paddingLeft: 80,
  paddingRight: 80,
  paddingBottom: 60,
  paddingTop: theme.spacing(16),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(3),
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

export const BoxLabel = styled(Box)(({ theme, isIcon }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: isIcon ? '24px 24px 0px 24px' : theme.spacing(0),
}))

export const BoxGroup = styled(Box)(({ theme, isCalendar }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  gap: theme.spacing(isCalendar ? 0 : 3),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))
