import { store } from 'src/App'
import _ from 'lodash'
import {
  setFilterValue,
  setReduxValue,
  setSelectedRounds,
} from 'src/redux/slices/eExamination'

export const handleSelect =
  ({ check, value }) =>
  (dispatch) => {
    const state = store.getState()
    const selectedRounds = _.get(state.eExamination, 'selectedRounds', [])
    if (check) {
      dispatch(
        setSelectedRounds({
          value: [...selectedRounds, value],
        })
      )
    } else {
      const newSelected = _.filter(selectedRounds, (ele) => {
        return ele.uuid != value.uuid
      })
      dispatch(
        setSelectedRounds({
          value: newSelected,
        })
      )
    }

    const { date, startTime } = value
    let notAvailableTime = _.get(state.eExamination, 'notAvailableTime', {})
    let time = {}
    if (startTime == '08:30' || startTime == '10:30') {
      time = { '08:30': check, '10:30': check }
    } else {
      time = { '13:30': check, '15:30': check }
    }
    const currentValue = _.get(notAvailableTime, [date], {})
    dispatch(
      setReduxValue({
        index: 'notAvailableTime',
        value: {
          ...notAvailableTime,
          [date]: { ...currentValue, ...time },
        },
      })
    )
  }

export const loadNextPage = (page) => (dispatch) => {
  dispatch(setFilterValue({ index: 'isFetch', value: true }))
  dispatch(setFilterValue({ index: 'page', value: page }))
}

export const handleAvailableRow = (
  examinersLength,
  available,
  date,
  startTime,
  disabled
) => {
  const state = store.getState()
  const notAvailableTime = _.get(state.eExamination, 'notAvailableTime', {})
  return (
    available > 0 &&
    examinersLength <= available &&
    !disabled &&
    !_.get(notAvailableTime, `["${date}"]["${startTime}"]`, false)
  )
}
