import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme, isScrolled }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  position: isScrolled ? 'fixed' : 'absolute',
  margin: 'auto',
  top: '72px',
  padding: `0 ${theme.spacing(10)}`,
  background: theme.palette?.background?.blue,
  ...fixedHeaderResponsive(isScrolled),
  [theme.breakpoints.down('lg')]: {
    minWidth: '768px',
    padding: `0 ${theme.spacing(3)}`,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '374px',
    padding: `0 ${theme.spacing(2.5)}`,
  },
}))

export const fixedHeaderResponsive = (isScrolled) => {
  if (!isScrolled) return null

  return {
    width: '100%',
    maxWidth: '1520px',
    position: 'fixed',
    top: 71,
    left: '50%',
    transform: 'translate(-50%)',
    zIndex: 99,
  }
}

export const StyledListHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '72px',
  padding: `${theme.spacing(2)} 0`,
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(3)} 0`,
    borderBottom: '0px',
  },
}))

export const StyledRightFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  '& #btn-filter': { display: 'inline-flex' },
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'end',
  },
}))

export const StyledNotiDot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 5,
  top: 7,
  width: '6px !important',
  height: 6,
  background: theme.palette.secondary?.main,
  borderRadius: '100%',
}))
