export const consentObj = {
  consentBodyText: {
    consentMainHeader: 'นโยบายความเป็นส่วนตัว (Privacy Notice)',
    consentSubHeader: 'Privacy Notice',
  },
  TERMS_AND_CONDITIONS_: {
    consentMainHeader: 'ข้อตกลงและเงื่อนไขการใช้งาน (Terms And Conditions)',
    consentSubHeader: 'Terms And Conditions',
  },
}
