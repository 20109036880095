import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { QRCodeCanvas } from 'qrcode.react'
import { useParams } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import Link from '@mui/icons-material/Link'
import Template from 'src/components/Template'
import { Column, Row } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { StyledTooltip } from '../../Learning/Styled'
import { StyledContainer } from '../Styled'
import Stepper from '../Stepper'
import Footer from '../Footer'
import { StepTitle } from '..'
import IdCardSection from './IdCardSection'
import FaceCaptureSection from './FaceCaptureSection'
import SuccessStep from './SuccessStep'
import AlertDialog from './AlertDialog'
import { handleInitVerify } from './events'
import {
  StyledContent,
  StyledHeadWrapper,
  StyledQrCodeBox,
  StyledResponsiveRow,
} from './Styled'

const Verification = () => {
  const { id: uuid } = useParams()
  const auth = useAuth()
  const isLg = isMediaWidthMoreThan('lg')
  const activeStep = useSelector(
    (state) => state.eLearningEnroll.activeStep,
    shallowEqual
  )
  const isVerifyStep = activeStep === 2
  const isSuccessStep = activeStep === 3

  useEffect(() => {
    handleInitVerify(uuid, auth)
  }, [uuid])

  return (
    <>
      <Template noMinHeight={isSuccessStep} sx={{ marginTop: 9 }}>
        <StyledContainer>
          <Stepper activeStep={activeStep} />
          {!isLg && isVerifyStep && <Divider sx={{ borderColor: '#DBE4F1' }} />}
          {isVerifyStep && (
            <StyledContent>
              <StyledHeadWrapper>
                <StepTitle
                  isWidthAuto
                  label="กรุณาอัปโหลดเอกสารและถ่ายรูปเพื่อยืนยันตัวตน"
                />

                {isLg && <QrCodeBox />}
              </StyledHeadWrapper>

              <StyledResponsiveRow>
                <IdCardSection />

                <FaceCaptureSection />
              </StyledResponsiveRow>
            </StyledContent>
          )}
          {isSuccessStep && <SuccessStep />}
        </StyledContainer>
      </Template>

      {isVerifyStep && <Footer isVerification />}

      <AlertDialog />
    </>
  )
}

export default Verification

export const QrCodeBox = () => {
  const [open, setOpen] = useState(false)
  const pageUrl = window.location.href
  return (
    <StyledQrCodeBox>
      <Row sx={{ width: 'auto', gap: 1.5, alignItems: 'start' }}>
        <InfoOutlined color="info" sx={{ mt: 0.5 }} />
        <Typography variant="h6" sx={{ width: 300, fontWeight: 400 }}>
          สามารถใช้โทรศัพท์มือถือสแกน QR Code เพื่อเพิ่มความสะดวกในการถ่ายภาพ
          เอกสาร และสแกนใบหน้า
        </Typography>
      </Row>
      <Column
        sx={{ p: 1, pb: 0 }}
        onMouseLeave={() =>
          setTimeout(() => {
            setOpen(false)
          }, 700)
        }
      >
        <QRCodeCanvas id="qr-code" size={102} value={pageUrl} />
        <StyledTooltip
          title="คัดลอกแล้ว"
          placement="bottom"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={open}
        >
          <Button
            startIcon={<Link />}
            onClick={() => {
              navigator.clipboard.writeText(pageUrl)
              setOpen(true)
            }}
          >
            คัดลอกลิงก์
          </Button>
        </StyledTooltip>
      </Column>
    </StyledQrCodeBox>
  )
}
