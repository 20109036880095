import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CameraDialogComp from 'src/components/CameraDialog'
import { closeDialog } from 'src/redux/slices/eLearning/enroll'
import { handleOpenCamera } from '../../events'
import { handleDetectFace, handleVerifyFail } from '../events'

const CameraDialog = () => {
  const { isOpen, faceImage } = useSelector(
    (state) => state.eLearningEnroll.camera,
    shallowEqual
  )
  const dispatch = useDispatch()
  const field = 'faceImage'
  return (
    <CameraDialogComp
      isLiveness
      field={field}
      isOpen={isOpen}
      image={faceImage}
      onCapture={(livenessData) => dispatch(handleDetectFace(livenessData))}
      onOpenCamera={() => dispatch(handleOpenCamera(field))}
      onError={(error) => dispatch(handleVerifyFail(error))}
      onClose={() => dispatch(closeDialog())}
    />
  )
}

export default CameraDialog
