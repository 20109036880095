import React, { Fragment, useEffect, useState } from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'
import {
  handleLogout,
  redirectToBackend,
  redirectToLearningPath,
} from '../AppMenu/events'
import { StyledHoverLine } from '../Styled'
import { StyledHoverList, StyledLeftAppMenu, StyledListItem } from './Styled'
import { handleClickMenu, handleDialogSentMail } from './events'
import useLocalStorage from '@rehooks/local-storage'
import { useAuth } from 'react-oidc-context'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { checkSentMailPosoect } from '../'
import { menuList, noLoginMenuList, permitMenu } from '../model'
import { path } from 'src/constants/path'

export default function RightAppMenu({ open, setOpen, isLogin }) {
  const dispatch = useDispatch()
  const { alertProspect, coursePosition } = useSelector(
    (state) => ({
      alertProspect: state.manageProspectEnrollment.alertProspect,
      coursePosition: state.user.coursePosition,
    }),
    shallowEqual
  )
  const [type] = useLocalStorage('type')
  const auth = useAuth()
  const [user] = useLocalStorage('user')
  const userType = _.get(user, 'userType', 'STAFF')
  const agentRole = _.get(user, 'agentRole', '-')
  const abbreviation = _.get(user, 'distributionChannel.abbreviation', '')

  const checked = checkSentMailPosoect(alertProspect)
  const [isOnPosition, setOnPosition] = useState(false)
  const staffType = _.get(user, 'staffProfile.type', null)
  const nonAcademy = staffType === 'NON_ACADEMY'
  const isStaff = userType === 'STAFF' && !nonAcademy

  const handleScroll = () => {
    if (!_.isEmpty(window.location.hash)) {
      const position = window.pageYOffset
      setOnPosition(position >= coursePosition)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [coursePosition])

  return (
    <StyledLeftAppMenu open={open}>
      <nav aria-label="right-app-menu">
        <StyledHoverList>
          {isLogin ? (
            <>
              {menuList.map((menu, index) => {
                if (menu.isDesk || menu.type === 'menu') {
                  return <Fragment key={index}></Fragment>
                }
                return (
                  <ListItems
                    key={index}
                    href={menu.href}
                    onClick={menu.onClick}
                    isOnPosition={menu.isPositionCheck && isOnPosition}
                    setOpen={menu.label === 'หลักสูตรแนะนำ' ? setOpen : null}
                  >
                    {menu.label}
                  </ListItems>
                )
              })}
              <ListItems href="/my-profile">โปรไฟล์</ListItems>
              {userType === permitMenu.agent &&
                agentRole === permitMenu.manager &&
                (abbreviation === permitMenu.agc ||
                  abbreviation === permitMenu.cs) && (
                  <ListItems href="/manage-prospect">โปรไฟล์ทีม</ListItems>
                )}
              <ListItems href="/my-class">คลาสของฉัน</ListItems>
              <ListItems
                data-testid="my-learning-path-menu"
                href={path.learningPath}
              >
                เส้นทางการเรียนรู้ของฉัน
              </ListItems>
              <ListItems
                data-testid="collection-menu"
                href={'/e-learning/collection'}
              >
                Collection ทั้งหมด
              </ListItems>
              <ListItems href="/catalog">ของรางวัลทั้งหมด</ListItems>
              {isStaff && (
                <ListItems onClick={() => redirectToBackend()}>
                  Switch to Admin View
                </ListItems>
              )}
              <ListItems
                onClick={() =>
                  checked
                    ? dispatch(handleDialogSentMail())
                    : dispatch(handleLogout(auth, type))
                }
              >
                ออกจากระบบ
              </ListItems>
            </>
          ) : (
            noLoginMenuList.map((menu, index) => (
              <ListItems key={index} href={menu.href}>
                {menu.label}
              </ListItems>
            ))
          )}
          <StyledHoverLine id="line" vertical />
        </StyledHoverList>
      </nav>
    </StyledLeftAppMenu>
  )
}

export const ListItems = ({
  children,
  href,
  onClick,
  isOnPosition,
  setOpen,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const alertProspect = useSelector(
    (state) => state.manageProspectEnrollment.alertProspect,
    shallowEqual
  )
  const fullPath = window.location.href.replace(window.location.origin, '')
  const isActive =
    (window.location.pathname === href && !isOnPosition) ||
    (fullPath === href && isOnPosition)
  const checked = checkSentMailPosoect(alertProspect)
  if (checked) href = null

  return (
    <StyledListItem active={isActive ? 1 : 0}>
      <ListItemButton
        onClick={() => {
          if (checked) {
            dispatch(handleDialogSentMail())
            return
          }

          setOpen && setOpen(false)
          if (href === path.learningPath) {
            dispatch(redirectToLearningPath(history))
          } else handleClickMenu(href, onClick)
        }}
      >
        <Typography variant={isActive ? 'body1b' : 'body1'}>
          {children}
        </Typography>
      </ListItemButton>
    </StyledListItem>
  )
}
