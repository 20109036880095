import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const StyledDashedBox = styled(Column)(({ theme, disabled }) => ({
  opacity: disabled ? 0.5 : 1,
  border: '2px dashed',
  borderColor: theme.palette?.blue?.blueBorder,
  padding: theme.spacing(4),
  gap: theme.spacing(4),
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    '& > img': { minWidth: 254, width: 254, height: 160 },
  },
}))
