import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { StyledDrawer } from './Styled'
import { handleFilter } from '../events'

import { setBodyCalendar } from '../../../../../redux/slices/myProfile'
const FilterDrawer = () => {
  const { isDrawer, bodyCalendar } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )
  const dispatch = useDispatch()

  return (
    <StyledDrawer open={isDrawer} onClose={() => dispatch(handleFilter())}>
      <Box id="header">
        <Typography variant="h5">ตัวกรอง</Typography>
        <IconButton
          data-testid="btn-close"
          color="primary"
          onClick={() => dispatch(handleFilter())}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider id="divider" />
      <Box id="body" sx={{ mx: 3 }}>
        <Typography variant="h6">ประเภทตารางงาน</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={
              <Checkbox
                value="myClass"
                name="myClass"
                onClick={(e) =>
                  dispatch(
                    setBodyCalendar({ key: 'myClass', value: e.target.checked })
                  )
                }
              />
            }
            label="My Class"
            checked={bodyCalendar.myClass}
          />

          <FormControlLabel
            control={
              <Checkbox
                value="closed"
                name="closed"
                onClick={(e) =>
                  dispatch(
                    setBodyCalendar({
                      key: 'closed',
                      value: e.target.checked,
                    })
                  )
                }
              />
            }
            label="Closed"
            checked={bodyCalendar.closed}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="cancelClass"
                name="cancelClass"
                onClick={(e) =>
                  dispatch(
                    setBodyCalendar({
                      key: 'cancelClass',
                      value: e.target.checked,
                    })
                  )
                }
              />
            }
            label="Cancelled"
            checked={bodyCalendar.cancelClass}
          />
        </Box>
      </Box>
    </StyledDrawer>
  )
}

export default FilterDrawer
