import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette?.background?.blue,
}))

export const StyledContainer = styled(Row)(({ theme }) => ({
  margin: '46px 0',
  alignItems: 'start',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(5),
    flexDirection: 'column-reverse',
    alignItems: 'center',
    padding: '0 36px',
    gap: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 10,
    marginBottom: 30,
    padding: `0 ${theme.spacing(2)}`,
    gap: theme.spacing(2),
  },
}))
