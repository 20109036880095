export const listBreadcrumbs = [
  {
    isLink: true,
    color: 'text.secondary',
    label: 'หลักสูตรทั้งหมด',
    link: '/course',
  },
  {
    isLink: false,
    color: 'primary',
    label: 'รายละเอียดหลักสูตร',
  },
]

export const TAB_VALUE = {
  CONTENT: 'CONTENT',
  GET_READY: 'GET_READY',
}
