export const initialState = {
  isChecked: false,
  isSuccess: false,
  alertProspect: false,
  optionCourse: [],
  selected: [],
  course: {
    course: null,
    trainingPlan: null,
  },
  prospects: [],
  prospectAddDrawerList: [],
}
