import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'
import { keyframes } from '@mui/system'

export const StyledWaitingBox = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(14)} 0`,
  width: 717,
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background?.paper,
  filter: 'drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.2))',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: `${theme.spacing(14)} ${theme.spacing(4)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(4)} 4px`,
    margin: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
  },
}))

export const StyledLoadingProgress = styled(Row)(({ theme }) => ({
  width: 528,
  height: 39,
  background: theme?.palette?.text?.mercury,
  gap: '2px',
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('sm')]: { width: 300, height: 30 },
}))

const blink = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
`
export const StyledProgressBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  '&:last-of-type > .progress': { borderRadius: '0 8px 8px 0' },
  '&:first-of-type > .progress': { borderRadius: '8px 0 0 8px' },
}))

export const StyledProgressLoad = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 0,
  height: '100%',
  '&.current': { width: 0, transition: 'all 0.2s cubic-bezier(0, 0, 0.2, 1)' },
  '&.active.current': { width: '100%' },
  '&.active': {
    width: '100%',
    background: theme?.palette?.primary?.light,
    [theme.breakpoints.down('sm')]: { width: 13.07 },
  },
  '&.active.current::before': {
    content: "url('/image/man_walking.svg')",
    display: 'block',
    width: 17,
    height: 29,
    paddingTop: theme.spacing(0.5),
    paddingLeft: '3px',
    animation: `${blink} 0.5s infinite ease`,
    [theme.breakpoints.down('sm')]: {
      width: 5,
      padding: 0,
      transform: 'scale(0.65)',
    },
  },
}))
