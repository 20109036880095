import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledBox = styled(Row)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  justifyContent: 'center',
  boxShadow: '0px 12px 40px 0px rgba(73, 118, 186, 0.10)',
  '& .MuiLink-root': { cursor: 'pointer' },
  [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) },
}))
