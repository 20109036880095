export const mediaType = {
  E_TESTING: 'E_TESTING',
  E_EVALUATION: 'E_EVALUATION',
  EMBED_CONTENT: 'EMBED_CONTENT',
  LINK: 'LINK',
  HTML: 'HTML',
  LINK_TO: 'LINK_TO_ANOTHER_WEBSITE',
  UPLOAD_FILE: 'UPLOAD_FILE',
  SCORM: 'SCORM',
}

export const EMBED_TYPE = {
  LINK: 'LINK',
  HTML: 'HTML',
}