import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip, TextField, styled } from '@mui/material'

export default function SelectGroupChip(props) {
  const {
    sx,
    boxSx,
    labelText,
    required,
    id,
    name,
    disabled,
    textError,
    showTextError = false,
    options,
    handleChange,
    value,
    disableCloseOnSelect = true,
    renderOption,
    customChiplabel,
  } = props
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="body2"
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <Autocomplete
        data-testid={id}
        id={id}
        name={name}
        sx={sx}
        options={options}
        getOptionLabel={(option) => option?.label}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <StyledChip
              {...getTagProps({ index })}
              label={customChiplabel ? customChiplabel(option) : option.label}
              key={index}
            />
          ))
        }}
        disabled={disabled}
        renderInput={(params) => <TextField {...params} />}
        renderOption={
          renderOption && ((props, option) => renderOption(props, option))
        }
        onChange={handleChange}
        value={value}
        multiple
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disableCloseOnSelect={disableCloseOnSelect}
        filterSelectedOptions={true}
      />

      {showTextError && (
        <>
          {textError ? (
            <Typography sx={{ height: '20px' }} variant="body2" color="error">
              {textError}
            </Typography>
          ) : (
            <Box sx={{ height: '20px' }}>{textError}</Box>
          )}
        </>
      )}
    </Box>
  )
}

const StyledChip = styled(Chip)(() => ({
  background: 'unset',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  fontSize: '17px',
  lineHeight: '18px',
  color: '#333333',
  ['& .MuiChip-label']: {
    display: 'flex',
    flexDirection: 'row',
  },
}))
