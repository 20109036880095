import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Template from 'src/components/Template'

import { fetchCourseBatchDetail } from './events'
import Header from '../Header'
import {
  StyledCard,
  StyledLabelIcon,
  StyledRowFooter,
  StyledRow,
  StyledLits,
  StyledFooter,
  StyledContainer,
} from './Styled'
import { isMediaWidthMoreThan, convertDateToThaiString } from 'src/utils/lib'
import _ from 'lodash'
import { Column, Row } from 'src/components/Layout/Styled'
import Typography from '@mui/material/Typography'
import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'

import ContainedButton from './ContainedButton'
import Divider from '@mui/material/Divider'
import ContentNoClass from 'src/components/ContentNoClass'

const CourseBatchDetail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { detail } = useSelector(
    (state) => state.courseBatchDetail,
    shallowEqual
  )

  useEffect(() => {
    dispatch(fetchCourseBatchDetail(id))
  }, [])

  const isSm = isMediaWidthMoreThan('sm')
  const isMediaLg = isMediaWidthMoreThan('lg')

  const dateRange =
    convertDateToThaiString(_.get(detail, 'periodStart', '')) +
    ' - ' +
    convertDateToThaiString(_.get(detail, 'periodEnd', ''))

  const fontWeight = isSm & isMediaLg ? 'h4' : 'h6'
  const fontSize = isSm & isMediaLg ? '38px' : '28px'
  const fontSizeSubject = isSm ? '28px' : '20px'
  const subject = _.get(detail, 'eLearningCourse.subject', '')

  const learners = _.get(detail, 'eLearningCourseBatchLearner', [])

  return (
    <>
      <Header />
      <Template noMinHeight maxWidth={'1280px'}>
        <StyledContainer>
          <StyledCard>
            <Row id="button">
              <ContainedButton />
              <Column sx={{ width: 'auto', gap: '16px' }}>
                <Typography
                  variant={fontWeight}
                  sx={{
                    fontSize: fontSize,
                  }}
                >
                  {_.get(detail, 'eLearningCourse.name', '')}
                </Typography>
                {subject && (
                  <Typography
                    sx={{
                      fontSize: fontSizeSubject,
                      color: '#5f5f5f',
                    }}
                  >
                    {'วิชา ' + subject}
                  </Typography>
                )}
              </Column>
            </Row>
          </StyledCard>
          <Divider />
        </StyledContainer>
        <StyledFooter>
          <StyledRowFooter>
            <StyledLabelIcon>
              {<img src="/icon/vector.svg" fontSize="24px" />}
              <Typography
                width={274}
                maxWidth={274}
                variant="body1"
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {'ประเภทหลักสูตร: ระดับสูง'}
              </Typography>
            </StyledLabelIcon>
            <StyledLabelIcon>
              {<img src="/icon/vector.svg" fontSize="24px" />}
              <Typography
                width={172}
                maxWidth={172}
                variant="body1"
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {' รอบอบรมที่ '}
                {_.get(detail, 'sequence', 1)}
              </Typography>
            </StyledLabelIcon>
            <StyledLabelIcon>
              <InsertInvitationOutlined
                color="text.secondary"
                fontSize="24px"
              />
              <Typography
                maxWidth={300}
                variant={fontSizeSubject}
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {dateRange}
              </Typography>
            </StyledLabelIcon>
          </StyledRowFooter>
          <Typography
            variant={'h6'}
            sx={{
              fontSize: '24px',
              color: '#333333',
            }}
          >
            {'รายชื่อผู้มีสิทธิ์อบรม'}
          </Typography>
          <StyledRow>
            <StyledLabelIcon>
              <Typography
                variant={'body1b'}
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {'ลำดับ'}
              </Typography>
            </StyledLabelIcon>
            <StyledLabelIcon>
              <Typography
                variant={'body1b'}
                sx={{
                  fontSize: '20px',
                  color: '#333333',
                }}
              >
                {'ชื่อผู้มีสิทธิ์อบรม'}
              </Typography>
            </StyledLabelIcon>
          </StyledRow>
          <Column>
            {learners.length > 0 ? (
              learners.map((item, index) => {
                const prefixTH = _.get(item, 'userProfile.prefixTH', '')
                return (
                  <>
                    <StyledLits index={index + 1}>
                      <StyledLabelIcon>
                        <Typography
                          variant={'body1'}
                          width={'33px'}
                          sx={{
                            fontSize: '20px',
                            color: '#333333',
                          }}
                        >
                          {index + 1}
                        </Typography>
                      </StyledLabelIcon>
                      <StyledLabelIcon>
                        <Typography
                          variant={'body1'}
                          sx={{
                            fontSize: '20px',
                            color: '#333333',
                          }}
                        >
                          {(prefixTH ? `${prefixTH} ` : '') +
                            _.get(item, 'userProfile.firstNameTH', '') +
                            ' ' +
                            _.get(item, 'userProfile.lastNameTH', '')}
                        </Typography>
                      </StyledLabelIcon>
                    </StyledLits>
                  </>
                )
              })
            ) : (
              <ContentNoClass />
            )}
          </Column>
          <Typography
            variant={'body1'}
            marginBottom={'64px'}
            marginTop={'16px'}
          >
            {
              'หากท่านมีข้อสงสัย/คำถามเพิ่มเติม สามารถติดต่อแอดมินได้ที่ Line: @axa-academy'
            }
          </Typography>
        </StyledFooter>
      </Template>
    </>
  )
}

export default CourseBatchDetail
