import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Typography, IconButton, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { addExaminer, setExaminerDrawer } from 'src/redux/slices/eExamination'
import { StyledDrawer } from './Styled'
import { Row } from 'src/components/Layout/Styled'
import AddExaminerTableMobile from './AddExaminerTableMobile'
import DrawerLoading from '../../DrawerLoading'

const AddEaxminerDrawerMobile = () => {
  const dispatch = useDispatch()
  const { examinerDrawer } = useSelector(
    (state) => ({
      examinerDrawer: state.eExamination.examinerDrawer,
    }),
    shallowEqual
  )
  return (
    <StyledDrawer
      open={examinerDrawer}
      onClose={() => dispatch(setExaminerDrawer(false))}
    >
      <DrawerLoading />
      <Row
        sx={{
          justifyContent: 'space-between',
          px: 2,
        }}
      >
        <Typography variant="h6">เพิ่มรายชื่อ</Typography>
        <IconButton onClick={() => dispatch(setExaminerDrawer(false))}>
          <CloseIcon sx={{ color: '#00008F' }} />
        </IconButton>
      </Row>
      <AddExaminerTableMobile />
      <Row
        sx={{
          mt: 'auto',
          justifyContent: 'center',
          columnGap: 3,
          pt: 2,
          px: 2,
        }}
      >
        <Button
          sx={{ width: 'calc(50% - 12px)' }}
          variant="outlined"
          onClick={() => dispatch(setExaminerDrawer(false))}
        >
          ยกเลิกดำเนินการ
        </Button>
        <Button
          sx={{ width: 'calc(50% - 12px)' }}
          variant="contained"
          onClick={() => dispatch(addExaminer())}
        >
          ตกลง
        </Button>
      </Row>
    </StyledDrawer>
  )
}
export default AddEaxminerDrawerMobile
