import React from 'react'
import { Divider, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'
import { Column, Row } from 'src/components/Layout/Styled'
import { progressTitle } from '../constant'

const ProgressBar = () => {
  const { progress } = useSelector(
    (state) => ({
      progress: state.eExamination.progress,
    }),
    shallowEqual
  )
  return (
    <Row sx={{ my: 4, alignItems: 'normal' }}>
      {_.map(progressTitle, (ele, ind) => {
        const active = progress >= ind
        return (
          <React.Fragment key={ind}>
            {ind > 0 && (
              <Divider
                sx={{
                  width: { md: '23%', sm: '12.5%', xs: '29%' },
                  borderTop: '2px solid',
                  borderBottom: '0',
                  borderColor: active ? '#00008F' : '#DBE4F1',
                  mt: { sm: '38px', xs: '30px' },
                }}
              />
            )}
            <Progress title={ele} index={ind + 1} active={active} />
          </React.Fragment>
        )
      })}
    </Row>
  )
}

const Progress = ({ title, index, active }) => {
  return (
    <Column
      sx={{
        rowGap: 1,
        width: { md: '18%', sm: '25%', xs: '14%' },
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          typography: { sm: 'h5', xs: 'body1b' },
          width: { sm: 38, xs: 30 },
          lineHeight: { sm: '38px', xs: '30px' },
          borderRadius: '50%',
          background: active ? '#00008F' : '#00000011',
          textAlign: 'center',
        }}
        color={active ? 'white.main' : 'text.lightGray'}
      >
        {index}
      </Typography>
      <Typography
        sx={{
          typography: { sm: 'body1', xs: 'body3' },
          textAlign: 'center',
          textWrap: { sm: 'nowrap' },
        }}
        color={active ? 'text.secondary' : 'text.lightGray'}
      >
        {title}
      </Typography>
    </Column>
  )
}

export default ProgressBar
