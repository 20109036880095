import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Card from '@mui/material/Card'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { StyledDrawer } from './Styled'
import { setOpenHistory } from 'src/redux/slices/myProfile'
import { Column, Row } from 'src/components/Layout/Styled'
import Table from 'src/components/Table'
import { handleFetchHistory } from './events'

const HistoryDrawer = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { isOpenHistory } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )
  const { table } = useSelector((state) => state.table)
  const { page, limit } = table

  useEffect(() => {
    if (isOpenHistory) {
      dispatch(handleFetchHistory({ table }))
    }
  }, [page, limit, isOpenHistory])
  return (
    <StyledDrawer
      open={isOpenHistory}
      onClose={() => dispatch(setOpenHistory())}
    >
      <Column sx={{ position: 'relative' }}>
        <Row
          sx={{
            p: 3,
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            backgroundColor: theme?.palette?.background?.paper,
            zIndex: 2,
          }}
        >
          <Typography variant="h5">
            ประวัติการเปลี่ยนแปลงคะแนนการอบรม
          </Typography>
          <IconButton
            data-testid="btn-close"
            color="primary"
            onClick={() => dispatch(setOpenHistory())}
          >
            <CloseIcon />
          </IconButton>
        </Row>
        <Divider />
        <Card sx={{ m: 3, overflowY: 'auto' }}>
          <Table />
        </Card>
      </Column>
    </StyledDrawer>
  )
}

export default HistoryDrawer
