import React from 'react'
import { Dialog, IconButton } from '@mui/material'
import { DialogImage } from './styled'
import CloseIcon from '@mui/icons-material/Close'

export const ImageDialog = (props) => {
  const { onClose, selectedValue, isOpen } = props
  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        sx: {
          minWidth: 'fit-content',
          width: 'fit-content',
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#00008f',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogImage src={selectedValue} alt="image"></DialogImage>
    </Dialog>
  )
}
