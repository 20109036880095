import _ from 'lodash'
import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import ListAlt from '@mui/icons-material/ListAlt'
import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { convertDateToThaiString, isMediaWidthMoreThan } from 'src/utils/lib'
import { redirectToLearningPath } from 'src/components/Appbar/AppMenu/events'
import { StyledTypography } from 'src/components/CourseCard/Styled'
import { Column, Row, StyledContainer } from '../../../components/Layout/Styled'
import Breadcrumb from '../../../components/Breadcrumb'
import Template from '../../../components/Template'
import ContentTitle from '../ELearningRegistrationDetail/components/ContentTitle'
import { handleModuleResult } from '../ELearningRegistrationDetail/ClassContent/events'
import {
  CertContainer,
  CertDetailContainer,
  StyledCardBoxPreview,
} from '../Grades/Styled'
import { LayoutPreview } from '../Grades/events'
import { StyledDateTime, StyledDetail } from '../Header/Styled'
import { fetchLearningPathGrade, onDownload } from './events'

const LearningPathGrades = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const history = useHistory()
  const isWidthLg = isMediaWidthMoreThan('lg')
  const data = useSelector((state) => state.registration.data, shallowEqual)
  const eCertification = _.get(data, 'eCertificationVersion', '')
  const eCertExpireDate = _.get(data, 'certificationExpireDate', '')
  const isCanDownloadCert = _.get(data, 'isCanDownloadCert', false)
  const eCertText = isCanDownloadCert ? 'ดาวน์โหลดประกาศนียบัตร' : 'หมดอายุแล้ว'
  const learningPath = _.get(data, 'eLearningLearningPath', {})
  const periodStart = _.get(learningPath, 'periodStart', '')
  const dateRange = convertDateToThaiString(periodStart)
  const hours = _.get(learningPath, 'totalHour', '')
  const minutes = _.get(learningPath, 'totalMinute', '') || null
  const learningPathCourses = _.get(
    learningPath,
    'eLearningLearningPathCourses',
    []
  )

  useEffect(() => {
    dispatch(fetchLearningPathGrade(uuid))
  }, [uuid])

  const listBreadcrumbs = [
    {
      isLink: true,
      color: 'text.secondary',
      label: 'เส้นทางการเรียนรู้ของฉัน',
      onClick: () => dispatch(redirectToLearningPath(history)),
    },
    {
      isLink: false,
      color: 'primary',
      label: 'ประกาศนียบัตร',
    },
  ]

  return (
    <Template>
      <StyledContainer>
        <Breadcrumb list={listBreadcrumbs} />
        <Box>
          <Typography
            variant={isWidthLg ? 'h3' : 'h4'}
            sx={{ wordWrap: 'break-word' }}
          >
            {_.get(learningPath, 'nameForBanner', '') ||
              _.get(learningPath, 'name', '')}
          </Typography>
          <StyledDetail>
            <StyledDateTime>
              <Row sx={{ width: 'auto', gap: 1 }}>
                <InsertInvitationOutlined fontSize="small" />
                <Typography variant="body1">{dateRange}</Typography>
              </Row>
              <Divider orientation="vertical" flexItem />
              <Row sx={{ width: 'auto', gap: 1 }}>
                <TimerOutlined fontSize="small" />
                <Typography variant="body1">
                  {hours} ชั่วโมง {minutes && `${minutes} นาที`}
                </Typography>
              </Row>
            </StyledDateTime>
          </StyledDetail>
        </Box>

        {!_.isEmpty(eCertification) && (
          <CertContainer>
            <StyledCardBoxPreview>
              <LayoutPreview version={eCertification} />
            </StyledCardBoxPreview>
            <CertDetailContainer>
              <Typography variant="h6" color="primary">
                ยินดีด้วย! คุณได้รับประกาศนียบัตร
              </Typography>
              <Button
                startIcon={<CloudDownloadIcon />}
                variant="contained"
                disabled={!isCanDownloadCert}
                onClick={() => dispatch(onDownload(uuid))}
              >
                {eCertText}
              </Button>
              {eCertExpireDate && (
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ margin: '0px auto' }}
                >
                  หมดอายุ: {eCertExpireDate}
                </Typography>
              )}
            </CertDetailContainer>
          </CertContainer>
        )}

        <Column>
          <ContentTitle icon={<ListAlt color="primary" />} title="หลักสูตร" />
          {learningPathCourses.map((course, index) => (
            <CourseDetailList key={index} data={course} />
          ))}
        </Column>
      </StyledContainer>
    </Template>
  )
}

export default LearningPathGrades

export const CourseDetailList = ({ data }) => {
  const eLearningCourse = _.get(data, 'eLearningCourse', {})
  const courseVersion = _.get(data, 'courseVersion', {})
  const resultObj = handleModuleResult(data)
  return (
    <Row sx={{ py: '14px', px: 2, gap: '10px' }}>
      <Column sx={{ gap: 0.5 }}>
        <Row sx={{ justifyContent: 'space-between', gap: 1 }}>
          <StyledTypography sx={{ WebkitLineClamp: '1' }}>
            {_.get(eLearningCourse, 'name', '') ||
              _.get(courseVersion, 'nameForLearner', '') ||
              _.get(courseVersion, 'englishName', '')}
          </StyledTypography>
          <Typography variant="body1b" color={resultObj.color}>
            {resultObj.text}
          </Typography>
        </Row>
        <Typography variant="body2" color="text.gray">
          {_.get(data, 'type', '')}
        </Typography>
      </Column>
    </Row>
  )
}
