import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { StyledDrawer } from '../../Styled'
import { addExaminer, setExaminerDrawer } from 'src/redux/slices/eExamination'
import { Row } from 'src/components/Layout/Styled'
import { Button, Typography } from '@mui/material'
import AddExaminerTable from './AddExaminerTable'
import DrawerLoading from '../../DrawerLoading'

const AddExaminerDrawer = () => {
  const dispatch = useDispatch()
  const { examinerDrawer } = useSelector(
    (state) => ({
      examinerDrawer: state.eExamination.examinerDrawer,
    }),
    shallowEqual
  )
  return (
    <StyledDrawer
      open={examinerDrawer}
      onClose={() => dispatch(setExaminerDrawer(false))}
    >
      <DrawerLoading />
      <Typography variant="h5" color="primary.main">
        เพิ่มรายชื่อ
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        กรุณาเลือกรายชื่อเพิ่มรายชื่อจากหน้าโปรไฟล์ทีมของคุณ
      </Typography>
      <AddExaminerTable />
      <Row sx={{ mt: 'auto', justifyContent: 'center', columnGap: '20px' }}>
        <Button
          sx={{ height: 50, width: 'calc(50% - 10px)' }}
          variant="outlined"
          onClick={() => dispatch(setExaminerDrawer(false))}
        >
          ยกเลิกดำเนินการ
        </Button>
        <Button
          sx={{ height: 50, width: 'calc(50% - 10px)' }}
          variant="contained"
          onClick={() => dispatch(addExaminer())}
        >
          ตกลง
        </Button>
      </Row>
    </StyledDrawer>
  )
}

export default AddExaminerDrawer
