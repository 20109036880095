import React, { useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { getQueryParams } from 'src/utils/lib'
import {
  StyledContainer,
  StyledResultContent,
} from 'src/components/Preview/Result/Styled'
import BannerShowResult from './BannerShowResult'
import BannerFinish from './BannerFinish'
import { handleGetScore } from './events'

const Result = ({ isModule }) => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const trainingPlan = getQueryParams('class')
  const dispatch = useDispatch()
  const { testResult, isTimeUp, isETestingOpen, error, isShowScoreNow } =
    useSelector(
      (state) => ({
        testResult: state.eTesting.testResult,
        isTimeUp: state.eTesting.isTimeUp,
        isETestingOpen: state.eTesting.isETestingOpen,
        error: state.eTesting.error,
        isShowScoreNow: _.get(state.eTesting.result, 'isShowScoreNow', false),
      }),
      shallowEqual
    )
  const payload = { uuid, trainingPlan, error, history }

  useEffect(() => {
    dispatch(handleGetScore(payload, isModule))
  }, [])

  if (error) {
    return (
      <StyledContainer>
        {isModule ? <BannerTimeOut /> : <LinkBack />}
      </StyledContainer>
    )
  }

  if (!isETestingOpen) {
    return (
      <StyledContainer>
        <BannerNotOpen />
      </StyledContainer>
    )
  }

  return (
    <StyledContainer>
      {testResult === 'NOW' || isShowScoreNow ? (
        <BannerShowResult />
      ) : (
        <BannerFinish />
      )}
      {!isTimeUp && !isModule && <LinkBack />}
    </StyledContainer>
  )
}

export default Result

export const BannerNotOpen = () => {
  return (
    <StyledResultContent>
      <img src="/icon/main_error.svg" />
      <Typography variant="h3" color="error">
        แบบทดสอบนี้ยังไม่เปิดใช้งาน
      </Typography>
      <Typography
        variant="h5"
        color="text.secondary"
        sx={{ width: 400, textAlign: 'center' }}
      >
        แบบทดสอบนี้ยังไม่ไม่ถึงเวลาทำแบบทดสอบ
      </Typography>
      <Typography
        variant="h5"
        color="text.secondary"
        sx={{ width: 246, textAlign: 'center' }}
      >
        กรุณารอให้ถึงเวลา
      </Typography>
    </StyledResultContent>
  )
}

export const BannerTimeOut = () => {
  return (
    <StyledResultContent>
      <img src="/icon/main_error.svg" />
      <Typography variant="h3" color="error">
        หมดเวลาทำแบบทดสอบ
      </Typography>
      <Typography
        variant="h5"
        color="text.secondary"
        sx={{ width: 400, textAlign: 'center' }}
      >
        แบบทดสอบนี้หมดเวลาแล้ว
      </Typography>
      <Typography
        variant="h5"
        color="text.secondary"
        sx={{ width: 246, textAlign: 'center' }}
      >
        กรุณาติดต่อเจ้าหน้าที่
      </Typography>
    </StyledResultContent>
  )
}

export const LinkBack = () => {
  const trainingPlan = getQueryParams('class')
  const mainPageLink = trainingPlan
    ? `/my-class/registration-detail/${trainingPlan}`
    : '/my-profile/standalone'
  return (
    <Link href={mainPageLink} sx={{ fontWeight: 500 }}>
      กลับหน้าหลัก
    </Link>
  )
}
