import { postLearningPathList } from 'src/services/eLearning/learningPath'
import {
  setLoadMore,
  startLoadMore,
  stopLoadMore,
} from 'src/redux/slices/eLearning/learningPath/list'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { isScrolledIntoElement } from 'src/utils/lib'

export const fetchLearningPathList = (body) => async (dispatch) => {
  dispatch(startLoading())
  try {
    await dispatch(postLearningPathList(body))
  } catch (error) {
    console.log(error)
  }
  dispatch(stopLoading())
}

export const handleLoadMore = () => (dispatch) => {
  const footer = document.getElementById('page-footer')
  const isFooterAppeared = isScrolledIntoElement(footer)
  if (isFooterAppeared) dispatch(setLoadMore())
}

export const handleSeeMore = (filterBody) => async (dispatch) => {
  if (filterBody.page == 1) return

  dispatch(startLoadMore())
  await dispatch(postLearningPathList(filterBody))
  dispatch(stopLoadMore())
}
