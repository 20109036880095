import _ from 'lodash'
import { closeDialog, setTime } from 'src/redux/slices/eLearning/enroll'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getCheckFailScanCount } from 'src/services/eLearning/enroll'
import { getEnrollState } from '../../events'

export const handleCountdown = (timer) => async (dispatch) => {
  const { mins, secs } = getEnrollState().time
  let newTime = { mins, secs }
  if (mins === 0 && secs === 0) {
    clearInterval(timer)
  } else if (secs === 0) {
    newTime.mins = mins - 1
    newTime.secs = 59
  } else {
    newTime.secs = secs - 1
  }
  dispatch(setTime(newTime))

  // time out
  if (mins === 0 && secs === 1) {
    dispatch(startLoading())
    setTimeout(async () => {
      const uuid = _.get(getEnrollState().data, 'uuid', '')
      await dispatch(getCheckFailScanCount(uuid))
      dispatch(stopLoading())
      dispatch(closeDialog())
    }, 1500)
  }
}
