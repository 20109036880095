import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledBackgroundLoading } from './Styled'

const PageLoading = () => {
  const { isLoading, isLoading2 } = useSelector(
    (state) => state.loading,
    shallowEqual
  )
  return (
    <StyledBackgroundLoading isLoading={isLoading || isLoading2}>
      <CircularProgress />
    </StyledBackgroundLoading>
  )
}

export default PageLoading
