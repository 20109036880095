import axios from 'axios'
import _ from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  prospectSubmit,
  prospect,
  prospectUploadImage,
  prospectUploadTemplate,
  prospectSubmitTemps,
  prospectCourseList,
  prospectCheckProspect,
  prospectCheckAddList,
  prospectRegisterAadTest,
  prospectCancelRegistration,
  prospectHistory,
  prospectAddToClass,
  prospectSentMail,
  prospectExamination,
  prospectExaminationHistory,
  prospectDeleteManagerExamination,
  prospectDeleteMail,
} from '../../utils/apiPath'

export const formManageProspect = createAsyncThunk(
  'formManageProspect',
  async ({ body, caseType }, { rejectWithValue }) => {
    try {
      let response = {}
      if (caseType == 'create') {
        response = await axios.post(prospectSubmit, body)
      } else {
        response = await axios.put(prospectSubmit, body)
      }
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getManageProspectById = createAsyncThunk(
  'getManageProspectById',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${prospect}/${uuid}`)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadImageProspect = createAsyncThunk(
  'uploadImageProspect',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectUploadImage, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadTemplateProspect = createAsyncThunk(
  'uploadTemplateProspect',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectUploadTemplate, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const submitUploadProspect = createAsyncThunk(
  'submitUploadProspect',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectSubmitTemps, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getProspectCourseList = createAsyncThunk(
  'getProspectCourseList',
  async (n, { rejectWithValue }) => {
    try {
      const response = await axios.get(prospectCourseList)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postProspectCheckProspect = createAsyncThunk(
  'postProspectCheckProspect',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectCheckProspect, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postProspectCheckAddList = createAsyncThunk(
  'postProspectCheckAddList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectCheckAddList, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postProspectRegisterAadTest = createAsyncThunk(
  'postProspectRegisterAadTest',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectRegisterAadTest, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postProspectExamination = createAsyncThunk(
  'postProspectExamination',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectExamination, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putProspectCancelRegistration = createAsyncThunk(
  'putProspectCancelRegistration',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(prospectCancelRegistration, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const deleteManagerExamination = createAsyncThunk(
  'deleteManagerExamination',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.delete(prospectDeleteManagerExamination, {
        data: body,
      })
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postManageProspectHistory = createAsyncThunk(
  'postManageProspectHistory',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectHistory, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postManageProspectExaminationHistory = createAsyncThunk(
  'postManageProspectHistory',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectExaminationHistory, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postProspectAddToClass = createAsyncThunk(
  'postProspectAddToClass',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectAddToClass, body)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postProspectSentMail = createAsyncThunk(
  'uploadImageProspect',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectSentMail, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postProspectDeleteMail = createAsyncThunk(
  'postProspectDeleteMail',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(prospectDeleteMail, body)
      return _.get(response, 'data', {})
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
