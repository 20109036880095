import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(9),
  background: theme.palette?.background?.blue,
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: `1px ${theme.spacing(10)} ${theme.spacing(6.5)}`,
  [theme.breakpoints.down('lg')]: {
    padding: `1px ${theme.spacing(4)} ${theme.spacing(5)}`,
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
}))

export const StyledRowContainer = styled(Row)(({ theme }) => ({
  marginTop: 94,
  alignItems: 'start',
  gap: theme.spacing(5),
  [theme.breakpoints.down('sm')]: { marginTop: 87 },
}))

export const StyledCardBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: 954,
  minWidth: 954,
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: 'auto',
  marginRight: 'auto',
  gap: theme.spacing(3),
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: { width: '100%', minWidth: '100%' },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

export const StyledLoading = styled(CircularProgress)(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: '50%',
  translate: '-50%',
  width: 24,
  height: 24,
  [theme.breakpoints.down('sm')]: { left: 0, translate: 0 },
}))

export const StyledGrid = styled((props) => (
  <Grid {...props} item xs={12} sm={4} />
))(({ theme }) => ({
  [theme.breakpoints.down('sm')]: { height: 450 },
}))
