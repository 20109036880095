import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import { Column, Row } from 'src/components/Layout/Styled'
import ExaminerInfoItem from './ExaminerInfoItem'

const ExaminerInfo = () => {
  const { examiners, selfRegister } = useSelector(
    (state) => ({
      examiners: state.eExamination.examiners,
      selfRegister: state.eExamination.selfRegister,
    }),
    shallowEqual
  )
  return (
    <Column sx={{ background: '#FAFAFA', mb: 2, p: 3, rowGap: 2 }}>
      <Row>
        <GroupsOutlinedIcon sx={{ mr: 1, fontSzie: 24, color: '#00008F' }} />
        <Typography variant="h6" color="primary.main">
          {selfRegister
            ? 'รายละเอียดข้อมูลส่วนตัว'
            : `รายละเอียดผู้สมัครสอบ (${examiners.length})`}
        </Typography>
      </Row>
      {examiners.map((ele, ind) => {
        return <ExaminerInfoItem key={ind} index={ind} />
      })}
    </Column>
  )
}

export default ExaminerInfo
