import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const HeadTable = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.primary?.main}`,
  color: theme.palette.primary?.main,
  width: '100%',
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  gap: theme.spacing(2),
}))

export const StyledImage = styled('img')(() => ({
  width: '100%',
  height: 'auto',
  maxWidth: '24px',
  maxHeight: '24px',
}))
