export const initialState = {
  tab: 'ALL',
  filter: [],
  filterBody: {
    search: '',
    startDate: '',
    endDate: '',
    limit: 20,
    order: 'DESC',
    page: 1,
    sort: 'announcementAt',
  },
  announceList: [],
  totalannounceList: 0,
  error: null,
  isLoadMore: false,
}
