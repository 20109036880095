import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import DateRangeInput from 'src/components/DateRangeInput'

import { setSearch } from 'src/redux/slices/eExamination/announceExaminer/list'
import Search from '../../../components/Search'
import { StyledFilterContainer, StyledSearchDateRow } from './Styled'
import { handleChangePeriod, handleResetPeriod } from './events'
import Typography from '@mui/material/Typography'

const FilterHeader = () => {
  const { filterBody } = useSelector(
    (state) => state.announceExaminerList,
    shallowEqual
  )

  return (
    <StyledFilterContainer>
      <Typography variant="h6" justifyContent="start">
        ประกาศผลผู้มีสิทธิ์สอบ
      </Typography>
      <SearchAndDatePicker filterBody={filterBody} />
    </StyledFilterContainer>
  )
}

export default FilterHeader

export const SearchAndDatePicker = ({ filterBody }) => {
  const dispatch = useDispatch()
  const search = _.get(filterBody, 'search', '')
  const startDate = _.get(filterBody, 'startDate', '')
  const endDate = _.get(filterBody, 'endDate', '')
  const [text, setText] = useState(search)
  const isStartDate = startDate != ''
  const isFinishDate = endDate != ''
  const defaultStart = isStartDate ? new Date(startDate) : new Date()
  const defaultFinish = isFinishDate ? new Date(endDate) : new Date()

  return (
    <StyledSearchDateRow>
      <Search
        placeholder="ค้นหาสนามหรือสถานที่สอบ"
        text={text}
        setText={(e) => setText(e)}
        handleSearch={() => dispatch(setSearch(text))}
      />
      <DateRangeInput
        placeholder="ค้นหาวันที่สอบ"
        dateState={{
          startDate: defaultStart,
          endDate: defaultFinish,
          display: isStartDate,
          key: 'selection',
        }}
        onChange={(selectedDates) =>
          dispatch(handleChangePeriod(selectedDates))
        }
        onReset={() => dispatch(handleResetPeriod())}
      />
    </StyledSearchDateRow>
  )
}
