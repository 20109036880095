import React from 'react'
import Consent from 'src/modules/Document/Consent'
import { openDialog } from 'src/redux/slices/alertDialog'

export const getLabelFromOption = ({ value, options }) => {
  if (value) {
    const findValue = options.find((item) => item.value === value)
    return findValue.label
  } else {
    return '-'
  }
}

export const handleConsent = () => (dispatch) => {
  dispatch(
    openDialog({
      type: 'custom',
      customWidth: '85%',
      content: <Consent />,
    })
  )
}
