import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ChevronRight from '@mui/icons-material/ChevronRight'
import {
  COURSE_TYPE,
  EXPIRATION_TYPE,
  TIME_SPAN_TYPE,
} from 'src/constants/eLearning'
import {
  convertFormatTime,
  calculateDateDiffToText,
  isMediaWidthMoreThan,
} from 'src/utils/lib'
import { Column, Row } from 'src/components/Layout/Styled'
import {
  StyledAssigneeImage,
  StyledAssigneeWrapper,
  StyledContainer,
  StyledDetailCard,
} from './Styled'
import { handleModuleETesting } from './events'

const RightContent = () => {
  const history = useHistory()
  const [isExpanded, setExpand] = useState(false)
  const data = useSelector((state) => state.eLearningDetail.data, shallowEqual)
  const assignees = _.get(data, 'setting.assignees', []).filter(
    (item) => item.type === 'SPEAKER'
  )
  const courseType = _.get(data, 'courseType', '')
  const displayAssignees = useMemo(
    () => (isExpanded ? assignees : _.filter(assignees, (e, i) => i < 2)),
    [assignees, isExpanded]
  )
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')
  const width = { lg: 468, md: '100%' }
  const fontSize = isLg ? 'h6' : isNotSm ? 'h5' : 'body1b'
  const announcementData = _.get(data, 'announcementData', '')
  const courseUuid = _.get(data, 'uuid', '')

  return (
    <Column sx={{ minWidth: width, width }}>
      <BoxContents data={data} />

      {assignees.length > 0 && (
        <Column sx={{ gap: 1, mb: { lg: 4, md: 4, sm: 2, xs: 2 } }}>
          <Typography variant={fontSize} color="primary">
            วิทยากรบรรยาย
          </Typography>

          <StyledAssigneeWrapper>
            {displayAssignees.map((assignee, index) => (
              <AssigneeContent key={index} data={assignee} />
            ))}
          </StyledAssigneeWrapper>

          {assignees.length > 3 && (
            <Box m="auto">
              <Button
                size={isNotSm ? 'm' : 's'}
                variant="outlined"
                onClick={() => setExpand(!isExpanded)}
              >
                {isExpanded ? 'ซ่อนวิทยากรบรรยาย' : 'ดูวิทยากรบรรยายเพิ่มเติม'}
              </Button>
            </Box>
          )}
        </Column>
      )}

      {courseType === COURSE_TYPE.OIC && !_.isEmpty(announcementData) && (
        <Column gap={1}>
          <Typography variant={fontSize} color="primary">
            ประกาศผลผู้มีสิทธิ์เรียนคลาสนี้
          </Typography>
          <StyledDetailCard fullWidth sx={{ p: 2 }}>
            <Row sx={{ gap: 1.5 }}>
              <img src="/icon/e-learning/ic_person_board.svg" />
              <Column>
                <Typography
                  variant={isNotSm ? 'body2' : 'body3'}
                  color="text.gray"
                >
                  ประกาศเมื่อ {announcementData}
                </Typography>
                <Typography variant="body1b">
                  สามารถเช็ครายชื่อได้ที่นี่
                </Typography>
              </Column>
            </Row>
            <IconButton
              data-testid="btn-class"
              onClick={() => history.push(`/learners/${courseUuid}`)}
            >
              <ChevronRight />
            </IconButton>
          </StyledDetailCard>
        </Column>
      )}
    </Column>
  )
}

export default RightContent

export const BoxContents = ({ data }) => {
  const setting = _.get(data, 'setting', {})
  const sellCourse = _.get(setting, 'sellCourse', {})
  const time = convertFormatTime({
    hours: _.get(data, 'totalHour', 0),
    minutes: _.get(data, 'totalMinute', 0),
  })
  const lessons = _.get(data, 'eLearningCourseLesson', [])
  const eTesting = handleModuleETesting(lessons)
  const courseAccessExpiration = _.get(data, 'courseAccessExpiration', '')
  const collectHours = _.get(data, 'setting.collectHours', {})
  const courseType = _.get(data, 'courseType', '')
  const isOic = courseType === COURSE_TYPE.OIC
  const assignees = _.get(data, 'setting.assignees', [])
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <StyledContainer isOnly={!isOic && assignees.length === 0}>
      {sellCourse.isSell && (
        <DetailCard
          fullWidth
          icon="ic_banknotes.svg"
          sx={{ minHeight: isNotSm ? 64 : 52 }}
        >
          ค่าใช้จ่าย{' '}
          <Typography
            variant="span"
            color="primary"
            sx={{ fontSize: '28px', fontWeight: 700 }}
          >
            {Number(
              `${_.get(sellCourse, 'cost', 0)}`.replace(',', '')
            ).toLocaleString()}
          </Typography>{' '}
          บาท
        </DetailCard>
      )}
      {isOic && (
        <>
          <DetailCard isBorder icon="ic_face_detection.svg">
            มีการถ่ายรูป
            <br />
            ยืนยันตัวตน
          </DetailCard>
          <DetailCard isBorder icon="ic_upload_file.svg">
            อัพโหลดเอกสาร
            <br />
            ยืนยันตัวตน
          </DetailCard>
        </>
      )}
      {!isOic && courseAccessExpiration === EXPIRATION_TYPE.TIME_SPAN && (
        <DetailCard
          isBorder
          id="timeSpan"
          icon="ic_screen_time.svg"
          sx={{ px: 1.5, gap: '14px' }}
        >
          เรียนให้จบภายใน {_.get(data, 'timeSpan', 0)}{' '}
          {TIME_SPAN_TYPE[_.get(data, 'timeSpanType', '')]}
          <br />
          <Typography variant="span" color="secondary">
            นับจากวันที่เริ่มอบรม
          </Typography>
        </DetailCard>
      )}
      {isOic && <LearningPeriod data={data} />}
      <DetailCard icon="ic_timer.svg">
        ความยาวทั้งหมด
        <br />
        {time}
      </DetailCard>
      <DetailCard icon="ic_book_open.svg">
        เนื้อหาทั้งหมด
        <br />
        {lessons.length} บทเรียน
      </DetailCard>
      {eTesting.length > 0 && (
        <DetailCard icon="ic_list.svg">
          แบบฝึกหัดทั้งหมด
          <br />
          {eTesting.length} แบบฝึกหัด
        </DetailCard>
      )}
      {data.hasCertificate && (
        <DetailCard icon="ic_cert.svg">ได้รับประกาศนียบัตร</DetailCard>
      )}
      {collectHours.isCollect && (
        <DetailCard icon="ic_box_check.svg">
          เก็บชั่วโมงวิชาชีพ
          <br />
          ตัวแทนประกันชีวิต
        </DetailCard>
      )}
    </StyledContainer>
  )
}

export const DetailCard = ({ id, isBorder, fullWidth, icon, children, sx }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <StyledDetailCard id={id} isBorder={isBorder} fullWidth={fullWidth} sx={sx}>
      <img src={`/icon/e-learning/${icon}`} />
      <Typography variant={isNotSm ? 'body1' : 'body2'} sx={{ width: 'auto' }}>
        {children}
      </Typography>
    </StyledDetailCard>
  )
}

export const AssigneeContent = ({ data }) => {
  const image = _.get(data, 'image', '')
  const imageSrc = `${window.__env__.REACT_APP_FILE_URL}${image}`
  const name = _.get(data, 'name', '')
    ?.replace('นางสาว', '')
    ?.replace('นาง', '')
    ?.replace('นาย', '')
    ?.trim()
  return (
    <Box>
      <StyledAssigneeImage src={imageSrc} />
      <Column>
        <Typography variant="body1b">{name}</Typography>
        <Typography>{_.get(data, 'area.station.station', '-')}</Typography>
      </Column>
    </Box>
  )
}

export const LearningPeriod = ({ data }) => {
  const eLearningCourseBatchLearner = _.get(
    data,
    'eLearningCourseBatchLearner',
    {}
  )
  const eLearningCourseBatch = _.get(
    eLearningCourseBatchLearner,
    'eLearningCourseBatch',
    {}
  )

  if (_.isEmpty(eLearningCourseBatchLearner)) return <></>

  const dateDiff = calculateDateDiffToText(
    _.get(eLearningCourseBatch, 'periodStart', ''),
    _.get(eLearningCourseBatch, 'periodEnd', '')
  )
  return (
    <DetailCard
      isBorder
      id="timeSpan"
      icon="ic_screen_time.svg"
      sx={{ px: 1.5, gap: '14px' }}
    >
      เรียนให้จบภายใน {dateDiff.value} {dateDiff.prefix}
      <br />
      <Typography variant="span" color="secondary">
        นับจากวันที่เริ่มอบรม
      </Typography>
    </DetailCard>
  )
}
