import React from 'react'
import { Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { Column } from 'src/components/Layout/Styled'
import Round from './Round'

const SelectedRound = () => {
  const { selectedRounds } = useSelector(
    (state) => ({
      selectedRounds: state.eExamination.selectedRounds,
    }),
    shallowEqual
  )
  return (
    <Column sx={{ rowGap: 1 }}>
      <Typography variant="body1">รอบที่ถูกเลือก</Typography>
      {selectedRounds.length > 0 ? (
        selectedRounds.map((ele, ind) => {
          return <Round index={ind} key={ind} />
        })
      ) : (
        <Column
          sx={{
            px: 2,
            py: 1,
            minHeight: { lg: 104, xs: 40 },
            background: '#F5F5F5',
          }}
        >
          <Typography variant="body1" color="action.disabled">
            ยังไม่มีรอบที่ถูกเลือก
          </Typography>
        </Column>
      )}
    </Column>
  )
}

export default SelectedRound
