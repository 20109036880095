import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getContentHome } from 'src/services/home'
import { initialState } from './model'

const home = createSlice({
  name: 'home',
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContentHome.fulfilled, (state, { payload }) => {
        state.data = _.get(payload, 'data', null)
      })
      .addCase(getContentHome.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { resetData } = home.actions

export default home.reducer
