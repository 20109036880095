import React from 'react'
import { Column } from 'src/components/Layout/Styled'
import { Typography } from '@mui/material'
import Summary from './Summary'
import ExaminerInfo from './ExaminerInfo'
import ConsentCheckBox from './ConsentCheckbox'

const Confirmation = () => {
  return (
    <Column>
      <Typography variant="h6" sx={{ mb: 4 }}>
        ยืนยันข้อมูลการสมัครสอบ
      </Typography>
      <Summary />
      <ExaminerInfo />
      <ConsentCheckBox />
    </Column>
  )
}

export default Confirmation
