import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { height } from '../Styled'

export const StyledLeftContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})(({ theme, isFullScreen }) => ({
  position: isFullScreen ? 'absolute' : 'relative',
  display: isFullScreen ? 'flex' : 'block',
  width: '100%',
  height,
  paddingTop: isFullScreen ? 0 : theme.spacing(12),
  margin: '0 auto',
  overflow: 'auto',
  background: isFullScreen
    ? theme.palette?.common?.black
    : theme.palette?.background?.paper,
  zIndex: isFullScreen ? 11 : 1,
}))
