import styled from '@mui/system/styled'
import { Accordion, AccordionSummary } from '@mui/material'
import { Box } from '@mui/material'

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'unset',
  margin: 0,
  background: theme.palette.background.default,
  padding: '0 20px',
  paddingBottom: theme.spacing(2),
  '&.Mui-expanded': { margin: '0 !important' },
  '::before': { opacity: 0 },
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: '0',
}))

export const StyledDivider = styled('div')(({ theme }) => ({
  width: 'calc(100% - 24px)',
  marginLeft: theme.spacing(3),
  marginTop: theme.spacing(3),
  height: '1px',
  background: 'repeating-linear-gradient(90deg, #DBE4F1 0 7px, #0000 0 12px)',
}))

export const StyledInputHidden = styled('input')({ display: 'none' })

export const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

export const StyledDashedBox = styled(Box)(({theme}) => ({
  width: '100%',
  height: '72px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text?.gray,
  backgroundImage:
    'linear-gradient(to left, #CCCCCC 50%, transparent 50%), linear-gradient(to left, #CCCCCC 50%, transparent 50%), linear-gradient(to top, #CCCCCC 50%, transparent 50%), linear-gradient(to top, #CCCCCC 50%, transparent 50%)',
  backgroundPosition: 'left top, left bottom, left top, right top',
  backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
  backgroundSize: '12.5px 1px, 12.5px 1px, 1px 12.5px, 1px 12.5px',
}))

export const StyledFileInputBox = styled(Box)(() => ({
  border: '1px solid #CCCCCC',
  padding: '10.5px 20px',
  height: 45,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}))