import styled from '@mui/system/styled'
import { TableCell, TableRow } from '@mui/material'

export const StyledTableRow = styled(TableRow)(({ theme, $available }) => ({
  background: $available
    ? theme?.palette?.background?.paper
    : theme?.palette?.action?.disabledBackground,
  '&:nth-of-type(even)': {
    background: $available
      ? '#7698CB1A'
      : theme?.palette?.action?.disabledBackground,
  },
}))

export const StyledTableCell = styled(TableCell)(({ theme, $available }) => ({
  padding: '11px 16px',
  borderColor: theme?.palette?.blue?.blueBorder,
  borderBottomWidth: 2,
  verticalAlign: 'top',
  color: $available
    ? theme?.palette?.text?.primary
    : theme?.palette?.text?.lightGray,
}))
