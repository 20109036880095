import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Typography, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Column, Row } from 'src/components/Layout/Styled'
import { setSideBarDrawer } from 'src/redux/slices/eExamination'
import { StyledDivider } from '../../Styled'
import SelectedRound from '../SelectedRound'
import Examiner from '../Examiner'
import { StyledDrawer } from './Styled'

const DetailSideBarDrawer = () => {
  const dispatch = useDispatch()
  const { sideBarDrawer } = useSelector(
    (state) => ({
      sideBarDrawer: state.eExamination.sideBarDrawer,
    }),
    shallowEqual
  )
  return (
    <StyledDrawer
      open={sideBarDrawer}
      onClose={() => dispatch(setSideBarDrawer(false))}
    >
      <Row
        sx={{
          px: 2,
          py: 2.5,
          justifyContent: 'space-between',
          background: '#00008F',
        }}
      >
        <Typography variant="h6" color="text.white">
          รายละเอียดการจอง
        </Typography>
        <IconButton onClick={() => dispatch(setSideBarDrawer(false))}>
          <CloseIcon sx={{ color: '#FFFFFF' }} />
        </IconButton>
      </Row>
      <Column sx={{ px: 2, pt: 0.5, pb: 3 }}>
        <Examiner />
        <StyledDivider />
        <SelectedRound />
      </Column>
    </StyledDrawer>
  )
}

export default DetailSideBarDrawer
