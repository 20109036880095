import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Row } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import Toolbar from './Toolbar'
import { dayThList } from './model'
import {
  handleBatchEvent,
  handleSelectBatch,
  handleSelectEvent,
} from './events'
import {
  StyledColumn,
  StyledDivider,
  StyledEventBox,
  StyledHeadBox,
} from './Styled'
import './calendar.css'

const BatchCalendar = () => {
  const { batches, height } = useSelector(
    (state) => ({
      batches: handleBatchEvent(state),
      height: state.eLearningEnroll.height,
    }),
    shallowEqual
  )
  const calendarRef = useRef(null)

  return (
    <StyledColumn>
      <Toolbar calendarRef={calendarRef} />
      <StyledDivider />
      <Row>
        {dayThList.map((day) => (
          <StyledHeadBox key={day}>
            <Typography variant="body1b">{day}</Typography>
          </StyledHeadBox>
        ))}
      </Row>
      <Box id="batch-calendar" sx={{ height: `${height}px !important` }}>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          timeZone="GMT+7"
          height="100%"
          headerToolbar={false}
          fixedWeekCount={false}
          events={batches}
          eventContent={(props) => <BatchEvent {...props} />}
        />
      </Box>
    </StyledColumn>
  )
}

export default BatchCalendar

export const BatchEvent = ({ event }) => {
  const batch = useSelector(
    (state) => state.eLearningEnroll.body.batch,
    shallowEqual
  )
  const isSelected = _.get(batch, 'periodStart', null) === event.startStr
  const dispatch = useDispatch()
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    handleSelectEvent(event, isSelected)
  }, [batch])

  if (isSelected) return <Box />

  return (
    <StyledEventBox
      id={`e-${event.startStr}`}
      onClick={() => dispatch(handleSelectBatch(event))}
    >
      <Typography variant={isNotSm ? 'body1b' : 'body3b'} color="primary">
        {event.start.getDate()}
      </Typography>
      <Typography variant={isNotSm ? 'body3b' : 'body4b'} color="primary">
        เลือกรอบนี้
      </Typography>
    </StyledEventBox>
  )
}
