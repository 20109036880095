import { styled } from '@material-ui/core'
import { Drawer } from '@mui/material'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  '& .MuiPaper-root.MuiDrawer-paper': {
    width: 320,
    alignItems: 'center',
  },
}))
