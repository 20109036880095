import _ from 'lodash'
import { store } from 'src/App'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  setInitialPreview,
  setVideoState,
} from 'src/redux/slices/eLearning/learning'
import {
  checkELearningConnection,
  getCourseLessonLearning,
  getCourseLogLearnerPerLesson,
  postCourseLogLearnerPerLesson,
  putCourseLogLearner,
  updateELearningConnection,
} from 'src/services/eLearning/learning'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  openErrorDialog,
} from 'src/redux/slices/alertDialog'
import {
  COURSE_TYPE,
  E_LEARNING_LEARNER_LOG_RESULT,
  MODULE_TYPE,
} from 'src/constants/eLearning'
import { handlePutLogLearnerPerLesson } from 'src/redux/slices/eLearning/learning/events'
import { EMBED_TYPE } from './model'

export const handleInitLearning = (socketId, uuidProps) => (dispatch) => {
  const { uuid, batchUuid } = uuidProps

  if (_.isEmpty(socketId)) return

  // general no batch uuid
  if (_.isEmpty(batchUuid)) {
    dispatch(handleInitialPreview(uuidProps))
  } else {
    // for oic
    // check validate first
    const { isValidate } = getLearningState()
    if (!isValidate) {
      dispatch(
        openDialog({
          isCloseDialog: false,
          iconType: 'error',
          title: 'กรุณายืนยันตัวตนก่อนเข้าเรียน',
          message: ['กรุณาถ่ายรูปหน้าตรงของคุณ เพื่อยืนยันตัวตนก่อนเข้าเรียน'],
          buttonRight: {
            label: 'ตกลง',
            handleClick: () =>
              (window.location.href = `/e-learning/course/${uuid}`),
          },
        })
      )
      dispatch(stopLoading())
      return
    }

    // check connection
    const payload = { socketId, eLearningCourseBatchUuid: batchUuid }
    dispatch(checkELearningConnection(payload))
  }
}

export const handleSocket = (socket, setSocketId, uuidProps) => (dispatch) => {
  const onConnect = () => {
    console.log('socket connected', socket.connected)
    if (socket.connected) {
      setSocketId(socket.id)
      socket.on(socket.id, (res) => {
        console.log('socket res', res)
        dispatch(handleSocketResponse(res, socket, uuidProps))
      })
    }
  }

  const onDisconnect = () => socket.disconnect()

  socket.on('connect', onConnect)
  socket.on('disconnect', onDisconnect)
  return () => {
    socket.off('connect', onConnect)
    socket.off('disconnect', onDisconnect)
  }
}

export const handleSocketResponse = (res, socket, uuidProps) => (dispatch) => {
  const previousPath = `/e-learning/course/${uuidProps.uuid}`
  if (socket.id === res.connectedId && res.hasConnected) {
    if (!res.newSocketId) {
      dispatch(handleInitialPreview(uuidProps))
    }
  } else {
    dispatch(
      openDialog({
        isCloseDialog: false,
        title: 'มีการเรียนคลาสนี้อยู่แล้ว',
        message: [
          'มีการเรียนคลาสนี้อยู่ในอุปกรณ์อื่น',
          'ต้องการเปลี่ยนมาใช้งานอุปกรณ์นี้หรือไม่?',
        ],
        buttonLeft: {
          label: 'ออกจากหน้านี้',
          handleClick: () => (window.location.href = previousPath),
        },
        buttonRight: {
          label: 'ใช้งานอุปกรณ์นี้',
          handleClick: () => dispatch(handleStayOnPage(socket, uuidProps)),
        },
      })
    )
  }

  if (res.isForceDisconnect) {
    dispatch(setVideoState({ playing: false, isForceDisconnect: true }))
    dispatch(
      openDialog({
        isCloseDialog: false,
        title: 'พบการเข้าสู่ระบบใหม่',
        message: ['พบการเข้าสู่ระบบใหม่ ระบบจะนำคุณออกจากหน้านี้'],
        buttonRight: {
          label: 'ตกลง',
          handleClick: () => (window.location.href = previousPath),
        },
      })
    )

    setTimeout(() => {
      window.location.href = previousPath
    }, 5000)
  }
}

export const handleStayOnPage = (socket, uuidProps) => async (dispatch) => {
  dispatch(loadingDialog())
  const payload = {
    newSocketId: socket.id,
    eLearningCourseBatchUuid: uuidProps.batchUuid,
  }
  await dispatch(updateELearningConnection(payload))
  dispatch(closeDialog())
  dispatch(handleInitialPreview(uuidProps))
}

export const getLearningState = () => {
  return store.getState().eLearningLearning
}

export const handleIsOic = () => {
  const courseType = _.get(getLearningState().course, 'courseType', '')
  return courseType === COURSE_TYPE.OIC
}

export const handleUpdateLog = () => (dispatch) => {
  const { isCourseCompleted, eLearningCourseBatchLearner } = getLearningState()
  if (isCourseCompleted || _.isEmpty(eLearningCourseBatchLearner)) return
  dispatch(putCourseLogLearner({ eLearningCourseBatchLearner }))
}

export const handleInitialPreview = (uuidProps) => async (dispatch) => {
  const { uuid, batchUuid } = uuidProps

  dispatch(startLoading())
  const response = await dispatch(getCourseLessonLearning({ uuid, batchUuid }))
  if (_.isNil(response.error)) {
    const data = _.get(response.payload, 'data', {})
    const eLearningCourseBatchLearner = {
      id: _.get(data, 'eLearningCourseBatchLearner.id', ''),
      uuid: _.get(data, 'eLearningCourseBatchLearner.uuid', ''),
    }
    const batchLearnerUuid = _.get(eLearningCourseBatchLearner, 'uuid', '')
    const resLog = await dispatch(
      getCourseLogLearnerPerLesson(batchLearnerUuid)
    )
    const dataLogs = _.get(resLog.payload, 'data', [])
    if (dataLogs.length === 0) {
      const resCreateLog = await dispatch(
        postCourseLogLearnerPerLesson({ eLearningCourseBatchLearner })
      )
      dataLogs.concat(_.get(resCreateLog.payload, 'data', []))
    }

    dispatch(setInitialPreview({ ...data, logs: dataLogs }))
  } else {
    const constraints = _.get(response.meta.response, 'data.constraints', {})
    dispatch(
      openErrorDialog({
        title: _.get(constraints, 'title', ''),
        message: [_.get(constraints, 'value', '')],
        buttonRight: {
          handleClick: () =>
            (window.location.href = `/e-learning/course/${uuid}`),
        },
      })
    )
  }
  dispatch(stopLoading())
}

export const handleHtmlLink = (type, value) => {
  if (type === EMBED_TYPE.HTML) {
    const strArr = value?.split(' ') || []
    const src = strArr.find((word) => word?.includes('src'))
    if (typeof src === 'string') {
      return src?.replace('src="', '').replace('"', '')
    } else return ''
  }
}

export const handleUpdateLogPerLesson = (isVideoEnd) => async (dispatch) => {
  const { selectedLesson, eLearningCourseBatchLearner } = getLearningState()
  const eLearningLessonRefModule = _.get(
    selectedLesson,
    'eLearningLessonRefModule',
    {}
  )
  const data = handleLogModuleData(selectedLesson, isVideoEnd)
  const body = {
    eLearningCourseBatchLearner,
    eLearningLessonRefModule,
    logLearnerPerLessonUuid: _.get(selectedLesson, 'logUuid', ''),
    data,
  }
  await dispatch(handlePutLogLearnerPerLesson(body))
}

export const handleLogModuleData = (selectedLesson, isVideoEnd) => {
  const type = _.get(selectedLesson, 'moduleType', '')
  const percent = _.get(selectedLesson, 'progress', 0)
  const result = handleLogResult(selectedLesson)
  const defaultData = { percent, result }
  switch (type) {
    case MODULE_TYPE.EMBED_CONTENT: {
      const duration = _.get(selectedLesson, 'videoState.playedSeconds', 0) || 0
      const playedPercent = _.get(selectedLesson, 'playedPercent', 0)
      const videoObj = {
        duration,
        playedPercent: percent > playedPercent ? percent : playedPercent,
      }
      if (isVideoEnd) {
        return {
          percent: 100,
          result: E_LEARNING_LEARNER_LOG_RESULT.COMPLETE,
          ...videoObj,
        }
      } else return { ...defaultData, ...videoObj }
    }
    case MODULE_TYPE.UPLOAD_FILE: {
      const files = _.get(selectedLesson, 'fileList', [])
        .map((file) => ({ uuid: file.uuid, percent: file.process }))
        .map((file) => ({ ...file, result: handleLogResult(file) }))
      return { ...defaultData, files }
    }
    case MODULE_TYPE.LINK_TO:
      return defaultData
    default:
      return defaultData
  }
}

export const handleLogResult = (selectedLesson) => {
  const percent = _.get(
    selectedLesson,
    'progress',
    _.get(selectedLesson, 'percent', 0)
  )
  const oldResult = _.get(selectedLesson, 'result', '')
  const hasCompleted = oldResult === E_LEARNING_LEARNER_LOG_RESULT.COMPLETE
  if (hasCompleted) return E_LEARNING_LEARNER_LOG_RESULT.COMPLETE
  if (percent === 0) return E_LEARNING_LEARNER_LOG_RESULT.PENDING
  if (percent === 100) return E_LEARNING_LEARNER_LOG_RESULT.COMPLETE
  else return E_LEARNING_LEARNER_LOG_RESULT.INPROGRESS
}
