import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { StyledAccordionSummary } from './Styled'
import { Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const CollapseTitle = ({ index }) => {
  const { examiner, formError } = useSelector(
    (state) => ({
      examiner: state.eExamination.examiners[index],
      formError: state.eExamination.formNextButtonDisabled[index],
    }),
    shallowEqual
  )
  return (
    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6">{`${examiner.firstNameTH} ${examiner.lastNameTH}`}</Typography>
      {formError ? (
        <ErrorIcon sx={{ fontSize: 20, color: '#D64F65', my: 'auto', ml: 1 }} />
      ) : (
        <CheckCircleIcon
          sx={{ fontSize: 20, color: '#55D37A', my: 'auto', ml: 1 }}
        />
      )}
    </StyledAccordionSummary>
  )
}

export default CollapseTitle
