import styled from '@mui/system/styled'
import { TableCell, Checkbox, TableRow, InputBase, Box } from '@mui/material'
import Search from '@mui/icons-material/Search'

export const StyledExaminerImage = styled('img')(() => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
}))

export const StyledTableRow = styled(TableRow)(() => ({
  border: '1px solid #DBE4F199',
}))

export const StyledTableCell = styled(TableCell)(() => ({
  padding: '11px 16px',
  height: 52,
  border: 0,
}))

export const StyledCheckbox = styled(Checkbox)(() => ({
  padding: 0,
}))

export const BoxSearch = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme?.shape?.borderRadius,
  border: `1px solid ${theme.palette.text?.silver}`,
  backgroundColor: theme?.palette?.text?.white,
  width: '100%',
  minWidth: '250px',
  '&:hover': {
    backgroundColor: theme?.palette?.text?.wildSand,
  },
  marginLeft: 0,
  [theme.breakpoints.up('lg')]: {
    width: 'auto',
    maxHeight: '37.5px',
  },
}))

export const SearchInput = styled(InputBase)(({ theme }) => ({
  maxHeight: '37.5px',
  width: 'calc(100% - 36px)',
  marginLeft: theme.spacing(1),
}))

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% - 10px)',
  width: 20,
  height: 20,
  cursor: 'pointer',
}))
