import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import StarsIcon from '@mui/icons-material/Stars'
import { ScoreBox } from './Styled'
import { convertNumberWithComma } from 'src/utils/lib'

const Score = () => {
  const { myPoint } = useSelector(
    (state) => ({
      myPoint: state.catalog.myPoint,
    }),
    shallowEqual
  )
  return (
    <ScoreBox>
      <Typography
        sx={{ typography: { sm: 'h6', xs: 'body1b' } }}
        color="primary.contrast"
      >
        คุณมี
      </Typography>
      <StarsIcon sx={{ color: 'primary.contrast', ml: 1, mr: 1.5 }} />
      <Typography
        sx={{ typography: { sm: 'h6', xs: 'body1b' } }}
        color="primary.contrast"
      >
        {convertNumberWithComma(myPoint) || 0} คะแนน
      </Typography>
    </ScoreBox>
  )
}

export default Score
