import { createSlice } from '@reduxjs/toolkit'
import { initialState, prefixTHtoEN } from './model'
import _ from 'lodash'
import { checkIdCard } from '../../../utils/lib'

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setInitialBody: (state, { payload }) => {
      const prefixTH = _.get(payload, 'prefixTH', '') || ''
      state.body.prefixTH = prefixTH
      state.body.firstNameTH = _.get(payload, 'firstNameTH', '') || ''
      state.body.lastNameTH = _.get(payload, 'lastNameTH', '') || ''
      state.body.prefix =
        _.get(payload, 'prefix', '') || prefixTHtoEN[prefixTH] || ''
      state.body.firstName = _.get(payload, 'firstName', '') || ''
      state.body.lastName = _.get(payload, 'lastName', '') || ''
      state.body.idCardNo = _.get(payload, 'idCardNo', '') || ''
    },
    setProfileData: (state, action) => {
      state.data = action.payload
    },
    setDisableButton: (state, { payload }) => {
      const { values, isProspect, isCS, nonAcademy } = payload
      if (nonAcademy) {
        state.btnDisabled =
          _.isEmpty(values.prefixTH) ||
          _.isEmpty(values.firstNameTH) ||
          _.isEmpty(values.lastNameTH) ||
          _.isEmpty(values.firstName) ||
          _.isEmpty(values.lastName) ||
          !checkIdCard(values.idCardNo)
      } else if (isProspect) {
        state.btnDisabled =
          _.isEmpty(values.prefixTH) ||
          _.isEmpty(values.firstName) ||
          _.isEmpty(values.lastName)
      } else if (isCS) {
        state.btnDisabled = false
      } else {
        state.btnDisabled = _.isEmpty(values.prefixTH)
      }
    },
    setOpenDialog: (state, { payload }) => {
      state.alertDialog.isOpen = true
      state.alertDialog.title = _.get(payload, 'title', '')
      state.alertDialog.message = _.get(payload, 'message', [])
      state.alertDialog.buttonLeft = _.get(payload, 'buttonLeft', {})
      state.alertDialog.buttonRight = _.get(payload, 'buttonRight', {})
    },
    setOpenSuccessDialog: (state, { payload }) => {
      state.alertDialog.isOpen = true
      state.alertDialog.title = _.get(payload, 'title', '')
      state.alertDialog.message = []
      state.alertDialog.iconType = 'success'
      state.alertDialog.buttonLeft = {}
      state.alertDialog.buttonRight = {
        label: _.get(payload, 'buttonLabel', 'ตกลง'),
        handleClick: _.get(payload, 'handleClick', null),
      }
    },
    setOpenErrorDialog: (state, { payload }) => {
      state.alertDialog.isOpen = true
      state.alertDialog.title = _.get(payload, 'title', '')
      const message = _.get(payload, 'message', 'ดำเนินการไม่สำเร็จ')
      state.alertDialog.message =
        typeof message == 'string' ? [message] : message
      state.alertDialog.iconType = 'error'
      state.alertDialog.buttonLeft = {}
      state.alertDialog.buttonRight = _.get(payload, 'buttonRight', {})
    },
    setCloseDialog: (state) => {
      state.alertDialog = { ...initialState.alertDialog }
    },
    setLoadingDialog: (state) => {
      state.alertDialog.isLoading = true
    },
    setStopLoadingDialog: (state) => {
      state.alertDialog.isLoading = false
    },
  },
})

export const {
  setInitialBody,
  setProfileData,
  setDisableButton,
  setOpenDialog,
  setOpenSuccessDialog,
  setOpenErrorDialog,
  setCloseDialog,
  setLoadingDialog,
  setStopLoadingDialog,
} = profile.actions

export default profile.reducer
