import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Template from 'src/components/Template'
import CourseCard from 'src/components/CourseCard'
import ContentNoClass from 'src/components/ContentNoClass'
import { Column } from 'src/components/Layout/Styled'
import { getELearningFilter } from 'src/services/eLearning/list'
import AlertDialog from './Detail/EnrollButton/AlertDialog'
import Header from '../components/Header'
import {
  StyledCardBox,
  StyledContainer,
  StyledGrid,
  StyledLoading,
  StyledRowContainer,
  StyledWrapper,
} from '../components/Styled'
import { SECTION_KEY } from '../model'
import { MobileHeader, TabletDesktopHeader } from '../components'
import Filter from './Filter'
import ListHeader from './ListHeader'
import { fetchCourseList, handleLoadMore, handleSeeMore } from './events'

const CourseList = () => {
  const dispatch = useDispatch()
  const { isLoadMore, courses, filterBody, totalCourses } = useSelector(
    (state) => state.eLearningList,
    shallowEqual
  )
  const triggerProps = [
    filterBody.order,
    filterBody.sortBy,
    filterBody.courseType,
    filterBody.courseStatus,
    filterBody.myCourse,
    filterBody.acquiredSkill,
    filterBody.productType,
    filterBody.startDate,
    filterBody.finishDate,
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getELearningFilter())

    window.addEventListener('scroll', () => dispatch(handleLoadMore()))
    return () => {
      window.removeEventListener('scroll', () => dispatch(handleLoadMore()))
    }
  }, [])

  useEffect(() => {
    dispatch(fetchCourseList(filterBody))
  }, [...triggerProps])

  useEffect(() => {
    dispatch(handleSeeMore(filterBody))
  }, [filterBody.page])

  return (
    <StyledWrapper>
      <Template maxWidth={1440}>
        <MobileHeader keySection={SECTION_KEY.COURSE} total={totalCourses} />
        <Header title="หลักสูตร E-learning ทั้งหมด" />
        <StyledContainer>
          <ListHeader />
          <StyledRowContainer>
            <Filter />
            <Column sx={{ gap: 3 }}>
              <TabletDesktopHeader
                keySection={SECTION_KEY.COURSE}
                total={totalCourses}
              />
              <StyledCardBox>
                <Grid container spacing={2}>
                  {courses.length > 0 ? (
                    courses.map((course, index) => (
                      <StyledGrid key={index}>
                        <CourseCard
                          course={course}
                          keySection={SECTION_KEY.E_LEARNING}
                        />
                      </StyledGrid>
                    ))
                  ) : (
                    <ContentNoClass />
                  )}
                </Grid>
                {isLoadMore && <StyledLoading />}
              </StyledCardBox>
            </Column>
          </StyledRowContainer>
        </StyledContainer>
        <AlertDialog />
      </Template>
    </StyledWrapper>
  )
}

export default CourseList
