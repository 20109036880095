import { store } from 'src/App'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import _ from 'lodash'
import {
  addExaminer,
  setReduxValue,
  setSelectedRounds,
  setOtherDocConfig,
} from 'src/redux/slices/eExamination'
import {
  getAddress,
  getConsent,
  getGender,
  getMyExaminerProfile,
  getQualification,
  checkOtherDocConfig,
} from 'src/services/eExamination'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { setData } from 'src/redux/slices/document'
import { getGenderFromPrefix } from 'src/utils/lib'

export const convertDateShortTh = (date) => {
  dayjs.extend(buddhistEra)
  dayjs.locale('th')
  return dayjs(date).format('DD MMM BBBB')
}

export const removeRound = (index) => (dispatch) => {
  const state = store.getState()
  const selectedRounds = _.get(state.eExamination, 'selectedRounds', [])
  let newRound = [...selectedRounds]
  const deletedRound = newRound.splice(index, 1)
  dispatch(setSelectedRounds({ value: newRound }))

  const { date, startTime } = deletedRound[0]
  let notAvailableTime = _.get(state.eExamination, 'notAvailableTime', {})
  let time = {}
  if (startTime == '08:30' || startTime == '10:30') {
    time = { '08:30': false, '10:30': false }
  } else {
    time = { '13:30': false, '15:30': false }
  }
  const currentValue = _.get(notAvailableTime, [date], {})
  dispatch(
    setReduxValue({
      index: 'notAvailableTime',
      value: {
        ...notAvailableTime,
        [date]: { ...currentValue, ...time },
      },
    })
  )
}

export const handleSelfRegister = () => async (dispatch) => {
  const user = JSON.parse(window.localStorage.getItem('user'))
  const localUserType = _.get(user, 'userType', 'STAFF')
  const localRole = _.get(user, 'agentRole', '')
  const localAbbreviation = _.get(user, 'distributionChannel.abbreviation', '')
  const selfRegister = !(
    localUserType === 'AGENT' &&
    localRole === 'Manager' &&
    (localAbbreviation === 'AGC' || localAbbreviation === 'CS')
  )
  dispatch(
    setReduxValue({
      index: 'selfRegister',
      value: selfRegister,
    })
  )
  const prospectDetail = JSON.parse(localStorage.getItem('prospectDetail'))
  if (!prospectDetail || selfRegister) {
    await dispatch(getMyExaminerProfile())
    const state = store.getState()
    if (state.eExamination.examiners?.[0]?.zipCode) {
      await dispatch(
        getAddress({ index: 0, value: state.eExamination.examiners[0].zipCode })
      )
    }
  }
}

export const fetchInitials = () => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(getGender())
  await dispatch(getQualification())
  const result = await dispatch(getConsent())
  const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
  if (requestStatus == 'fulfilled') {
    dispatch(setData(_.get(result, 'payload', {})))
  }
  const response = await dispatch(checkOtherDocConfig())
  const responseStatus = _.get(response, 'meta.requestStatus', 'rejected')
  if (responseStatus == 'fulfilled') {
    dispatch(setOtherDocConfig(_.get(response, 'payload', '')))
  }
  dispatch(handleSelfRegister())
  dispatch(checkProspectDetail())
  dispatch(stopLoading())
}

export const checkProspectDetail = () => (dispatch) => {
  const prospectDetail = JSON.parse(localStorage.getItem('prospectDetail'))
  if (prospectDetail) {
    const state = store.getState()
    const { genderOptions } = state.eExamination.options
    prospectDetail.map(
      (prospect) =>
        (prospect['gender'] = getGenderFromPrefix(
          prospect.prefix,
          genderOptions
        ))
    )
    dispatch(
      setReduxValue({ index: 'selectedExaminerList', value: prospectDetail })
    )
    dispatch(addExaminer())
    dispatch(setReduxValue({ index: 'selectedExaminerList', value: [] }))
  }
}
