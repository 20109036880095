import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledCard = styled(Row)(({ theme, index }) => ({
  background:
    index % 2 === 0
      ? theme.palette?.background?.paper
      : theme.palette?.background?.blue,
  alignItems: 'start',
  padding: '16px 24px',
}))

export const StyledImgBox = styled(Box)(({ theme, height }) => ({
  img: { width: '100%', height, objectFit: 'cover' },
  width: 302,
  [theme.breakpoints.down('lg')]: { width: 263 },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}))

export const StyledCardContent = styled(Column)(({ theme, isOldSystem }) => ({
  height: '100%',
  padding: theme.spacing(2.5),
  justifyContent: 'start',
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    width: 'calc(100% - 263px)',
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    gap: theme.spacing(0.5),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2.5),
    gap: theme.spacing(3),
  },
}))

export const StyledCardFooter = styled(Row)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'end',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(1.5),
    '& > .MuiBox-root#button': { width: '100%', justifyContent: 'end' },
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(3),
    '& > .MuiBox-root#button button': { width: '100%' },
  },
}))

export const StyledRowFooter = styled(Row)(({ theme }) => ({
  width: 'auto',
  gap: theme.spacing(3),
  alignItems: 'start',
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(0.5),
    paddingBottom: theme.spacing(0),
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

export const StyledLabelIcon = styled(Row)(({ theme }) => ({
  width: 'auto',
  gap: theme.spacing(0.5),
  color: theme.palette.text?.secondary,
  alignItems: 'center',
}))
