import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid, Typography, IconButton } from '@mui/material'
import Filter from './Filter'
import ResultTable from './ResultTable'
import ResultTableMobile from './ResultTableMobile'
import DetailSideBar from './DetailSideBar'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { setSideBarDrawer, setWidth } from 'src/redux/slices/eExamination'
import DetailSideBarDrawer from './DetailSideBarDrawer'
import FilterMobile from './Filter/FilterMobile'
import { Dot } from '../Styled'
const { Column, Row } = require('src/components/Layout/Styled')

const ExamRound = () => {
  const dispatch = useDispatch()
  const { width, examiners, selectedRounds, roundList } = useSelector(
    (state) => ({
      width: state.eExamination.windowWidth,
      examiners: state.eExamination.examiners,
      selectedRounds: state.eExamination.selectedRounds,
      roundList: state.eExamination.roundList,
    }),
    shallowEqual
  )
  const hasWindow = typeof window !== 'undefined'
  useEffect(() => {
    if (hasWindow) {
      dispatch(setWidth(window.innerWidth))
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize())
    }
  }, [hasWindow])
  function handleResize() {
    dispatch(setWidth(window.innerWidth))
  }
  return (
    <Column>
      <Row sx={{ mb: 4, justifyContent: 'space-between' }}>
        <Row sx={{ width: 'auto' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 100 100"
            style={{
              display: 'block',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: '8px',
            }}
          >
            <path
              fill="currentColor"
              d="M37.18 0c-9.53 0-19.061 3.624-26.31 10.871c-14.495 14.495-14.495 38.122 0 52.617c13.57 13.568 35.1 14.367 49.687 2.528l3.576 3.576a3.956 5.958 45 0 0 .646 3.613L90.73 99.156a3.956 5.958 45 0 0 7.01-1.416a3.956 5.958 45 0 0 1.416-7.01l-25.95-25.95a3.956 5.958 45 0 0-3.616-.647l-3.576-3.576c11.839-14.588 11.043-36.118-2.526-49.686C56.241 3.624 46.71 0 37.18 0m0 8.217c7.397 0 14.795 2.834 20.463 8.502a28.977 28.977 0 0 1 6.328 9.47H45.434v5.872h20.248a28.91 28.91 0 0 1-1.227 14.85H45.434v5.87h16.123a29.227 29.227 0 0 1-3.914 4.862a28.875 28.875 0 0 1-40.924 0a28.875 28.875 0 0 1 0-40.924c5.667-5.668 13.064-8.502 20.46-8.502m-11.576 12.54c-2.125 0-3.913 1.789-3.913 3.913v8.91c0 2.124 1.788 3.912 3.913 3.912h8.91c2.124 0 3.912-1.788 3.912-3.912v-8.91c0-2.124-1.788-3.912-3.912-3.912zm1.087 5h6.735v6.735H26.69zM25.604 41.48c-2.125 0-3.913 1.788-3.913 3.912v8.91c0 2.124 1.788 3.912 3.913 3.912h8.91c2.124 0 3.912-1.788 3.912-3.912v-8.91c0-2.124-1.788-3.912-3.912-3.912zm1.087 5h6.735v6.734H26.69z"
              color="#00008F"
            />
          </svg>
          <Typography variant="h6">เลือกรอบสอบที่ต้องการ</Typography>
        </Row>
        {width <= 450 ? (
          <Row sx={{ width: 'auto' }}>
            <IconButton
              sx={{
                positon: 'relative',
                borderRadius: 0,
              }}
              onClick={() => dispatch(setSideBarDrawer(true))}
            >
              <PlaylistAddCheckIcon sx={{ color: '#00008F' }} />
              {(examiners.length > 0 || selectedRounds.length > 0) && <Dot />}
            </IconButton>
          </Row>
        ) : width < 1280 ? (
          <Row sx={{ width: 'auto' }}>
            <IconButton
              size="small"
              sx={{
                positon: 'relative',
                columnGap: 1,
                border: '1px solid #00008F',
                borderRadius: 0,
                p: '4px 10px',
              }}
              onClick={() => dispatch(setSideBarDrawer(true))}
            >
              <PlaylistAddCheckIcon sx={{ fontSize: 18, color: '#00008F' }} />
              <Typography
                sx={{ fontWeight: 500 }}
                variant="body1"
                color="primary.main"
              >
                รายละเอียดการจอง
              </Typography>
              {(examiners.length > 0 || selectedRounds.length > 0) && <Dot />}
            </IconButton>
          </Row>
        ) : (
          ''
        )}
      </Row>
      <Grid container spacing={3}>
        <Grid item lg={9.5} xs={12}>
          <Column>
            {width > 375 ? <Filter /> : <FilterMobile />}
            {roundList.length > 0 &&
              (width >= 700 ? <ResultTable /> : <ResultTableMobile />)}
          </Column>
        </Grid>
        {width >= 1280 ? (
          <Grid item xs={2.5}>
            <Typography variant="h6" color="primary.main" sx={{ mt: 1, mb: 3 }}>
              รายละเอียดการจอง
            </Typography>
            <DetailSideBar />
          </Grid>
        ) : (
          <DetailSideBarDrawer />
        )}
      </Grid>
    </Column>
  )
}

export default ExamRound
