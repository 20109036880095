import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import styled from '@mui/system/styled'
import { Row } from '../Layout/Styled'

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme?.palette?.primary?.contrast,
  boxShadow: theme?.palette?.boxShadow?.menu,
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 1220,
  height: 72,
  margin: 'auto',
  padding: `0 !important`,
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(3)} !important`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `0 0 0 ${theme.spacing(2.5)} !important`,
  },
  '& > .MuiBox-root:first-of-type': {
    display: 'flex',
    gap: theme.spacing(3),
    [theme.breakpoints.down('lg')]: { display: 'none' },
  },
}))

export const StyledAppMenuButton = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginRight: '12px',
  },
  '& .MuiDivider-root': {
    borderLeft: `solid 1px ${theme.palette?.blue?.blueLine}`,
    height: '72px',
  },
  '& .MuiButtonBase-root': {
    color: theme.palette.primary?.main,
  },
}))

export const StyledHoverTab = styled(Row, {
  shouldForwardProp: (prop) => prop !== 'isLogin',
})(({ isLogin }) => ({
  position: 'relative',
  '& a:nth-of-type(1):hover ~ #line': {
    display: 'block',
    width: isLogin ? 138 : 103,
    left: 0,
  },
  '& a:nth-of-type(2):hover ~ #line': {
    display: 'block',
    width: isLogin ? 105 : 100,
    left: 139,
  },
  '& a:nth-of-type(3):hover ~ #line': {
    display: 'block',
    width: 117,
    left: 244,
  },
  '& a:nth-of-type(4):hover ~ #line': {
    display: 'block',
    width: 110,
    left: 360,
  },
  '& a:nth-of-type(5):hover ~ #line': {
    display: 'block',
    width: 112,
    left: 475,
  },
  '& a:nth-of-type(6):hover ~ #line': {
    display: 'block',
    width: 119,
    left: 586,
  },
}))

export const StyledLink = styled(Link)(({ theme, active }) => ({
  padding: theme.spacing(3),
  cursor: 'pointer',
  borderBottom: active ? `solid 4px ${theme.palette.secondary?.main}` : 'unset',
  textAlign: 'center',
  transition: 'color 0.3s ease',
  '&:hover': { color: theme.palette.primary?.dark },
}))

export const StyledHoverLine = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'vertical',
})(({ theme, vertical }) => ({
  position: 'absolute',
  display: 'none',
  height: vertical ? 57 : 4,
  width: vertical ? 3 : 0,
  top: vertical ? 0 : 72,
  left: 0,
  transition: 'all 0.3s ease',
  background: theme.palette.secondary?.main,
}))

export const TextBox = styled(Box)(() => ({
  display: 'flex',
  gap: '11px',
  alignItems: 'center',
}))