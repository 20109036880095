import React from 'react'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import { StyledCircleBox, StyledContentBox } from './Styled'

const GetReadyTab = () => {
  return (
    <Column sx={{ p: 3, background: '#FFF', gap: 2 }}>
      <Column sx={{ gap: 0.5, textAlign: 'center' }}>
        <Typography variant="body1b">
          หลักสูตรนี้เป็นการอบรมที่{' '}
          <Typography variant="span" color="primary">
            “มีการยืนยันตัวตน”
          </Typography>
        </Typography>
        <Typography>
          กรุณาเตรียมอุปกรณ์และหลักฐานในการยืนยันตัวตน
          <br />
          ให้พร้อมก่อนการเข้าเรียน
        </Typography>
      </Column>
      <Column sx={{ alignItems: 'center' }}>
        <ContentBox
          title="อุปกรณ์ที่ใช้จะต้องมีกล้อง"
          subTitle="คอมพิวเตอร์, Notebook, Tablet, มือถือ ที่มีกล้อง"
          img="ic_webcam.svg"
        />
        <ContentBox
          title="หลักฐานที่ใช้ในการยืนยันตัวตน"
          subTitle="บัตรประชาชนหรือใบขับขี่ ที่ยังไม่หมดอายุ"
          img="ic_user_id.svg"
        />
        <ContentBox
          title="สัญญาณอินเทอร์เน็ต"
          subTitle={
            <Typography variant="span">
              มีสัญญาณอินเทอร์เน็ตตลอด
              <br />
              ระยะเวลาการอบรม
            </Typography>
          }
          img="ic_wifi.svg"
        />
        <ContentBox
          title="สภาพแวดล้อมที่เหมาะสม"
          subTitle={
            <Typography variant="span">
              มีแสงสว่างเพียงพอ, ไม่มีสิ่งปิดบังใบหน้า,
              <br />
              ไม่มีผู้อื่นอยู่ในกล้องขณะถ่ายภาพ
            </Typography>
          }
          img="ic_face_id.svg"
        />
      </Column>
    </Column>
  )
}

export default GetReadyTab

export const ContentBox = ({ title, subTitle, img }) => {
  return (
    <StyledContentBox>
      <StyledCircleBox>
        <img src={`/icon/e-learning/${img}`} />
      </StyledCircleBox>
      <Typography variant="body2b">{title}</Typography>
      <Typography variant="body3" color="text.lightGray">
        {subTitle}
      </Typography>
    </StyledContentBox>
  )
}
