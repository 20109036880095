import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { setExaminerDetail } from 'src/redux/slices/eExamination'
import BasicSelect from 'src/components/Input/Select'

const Select = ({
  index,
  obj,
  label,
  options,
  isDisabled = false,
  sm = 6,
  md = 'unset',
}) => {
  const dispatch = useDispatch()
  const { value } = useSelector(
    (state) => ({
      value: state.eExamination.examiners[index][obj],
    }),
    shallowEqual
  )
  return (
    <Grid item md={md} sm={sm} xs={12}>
      <BasicSelect
        selectSx={{
          height: 50,
          background: '#FFFFFF',
          '& .MuiSelect-icon': { color: '#00008F' },
          '&.Mui-disabled .MuiInputBase-input': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.70)',
          },
        }}
        placeholder="กรุณาเลือก"
        labelText={label}
        value={value || ''}
        required
        disabled={isDisabled}
        handleChange={(e) =>
          dispatch(setExaminerDetail({ index, obj, value: e.target.value }))
        }
        options={options || [{ value: value || '', label: value || '' }]}
      />
    </Grid>
  )
}

export default Select
