import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getELearningFilter,
  getELearningLikeCourse,
  getELearningOngoingCourse,
  postELearningList,
} from 'src/services/eLearning/list'
import { initialState } from './model'

const eLearningList = createSlice({
  name: 'eLearningList',
  initialState,
  reducers: {
    startLoadMore: (state) => {
      state.isLoadMore = true
    },
    stopLoadMore: (state) => {
      state.isLoadMore = false
    },
    setFilter: (state, { payload }) => {
      const { key, value } = payload
      state.filterBody[key] = value
      state.filterBody.page = 1
    },
    setLoadMore: (state) => {
      const nextPage = state.filterBody.page + 1
      const maxPage = _.ceil(state.totalCourses / 12)
      if (nextPage <= maxPage) state.filterBody.page = nextPage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getELearningFilter.fulfilled, (state, { payload }) => {
        state.filterData = _.get(payload, 'data', {})
      })
      .addCase(getELearningFilter.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postELearningList.fulfilled, (state, action) => {
        const { meta, payload } = action
        const courses = _.get(payload, 'data.courses', [])
        const isFilter = _.get(meta.arg, 'page', 1) == 1
        if (isFilter) state.courses = courses
        else state.courses = _.concat(state.courses, courses)
        state.totalCourses = _.get(payload, 'data.totalCourses', [])
      })
      .addCase(postELearningList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getELearningOngoingCourse.fulfilled, (state, action) => {
        const { meta, payload } = action
        const courses = _.get(payload, 'data.courses', [])
        const isFilter = _.get(meta.arg, 'page', 1) == 1
        if (isFilter) state.courses = courses
        else state.courses = _.concat(state.courses, courses)
        state.totalCourses = _.get(payload, 'data.totalCourses', [])
      })
      .addCase(getELearningOngoingCourse.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getELearningLikeCourse.fulfilled, (state, action) => {
        const { meta, payload } = action
        const courses = _.get(payload, 'data.courses', [])
        const isFilter = _.get(meta.arg, 'page', 1) == 1
        if (isFilter) state.likeCourses = courses
        else state.likeCourses = _.concat(state.likeCourses, courses)
        state.totalLikeCourses = _.get(payload, 'data.totalCourses', [])
      })
      .addCase(getELearningLikeCourse.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { startLoadMore, stopLoadMore, setFilter, setLoadMore } =
  eLearningList.actions

export default eLearningList.reducer
