import React, { useEffect } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Container } from '@mui/material'
import Template from 'src/components/Template'
import AllArticle from './AllArticle'
import ListHeader from '../ListHeader'
import { isMediaWidth, isMediaWidthMoreThan } from 'src/utils/lib'
import { fetchDataList } from './events'
import { resetDataList, setFilterValue } from 'src/redux/slices/eContent'
import { getOptions } from 'src/services/eContent'
import { ECONTENT_TYPE } from '../model'

const EKnowledgeList = () => {
  const dispatch = useDispatch()
  const isMobile = isMediaWidth('xs')
  const isLaptop = isMediaWidthMoreThan('md')
  const { filter, page, type, order, isFetchSearch } = useSelector(
    (state) => ({
      filter: state.eContent.filter,
      page: state.eContent.filter.page,
      type: state.eContent.filter.type,
      order: state.eContent.filter.order,
      isFetchSearch: state.eContent.isFetchSearch,
    }),
    shallowEqual
  )
  useEffect(() => {
    dispatch(getOptions())
    dispatch(resetDataList())
    return () =>
      dispatch(
        setFilterValue({ key: 'type', value: [ECONTENT_TYPE.E_CONTENT] })
      )
  }, [])
  useEffect(() => {
    if (type.length == 1) {
      dispatch(
        fetchDataList({
          ...filter,
          page,
          type,
          order,
        })
      )
    }
  }, [page, type, order, isFetchSearch])

  return (
    <>
      <Container maxWidth="lg">
        <ListHeader isMobile={isMobile} hasFilter />
        <Template maxWidth="100%">
          <AllArticle isMobile={isMobile} isLaptop={isLaptop} />
        </Template>
      </Container>
    </>
  )
}

export default EKnowledgeList
