import React from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { setModuleProgress } from 'src/redux/slices/eLearning/learning'
import { handleUpdateLogPerLesson } from '../../../events'
import { StyledLink, StyledLinkBox } from './Styled'

const LinkToWebsite = ({ selectedLesson }) => {
  const link = _.get(selectedLesson, 'link', '')
  const dispatch = useDispatch()
  return (
    <StyledLinkBox>
      <Typography variant="h6">
        {_.get(selectedLesson, 'linkDescription', '')}
      </Typography>
      <StyledLink
        variant="h6"
        target="_blank"
        href={link}
        onClick={() => {
          dispatch(setModuleProgress(100))
          dispatch(handleUpdateLogPerLesson())
        }}
      >
        คลิกที่นี่
      </StyledLink>
    </StyledLinkBox>
  )
}

export default LinkToWebsite
