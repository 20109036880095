import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from 'src/utils/lib'

const StepNumber = ({ value, disabled }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <Box sx={{ width: 94, px: isNotSm ? 1 : 0 }}>
      <Typography
        variant={isNotSm ? 'h6' : 'body1b'}
        color={disabled ? 'text.lightGray' : 'primary'}
      >
        ขั้นตอนที่ {value}
      </Typography>
    </Box>
  )
}

export default StepNumber
