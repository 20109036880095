export const initialState = {
  tab: 'ALL',
  filter: [],
  filterBody: {
    name: '',
    startDate: '',
    finishDate: '',
    courseBatchUuid: '',
    trainingResult: [],
    status: [],
    page: 1,
  },
  batchs: [],
  classes: [],
  totalClasses: 0,
  error: null,
  isLoadMore: false,
}
