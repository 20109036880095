import React, { createRef, useEffect } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'

import DailyAndWeek from './DailyAndWeek'
import Month from './Month'
import Header from './Header'

import CalendarViewDay from '@mui/icons-material/CalendarViewDay'
import CalendarViewWeek from '@mui/icons-material/CalendarViewWeek'
import CalendarViewMonth from '@mui/icons-material/CalendarViewMonth'
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined'
import './calendar.css'
import { ButtonCalendar, BoxGroupHeader } from './Styled'
import { mapColor, handleChangeMenu } from './events'
import { fetchCalendar } from './Header/events'
import { BoxCard } from '../ToDoList/Styled'

export const ContentTooltip = ({ event }) => {
  const history = useHistory()
  const allDay = _.get(event, 'allDay', false)
  const description = _.get(event, 'extendedProps.description', '')
  const dateTime = _.get(event, 'extendedProps.dateTime', '')
  const trainingPlanUuid = _.get(event, 'extendedProps.trainingPlanUuid', '')

  return (
    <Box sx={{ my: 0.5 }}>
      <Typography variant="tooltip">{description}</Typography>
      {!allDay && (
        <>
          <Divider sx={{ borderColor: '#FFFFFF', my: 0.5 }} />
          <Typography variant="tooltip">{dateTime}</Typography>
          <Divider sx={{ borderColor: '#FFFFFF', my: 0.5 }} />
          <Typography
            variant="tooltip"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              if (
                _.includes(
                  [
                    'ANNOUNCEMENT',
                    'CANCEL',
                    'CANCEL_BY_SELF',
                    'CANCEL_BY_ADMIN',
                    'CANCEL_EXAMINATION',
                    'CANCEL_BY_MANAGER',
                  ],
                  event._def.extendedProps.status
                )
              ) {
                history.push('/my-class?tab=MY_SCHEDULE')
              } else {
                history.push(
                  `/my-class/registration-detail/${trainingPlanUuid}`
                )
              }
            }}
          >
            {_.includes(
              [
                'ANNOUNCEMENT',
                'CANCEL',
                'CANCEL_BY_SELF',
                'CANCEL_BY_ADMIN',
                'CANCEL_EXAMINATION',
                'CANCEL_BY_MANAGER',
              ],
              event._def.extendedProps.status
            )
              ? 'ไปหน้าการสมัครสอบของฉัน'
              : 'ไปหน้ารายละเอียดคลาส'}
          </Typography>
        </>
      )}
    </Box>
  )
}

const Index = () => {
  const calendarRef = createRef()
  const history = useHistory()
  const dispatch = useDispatch()
  const { tapMenu, isFetch, bodyCalendar } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )

  useEffect(() => {
    fetchCalendar({ calendarRef, bodyCalendar })
  }, [])

  return (
    <BoxCard>
      <Box sx={{ m: 3 }}>
        <BoxGroupHeader>
          <Box>
            <Typography variant="h6" color="primary">
              ปฎิทินของฉัน
            </Typography>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <ButtonCalendar
              variant={mapColor(tapMenu, 'DAILY')}
              startIcon={<CalendarViewDay sx={{ ml: 1.5 }} />}
              onClick={() => dispatch(handleChangeMenu('DAILY'))}
            />
            <ButtonCalendar
              variant={mapColor(tapMenu, 'WEEKLY')}
              startIcon={<CalendarViewWeek sx={{ ml: 1.5 }} />}
              onClick={() => dispatch(handleChangeMenu('WEEKLY'))}
            />
            <ButtonCalendar
              variant={mapColor(tapMenu, 'MONTH')}
              startIcon={<CalendarViewMonth sx={{ ml: 1.5 }} />}
              onClick={() => dispatch(handleChangeMenu('MONTH'))}
            />
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => history.push('/my-class')}
            >
              <ArrowForwardOutlined color="primary" />
            </IconButton>
          </Box>
        </BoxGroupHeader>
        <Header calendarRef={calendarRef} />

        {tapMenu == 'DAILY' && !isFetch && (
          <DailyAndWeek calendarRef={calendarRef} initialView="timeGridDay" />
        )}
        {tapMenu == 'WEEKLY' && !isFetch && (
          <DailyAndWeek calendarRef={calendarRef} initialView="timeGridWeek" />
        )}
        {tapMenu == 'MONTH' && !isFetch && <Month calendarRef={calendarRef} />}
      </Box>
    </BoxCard>
  )
}

export default Index
