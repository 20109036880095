import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  additionalConsent,
  learnerDocument,
  registerDocument,
  additionalCancelSteal,
  uploadLearnerDocument,
} from 'src/utils/apiPath'

export const getLearnerDocument = createAsyncThunk(
  'learnerDocument/getLearnerDocument',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, classLearner } = payload
      const response = await axios.get(
        `${learnerDocument}/${uuid}?id=${classLearner}`
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getRegisterDocument = createAsyncThunk(
  'learnerDocument/getRegisterDocument',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${registerDocument}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const putLearnerDocument = createAsyncThunk(
  'learnerDocument/putLearnerDocument',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, body } = payload
      const response = await axios.put(`${learnerDocument}/${uuid}`, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postRegisterDocument = createAsyncThunk(
  'learnerDocument/postRegisterDocument',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, body } = payload
      const response = await axios.post(`${registerDocument}/${uuid}`, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getAddtionalConsent = createAsyncThunk(
  'consent',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, managerId } = payload

      const response = await axios.get(
        `${additionalConsent}/${uuid}/${managerId}`
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getAddtionalConsentAndDocs = createAsyncThunk(
  'consentAndDocs',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, id, managerId } = payload
      const response = await axios.get(
        `${additionalConsent}/${id}/${uuid}/${managerId}`
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postAddtionalConsentAndDocs = createAsyncThunk(
  'postConsentAndDocst',
  async (payload, { rejectWithValue }) => {
    try {
      const { uuid, body, managerId, id, formatDate, formatIdCard } = payload
      const newBody = {
        ...body,
        idCardNo: formatIdCard,
        dateOfBirth: formatDate,
      }
      const response = await axios.post(
        `${additionalConsent}/${id}/${uuid}/${managerId}`,
        newBody
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCancelSteal = createAsyncThunk(
  'additionalCancelSteal',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(additionalCancelSteal, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const uploadRegisterDocument = createAsyncThunk(
  'classDetail/uploadRegisterDocument',
  async ({ body, name }, { rejectWithValue }) => {
    try {
      const response = await axios.post(uploadLearnerDocument, body)
      return {..._.get(response, 'data', {}), name}
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)