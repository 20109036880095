import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette?.other?.divider}`,
  width: '100%',
  padding: '25px 16px',
  gap: '16px',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
  },
}))

export const Enroll = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary?.main,
  gap: '10px',
  cursor: 'pointer',
}))

export const CellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '0px',
  },
}))

export const StyledDivider = styled(Box)(({ theme }) => ({
  width: '1px',
  height: '20px',
  background: theme.palette.text?.secondary,
}))

export const LeftCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
}))
