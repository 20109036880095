import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  mayProfile,
  userCalendar,
  learnerTodoList,
  learnerTodoListStandalone,
  profileBadge,
  profilePointHistory,
  redeemableCatalog,
} from '../../utils/apiPath'

export const getMyProfile = createAsyncThunk(
  'learner/getMyProfile',
  async (i, { rejectWithValue }) => {
    try {
      const response = await axios.get(mayProfile)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postUserCalendar = createAsyncThunk(
  'learner/userCalendar',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(userCalendar, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postLearnerTodoList = createAsyncThunk(
  'learner/todoList',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(learnerTodoList, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postLearnerTodoListStandalone = createAsyncThunk(
  'learner/postLearnerTodoListStandalone',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(learnerTodoListStandalone, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postPointHistory = createAsyncThunk(
  'profile/postPointHistory',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(profilePointHistory, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getProfileBadge = createAsyncThunk(
  'profile/getProfileBadge',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(profileBadge)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getRedeemableCatalog = createAsyncThunk(
  'profile/getRedeemableCatalog',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(redeemableCatalog)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
