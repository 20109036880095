import {
  setSectionEvaluate,
  setSectionResult,
} from 'src/redux/slices/eEvaluation'
import {
  startLessonLoading,
  stopLessonLoading,
} from 'src/redux/slices/eLearning/learning'
import { getEEvaluationDetail } from 'src/services/eEvaluation'

export const fetchEEvaluation = (payload, isReview) => async (dispatch) => {
  dispatch(startLessonLoading())
  if (isReview) dispatch(setSectionResult())
  else dispatch(setSectionEvaluate())
  await dispatch(getEEvaluationDetail(payload))
  dispatch(stopLessonLoading())
}
