import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const ClassStatusContainer = styled(Box)(({ theme, color, status }) => {
  let height = 244
  if (status === 'ELIGIBLE_ANNOUNCE') height = 284
  if (status === 'REJECTED_DOCUMENT') height = 294
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    height,
    background: _.get(theme, `palette.${color}`, ''),
    borderRadius: '2px',
    padding: '32px',
  }
})

export const BoxLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'center',
  alignItems: 'center',
}))

export const LabelContact = styled(Typography)(() => ({
  textDecoration: 'underline',
  cursor: 'pointer',
}))
