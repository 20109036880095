import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { fullformStatus } from 'src/utils/apiPath'

export const getFullFormStatus = createAsyncThunk(
  'dms/getFullFormStatus',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${fullformStatus}/${uuid}`)
      return _.get(response, 'data', [])
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
