import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Column, Row } from 'src/components/Layout/Styled'
import { E_LEARNING_LEARNER_LOG_RESULT } from 'src/constants/eLearning'
import { StyledTypography } from '../../Styled'
import {
  StyledCarouselArrow,
  StyledCourseCard,
  StyledIconWrapper,
  StyledProgressIcon,
  StyledSuccessIcon,
  StylelFailIcon,
} from './Styled'
import './style.css'
import { handleSelectCourse } from './events'

const CourseList = () => {
  const dispatch = useDispatch()
  const data = useSelector(
    (state) => state.eLearningLearningPath.data,
    shallowEqual
  )
  const courses = _.get(data, 'eLearningLearningPathCourses', [])
  const isRegister = _.get(data, 'isRegister', false)
  return (
    <Column gap={1}>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h5" color="primary">
          รายการหลักสูตร ({courses.length})
        </Typography>
        {isRegister && (
          <Row sx={{ width: 'auto', gap: 1 }}>
            <Row sx={{ width: 'auto', gap: 0.5 }}>
              <StyledSuccessIcon />
              <Typography variant="body2" color="text.secondary">
                สำเร็จการอบรม
              </Typography>
            </Row>
            <Typography variant="body2" color="text.secondary">
              /
            </Typography>
            <Row sx={{ width: 'auto', gap: 0.5 }}>
              <StylelFailIcon />
              <Typography variant="body2" color="text.secondary">
                ไม่ผ่านการอบรม
              </Typography>
            </Row>
            <Typography variant="body2" color="text.secondary">
              /
            </Typography>
            <Row sx={{ width: 'auto', gap: 0.5 }}>
              <StyledProgressIcon />
              <Typography variant="body2" color="text.secondary">
                กำลังเรียน
              </Typography>
            </Row>
          </Row>
        )}
      </Row>
      <Box>
        <Carousel
          className="learning-path-course-list"
          swipeable
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          onClickItem={(_, item) => dispatch(handleSelectCourse(item))}
          renderArrowPrev={(onClickHandler) => (
            <CustomArrow onClick={onClickHandler} />
          )}
          renderArrowNext={(onClickHandler) => (
            <CustomArrow onClick={onClickHandler} isNext />
          )}
        >
          {courses.map((course, index) => (
            <CourseCard key={index} data={course} />
          ))}
        </Carousel>
      </Box>
    </Column>
  )
}

export default CourseList

export const CustomArrow = ({ onClick, isNext }) => {
  return (
    <StyledCarouselArrow isNext={isNext} color="primary" onClick={onClick}>
      {isNext ? <ChevronRight /> : <ChevronLeft />}
    </StyledCarouselArrow>
  )
}

export const CourseCard = ({ data }) => {
  const { selectedCourse, isRegister } = useSelector(
    (state) => ({
      selectedCourse: state.eLearningLearningPath.selectedCourse,
      isRegister: _.get(state.eLearningLearningPath.data, 'isRegister', false),
    }),
    shallowEqual
  )
  const course = _.get(data, 'course', {})
  const coverImage = _.get(course, 'coverImage', course.imageKey)
  return (
    <StyledCourseCard
      type={data.type}
      selected={selectedCourse === course.uuid}
    >
      {isRegister && <CourseStatusIcon status={_.get(data, 'status', '')} />}
      <img src={`${window.__env__.REACT_APP_FILE_URL}/get${coverImage}`} />
      <Box p={2}>
        <StyledTypography variant="body1b" sx={{ lineHeight: '24px' }}>
          {_.get(course, 'nameForLearner', '') ||
            _.get(course, 'name', '') ||
            _.get(course, 'englishName', '')}
        </StyledTypography>
      </Box>
    </StyledCourseCard>
  )
}

export const CourseStatusIcon = ({ status }) => {
  switch (status) {
    case E_LEARNING_LEARNER_LOG_RESULT.INPROGRESS:
      return (
        <StyledIconWrapper>
          <StyledProgressIcon isCard />
        </StyledIconWrapper>
      )
    case E_LEARNING_LEARNER_LOG_RESULT.PASS:
      return (
        <StyledIconWrapper>
          <StyledSuccessIcon isCard />
        </StyledIconWrapper>
      )
    case E_LEARNING_LEARNER_LOG_RESULT.FAIL:
      return (
        <StyledIconWrapper>
          <StylelFailIcon isCard />
        </StyledIconWrapper>
      )
    default:
      return <></>
  }
}
