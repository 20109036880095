import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const BoxCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  border: '1px solid #DBE4F1',
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: '2px',
}))

export const BoxContent = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(3),
  gap: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const BoxImage = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const BoxColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  maxWidth: 'calc(100% - 148px)',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}))

export const Image = styled('img')(({ theme }) => ({
  width: 98,
  height: 98,
  objectFit: 'cover',
  borderRadius: '50%',
  border: `4px solid ${theme.palette.primary?.main}`,
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
}))

export const BoxListContent = styled(Box)(({ theme, isProfile }) => ({
  display: 'flex',
  flexDirection: isProfile ? 'column' : 'row',
  width: '100%',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}))

export const ColumnCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #DBE4F1',
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: '4px',
}))

export const BlueBgHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  background: theme.palette.primary.main,
  padding: '4px 12px',
}))

export const PointBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '8px 12px',
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1),
  border: '1px solid #00008F',
  boxShadow: '0px -3px 0px 0px #00006D inset',
}))
