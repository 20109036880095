import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import _ from 'lodash'
import { io } from 'socket.io-client'
import { COURSE_TYPE } from 'src/constants/eLearning'
import { getQueryParams } from 'src/utils/lib'
import FloatingButtons from './components/FloatingButtons'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import CameraContent from './CameraDialog'
import ClickDialog from './ClickDialog'
import LearningAlertDialog from './AlertDialog'
import { initialDialog } from './AlertDialog/model'
import { StyledContainer } from './Styled'
import { handleInitLearning, handleSocket, handleUpdateLog } from './events'

const { __env__: env } = window

const socket = io.connect(env.REACT_APP_SOCKET_URL, {
  autoConnect: false,
  transports: ['websocket', 'polling'],
  auth: {
    Authorization: localStorage.getItem('token'),
  },
})

const Learning = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const batchUuid = getQueryParams('batch')
  const course = useSelector(
    (state) => state.eLearningLearning.course,
    shallowEqual
  )
  const courseType = _.get(course, 'courseType', '')
  const isOic = courseType === COURSE_TYPE.OIC
  const [socketId, setSocketId] = useState('')
  const uuidProps = { uuid, batchUuid }
  const [alertDialog, setAlertDialog] = useState({ ...initialDialog })

  /* ------ socket handling ------- */
  useEffect(() => {
    socket.connect()
  }, [])

  useEffect(() => {
    dispatch(handleSocket(socket, setSocketId, uuidProps))
  }, [])

  useEffect(() => {
    dispatch(handleInitLearning(socketId, uuidProps))
  }, [socketId])
  /* ------ socket handling ------- */

  useEffect(() => {
    const intervalCall = setInterval(() => dispatch(handleUpdateLog()), 10000)
    return () => {
      clearInterval(intervalCall)
    }
  }, [])

  if (_.isEmpty(course)) return <></>

  return (
    <Box sx={{ position: 'relative' }}>
      {isOic && (
        <>
          <CameraContent />
          <ClickDialog />
        </>
      )}

      <StyledContainer>
        <FloatingButtons socket={socket} />

        <LeftPanel />

        <RightPanel setAlertDialog={setAlertDialog} />
      </StyledContainer>

      <LearningAlertDialog
        alertDialog={alertDialog}
        setAlertDialog={setAlertDialog}
      />
    </Box>
  )
}

export default Learning
