import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Template from 'src/components/Template'
import { Row } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { LikeIconButton } from '../../../components/LikeButton'
import { StyledTypography } from '../Styled'
import {
  StyledHeaderImage,
  StyledTextBtn,
  StyledHeaderWrapper,
  StyledHeaderContainer,
  StyledTooltip,
} from './Styled'
import { handleLikeCourse } from '../events'
import { handleBack } from './events'

const Header = () => {
  const { data, isLikeLoading } = useSelector(
    (state) => ({
      data: state.eLearningLearningPath.data,
      isLikeLoading: state.eLearningLearningPath.isLikeLoading,
    }),
    shallowEqual
  )
  const coverImage = _.get(data, 'coverImage', '')
  const isNotSm = isMediaWidthMoreThan('sm')
  const isLike = _.get(data, 'isLike', false)
  const dispatch = useDispatch()

  if (isNotSm) {
    return <TabletDesktopHeader data={data} coverImage={coverImage} />
  }

  return (
    <StyledHeaderWrapper>
      <Row sx={{ justifyContent: 'space-between' }}>
        <StyledTextBtn onClick={handleBack}>กลับ</StyledTextBtn>
        <Typography variant="body1b">เส้นทางการเรียนรู้</Typography>
        <LikeIconButton
          isLike={isLike}
          isLoading={isLikeLoading}
          onClick={() => dispatch(handleLikeCourse())}
        />
      </Row>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_FILE_URL}/get${coverImage}`}
        />
      )}
      <StyledTypography variant="h6">
        {_.get(data, 'nameForBanner', '')}
      </StyledTypography>
    </StyledHeaderWrapper>
  )
}

export default Header

export const TabletDesktopHeader = ({ data, coverImage }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const name = _.get(data, 'nameForBanner', '')
  return (
    <StyledHeaderWrapper>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_API_URL}/file/get/${coverImage}`}
        />
      )}
      <Template noMinHeight>
        <StyledTextBtn onClick={handleBack}>กลับ</StyledTextBtn>

        <StyledHeaderContainer>
          <Box>
            <Typography variant="h6" color="text.alabaster">
              เส้นทางการเรียนรู้
            </Typography>
            <StyledTooltip title={name}>
              <StyledTypography variant={isLg ? 'h2' : 'h4'} color="text.white">
                {name}
              </StyledTypography>
            </StyledTooltip>
          </Box>
        </StyledHeaderContainer>
      </Template>
    </StyledHeaderWrapper>
  )
}
