import _ from 'lodash'

import { openDialog, closeDialog } from '../../../redux/slices/alertDialog'

import {
  setFetchData,
  setChangeCourse,
  setDeleteProspect,
  setFetchProspect,
  setChecked,
  setIsSuccess,
  setAlertProspect,
} from '../../../redux/slices/manageProspectEnrollment'
import { startLoading, stopLoading } from '../../../redux/slices/loading'

import {
  getProspectCourseList,
  postProspectCheckProspect,
  postProspectAddToClass,
} from '../../../services/manageProspect'

export const handleDialog = (text) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'บันทึกข้อมูล  -> ' + text,
      message: ['คุณต้องการบันทึกข้อมูลนี้?'],
      buttonLeft: {
        label: 'ยกเลิก',
      },
      buttonRight: {
        handleClick: () => dispatch(closeDialog()),
      },
    })
  )
}

export const fetchData = (prospect) => async (dispatch) => {
  dispatch(startLoading())
  const data = await Promise.all([
    dispatch(getProspectCourseList()),
    dispatch(
      postProspectCheckProspect({
        trainingPlanUuid: '',
        prospects: _.isArray(prospect) ? prospect : Array(prospect),
      })
    ),
  ])

  dispatch(
    setFetchData({
      optionCourse: filterOptionCourse(_.get(data, '[0].payload', [])),
      prospects: _.get(data, '[1].payload', []),
    })
  )
  dispatch(stopLoading())
}

export const filterOptionCourse = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      label: `${item.courseCode} - ${item.englishName}`,
      value: item.uuid,
      classDate: item.classDate.map((itemClass) => {
        return {
          label: itemClass.startDate + ' - ' + itemClass.finishDate,
          value: itemClass.uuid,
        }
      }),
    }
  })
}

export const handleChangeCourse = (props) => (dispatch) => {
  dispatch(setChangeCourse(props))
}

export const handleDelete =
  (index, uuid, prospectDetail, setProspectDetail) => (dispatch) => {
    const detail = prospectDetail
    const prospectDetailIndex = _.findIndex(detail, (ele) => ele.uuid == uuid)
    detail.splice(prospectDetailIndex, 1)
    setProspectDetail(detail)
    dispatch(setDeleteProspect(index))
  }

export const checkDataSuccess = (prospects) => {
  if (!prospects.length) return 0
  let count = 0
  prospects.filter((item) => {
    if (item.status == 'SUCCESS') count++
  })
  return count
}

export const handleFetchStatus = (course, prospects) => async (dispatch) => {
  dispatch(startLoading())

  const result = await dispatch(
    postProspectCheckProspect({
      trainingPlanUuid: _.get(course, 'trainingPlan.value', ''),
      prospects: prospects.map((item) => item.uuid),
    })
  )
  dispatch(setFetchProspect(_.get(result, 'payload', [])))
  dispatch(setChecked(true))
  dispatch(stopLoading())
}

export const handleChecked = () => async (dispatch) => {
  dispatch(setChecked())
}
export const findUuidSuccess = (prospects) => {
  if (!prospects.length) return []
  let result = []
  prospects.filter((item) => {
    if (item.status == 'SUCCESS') result.push(item.uuid)
  })
  return result
}
export const handleSubmit = (prospects, trainingPlanUuid) => (dispatch) => {
  const body = {
    trainingPlanUuid,
    prospects: findUuidSuccess(prospects),
  }
  dispatch(
    openDialog({
      title: 'บันทึกข้อมูล',
      message: ['คุณต้องการบันทึกข้อมูลนี้?'],
      buttonLeft: {
        label: 'ยกเลิก',
      },
      buttonRight: {
        handleClick: () => dispatch(actionSubmit(body)),
      },
    })
  )
}

export const actionSubmit = (body) => async (dispatch) => {
  dispatch(startLoading())
  const result = await dispatch(postProspectAddToClass(body))
  const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
  if (requestStatus === 'fulfilled') {
    dispatch(setIsSuccess())
    dispatch(closeDialog())
    dispatch(setAlertProspect(false))
  }

  dispatch(stopLoading())
}
