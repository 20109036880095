import React from 'react'
import IconButton from '@mui/material/IconButton'
import FilterListIcon from '@mui/icons-material/FilterList'
import { StyledNotiDot } from './Styled'

const FilterButton = ({ onClick, isOnFilter }) => {
  return (
    <IconButton
      id="btn-filter"
      data-testid="btn-filter"
      size="small"
      color="primary"
      sx={{ position: 'relative' }}
      onClick={onClick}
    >
      {isOnFilter && <StyledNotiDot />}
      <FilterListIcon />
    </IconButton>
  )
}

export default FilterButton
