import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import _ from 'lodash'
import dayjs from 'dayjs'

const DatePicker = (props) => {
  const {
    boxSx,
    labelText,
    required,
    disabled,
    id,
    onChange,
    textError,
    placeholder,
    value,
    hideTextError,
    disabledDate,
    hideDisableDateRange,
    ignoreDateRange,
    disabledInput,
    disabledStartDate,
    disabledEndDate,
    disabledMonthlyPlan,
    disabledDateRange,
    isShouldDisableDate,
    currentDate,
    variant,
    hideInput,
    onError,
  } = props

  const handleChange = (newValue) => {
    onChange(newValue)
  }

  const disabledList = []
  if (disabledDate && disabledDate.length > 0) {
    disabledDate.forEach((item) => {
      if (!_.isEqual(ignoreDateRange, item)) {
        var startDate = item.startDate
        var endDate = item.finishDate
        let start = dayjs(startDate)
        const end = dayjs(endDate)
        while (start <= end) {
          disabledList.push(
            dayjs(start).format(window.__env__.REACT_APP_DATE_DB)
          )
          start = dayjs(start).add(1, 'day')
        }
      }
    })
  }
  const disableDateRange = (date) => {
    let isDisabled = false
    const newDate = dayjs(date).format(window.__env__.REACT_APP_DATE_DB)

    if (currentDate && newDate < currentDate) return true

    const concatDate = dayjs(date)
      .hour(0)
      .format(window.__env__.REACT_APP_DATE_DB)
    const find = disabledList.find((item) => concatDate === item)

    if (find) isDisabled = true
    let checkDateStart = dayjs(disabledStartDate).format(
      window.__env__.REACT_APP_DATE_DB
    )
    let checkDateEnd = dayjs(disabledEndDate).format(
      window.__env__.REACT_APP_DATE_DB
    )

    if (disabledMonthlyPlan?.month) {
      const getFirstDayOfMonth = new Date(
        disabledMonthlyPlan.year,
        disabledMonthlyPlan.month,
        1
      )
      const getLastDayOfMonth = new Date(
        disabledMonthlyPlan.year,
        disabledMonthlyPlan.month + 1,
        0
      )
      const firstDayOfMonth = dayjs(getFirstDayOfMonth).format(
        window.__env__.REACT_APP_DATE_DB
      )
      const lastDayOfMonth = dayjs(getLastDayOfMonth).format(
        window.__env__.REACT_APP_DATE_DB
      )
      if (lastDayOfMonth < newDate || firstDayOfMonth > newDate) {
        isDisabled = true
      }
    }
    if (!disabledDateRange) {
      if (isShouldDisableDate) {
        checkDateStart =
          checkDateStart === 'Invalid Date' ? null : checkDateStart
        checkDateEnd = checkDateEnd === 'Invalid Date' ? null : checkDateEnd

        if (id === 'startDate') {
          isDisabled = newDate > checkDateEnd
          if (checkDateEnd === null) isDisabled = false
        } else {
          isDisabled = newDate < checkDateStart
          if (checkDateStart === null) isDisabled = false
        }
      } else {
        if (
          id === 'startDate' &&
          disabledEndDate !== null &&
          newDate > checkDateEnd
        ) {
          isDisabled = true
        }

        if (
          id === 'finishDate' &&
          !_.isEmpty(disabledEndDate) &&
          disabledEndDate < newDate
        ) {
          isDisabled = true
        }
        if (id === 'finishDate' && checkDateStart > newDate) {
          isDisabled = true
        }
      }
    }
    return isDisabled
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant={variant ?? 'body2'}
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled || disabledInput ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          value={value}
          inputFormat="dd/MM/yyyy"
          shouldDisableDate={!hideDisableDateRange ? disableDateRange : ''}
          label={''}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                width: '100%',
                svg: { color: '#CCCCCC' },
                display: hideInput ? 'none' : '',
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: placeholder ? placeholder : '',
                'data-testid': 'date-picker',
              }}
            />
          )}
          disabled={disabledInput}
          onError={onError}
        />
      </LocalizationProvider>
      {!hideTextError && (
        <>
          {textError ? (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          ) : (
            <Box sx={{ height: '25px' }}>{textError}</Box>
          )}
        </>
      )}
    </Box>
  )
}

export default DatePicker
