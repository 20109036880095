import _ from 'lodash'
import { setFetchData } from '../../redux/slices/myProfile'
import {
  getMyProfile,
  postUserCalendar,
  getProfileBadge,
  getRedeemableCatalog,
} from '../../services/myProfile'
import { startLoading, stopLoading } from '../../redux/slices/loading'

export const fetchData = (bodyCalendar) => async (dispatch) => {
  dispatch(startLoading())

  const result = await Promise.all([
    dispatch(getMyProfile()),
    dispatch(postUserCalendar(bodyCalendar)),
    dispatch(getProfileBadge()),
    dispatch(getRedeemableCatalog()),
  ])

  dispatch(
    setFetchData({
      details: _.get(result, '[0]payload.data', {}),
      calendar: _.get(result, '[1]payload.data', []),
      badgeData: _.get(result, '[2]payload.data', []),
      catalogData: _.get(result, '[3]payload.data', []),
      currentDate: bodyCalendar.startDate,
    })
  )

  dispatch(stopLoading())
}
