import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Template from 'src/components/Template'
import Grid from '@mui/material/Grid'
import { Column } from 'src/components/Layout/Styled'
import ELearningCard from 'src/components/ELearningCard'
import ContentNoClass from 'src/components/ContentNoClass'
import { LoadMore } from 'src/redux/slices/eLearning/collection'
import { getELearningFilter } from 'src/services/eLearning/list'
import { MobileHeader, TabletDesktopHeader } from '../../components'
import Header from '../../components/Header'
import { StyledLoading } from '../../components/Styled'
import { SECTION_KEY } from '../../model'
import {
  StyledWrapper,
  StyledContainer,
  StyledRowContainer,
  StyledCardBox,
  StyledGrid,
} from './styled'
import { filterCollection } from './events'
import Filter from './Filter'
import ListHeader from './ListHeader'

const List = () => {
  const dispatch = useDispatch()
  const { filter, totalCount, collection, isFetch, page } = useSelector(
    (state) => ({
      filter: state.eLearningCollection.filter,
      totalCount: state.eLearningCollection.totalCount,
      collection: state.eLearningCollection.collection,
      isFetch: state.eLearningCollection.isFetch,
      page: state.eLearningCollection.filter.page,
    }),
    shallowEqual
  )
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getELearningFilter())
  }, [])
  useEffect(() => {
    if (isFetch) {
      dispatch(filterCollection(filter))
    }
  }, [page, isFetch])
  useEffect(() => {
    const handleWindowScroll = () => {
      const footer = document.getElementById('page-footer')
      if (collection.length < totalCount && !isFetch) {
        if (
          window.innerHeight + Math.round(window.scrollY) >
          document.body.offsetHeight - footer.offsetHeight
        ) {
          dispatch(LoadMore())
        }
      }
    }
    window.addEventListener('scroll', handleWindowScroll)
    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [isFetch, collection])
  return (
    <StyledWrapper>
      <Template maxWidth={1440}>
        <MobileHeader keySection={SECTION_KEY.COLLECTION} total={totalCount} />
        <Header title="Collection ทั้งหมด" />
        <StyledContainer>
          <ListHeader />
          <StyledRowContainer>
            <Filter />
            <Column sx={{ gap: 3 }}>
              <TabletDesktopHeader
                keySection={SECTION_KEY.COLLECTION}
                total={totalCount}
              />
              <StyledCardBox>
                <Grid container spacing={2}>
                  {collection.length > 0 ? (
                    collection.map((data, index) => (
                      <StyledGrid key={index}>
                        <ELearningCard
                          course={data}
                          keySection={SECTION_KEY.COLLECTION}
                        />
                      </StyledGrid>
                    ))
                  ) : (
                    <ContentNoClass />
                  )}
                </Grid>
                {isFetch && <StyledLoading />}
              </StyledCardBox>
            </Column>
          </StyledRowContainer>
        </StyledContainer>
      </Template>
    </StyledWrapper>
  )
}
export default List
