import { Typography } from '@mui/material'
import React from 'react'

const Text = ({ data }) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontSize: '14px',
        display: 'block',
        whiteSpaceCollapse: 'collapse',
        lineHeight: '19.6px',
      }}
      dangerouslySetInnerHTML={{
        __html: data.content.text,
      }}
    />
  )
}

export default Text
