import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledHeadWrapper = styled(Row)(({ theme }) => ({
  alignItems: 'start',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: { gap: theme.spacing(2) },
}))

export const StyledContent = styled(Column)(({ theme }) => ({
  padding: `${theme.spacing(5)} 0`,
  gap: theme.spacing(5),
  [theme.breakpoints.down('md')]: { gap: theme.spacing(2) },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(3)} 0`,
  },
}))

export const StyledResponsiveRow = styled(Row)(({ theme }) => ({
  alignItems: 'start',
  gap: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
  [theme.breakpoints.down('sm')]: { gap: theme.spacing(5.5) },
}))

export const StyledWrapper = styled(Column)(({ theme }) => ({
  paddingLeft: 102,
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: { alignItems: 'start' },
  [theme.breakpoints.down('sm')]: { paddingLeft: 0 },
}))

export const StyledContainer = styled(Column)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: { width: 343 },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 50,
  padding: '0 22px',
  borderRadius: theme.spacing(0.5),
  [theme.breakpoints.down('sm')]: {
    height: 40,
    padding: `0 ${theme.spacing(2)}`,
  },
}))

export const StyledPreview = styled('img')(() => ({
  objectFit: 'cover',
  width: 254,
  height: 160,
}))

export const StyledQrCodeBox = styled(Row)(({ theme }) => ({
  width: 'auto',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  padding: `6px ${theme.spacing(2)}`,
  border: '1px solid',
  borderColor: theme.palette?.info?.main,
  borderRadius: theme.spacing(0.5),
  color: theme.palette?.info?.content,
}))

export const StyledRowWrapper = styled(Row)(({ theme }) => ({
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(2),
  },
}))
