import { store } from 'src/App'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getEConfiguration } from 'src/services/eConfiguration'
import { getCourseBatch } from 'src/services/eLearning/enroll'

export const fetchCourseDetail = (uuid) => async (dispatch) => {
  dispatch(getEConfiguration())
  dispatch(startLoading())
  await dispatch(getCourseBatch(uuid))
  dispatch(stopLoading())
}

export const getEnrollState = () => {
  return store.getState().eLearningEnroll
}
