import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import { formatData } from './events'

const StatusClass = ({ status, classStatusText }) => {
  return (
    <>
      <Typography
        variant="body1"
        color="text.primary"
        sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
      >
        <Dot color={formatData(status)} />
        {classStatusText}
      </Typography>
    </>
  )
}

export default StatusClass

export const Dot = ({ color }) => {
  return (
    <Box
      sx={{ width: '8px', height: '8px', bgcolor: color, borderRadius: '50%' }}
    ></Box>
  )
}
