import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const checkIn = createSlice({
  name: 'checkIn',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setFetchData: (state, { payload }) => {
      state.data = payload
    },
  },
})

export const { startLoading, stopLoading, setFetchData } = checkIn.actions

export default checkIn.reducer
