import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'

export const BoxCard = styled(Card)(() => ({
  width: '100%',
  border: '1px solid #DBE4F1',
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: '2px',
}))

export const BoxCardList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const BoxCardItem = styled(Card)(({ theme }) => ({
  width: 'calc(33% - 9px)',
  padding: theme.spacing(3),
  border: '1px solid #DBE4F1',
  borderRadius: '2px',
  boxShadow: 'unset',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const BoxScore = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  paddingTop: theme.spacing(2),
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'unset',
    gap: theme.spacing(6),
  },
}))
