import Typography from '@mui/material/Typography'
import React from 'react'
import { formatData } from './events'
import { Container, CellContainer } from './Styled'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
const NameCheckTableCell = ({
  startDateDay,
  startDateMonth,
  startDateYear,
  status,
}) => {
  return (
    <>
      <Container>
        <Typography variant="body1" sx={{ width: '140px' }}>
          {`${startDateDay} ${startDateMonth} ${startDateYear}`}
        </Typography>
        <CellContainer color={formatData(status, true)} status={status}>
          {status === 'STUDY' && <CheckCircleOutlineOutlinedIcon />}
          {status === 'MISSING' || status === 'TIME_OUT' ? (
            <CancelOutlinedIcon />
          ) : (
            ''
          )}
          <Typography
            variant="body1"
            sx={{ width: 'auto' }}
            color={formatData(status, true)}
          >
            {formatData(status, false)}
          </Typography>
        </CellContainer>
      </Container>
    </>
  )
}

export default NameCheckTableCell
