import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import { Form, Formik } from 'formik'
import Box from '@mui/material/Box'
import { setInitialBody } from '../../redux/slices/profile'
import { StyledDialog } from '../AlertDialog/Styled'
import { handleSubmit } from './events'
import { schama } from './schema'
import UpdateProfileForm from './UpdateProfileForm'
import { StyledDialogWrapper } from './Styled'
import { userType } from './UpdateProfileForm/model'

const UpdateProfileDialog = ({ isOpen, onClose }) => {
  const [user, setUser] = useLocalStorage('user')
  const isAgent = user?.userType === userType.AGENT
  const dispatch = useDispatch()
  const body = useSelector((state) => state.profile.body, shallowEqual)

  useEffect(() => {
    dispatch(setInitialBody(user))
  }, [user])

  if (_.isEmpty(body)) return <></>

  return (
    <Box>
      <StyledDialog customWidth={717} maxWidth={'sm'} open={isOpen}>
        <StyledDialogWrapper>
          <Formik
            initialValues={{ ...body, isAgent }}
            validationSchema={schama}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => {
              console.log('Form values:', values)
              dispatch(handleSubmit({ values, user, setUser, onClose }))
            }}
          >
            <Form>
              <UpdateProfileForm />
            </Form>
          </Formik>
        </StyledDialogWrapper>
      </StyledDialog>
    </Box>
  )
}

export default UpdateProfileDialog
