import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import DateRangeInput from 'src/components/DateRangeInput'

import { setSearch } from 'src/redux/slices/learner/list'
import Search from '../../../components/Search'
import { StyledFilterContainer, StyledSearchDateRow } from './Styled'
import { handleChangePeriod, handleResetPeriod } from './events'
import Typography from '@mui/material/Typography'

const FilterHeader = () => {
  const { filterBody } = useSelector(
    (state) => state.classLearnerList,
    shallowEqual
  )

  return (
    <StyledFilterContainer>
      <Typography variant="h6" justifyContent="start" width="700px">
        ประกาศผลผู้มีสิทธิ์อบรม E-Learning
      </Typography>
      <SearchAndDatePicker filterBody={filterBody} />
    </StyledFilterContainer>
  )
}

export default FilterHeader

export const SearchAndDatePicker = ({ filterBody }) => {
  const dispatch = useDispatch()
  const courseName = _.get(filterBody, 'courseName', '')
  const startDate = _.get(filterBody, 'startDate', '')
  const finishDate = _.get(filterBody, 'finishDate', '')

  const [text, setText] = useState(courseName)
  const isStartDate = startDate !== ''
  const isFinishDate = finishDate !== ''
  const defaultStart = isStartDate ? new Date(startDate) : new Date()
  const defaultFinish = isFinishDate ? new Date(finishDate) : new Date()

  return (
    <StyledSearchDateRow>
      <Search
        placeholder="ค้นหาชื่อหลักสูตร"
        text={text}
        setText={(e) => setText(e)}
        handleSearch={() => dispatch(setSearch(text))}
      />
      <DateRangeInput
        label="วันที่เริ่มอบรม:"
        placeholder="วว/ดด/ปปปป - วว/ดด/ปปปป"
        dateState={{
          startDate: defaultStart,
          endDate: defaultFinish,
          display: isStartDate,
          key: 'selection',
        }}
        onChange={(selectedDates) =>
          dispatch(handleChangePeriod(selectedDates))
        }
        onReset={() => dispatch(handleResetPeriod())}
      />
    </StyledSearchDateRow>
  )
}
