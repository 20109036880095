import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningCourseBatchLearner,
  eLearningCourseBatchLearnerLog,
  eLearningGetCertificate,
  eLearningLearningPathGrade,
} from '../../../utils/apiPath'

export const getRegistrationDetail = createAsyncThunk(
  'getRegistrationDetail',
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningGetCertificate}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getCourseBatchLearner = createAsyncThunk(
  'getCourseBatchLearnerLog',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseBatchLearner}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getCourseBatchLearnerLog = createAsyncThunk(
  'getCourseBatchLearnerLog',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${eLearningCourseBatchLearnerLog}/${uuid}`
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getLearningPathGrade = createAsyncThunk(
  'getLearningPathGrade',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningLearningPathGrade}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
