import { styled } from '@material-ui/core'
import { Drawer, TableCell } from '@mui/material'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  '& .MuiPaper-root.MuiDrawer-paper': {
    width: 320,
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    alignItems: 'center',
  },
}))

export const StyledTableCell = styled(TableCell)(() => ({
  padding: '11px 16px',
  height: 52,
  wordBreak: 'break-word',
}))
