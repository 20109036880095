import React from 'react'
import ArrowForward from '@mui/icons-material/ArrowForward'

import { openDialog, closeDialog } from '../../../redux/slices/alertDialog'
import { ContentDialogSkill } from '.'

export const handleMySkill = (history) => (dispatch) => {
  dispatch(
    openDialog({
      customWidth: 640,
      title: 'My Skill & Qualification',
      message: ['Skill ปัจจุบันของฉัน และ  Skill ที่ต้องเรียนรู้เพิ่มเติม'],
      content: <ContentDialogSkill />,
      buttonRight: {
        label: 'ตกลง',
        handleClick: () => dispatch(closeDialog()),
      },
      buttonLeft: {
        label: 'ไปที่หลักสูตรทั้งหมด',
        endIcon: <ArrowForward color="primary" />,
        handleClick: () => {
          history.push('/course')
          dispatch(closeDialog())
        },
      },
    })
  )
}

export const filterMySkill = (list) => {
  if (!list.length) return []
  return list.map((item) => item.productType)
}
