import React from 'react'

export const displayTh = {
  cameraMinSpecificationsHeadingText:
    'Camera does not meet minimum specifications',
  cameraMinSpecificationsMessageText:
    'Camera must support at least 320*240 resolution and 15 frames per second.',
  cameraNotFoundHeadingText: 'Camera is not accessible.',
  cameraNotFoundMessageText:
    'Check that a camera is connected and there is not another application using the camera. You may have to go into settings to grant camera permissions and close out all instances of your browser and retry.',
  a11yVideoLabelText: 'Webcam for liveness check',
  cancelLivenessCheckText: 'Cancel Liveness check',
  goodFitCaptionText: '',
  goodFitAltText:
    "Ilustration of a person's face, perfectly fitting inside of an oval.",
  hintCenterFaceInstructionText:
    'Instruction: Before starting the check, make sure your camera is at the center top of your screen and center your face to the camera. When the check starts an oval will show up in the center. You will be prompted to move forward into the oval and then prompted to hold still. After holding still for a few seconds, you should hear check complete.',
  hintFaceOffCenterText:
    'Face is not in the oval, center your face to the camera.',
  hintMoveFaceFrontOfCameraText: 'Move face in front of camera',
  hintTooManyFacesText: 'Ensure only one face is in front of camera',
  hintFaceDetectedText: 'Face detected',
  hintCanNotIdentifyText: 'Move face in front of camera',
  hintTooCloseText: 'ใกล้เกินไป',
  hintTooFarText: 'ขยับเข้ามาให้อยู่ในกรอบ',
  hintConnectingText: 'กำลังเชื่อมต่อ',
  hintVerifyingText: 'กำลังประมวลข้อมูล',
  hintCheckCompleteText: 'Check complete',
  hintIlluminationTooBrightText: 'Move to dimmer area',
  hintIlluminationTooDarkText: 'Move to brighter area',
  hintIlluminationNormalText: 'Lighting conditions normal',
  hintHoldFaceForFreshnessText: 'ระบบกำลังประมวลผล กรุณาอย่าขยับ',
  hintMatchIndicatorText: '50% completed. Keep moving closer.',
  photosensitivityWarningBodyText:
    'This check flashes different colors. Use caution if you are photosensitive.',
  photosensitivityWarningHeadingText: 'Photosensitivity warning',
  photosensitivityWarningInfoText:
    'Some people may experience epileptic seizures when exposed to colored lights. Use caution if you, or anyone in your family, have an epileptic condition.',
  photosensitivityWarningLabelText: 'More information about photosensitivity',
  photosensitivyWarningBodyText: '',
  photosensitivyWarningHeadingText: '',
  photosensitivyWarningInfoText:
    'Some people may experience epileptic seizures when exposed to colored lights. Use caution if you, or anyone in your family, have an epileptic condition.',
  photosensitivyWarningLabelText: 'More information about photosensitivity',
  retryCameraPermissionsText: 'Retry',
  recordingIndicatorText: '',
  tooFarCaptionText: '',
  tooFarAltText:
    "Illustration of a person's face inside of an oval; there is a gap between the perimeter of the face and the boundaries of the oval.",
  waitingCameraPermissionText: 'Waiting for you to allow camera permission.',
  hintCenterFaceText: 'จัดใบหน้าของคุณให้อยู่ตรงกลาง',
  startScreenBeginCheckText: <img src="/icon/e-learning/ic_camera.svg" />,
}
export const ERROR_TYPE = {
  DETECT: 'DETECT',
  VERIFY: 'VERIFY',
  SYSTEM: 'SYSTEM',
  TIMEOUT: 'TIMEOUT',
  RUNTIME_ERROR: 'RUNTIME_ERROR',
  SERVER_ERROR: 'SERVER_ERROR',
  CONNECTION_TIMEOUT: 'CONNECTION_TIMEOUT',
  FACE_DISTANCE_ERROR: 'FACE_DISTANCE_ERROR',
  CAMERA_ACCESS_ERROR: 'CAMERA_ACCESS_ERROR',
  CAMERA_FRAMERATE_ERROR: 'CAMERA_FRAMERATE_ERROR',
  FRESHNESS_TIMEOUT: 'FRESHNESS_TIMEOUT',
  MOBILE_LANDSCAPE_ERROR: 'MOBILE_LANDSCAPE_ERROR',
}

// isCount = นับครั้งที่ผิดพลาดหรือไม่
export const ERROR_OBJ = {
  [ERROR_TYPE.DETECT]: {
    title: 'ตรวจจับใบหน้าไม่สำเร็จ',
    message: 'ระบบตรวจจับใบหน้าไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
    isCount: true,
  },
  [ERROR_TYPE.VERIFY]: {
    title: 'ยืนยันตัวตนไม่สำเร็จ',
    message: 'ใบหน้าไม่ตรงกับบัตรประชาชน กรุณาลองใหม่อีกครั้ง',
    isCount: true,
  },
  [ERROR_TYPE.SYSTEM]: {
    title: 'ผิดพลาด',
    message: 'กรุณาลองใหม่อีกครั้ง',
    isCount: false,
  },
  [ERROR_TYPE.TIMEOUT]: {
    title: 'ตรวจจับใบหน้าไม่สำเร็จ',
    message: 'ระบบดำเนินการไม่สำเร็จภายในเวลาที่กำหนด กรุณาลองใหม่อีกครั้ง',
    isCount: false,
  },
  [ERROR_TYPE.RUNTIME_ERROR]: {
    title: 'ผิดพลาด',
    message: 'ระบบขัดข้อง กรุณาลองใหม่อีกครั้ง',
    isCount: false,
  },
  [ERROR_TYPE.SERVER_ERROR]: {
    title: 'ผิดพลาด',
    message: 'ระบบขัดข้อง กรุณาลองใหม่อีกครั้ง',
    isCount: false,
  },
  [ERROR_TYPE.CONNECTION_TIMEOUT]: {
    title: 'Connection Timeout',
    message: 'ทำการเชื่อมต่อระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
    isCount: false,
  },
  [ERROR_TYPE.CAMERA_ACCESS_ERROR]: {
    title: 'เปิดกล้องไม่สำเร็จ',
    message: [
      'ระบบไม่สามารถเข้าถึงกล้องของคุณได้ ',
      'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
    ],
    isCount: false,
  },
  [ERROR_TYPE.CAMERA_FRAMERATE_ERROR]: {
    title: 'ผิดพลาด',
    message: [
      'คุณภาพของกล้องไม่ตรงตามความต้องการของระบบ ',
      'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
    ],
    isCount: false,
  },
  [ERROR_TYPE.FRESHNESS_TIMEOUT]: {
    title: 'ตรวจสอบคุณภาพไม่สำเร็จ',
    message:
      'ระบบตรวจสอบคุณภาพของรูปภาพไม่สำเร็จภายในเวลาที่กำหนด กรุณาลองใหม่อีกครั้ง',
    isCount: false,
  },
  [ERROR_TYPE.MOBILE_LANDSCAPE_ERROR]: {
    title: 'ไม่รองรับโหมดแนวนอน',
    message: 'ระบบไม่รองรับการแสกนใบหน้าในโหมดแนวนอน กรุณาลองใหม่อีกครั้ง',
    isCount: false,
  },
}
