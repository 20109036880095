import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { COURSE_TYPE } from 'src/constants/eLearning'
import QuestionContent from '../../LeftPanel/QuestionContent'
import { LessonList } from '..'
import { TAB_VALUE } from './model'
import { StyledTabWrapper } from './Styled'

const MobileTabDisplay = () => {
  const [tab, setTab] = useState(TAB_VALUE.LESSON)
  const courseType = useSelector(
    (state) => _.get(state.eLearningLearning.course, 'courseType', ''),
    shallowEqual
  )
  return (
    <>
      <StyledTabWrapper>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(e, tab) => setTab(tab)}
        >
          <Tab label="เนื้อหาหลักสูตร" value={TAB_VALUE.LESSON} />
          {courseType === COURSE_TYPE.OIC && (
            <Tab label="ตั้งคำถาม / พูดคุย" value={TAB_VALUE.QUESTION} />
          )}
        </Tabs>
      </StyledTabWrapper>
      {tab === TAB_VALUE.LESSON && <LessonList />}
      {tab === TAB_VALUE.QUESTION && <QuestionContent />}
    </>
  )
}

export default MobileTabDisplay
