import { convertFormatDateTime } from '../../../utils/lib'
export const initialState = {
  isFetch: false,
  tapMenu: 'DAILY',
  details: {},
  calendar: [],
  isDrawer: false,
  currentDate: convertFormatDateTime({ value: new Date(), type: 'dateDb' }),
  toggle: false,
  bodyCalendar: {
    startDate: convertFormatDateTime({ value: new Date(), type: 'dateDb' }),
    toDate: convertFormatDateTime({ value: new Date(), type: 'dateDb' }),
    myClass: true,
    cancelClass: true,
    closed: true,
  },
  isOpenHistory: false,
  isBadgeSeeMore: false,
  badgeData: [],
  isCatalogSeeMore: false,
  catalogData: [],
}
