import React from 'react'
import _ from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import { path } from 'src/constants/path'
import {
  COURSE_TYPE,
  LEARNING_STATUS,
  E_LEARNING_LEARNER_STATUS,
} from 'src/constants/eLearning'
import { StyledBigButton } from './Styled'
import { handleEnrollment, handleGetInClass } from './events'

const EnrollButton = ({ color, scrolled }) => {
  const eLearningCourse = useSelector(
    (state) => state.eLearningDetail.data,
    shallowEqual
  )
  const learnerStatus = _.get(eLearningCourse, 'learnerStatus', undefined)
  const learnerResult = _.get(eLearningCourse, 'learnerResult', undefined)
  const isLearnerFinished = !_.isEmpty(learnerResult)
  const isEnrollOpen = _.get(eLearningCourse, 'isEnrollOpen', '')
  const isLearningButtonEnable = _.get(
    eLearningCourse,
    'isLearningButtonEnable',
    false
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const isVerify = _.get(eLearningCourse, 'isVerify', false)
  const batchLearner = _.get(eLearningCourse, 'eLearningCourseBatchLearner', {})
  const batchLearnerUuid = _.get(batchLearner, 'uuid', '')

  // ไม่เคยสมัคร และสามารถสมัครได้
  if (_.isUndefined(learnerStatus)) {
    // เปิดให้สมัคร
    if (isEnrollOpen) return <SubmitEnrollButton />
    // ยังไม่เปิดให้สมัคร
    return <EnrollCloseButton />
  }

  // รอการยืนยันตัวตน
  if (isVerify) {
    const uuid = _.get(batchLearner, 'eLearningCourseBatch.uuid', '')
    return (
      <StyledBigButton
        onClick={() =>
          (window.location.href = `${path.eLearningVerification}/${uuid}?learner=${batchLearnerUuid}`)
        }
      >
        รอยืนยันตัวตน
      </StyledBigButton>
    )
  }

  // เรียนจบ
  if (isLearnerFinished) {
    // บังคับลงทะเบียนอีกครั้งจาก Learning path
    if (isEnrollOpen) return <SubmitEnrollButton />

    // เรียนผ่าน
    const isOic = _.get(eLearningCourse, 'courseType', '') === COURSE_TYPE.OIC
    if (isOic) {
      return (
        <StyledBigButton
          onClick={() =>
            history.push(`${path.myClassELearning}/${batchLearnerUuid}`)
          }
        >
          คุณจบการอบรมหลักสูตรนี้แล้ว ตรวจสอบผลการเรียน
        </StyledBigButton>
      )
    } else {
      return (
        <StyledBigButton onClick={() => dispatch(handleGetInClass(history))}>
          ทบทวนอีกครั้ง
        </StyledBigButton>
      )
    }
  }

  switch (learnerStatus) {
    // คุณสมบัติไม่ผ่าน
    case E_LEARNING_LEARNER_STATUS.INELIGIBLE:
      return (
        <Column gap="10px" alignItems="center">
          <StyledBigButton scrolled={scrolled} disabled>
            ไม่สามารถลงทะเบียนเรียนได้ เนื่องจากไม่ผ่านคุณสมบัติเบื้องต้น
          </StyledBigButton>
          {!scrolled && (
            <Typography color={color || 'text.white'} textAlign="center">
              หากต้องการสอบถามข้อมูลเพิ่มเติม กรุณาติดต่อเจ้าหน้าที่ KTAXA
            </Typography>
          )}
        </Column>
      )
    // ประกาศผลการสมัครแล้ว
    case E_LEARNING_LEARNER_STATUS.ELIGIBLE_ANNOUNCE: {
      if (isLearningButtonEnable) {
        // อยู่ในระหว่างเรียน
        const isPending =
          _.get(eLearningCourse, 'learningStatus', '') ===
          LEARNING_STATUS.PENDING
        return (
          <StyledBigButton onClick={() => dispatch(handleGetInClass(history))}>
            {isPending ? 'เริ่มเรียน' : 'เรียนต่อ'}
          </StyledBigButton>
        )
      } else {
        return <StyledBigButton disabled>รอเข้าอบรม</StyledBigButton>
      }
    }
    case E_LEARNING_LEARNER_STATUS.WAITING_ANNOUNCE:
      return <StyledBigButton disabled>รอประกาศสิทธิ์</StyledBigButton>
    default:
      return <EnrollCloseButton />
  }
}

export default EnrollButton

export const SubmitEnrollButton = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  return (
    <StyledBigButton onClick={() => dispatch(handleEnrollment(uuid))}>
      ลงทะเบียน
    </StyledBigButton>
  )
}

export const EnrollCloseButton = () => {
  return <StyledBigButton disabled>ปิดรับสมัคร</StyledBigButton>
}
