import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/system/styled'

export default function BasicSelect(props) {
  const {
    sx,
    boxSx,
    labelText,
    required,
    id,
    name,
    disabled,
    textError,
    showTextError = true,
    options,
    handleChange,
    defaultValue,
    renderInput,
    optionImage,
    isTrainingPlans,
    isHideSort,
    isLoading,
  } = props
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="body2"
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <Autocomplete
        data-testid={id}
        id={id}
        name={name}
        sx={sx}
        options={
          isHideSort
            ? options
            : options.sort((a, b) => -b.label.localeCompare(a.label))
        }
        getOptionLabel={(option) => option.label}
        disabled={disabled}
        renderOption={(renderProps, option) => {
          if (isLoading) return <LoadingOption />
          return (
            <Box {...renderProps} key={option.value} id={option.value}>
              {optionImage && (
                <ListStaffImage
                  src={`${window.__env__.REACT_APP_FILE_URL}${option.image}`}
                />
              )}

              <Typography variant="body1" id={option.value}>
                {isTrainingPlans
                  ? `${option.firstNameTH} ${option.lastNameTH}`
                  : option.label}
              </Typography>
            </Box>
          )
        }}
        renderInput={renderInput}
        onChange={(e, element) => handleChange(e, element)}
        value={defaultValue}
      />

      {showTextError && (
        <>
          {textError ? (
            <Typography sx={{ height: '20px' }} variant="body2" color="error">
              {textError}
            </Typography>
          ) : (
            <Box sx={{ height: '20px' }}>{textError}</Box>
          )}
        </>
      )}
    </Box>
  )
}

const ListStaffImage = styled('img')({
  width: '35px',
  height: '35px',
  objectFit: 'cover',
  borderRadius: '50%',
  marginRight: '10px',
})

export const LoadingOption = () => {
  return (
    <Box sx={{ width: '100%', height: 50, position: 'relative' }}>
      <CircularProgress
        sx={{
          position: 'absolute',
          left: 220,
          top: 5,
          transform: 'translateX(-50%)',
        }}
      />
    </Box>
  )
}
