import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Template from 'src/components/Template'
import Header from './Header'
import TopSection from './TopSection'
import Content from './Content'
import { handleInitialPreview } from './events'
import { LoadingCircular, StyledWrapper } from './Styled'

const Detail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const isLoading = useSelector(
    (state) => state.eLearningLearningPath.isLoading,
    shallowEqual
  )

  useEffect(() => {
    dispatch(handleInitialPreview(id))
  }, [])

  if (isLoading) {
    return <LoadingCircular isLoading />
  }

  return (
    <StyledWrapper>
      <Header />

      <Template noMinHeight>
        <TopSection />
      </Template>

      <Content />
    </StyledWrapper>
  )
}

export default Detail
