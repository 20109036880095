import { IconButton, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { shallowEqual, useSelector } from 'react-redux'
import { Column, Row } from 'src/components/Layout/Styled'
import CloseIcon from '@mui/icons-material/Close'
import EventIcon from '@mui/icons-material/Event'
import ScheduleIcon from '@mui/icons-material/Schedule'
import BusinessIcon from '@mui/icons-material/Business'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { convertDateShortTh, removeRound } from '../../events'

const Round = ({ index }) => {
  const { round, examiners } = useSelector(
    (state) => ({
      round: state.eExamination.selectedRounds[index],
      examiners: state.eExamination.examiners,
    }),
    shallowEqual
  )
  return (
    <Column
      sx={{
        px: 2,
        py: 1,
        background:
          examiners.length <= round.available ? '#7698CB1A' : '#F5F5F5',
        rowGap: 1,
      }}
    >
      <RowDetail
        icon={<EventIcon sx={{ color: '#0000008A', fontSize: 16 }} />}
        detail={convertDateShortTh(round.date)}
        deleteBtn
        index={index}
      />
      <RowDetail
        icon={<ScheduleIcon sx={{ color: '#0000008A', fontSize: 16 }} />}
        detail={`${round.startTime} - ${round.endTime}`}
      />
      <RowDetail
        icon={<BusinessIcon sx={{ color: '#0000008A', fontSize: 16 }} />}
        detail={round.examinationField}
      />
      <RowDetail
        icon={
          <LocationOnOutlinedIcon sx={{ color: '#0000008A', fontSize: 16 }} />
        }
        detail={round.examinationLocation}
      />
      {examiners.length > round.available && (
        <Typography variant="body1b" color="error.main">
          จำนวนผู้สมัครเกินจำนวนที่ว่าง
        </Typography>
      )}
    </Column>
  )
}
const RowDetail = ({ icon, detail, deleteBtn, index }) => {
  const dispatch = useDispatch()
  return (
    <Row sx={{ columnGap: '6px' }}>
      {icon}
      <Typography variant="body1b">{detail}</Typography>
      {deleteBtn && (
        <IconButton
          sx={{ ml: 'auto', p: 0 }}
          onClick={() => dispatch(removeRound(index))}
        >
          <CloseIcon sx={{ color: '#00008F' }} />
        </IconButton>
      )}
    </Row>
  )
}
export default Round
