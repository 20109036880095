import React from 'react'
import Typography from '@mui/material/Typography'
import { StyledDashedBox } from './Styled'

const ExampleImage = ({ src = 'example_id_card.svg', disabled }) => {
  return (
    <StyledDashedBox sx={{ gap: 4 }} disabled={disabled}>
      <img src={`/image/${src}`} />
      <Typography variant="body1b" sx={{ fontStyle: 'italic' }}>
        ตัวอย่าง
      </Typography>
    </StyledDashedBox>
  )
}

export default ExampleImage
