import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import { convertFormatTime } from 'src/utils/lib'
import { Column } from 'src/components/Layout/Styled'
import { handleGetInClass } from '../events'
import { StyledFootBox } from '../Styled'
import { SECTION_KEY, SECTION_OBJ } from '../model'
import { StyledContentButton, StyledFlex } from './Styled'

const Content = ({ course, keySection }) => {
  const { link, keyCount, unit } = SECTION_OBJ[keySection]
  const courseLink = `${link}/${_.get(course, 'uuid', '')}`
  const time = convertFormatTime(course, true)
  const eTestingAmount = _.get(course, 'eTestingAmount', 0)
  const isInProgress = _.get(course, 'isInProgress', false)
  const progressPercent = _.get(course, 'progressPercent', 0)
  const dispatch = useDispatch()
  return (
    <>
      <Column gap={2} id="hover-content">
        <StyledFlex sx={{ flexDirection: 'column' }}>
          <FlexContent icon={<TimerOutlined color="primary" />}>
            <Typography variant="body2" color="text.secondary">
              {time}
            </Typography>
            {keySection !== SECTION_KEY.COURSE && (
              <StyledFlex sx={{ ml: 1, gap: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  &#8226;
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {_.get(course, keyCount, 0)} {unit}
                </Typography>
              </StyledFlex>
            )}
          </FlexContent>
          {eTestingAmount > 0 && (
            <FlexContent
              icon={
                <img
                  src="/icon/ic_box_list.svg"
                  style={{ width: 18, height: 18 }}
                />
              }
              isText
            >
              แบบฝึกหัดทั้งหมด {eTestingAmount} แบบฝึกหัด
            </FlexContent>
          )}
        </StyledFlex>
      </Column>
      <StyledFootBox>
        <StyledContentButton isInProgress={isInProgress}>
          <Button
            variant="outlined"
            onClick={() => (window.location.href = courseLink)}
          >
            รายละเอียด
          </Button>
          {isInProgress && (
            <Button
              variant="contained"
              onClick={() => dispatch(handleGetInClass(course))}
            >
              {progressPercent === 0
                ? 'เริ่มเรียน'
                : progressPercent === 100
                ? 'ทบทวนอีกครั้ง'
                : 'เรียนต่อ'}
            </Button>
          )}
        </StyledContentButton>
      </StyledFootBox>
    </>
  )
}

export default Content

export const FlexContent = ({ children, icon, isText }) => {
  return (
    <StyledFlex
      sx={{
        alignItems: 'center',
        svg: { fontSize: '18px', width: 18, height: 18 },
      }}
    >
      {icon}
      {isText ? (
        <Typography variant="body2" color="text.secondary">
          {children}
        </Typography>
      ) : (
        children
      )}
    </StyledFlex>
  )
}
