import { createListenerMiddleware } from '@reduxjs/toolkit'
import { closeDialog, resetDialog } from '.'

export const listenerMiddlewareDialog = createListenerMiddleware()

listenerMiddlewareDialog.startListening({
  actionCreator: closeDialog,
  effect: (action, listenerApi) => {
    setTimeout(() => {
      listenerApi.dispatch(resetDialog())
    }, 500)
  },
})
