import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { handleNextAndPrev } from '../events'

const Index = ({ calendarRef }) => {
  const dispatch = useDispatch()
  const { currentDate, tapMenu } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )
  return (
    <>
      <Box>
        <IconButton>
          <ChevronLeft
            color="primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(
                handleNextAndPrev({
                  type: 'prev',
                  currentDate,
                  calendarRef,
                  tapMenu,
                })
              )
            }}
          />
        </IconButton>
      </Box>
      <Box>
        <IconButton>
          <ChevronRight
            color="primary"
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              dispatch(
                handleNextAndPrev({
                  type: 'next',
                  currentDate,
                  calendarRef,
                  tapMenu,
                })
              )
            }
          />
        </IconButton>
      </Box>
    </>
  )
}

export default Index
