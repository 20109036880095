import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const manageProspectEnrollment = createSlice({
  name: 'manageProspectEnrollment',
  initialState,
  reducers: {
    setFetchData: (state, { payload }) => {
      state.optionCourse = payload.optionCourse
      state.prospects = payload.prospects
    },
    setChangeCourse: (state, { payload }) => {
      if (payload.key == 'course') {
        state.course = {
          course: payload.value,
          trainingPlan: null,
        }
      } else {
        _.set(state, `course.${payload.key}`, payload.value)
      }
    },
    setDeleteProspect: (state, { payload }) => {
      const prospects = state.prospects
      prospects.splice(payload, 1)
      _.set(state, 'prospects', prospects)
    },
    setFetchProspect: (state, { payload }) => {
      state.prospects = payload
    },
    setChecked: (state, { payload }) => {
      state.isChecked = payload ? false : !state.isChecked
    },
    setIsSuccess: (state, { payload }) => {
      state.isSuccess = payload ? false : true
    },
    setAlertProspect: (state, { payload }) => {
      state.alertProspect = payload
    },
    setProspectAddDrawerList: (state, { payload }) => {
      state.prospectAddDrawerList = payload
    },
  },
})

export const {
  setAlertProspect,
  setFetchData,
  setFetchProspect,
  setDeleteProspect,
  setChangeCourse,
  setChecked,
  setIsSuccess,
  setProspectAddDrawerList,
} = manageProspectEnrollment.actions

export default manageProspectEnrollment.reducer
