export const listBreadcrumbs = [
  {
    isLink: true,
    color: 'text.secondary',
    label: 'โปรไฟล์',
    link: '/my-profile',
  },
  {
    isLink: false,
    color: 'primary',
    label: 'To Do Lists',
  },
]

export const headCells = [
  {
    id: 'courseName',
    disablePadding: false,
    label: 'หลักสูตร',
  },
  {
    id: 'type',
    label: 'ประเภท',
  },
  {
    id: 'dateRangeMonthlyPlanDate',
    label: 'วันที่จัดอบรม',
  },
  {
    id: 'dateRangeClassTestingAndEvaDate',
    label: 'ระยะเวลาในการจัดการ',
  },
  {
    id: 'statusTH',
    label: 'สถานะ',
  },
  {
    id: 'isShowButton',
    label: '',
    width: '120px',
    hideSortIcon: true,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'ดูคลาส',
    minWidth: '50px',
    hideE: true,
    hideSortIcon: true,
    hideEdit: true,
    hideDelete: true,
    viewPath: '',
  },
]

export const headCellStandalone = [
  {
    id: 'name',
    disablePadding: false,
    label: 'ชื่อ',
  },
  {
    id: 'type',
    label: 'ประเภท',
  },
  {
    id: 'statusText',
    label: 'สถานะ',
  },
]

export const defaultFilter = {
  courseNameChecked: false,
  courseName: '',

  typeChecked: false,
  type: {
    testChecked: false,
    assessmentChecked: false,
    evaluationChecked: false,
  },

  trainingDateChecked: false,
  startDateTraining: new Date(),
  toDateTraining: new Date(),
  displayDateTraining: false,

  handlingDateChecked: false,
  startDateHandlingDate: new Date(),
  toDateHandlingDate: new Date(),
  displayDateHandling: false,
  statusChecked: false,
  status: {
    doneChecked: false,
    notOpenYetChecked: false,
    openingChecked: false,
    passChecked: false,
    notPassChecked: false,
    notDoneChecked: false,
  },
}

export const defaultFilterStandalone = {
  nameChecked: false,
  name: '',
  typeChecked: false,
  type: {
    etestingChecked: false,
    evaluationChecked: false,
  },
  statusChecked: false,
  status: {
    waitingChecked: false,
    passChecked: false,
    notPassChecked: false,
  },
}

export const arrayType = [
  {
    label: 'Test',
    name: 'testChecked',
  },
  {
    label: 'Assessment',
    name: 'assessmentChecked',
  },
  {
    label: 'E-Evaluation',
    name: 'evaluationChecked',
  },
]

export const arrayTypeStandalone = [
  {
    label: 'E-Testing',
    name: 'etestingChecked',
  },
  {
    label: 'E-Evaluation',
    name: 'evaluationChecked',
  },
]

export const arrayStatus = [
  {
    label: 'ทำแล้ว',
    name: 'doneChecked',
  },
  {
    label: 'ยังไม่เปิดให้ทำ',
    name: 'notOpenYetChecked',
  },
  {
    label: 'กำลังเปิดให้ทำ',
    name: 'openingChecked',
  },
  {
    label: 'ผ่าน',
    name: 'passChecked',
  },
  {
    label: 'ไม่ผ่าน',
    name: 'notPassChecked',
  },
  {
    label: 'ยังไม่ได้ทำ',
    name: 'notDoneChecked',
  },
]

export const arrayStatusStandalone = [
  {
    label: 'ทำแล้ว',
    name: 'waitingChecked',
  },
  {
    label: 'ผ่าน',
    name: 'passChecked',
  },
  {
    label: 'ไม่ผ่าน',
    name: 'notPassChecked',
  },
]

export const arrayFilter = [
  {
    label: 'หลักสูตร',
    nameCheckBox: 'courseNameChecked',
    nameInput: 'courseName',
    placeholder: 'ค้นหาหลักสูตร',
    typeFilter: 'default',
  },
  {
    label: 'ประเภท',
    nameCheckBox: 'typeChecked',
    typeFilter: 'type',
    list: arrayType,
  },
  {
    label: 'วันที่จัดอบรม',
    nameCheckBox: 'trainingDateChecked',
    nameInput: 'startDateTraining',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    typeFilter: 'date',
    startDate: 'startDateTraining',
    endDate: 'toDateTraining',
    display: 'displayDateTraining',
  },
  {
    label: 'ระยะเวลาในการจัดการ',
    nameCheckBox: 'handlingDateChecked',
    nameInput: 'startDateHandlingDate',
    placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
    typeFilter: 'date',
    startDate: 'startDateHandlingDate',
    endDate: 'toDateHandlingDate',
    display: 'displayDateHandling',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    typeFilter: 'status',
    list: arrayStatus,
  },
]

export const arrayFilterStandalone = [
  {
    label: 'ชื่อ',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อ',
    typeFilter: 'default',
  },
  {
    label: 'ประเภท',
    nameCheckBox: 'typeChecked',
    typeFilter: 'type',
    list: arrayTypeStandalone,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    typeFilter: 'status',
    list: arrayStatusStandalone,
  },
]

export const DEFAULT_TYPE = ['ASSESSMENT', 'ONLINE_TEST', 'E_EVALUATION']
export const DEFAULT_STATUS = [
  'DONE',
  'NOT_YET_DONE',
  'OPENING',
  'PASS',
  'NOT_PASS',
  'WAITING',
]
export const DEFAULT_BODY = {
  limit: 20,
  page: 1,
  sort: 'courseName',
  order: 'DESC',
  courseName: '',
  type: DEFAULT_TYPE,
  monthlyPlanStartDate: '',
  monthlyPlanFinishDate: '',
  classStartDate: '',
  classFinishDate: '',
  status: DEFAULT_STATUS,
}

export const DEFAULT_TYPE_STANDALONE = ['E_TESTING', 'E_EVALUATION']
export const DEFAULT_STATUS_STANDALONE = ['WAITING', 'PASS', 'NOT_PASS']
export const DEFAULT_BODY_STANDALONE = {
  sort: 'createdAt',
  order: 'DESC',
  limit: 20,
  page: -1,
  type: DEFAULT_TYPE_STANDALONE,
  status: DEFAULT_STATUS_STANDALONE,
}

export const objStatus = {
  doneChecked: 'DONE',
  notOpenYetChecked: 'NOT_OPEN_YET',
  openingChecked: 'OPENING',
  passChecked: 'PASS',
  notPassChecked: 'NOT_PASS',
  notDoneChecked: 'NOT_YET_DONE',
}
export const objType = {
  testChecked: 'ONLINE_TEST',
  assessmentChecked: 'ASSESSMENT',
  evaluationChecked: 'E_EVALUATION',
}

export const objStatusStandalone = {
  waitingChecked: 'WAITING',
  passChecked: 'PASS',
  notPassChecked: 'NOT_PASS',
}
export const objTypeStandalone = {
  etestingChecked: 'E_TESTING',
  evaluationChecked: 'E_EVALUATION',
}
