import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ListAlt from '@mui/icons-material/ListAlt'
import KeyboardArrowUpTwoTone from '@mui/icons-material/KeyboardArrowUpTwoTone'
import KeyboardArrowDownTwoTone from '@mui/icons-material/KeyboardArrowDownTwoTone'
import { Column, Row } from 'src/components/Layout/Styled'
import { StyledTypography } from 'src/components/CourseCard/Styled'
import { StyledHeadLabel } from 'src/modules/ELearning/Course/Learning/RightPanel/LessonLabel/Styled'
import { StyledLessonLabel } from 'src/modules/ELearning/Course/Detail/LeftContent/ContentTab/Styled'
import { SubTitleContent } from 'src/modules/ELearning/Course/Detail/LeftContent/ContentTab/events'
import { moduleCompletedStatus } from 'src/redux/slices/eLearning/learning/events'
import CircularProgressWithLabel from '../components/CircularProgressWithLabel'
import ContentTitle from '../components/ContentTitle'
import { handleModuleResult } from './events'

const ClassContent = ({ isGradeLearning }) => {
  let data
  if (isGradeLearning) {
    data = useSelector((state) => state.registration.data, shallowEqual)
  } else {
    data = useSelector((state) => state.registration.data, shallowEqual)
  }

  const eLearningCourse = _.get(
    data,
    'eLearningCourseBatchLearner.eLearningCourse',
    {}
  )

  const eLearningCourseLesson = _.get(
    eLearningCourse,
    'eLearningCourseLesson',
    []
  )

  return (
    <Column>
      <ContentTitle
        icon={<ListAlt color="primary" />}
        title="เนื้อหาหลักสูตร"
      />
      {eLearningCourseLesson.map((lesson, index) => (
        <LessonHead key={index} data={lesson} />
      ))}
    </Column>
  )
}

export default ClassContent

export const LessonHead = ({ data }) => {
  const [isShow, setShow] = useState(false)
  const lessonName = _.get(data, 'name', '')
  const eLearningLessonRefModule = _.get(data, 'eLearningLessonRefModule', [])
  const passModuleList = eLearningLessonRefModule.filter((module) => {
    const result = _.get(module, 'courseLogLearnerPerLessons.data.result', '')
    return moduleCompletedStatus.includes(result)
  })
  return (
    <Column>
      <StyledLessonLabel sx={{ py: 1.5 }} onClick={() => setShow(!isShow)}>
        <Row sx={{ gap: 2 }}>
          <StyledHeadLabel>
            <Typography variant="body3b" color="primary">
              {passModuleList.length}/{eLearningLessonRefModule.length}
            </Typography>
          </StyledHeadLabel>
          <Column>
            <StyledTypography
              variant="body1b"
              color="primary"
              sx={{ WebkitLineClamp: '1' }}
            >
              {lessonName}
            </StyledTypography>
            <Typography variant="body2" color="text.gray">
              {eLearningLessonRefModule.length} โมดูล
            </Typography>
          </Column>
        </Row>
        <Box sx={{ width: 32, height: 32, svg: { width: 32, height: 32 } }}>
          {isShow ? <KeyboardArrowUpTwoTone /> : <KeyboardArrowDownTwoTone />}
        </Box>
      </StyledLessonLabel>

      {isShow &&
        eLearningLessonRefModule.map((module, index) => (
          <ModuleItem key={index} data={module} />
        ))}
    </Column>
  )
}

export const ModuleItem = ({ data }) => {
  const eLearningModule = _.get(data, 'eLearningModule', {})
  const courseLogLearnerPerLessons = _.get(
    data,
    'courseLogLearnerPerLessons.data',
    {}
  )
  const progress = _.get(courseLogLearnerPerLessons, 'percent', 0)
  const resultObj = handleModuleResult(courseLogLearnerPerLessons)
  return (
    <Row sx={{ py: '14px', px: 2, gap: '10px' }}>
      <CircularProgressWithLabel value={progress} size={30} />
      <Column sx={{ gap: 0.5 }}>
        <Row sx={{ justifyContent: 'space-between', gap: 1 }}>
          <StyledTypography sx={{ WebkitLineClamp: '1' }}>
            {_.get(eLearningModule, 'name', '-')}
          </StyledTypography>
          <Typography variant="body1b" color={resultObj.color}>
            {resultObj.text}
          </Typography>
        </Row>
        <Typography variant="body2" color="text.gray">
          {SubTitleContent({
            ...eLearningModule,
            eLearningModuleFileUpload: courseLogLearnerPerLessons.files,
          })}
        </Typography>
      </Column>
    </Row>
  )
}
