import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const CardContainer = styled(Box)(({ theme, status }) => ({
  display: 'flex',
  flexWrap: status !== 'ELIGIBLE_ANNOUNCE' ? 'nowrap' : 'wrap',
  gap: '32px',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('lg')]: {
    gap: '24px',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '20px',
    flexWrap: 'wrap',
  },
}))

export const StyledCard = styled(Box)(({ theme, status }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: status === 'ELIGIBLE_ANNOUNCE' ? '23%' : '50%',
  flexDirection: 'column',
  border: `1px solid ${theme.palette?.blue?.blueBorder}`,
  borderRadius: '2px',
  padding: '16px 24px',
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  [theme.breakpoints.down('lg')]: {
    width: '48.25%',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const StyledImage = styled('img')(() => ({
  width: '100%',
  height: 'auto',
  maxWidth: '24px',
  maxHeight: '24px',
}))

export const TextContainer = styled(Box)(({ paddingLeft }) => ({
  display: 'flex',
  gap: '14px',
  alignItems: 'center',
  width: '100%',
  paddingLeft: paddingLeft,
}))

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({ height: '100vh', '& .MuiDrawer-paper': { width: 360 } }))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
}))

export const StyledContent = styled(Box)(() => ({
  position: 'relative',
  opacity: 'unset',
  pointerEvents: 'unset',
}))