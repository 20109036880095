import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Column, Row } from 'src/components/Layout/Styled'
import ProgressBar from './ProgressBar'
import { setProgress } from 'src/redux/slices/eExamination'

const Header = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { progress } = useSelector(
    (state) => ({
      progress: state.eExamination.progress,
    }),
    shallowEqual
  )
  return (
    <Column sx={{ mt: { lg: '60px', xs: '24px' } }}>
      <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }}>
        สมัครสอบ E-Examination
      </Typography>

      <Row
        sx={{
          minHeight: '34px',
          width: 'fit-content',
          mt: 2,
          cursor: 'pointer',
        }}
        onClick={() => dispatch(setProgress(progress - 1))}
      >
        {progress !== 0 && (
          <>
            <ArrowBackIcon
              sx={{
                fontSize: '34px',
                mr: 1,
                p: 0.5,
                color: theme.palette.primary.main,
                border: `2px solid ${theme.palette.primary.main}`,
                borderRadius: '50%',
              }}
            />
            <Typography variant="body1b" color="primary.main">
              ย้อนกลับ
            </Typography>
          </>
        )}
      </Row>
      <ProgressBar />
    </Column>
  )
}

export default Header
