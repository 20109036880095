import Button from '@mui/material/Button'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const StyledCaptureButton = styled((props) => (
  <Button {...props} variant="contained" />
))(() => ({ width: 82, height: 82, borderRadius: '100%' }))

export const StyledCameraFrame = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  [theme.breakpoints.down('sm')]: { width: 255, height: 185 },
}))

export const StyledCameraReadyBox = styled(Column)(({ theme }) => ({
  height: 369,
  background: theme.palette?.grey?.grey700,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: { height: 229 },
}))
