import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#FFFFFF',
  [theme.breakpoints.down('lg')]: {
    padding: '0 36px',
  },
}))

export const StyledNoContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 1,
  minWidth: 342,
  height: 214,
  borderRadius: theme.spacing(1),
  boxShadow: '0px 4px 12px 1px rgba(73, 118, 186, 0.35)',
  backgroundColor: theme.palette?.background?.paper,
  margin: `0 ${theme.spacing(10)} ${theme.spacing(3)}`,
  [theme.breakpoints.down('lg')]: {
    margin: `0 ${theme.spacing(5)} ${theme.spacing(4)}`,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(0),
  },
}))

export const StyledButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  padding: 0,
  height: 40,
  width: 81,
  borderRadius: 4,
}))

export const StyledRowHeader = styled(Typography)(() => ({
  display: 'flex',
  width: '100%',
  margin: '0px',
  alignItems: 'center',
  justifyContent: 'space-between',
}))