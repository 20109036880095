import _ from 'lodash'
import { ECONTENT_TYPE } from '../../../modules/EContent/model'
import { getOptions, getSubCategoryOptions } from '../../../services/eContent'

const { createSlice } = require('@reduxjs/toolkit')
const { initialState } = require('./model')

const eContent = createSlice({
  name: 'eContent',
  initialState: initialState,
  reducers: {
    setLanding: (state, { payload }) => {
      state.landing = payload
    },
    setDataList: (state, { payload }) => {
      const { result, totalCount } = payload
      if (state.filter.page == 1) {
        state.dataList = result
      } else {
        state.dataList = [...state.dataList, ...result]
      }
      state.totalCount = totalCount
      state.isFetch = false
      state.isFetchSearch = false
    },
    resetDataList: (state) => {
      state.dataList = []
      state.totalCount = 0
      state.filter = {
        ...state.filter,
        type:
          state.filter.type.length != 1
            ? [ECONTENT_TYPE.E_CONTENT]
            : state.filter.type,
        category: '',
        subCategory: '',
        tag: [],
        startPublishDateFrom: '',
        startPublishDateTo: '',
        page: 1,
        order: 'DESC',
        tagSelected: [],
      }
      state.filterChecked = {
        category: false,
        subCategory: false,
        tagSelected: false,
        startPublishDate: false,
      }
    },
    setFilterValue: (state, { payload }) => {
      const { key, value } = payload
      state.filter[key] = value
    },
    loadMore: (state) => {
      state.filter.page++
      state.isFetch = true
      state.isFetchSearch = true
    },
    setIsFetch: (state, { payload }) => {
      state.isFetch = payload
    },
    setContentData: (state, { payload }) => {
      state.contentData = payload
      state.related.excludeId = [payload.uuid]
      state.isFetch = true
    },
    resetRelatedList: (state) => {
      state.related.list = []
      state.related.excludeId = []
      state.totalCount = 0
      state.contentData = { eContentTag: [], contents: [] }
    },
    setRelatedList: (state, { payload }) => {
      const { result, totalCount } = payload
      state.totalCount = totalCount - result.length
      state.related.list = [...state.related.list, ...result]
      state.related.excludeId = [
        ...state.related.excludeId,
        ...result.map((ele) => ele.uuid),
      ]
      state.isFetch = false
    },
    resetSearchData: (state) => {
      state.dataList = []
      state.totalCount = 0
      state.filter = {
        ...state.filter,
        type: [ECONTENT_TYPE.E_CONTENT, ECONTENT_TYPE.KB],
        category: '',
        subCategory: '',
        tag: [],
        startPublishDateFrom: '',
        startPublishDateTo: '',
        page: 1,
        order: 'DESC',
        tagSelected: [],
      }
      state.isFetchSearch = true
    },
    setIsFetchSearch: (state, { payload }) => {
      state.isFetchSearch = payload
    },
    setFilterMemo: (state) => {
      state.filterMemo = state.filter
      state.filterCheckedMemo = state.filterChecked
    },
    resetFilter: (state) => {
      state.filter = state.filterMemo
      state.filterChecked = state.filterCheckedMemo
    },
    clearFilter: (state) => {
      state.filter = {
        ...state.filter,
        type: [ECONTENT_TYPE.E_CONTENT, ECONTENT_TYPE.KB],
        category: '',
        subCategory: '',
        tag: [],
        startPublishDateFrom: '',
        startPublishDateTo: '',
        page: 1,
        order: 'DESC',
        tagSelected: [],
      }
      state.filterChecked = {
        category: false,
        subCategory: false,
        tagSelected: false,
        startPublishDate: false,
      }
    },
    setChecked: (state, { payload }) => {
      const { key, value } = payload
      state.filterChecked[key] = value
    },
    startPrint: (state) => {
      state.printLoading = true
    },
    stopPrint: (state) => {
      state.printLoading = false
    },
    startDownload: (state) => {
      state.downloadLoading = true
    },
    stopDownload: (state) => {
      state.downloadLoading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptions.fulfilled, (state, { payload }) => {
        const result = _.get(payload, 'data', {
          category: [],
          tag: [],
        })
        state.options.category = result.category
        state.options.tag = result.tag
      })
      .addCase(getOptions.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getSubCategoryOptions.fulfilled, (state, { payload }) => {
        state.options.subCategory = _.get(payload, 'data', [])
      })
      .addCase(getSubCategoryOptions.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})
export const {
  setLanding,
  setDataList,
  resetDataList,
  setFilterValue,
  loadMore,
  setIsFetch,
  setContentData,
  resetRelatedList,
  setRelatedList,
  resetSearchData,
  setIsFetchSearch,
  setFilterMemo,
  resetFilter,
  clearFilter,
  setChecked,
  startPrint,
  stopPrint,
  startDownload,
  stopDownload,
} = eContent.actions

export default eContent.reducer
