import React from 'react'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { StyledDetailCard } from './Styled'

export const DetailCard = ({ id, fullWidth, icon, children, sx }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <StyledDetailCard id={id} fullWidth={fullWidth} sx={sx}>
      {typeof icon === 'string' ? (
        <img src={`/icon/e-learning/${icon}`} />
      ) : (
        icon
      )}
      <Typography variant={isNotSm ? 'body1' : 'body2'} sx={{ width: 'auto' }}>
        {children}
      </Typography>
    </StyledDetailCard>
  )
}
