export const initialState = {
  error: null,
  isLoading: true,
  isCourseLoading: true,
  isExpanded: false,
  data: null,
  selectedCourse: '',
  courseType: '',
  course: {},
  // course version props
  isTrainingPlanLoading: true,
  isLoadMore: false,
  classList: [],
  totalClass: 0,
  filterBody: {
    courseStatus: ['OPEN'],
    startDate: '',
    finishDate: '',
    page: 1,
  },
}
