import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getCheckBatchFailScanCount } from 'src/services/eLearning/detail'
import { setFailTime } from 'src/redux/slices/eLearning/learning'
import { closeDialog } from 'src/redux/slices/alertDialog'
import { getLearningState } from '../events'

export const handleCountdown = (timer) => async (dispatch) => {
  const { failTime, eLearningCourseBatchLearner } = getLearningState()
  const { mins, secs } = failTime
  let newTime = { mins, secs }
  if (mins === 0 && secs === 0) {
    clearInterval(timer)
  } else if (secs === 0) {
    newTime.mins = mins - 1
    newTime.secs = 59
  } else {
    newTime.secs = secs - 1
  }
  dispatch(setFailTime(newTime))

  // time out
  if (mins === 0 && secs === 1) {
    dispatch(startLoading())
    setTimeout(async () => {
      const uuid = _.get(eLearningCourseBatchLearner, 'uuid', '')
      await dispatch(getCheckBatchFailScanCount(uuid))
      dispatch(closeDialog())
      dispatch(stopLoading())
    }, 1500)
  }
}
