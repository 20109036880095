import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledWrapper = styled(Row)(({ theme }) => ({
  justifyContent: 'center',
  gap: theme.spacing(3),
  button: {
    width: 180,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: 168,
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  [theme.breakpoints.down('sm')]: { gap: '18px' },
}))
