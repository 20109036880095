import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const StyledButton = styled((props) => (
  <Button {...props} variant="contained" />
))(({ theme }) => ({
  height: 50,
  padding: '0 22px',
  [theme.breakpoints.down('sm')]: { minWidth: 109 },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.blue?.blueBorder,
}))

export const StyledPreview = styled('img')(() => ({ objectFit: 'cover' }))

export const StyledColumn = styled(Column)(({ theme }) => ({
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    alignItems: 'start',
    gap: theme.spacing(3),
    '& > .MuiBox-root:not(#section-label)': {
      flexDirection: 'column',
      alignItems: 'start',
      gap: theme.spacing(0.5),
    },
  },
}))
