import _ from 'lodash'
import {
  setClearFile,
  setErrorText,
  setUploadDocument,
} from 'src/redux/slices/document'
import { uploadRegisterDocument } from 'src/services/document'

export const isTypeAllow = (value) => {
  const type = _.lowerCase(value)
  return type === 'jpg' || type === 'jpeg' || type === 'png' || type === 'pdf'
}

export const handleUploadDocument = (event, index, classLearner) => (dispatch) => {
  const file = event.target.files[0]
  const fileName = _.get(file, 'name', null)
  const props = { index, file, fileName, classLearner }
  if (fileName) {
    const getExtensionFile = fileName.split('.').pop()
    const sizeFile = _.get(file, 'size', 0)
    if (!isTypeAllow(getExtensionFile)) {
      dispatch(checkFileCondition(props, 'fileExtension'))
    } else if (sizeFile > 2000000) {
      dispatch(checkFileCondition(props, 'fileOverSize', sizeFile))
    } else {
      dispatch(checkFileCondition(props, 'success'))
    }
  } else {
    dispatch(setErrorText({ key: index }))
    dispatch(removeUploadedFile(index))
  }
}

export const checkFileCondition = (props, status, sizeFile) => (dispatch) => {
  const { index, file, fileName, classLearner } = props
  switch (status) {
    case 'fileInvalid':
      dispatch(removeUploadedFile(index))
      dispatch(
        setErrorText({ key: index, text: 'อัปโหลดไฟล์ล้มเหลว ลองใหม่อีกครั้ง' })
      )
      break
    case 'fileExtension':
      dispatch(removeUploadedFile(index))
      dispatch(setErrorText({ key: index, text: 'นามสกุลไฟล์ไม่ถูกต้อง' }))
      break
    case 'fileOverSize': {
      const size = mFormatter(sizeFile)
      dispatch(removeUploadedFile(index))
      dispatch(
        setErrorText({
          key: index,
          text: `ขนาดไฟล์ต้องมีขนาดไม่เกิน 2 MB ปัจจุบัน ${size}MB`,
        })
      )
      break
    }
    case 'success': {
      dispatch(setErrorText({ key: index }))
      if (classLearner) {
        dispatch(setUploadDocument({ key: index, file, fileName }))
      } else {
        const body = new FormData()
        body.append('file', file)
        dispatch(uploadRegisterDocument({ key: index, body, name: file.name }))
      }
      break
    }
  }
}

export const removeUploadedFile = (index) => (dispatch) => {
  const idInput = document.getElementById(`import-doc-${index}`)
  if (idInput) {
    idInput.value = null
    dispatch(setClearFile(index))
  }
}

export const mFormatter = (num) => {
  return Math.abs(num) > 999999
    ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1)
    : Math.sign(num) * Math.abs(num)
}
