import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement } from 'chart.js'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'

import * as events from './events'
import { LabelHeaderCard } from '../'
import * as Styled from './Styled'

ChartJS.register(ArcElement)

export const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  cutout: 28,
  cutoutPercentage: 0,
}

export const ContentDialogSkill = () => {
  const details = useSelector((state) => state.myProfile.details, shallowEqual)
  const mySkill = events.filterMySkill(_.get(details, 'skills.mySkill', []))
  const needSkill = events.filterMySkill(_.get(details, 'skills.needSkill', []))

  return (
    <Styled.BoxGroupDialog>
      <Styled.BoxContentHeader>
        <Styled.BoxHeaderDialog>
          <Typography variant="body1" sx={{ width: 270 }}>
            Skill ปัจจุบันของฉัน
          </Typography>
          <Typography variant="body1" sx={{ pl: 4 }}>
            Skill ที่ต้องเรียนรู้เพิ่มเติม
          </Typography>
        </Styled.BoxHeaderDialog>
      </Styled.BoxContentHeader>
      <Styled.BoxContentList>
        <Styled.BoxContentDialog>
          <Styled.BoxDialogMySkill>
            {mySkill.map((item, index) => (
              <RenderBoxItem key={index} item={item} />
            ))}
          </Styled.BoxDialogMySkill>
          <Styled.BoxDialogNeedSkill>
            {needSkill.map((item, index) => (
              <RenderBoxItem key={index} item={item} />
            ))}
          </Styled.BoxDialogNeedSkill>
        </Styled.BoxContentDialog>
      </Styled.BoxContentList>
    </Styled.BoxGroupDialog>
  )
}

export const RenderBoxItem = ({ item }) => {
  return (
    <Styled.BoxItem>
      <Typography variant="chip">{item}</Typography>
    </Styled.BoxItem>
  )
}

const Index = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { details } = useSelector((state) => state.myProfile, shallowEqual)
  const myPercent = _.get(details, 'skills.myPercent', 0)
  const backgroundColor =
    myPercent == 100 ? ['#00008F'] : ['#00008F', '#F49889']
  const data = {
    datasets: [
      {
        data: [myPercent, myPercent - 100],
        backgroundColor: !myPercent ? ['#DBE4F1'] : backgroundColor,
      },
    ],
  }

  let mySkill = events.filterMySkill(_.get(details, 'skills.mySkill', []))
  if (mySkill.length > 4) mySkill.slice(0, 4)

  return (
    <Styled.BoxContent>
      <Styled.BoxCard>
        <Box sx={{ m: 3 }}>
          <LabelHeaderCard
            label="ทักษะของฉัน"
            handleClick={() => dispatch(events.handleMySkill(history))}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
            <Styled.StyledBoxGraph>
              <Styled.StyledBoxPercentage>
                <Typography variant="body2b" color="primary">
                  {Number.parseFloat(myPercent).toFixed(0)} %
                </Typography>
              </Styled.StyledBoxPercentage>
              <Doughnut data={data} options={options} />
            </Styled.StyledBoxGraph>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {mySkill.slice(0, 4).map((item, index) => {
                return (
                  <Styled.BoxMySkill key={index}>
                    <Typography variant="chip">{item}</Typography>
                  </Styled.BoxMySkill>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Styled.BoxCard>
    </Styled.BoxContent>
  )
}

export default Index
