import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import { HeadTable, StyledImage } from '../../Styled'
import TestTableCell from './Cell'

const TestTable = () => {
  const data = useSelector((state) => state.registration.data, shallowEqual)
  const getData = _.get(data, 'courseTestAndEvaluate', [])
  const trainingPlanUuid = _.get(data, 'trainingPlanUuid', '')

  return (
    <>
      <Box>
        <HeadTable>
          <StyledImage src="/image/class/test-icon.png" />
          <Typography variant="h6">แบบทดสอบ / แบบประเมิน</Typography>
        </HeadTable>
        {getData.map((item) => (
          <TestTableCell
            key={item}
            data={item}
            trainingPlanUuid={trainingPlanUuid}
          />
        ))}
      </Box>
    </>
  )
}

export default TestTable
