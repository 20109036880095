import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import History from '@mui/icons-material/History'
import Box from '@mui/material/Box'
import { Column } from 'src/components/Layout/Styled'
import Table from 'src/components/Table'
import ContentTitle from '../components/ContentTitle'
import { fetchAttendanceHistory } from './events'

const AttendanceHistory = () => {
  const { id: uuid } = useParams()
  const table = useSelector((state) => state.table.table, shallowEqual)
  const isLoading = _.get(table, 'isLoading', false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAttendanceHistory(table, uuid))
  }, [table.limit, table.page])

  return (
    <Column>
      <ContentTitle
        icon={<History color="primary" />}
        title="ประวัติการเข้าเรียน"
      />
      <Box
        sx={{
          opacity: isLoading ? 0.6 : 1,
          pointerEvents: isLoading ? 'none' : 'auto',
        }}
      >
        <Table />
      </Box>
    </Column>
  )
}

export default AttendanceHistory
