import React, { useEffect } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { Row } from 'src/components/Layout/Styled'
import ContentCard from '../../ContentCard'
import { handleChangeDropDown } from './events'
import { loadMore } from 'src/redux/slices/eContent'

const TabContent = () => {
  const dispatch = useDispatch()
  const { totalCount, order, dataList, isFetch } = useSelector(
    (state) => ({
      totalCount: state.eContent.totalCount,
      order: state.eContent.filter.order,
      dataList: state.eContent.dataList,
      isFetch: state.eContent.isFetch,
    }),
    shallowEqual
  )
  useEffect(() => {
    const handleWindowScroll = () => {
      const footer = document.getElementById('page-footer')

      if (dataList.length < totalCount && !isFetch) {
        if (
          window.innerHeight + Math.round(window.scrollY) >
          document.body.offsetHeight - footer.offsetHeight
        ) {
          dispatch(loadMore())
        }
      }
    }
    window.addEventListener('scroll', handleWindowScroll)
    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [isFetch, dataList])

  return (
    <>
      <Box sx={{ py: 3 }}>
        <Row>
          เรียงโดย
          <FormControl sx={{ ml: 2, minWidth: '90px' }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={order}
              defaultValue={'DESC'}
              onChange={(e) => dispatch(handleChangeDropDown(e.target.value))}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={'DESC'}>ล่าสุด</MenuItem>
              <MenuItem value={'ASC'}>เก่าสุด</MenuItem>
            </Select>
          </FormControl>
        </Row>
      </Box>

      {dataList.length > 0 ? (
        <Grid
          container
          sx={{ width: 'calc(100% + 24px)', pb: '24px' }}
          spacing={3}
        >
          {dataList.map((item, index) => {
            return (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                lg={4}
                sx={{ height: 'auto' }}
              >
                <ContentCard item={item}></ContentCard>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Typography variant="body1" sx={{ textAlign: 'center', my: 20 }}>
          ไม่พบข้อมูล
        </Typography>
      )}
    </>
  )
}

export default TabContent
