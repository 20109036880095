import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import StarsIcon from '@mui/icons-material/Stars'
import { Column, Row } from 'src/components/Layout/Styled'
import { convertNumberWithComma } from 'src/utils/lib'
import { Image, RowContainer } from './Styled'

const CatalogList = () => {
  const { isCatalogSeeMore, catalogData } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )
  return (
    <Column>
      {catalogData.map((catalog, ind) => {
        if (ind <= 2) {
          return <CatalogRow key={ind} catalog={catalog} />
        }
      })}
      <Collapse in={isCatalogSeeMore} sx={{ mt: 2 }}>
        {catalogData.map((catalog, ind) => {
          if (ind > 2) {
            return <CatalogRow key={ind} catalog={catalog} />
          }
        })}
      </Collapse>
    </Column>
  )
}

export default CatalogList

const CatalogRow = ({ catalog }) => {
  return (
    <RowContainer>
      <Image
        src={`${window.__env__.REACT_APP_FILE_URL}/get${catalog.image.key}`}
      />
      <Typography variant="body1" sx={{ width: 'calc(100% - 160px)' }}>
        {catalog.name}
      </Typography>
      <Row sx={{ width: 'fit-content', ml: 'auto' }}>
        <Typography variant="body1">
          {convertNumberWithComma(catalog.point)}
        </Typography>
        <StarsIcon sx={{ ml: 2 }} />
      </Row>
    </RowContainer>
  )
}
