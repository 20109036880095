import _ from 'lodash'
import { startLoading, stopLoading } from '../../../redux/slices/loading'
import {
  headCells,
  DEFAULT_BODY,
  defaultFilter,
  DEFAULT_BODY_STANDALONE,
  headCellStandalone,
  defaultFilterStandalone,
} from './model'
import {
  setInitialTable,
  setSearchText,
  setFilterProp,
  resetTablePage,
} from '../../../redux/slices/table'
import {
  postLearnerTodoList,
  postLearnerTodoListStandalone,
} from '../../../services/myProfile'
import { toDoType } from '../../../constants/toDo'

export const setDefaultPage = () => (dispatch) => {
  dispatch(setSearchText(''))
  dispatch(setFilterProp(null))
}

export const handleFetchData = (props) => (dispatch) => {
  const { type, table, text, isSearch, standaloneType } = props
  if (type === toDoType.standalone) {
    const body = bodyFetchDataStandalone(table, text, isSearch, standaloneType)
    dispatch(fetchDataListStandalone({ body, type, table }))
  } else {
    const body = bodyFetchData(type, table, text, isSearch)
    dispatch(fetchDataList({ body, type, table }))
  }
  window.scrollTo(0, 0)
}

export const fetchDataList = (props) => async (dispatch) => {
  const { body, type, table } = props
  dispatch(startLoading())

  const result = await dispatch(postLearnerTodoList(body))
  const listData = _.get(result, 'payload.data.returnData', [])
  const totalData = _.get(result, 'payload.data.totalReturnData', 0)

  dispatch(
    setInitialTable({
      rows: mapListData(listData),
      allCount: totalData,
      headCells: headCells,
      placeholder: 'ค้นหาหลักสูตร',
      searchKey: 'courseName',
      isCheckBox: false,
      hiddenRowsPerPage: true,
      onDownload: 'hidden',
      handleSearch: (text) => dispatch(handleQuickSearch(text, type, table)),
    })
  )
  dispatch(stopLoading())
}

export const fetchDataListStandalone = (props) => async (dispatch) => {
  const { body, type, table } = props
  dispatch(startLoading())

  const result = await dispatch(postLearnerTodoListStandalone(body))
  const listData = _.get(result, 'payload.data.allResult', [])
  const totalData = _.get(result, 'payload.data.totalCount', 0)

  dispatch(
    setInitialTable({
      rows: listData.map((item) => ({
        ...item,
        type: item.typeText,
      })),
      allCount: totalData,
      headCells: headCellStandalone,
      placeholder: 'ค้นหาชื่อ',
      searchKey: 'createdAt',
      isCheckBox: false,
      hiddenRowsPerPage: true,
      onDownload: 'hidden',
      handleSearch: (text) => dispatch(handleQuickSearch(text, type, table)),
    })
  )
  dispatch(stopLoading())
}

export const mapListData = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      ...item,
      dateRangeMonthlyPlanDate: mapDateRange(item.dateRangeMonthlyPlan),
      dateRangeClassTestingAndEvaDate: mapDateRange(
        item.dateRangeClassTestingAndEva
      ),
      urlView: `/course/${item.courseVersionUuid}/class/${item.trainingPlanUuid}`,
    }
  })
}
export const mapDateRange = (obj) => {
  const startDateDay = _.get(obj, 'startDateDay', '')
  const startDateMonth = _.get(obj, 'startDateMonth', '')
  const startDateTime = _.get(obj, 'startDateTime', '')
  const startDateYear = _.get(obj, 'startDateYear', '')

  const finishDateDay = _.get(obj, 'finishDateDay', '')
  const finishDateMonth = _.get(obj, 'finishDateMonth', '')
  const finishDateTime = _.get(obj, 'finishDateTime', '')
  const finishDateYear = _.get(obj, 'finishDateYear', '')

  if (_.isEmpty(startDateDay) && _.isEmpty(finishDateDay)) return '-'
  const startDate = `${startDateDay} ${startDateMonth} ${startDateYear} ${startDateTime}`
  if (_.isEmpty(finishDateDay)) return startDate
  const finishDate = `${finishDateDay} ${finishDateMonth} ${finishDateYear} ${finishDateTime}`
  return `${startDate} - ${finishDate}`
}

export const handleQuickSearch = (text, type, table) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(handleFetchData({ type, table, text, isSearch: true }))
}

export const setDefaultFilter = (type, standaloneType) => {
  let result = defaultFilter
  if (type === toDoType.onlineTest) {
    result = {
      ...defaultFilter,
      typeChecked: true,
      type: {
        ...defaultFilter.type,
        testChecked: true,
      },
    }
  } else if (type === toDoType.assessment) {
    result = {
      ...defaultFilter,
      typeChecked: true,
      type: {
        ...defaultFilter.type,
        assessmentChecked: true,
      },
    }
  } else if (type === toDoType.evaluation) {
    result = {
      ...defaultFilter,
      typeChecked: true,
      type: {
        ...defaultFilter.type,
        evaluationChecked: true,
      },
    }
  } else if (type === toDoType.standalone) {
    result = {
      ...defaultFilterStandalone,
      typeChecked: !_.isNil(standaloneType),
      type: {
        etestingChecked:
          !_.isNil(standaloneType) && standaloneType === toDoType.eTesting,
        evaluationChecked:
          !_.isNil(standaloneType) && standaloneType === toDoType.eEvaluation,
      },
    }
  }
  return result
}

export const bodyFetchData = (type, table, searchText, isSearch) => {
  const { order, page, sort } = table
  const findSort = headCells.find((item) => item.id === sort)
  const newPage = isSearch && page <= 0 ? 1 : page
  let sortStr = sort
  if (!findSort) sortStr = 'dateRangeMonthlyPlanDate'
  if (sort === 'statusTH') sortStr = 'status'

  let result = {
    ...DEFAULT_BODY,
    courseName: searchText,
    order: order.toUpperCase(),
    page: newPage,
    sort: sortStr,
  }
  if (type === 'online-test') {
    result = {
      ...result,
      type: ['ONLINE_TEST'],
    }
  } else if (type === 'assessment') {
    result = {
      ...result,
      type: ['ASSESSMENT'],
    }
  } else if (type === 'evaluation') {
    result = {
      ...result,
      type: ['E_EVALUATION'],
    }
  }

  return result
}

export const bodyFetchDataStandalone = (
  table,
  searchText,
  isSearch,
  standaloneType
) => {
  const { order, page, sort } = table
  const newPage = isSearch && page <= 0 ? 1 : page
  let sortStr = sort
  if (sort === 'updatedAt') sortStr = 'createdAt'

  let type = [] // E_TESTING, E_EVALUATION
  if (standaloneType) {
    type = [_.toUpper(_.snakeCase(standaloneType))]
  }

  const body = {
    ...DEFAULT_BODY_STANDALONE,
    order: order.toUpperCase(),
    sort: sortStr,
    page: newPage,
    name: searchText,
    type,
    status: [],
  }

  return body
}
