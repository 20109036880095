import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { store } from 'src/App'
import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { checkDuplicateData, postApplyStudent } from 'src/services/eExamination'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { setProgress, setReduxValue } from 'src/redux/slices/eExamination'

export const handlenextStep = (step) => async (dispatch) => {
  const state = store.getState()
  if (step == 1) {
    dispatch(startLoading())
    const { examinersFormError, duplicatedError } = await dispatch(
      checkDuplicate()
    )
    dispatch(setReduxValue({ index: 'showFormError', value: true }))
    const formError = _.get(state.eExamination, 'formError', [])
    const errorCheck = _.find(examinersFormError, (ele) => {
      return ele
    })

    if (errorCheck) {
      let index = _.findIndex(formError, (ele) => {
        return !_.isEmpty(ele)
      })
      let element
      if (index >= 0) {
        element = document.getElementById(
          `${formError?.[index]?.[0]?.path}-${index}`
        )
      } else {
        index = _.findIndex(duplicatedError, (ele) => {
          return !_.isEmpty(ele)
        })
        element = document.getElementById(
          `${Object.keys(duplicatedError?.[index])?.[0]}-${index}`
        )
      }
      dispatch(stopLoading())
      if (element) {
        dispatch(
          openDialog({
            isCloseDialog: false,
            iconType: 'error',
            title: 'ข้อมูลไม่ถูกต้อง',
            message: ['กรุณาตรวจสอบข้อมูลอีกครั้ง'],
            buttonRight: {
              label: 'ตกลง',
              handleClick: () => {
                dispatch(closeDialog())
                element.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                })
              },
            },
          })
        )
      }
      return
    }
  }
  dispatch(stopLoading())
  dispatch(setProgress(step + 1))
}

const checkDuplicate = () => async (dispatch) => {
  const state = store.getState()
  const examiners = _.get(state.eExamination, 'examiners', [])
  let newExaminersFormError = [
    ..._.get(state.eExamination, 'examinersFormError', []),
  ]
  const users = examiners.map((user) => {
    return {
      uuid: user.uuid,
      idCardNo: user.idCardNo,
      mobileNo: user.mobileNo,
      type: user.type,
    }
  })
  const result = await dispatch(checkDuplicateData({ users }))
  const checkUser = _.get(result, 'payload', '')

  let newDuplicatedError = []
  checkUser.map((ele, ind) => {
    let error = {}
    if (!ele.id) {
      newExaminersFormError[ind] = true
      error.idCardNo = 'เลขประจำตัวประชาชนนี้มีในระบบแล้ว'
    }
    if (!ele.mobile) {
      newExaminersFormError[ind] = true
      error.mobileNo = 'หมายเลขโทรศัพท์มือถือนี้มีในระบบแล้ว'
    }
    newDuplicatedError.push(error)
  })
  dispatch(
    setReduxValue({ index: 'examinersFormError', value: newExaminersFormError })
  )
  dispatch(
    setReduxValue({ index: 'duplicatedError', value: newDuplicatedError })
  )
  return {
    examinersFormError: newExaminersFormError,
    duplicatedError: newDuplicatedError,
  }
}

export const handleSubmit = () => async (dispatch) => {
  dispatch(startLoading())
  const state = store.getState()
  const selectedRounds = _.get(state.eExamination, 'selectedRounds', [])
  const examiners = _.get(state.eExamination, 'examiners', [])
  const selfRegister = _.get(state.eExamination, 'selfRegister', [])
  const otherDocConfig = _.get(state.eExamination, 'otherDocConfig', {})

  const schedules = selectedRounds.map((ele) => ele.uuid)
  const students = examiners.map((ele) => {
    let otherDocsValues = []
    if (
      otherDocConfig &&
      otherDocConfig?.config?.status === 'ACTIVE' &&
      ele?.otherDocs
    ) {
      otherDocsValues = ele.otherDocs.map((item) => {
        return {
          ...item,
        }
      })
    }
    return {
      userProfile: ele.uuid,
      gender: ele.gender,
      province: ele.province,
      amphure: ele.amphure,
      tambon: ele.tambon,
      prefixTH: ele.prefix,
      firstNameTH: ele.firstNameTH,
      lastNameTH: ele.lastNameTH,
      mobileNo: ele.mobileNo,
      email: ele.email,
      dateOfBirth: ele.birthDate
        ? dayjs(ele.birthDate).format('YYYY-MM-DD')
        : null,
      address: ele.address,
      idCardImageKey: ele.cardImage,
      institution: ele.institution,
      between: ele.between,
      qualification: ele.qualification,
      branch: ele.branch,
      gpa: ele.gpa,
      otherDocuments: otherDocsValues,
      idCardNo: ele.idCardNo,
    }
  })

  const result = await dispatch(postApplyStudent({ schedules, students }))
  const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')

  if (requestStatus == 'fulfilled') {
    dispatch(
      openDialog({
        isCloseDialog: false,
        iconType: 'time',
        title: 'รอผลการตรวจสอบ',
        message: [
          'ระบบได้ทำการสมัครสอบให้คุณเรียบร้อยแล้ว',
          'โปรดติดตามผลการตรวจสอบเอกสารทางอีเมลอีกครั้ง',
        ],
        buttonRight: {
          label: selfRegister ? 'ไปที่หน้าการสมัครสอบของฉัน' : 'ตกลง',
          endIcon: <ArrowForwardIcon />,
          handleClick: () =>
            (window.location.href = selfRegister
              ? '/my-class?tab=MY_SCHEDULE'
              : '/manage-prospect'),
        },
      })
    )
  } else if (requestStatus == 'rejected') {
    const response = _.get(result, 'meta.response.data.constraints', {})
    dispatch(
      openDialog({
        isCloseDialog: false,
        iconType: 'error',
        title: response?.title || 'ไม่สามารถสมัครเข้าสอบได้',
        message: [response?.value || 'พบปัญหาบางอย่าง กรุณาลองอีกครั้ง'],
      })
    )
  }
  dispatch(stopLoading())
}
