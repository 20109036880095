import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const BoxCard = styled(Box)(({ theme }) => ({
  width: '100%',
  border: '1px solid #DBE4F1',
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: '2px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const StyledBoxGraph = styled(Box)(({ size }) => ({
  width: size ? size : '90px',
  height: size ? size : '90px',
  position: 'relative',
}))

export const StyledBoxPercentage = styled(Box)(() => ({
  position: 'absolute',
  margin: 'auto',
  left: 0,
  right: 0,
  top: '35%',
  textAlign: 'center',
}))

export const BoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(4),
}))

export const BoxContentDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  minWidth: 520,
  overflow: 'auto',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderTop: 'unset',
  height: 285,
  [theme.breakpoints.down('sm')]: {
    height: 190,
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.text?.secondary,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.secondary?.contrast,
    boxShadow: 'inset 1px 0px 0px #DEDEDE',
  },
}))

export const BoxHeaderDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: 56,
  alignItems: 'center',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  paddingLeft: theme.spacing(2),
}))

export const BoxContentHeader = styled(Box)(() => ({
  minWidth: 520,
}))

export const BoxContentList = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(2),
  },
}))

export const BoxGroupDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'auto',
  paddingLeft: '60px',
  paddingRight: '60px',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const BoxDialogMySkill = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: 248,
  margin: theme.spacing(2),
}))

export const BoxItem = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '25px',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}))

export const BoxDialogNeedSkill = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export const BoxMySkill = styled(Box)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '25px',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  width: '133px',
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('lg')]: {
    textOverflow: 'unset',
    width: 'fit-content',
  },
  [theme.breakpoints.down('sm')]: {
    textOverflow: 'ellipsis',
    width: '153px',
  },
}))

export const BoxContentScore = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))
