import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Typography from '@mui/material/Typography'
import { ColumnCard } from '../Styled'
import { StyledAccordion, StyledAccordionSummary } from './Styled'
import { Column, Row } from 'src/components/Layout/Styled'
import { setBadgeSeeMore } from 'src/redux/slices/myProfile'
import BadgeGrid from './BadgeGrid'

const Badge = () => {
  const dispatch = useDispatch()
  const { isBadgeSeeMore, badgeData } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )
  return (
    <ColumnCard
      sx={{
        mx: 3,
      }}
    >
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'text.white' }} />}
        >
          <Typography variant="h6" color="text.white">
            เหรียญรางวัล
          </Typography>
        </StyledAccordionSummary>
        {badgeData.length > 0 ? (
          <Column sx={{ px: 1.5, py: 3 }}>
            <Row>
              <BadgeGrid />
            </Row>
            {badgeData.length > 4 && (
              <Row
                sx={{ width: 'fit-content', margin: 'auto', cursor: 'pointer' }}
                onClick={() => dispatch(setBadgeSeeMore())}
              >
                <Typography
                  variant="body1b"
                  color="primary.main"
                  sx={{
                    textDecoration: 'underline 1px !important',
                    WebkitTextDecorationStyle: 'solid !important',
                    WebkitTextDecorationThickness: '1px !important',
                    WebkitTextDecorationLine: 'underline !important',
                  }}
                >
                  {isBadgeSeeMore ? 'ย่อลง' : 'ดูเพิ่มเติม'}
                </Typography>
                {isBadgeSeeMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Row>
            )}
          </Column>
        ) : (
          <Row sx={{ justifyContent: 'center', py: 3 }}>
            <Typography variant="body1b">ยังไม่มีเหรียญรางวัล</Typography>
          </Row>
        )}
      </StyledAccordion>
    </ColumnCard>
  )
}

export default Badge
