import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const StyledContainer = styled(Column)(({ theme }) => ({
  marginTop: theme.spacing(0),
  padding: `${theme.spacing(5)} 0`,
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
}))

export const StyledLoading = styled(CircularProgress)(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: '50%',
  translate: '-50%',
  width: 24,
  height: 24,
  [theme.breakpoints.down('sm')]: { left: 0, translate: 0 },
}))
