export const listTableHead = [
  {
    title: 'รายการ',
    align: 'flex-start',
    width: '25%',
  },
  {
    title: 'ผล',
    align: 'center',
    width: '25%',
  },
  {
    title: 'เกณฑ์ผ่าน',
    align: 'flex-end',
    width: '50%',
  },
]
