import React, { useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { shallowEqual, useSelector } from 'react-redux'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useHistory } from 'react-router-dom'

import StatusClass, { Dot } from './StatusClass'
import StudyPapersDrawer from './StudyPapersDrawer'
import StatusDrawer from './StatusDrawer'
import { CardContainer, StyledCard, StyledImage, TextContainer } from './Styled'

const ClassMenu = () => {
  const history = useHistory()
  const [toggleProps, setToggleProps] = useState({
    statusOpen: false,
    studyPapersOpen: false,
  })

  const data = useSelector((state) => state.registration.data, shallowEqual)
  const cStatus = _.get(data, 'classLearnerStatus', '')
  const classStatus = _.get(data, 'classStatus', '')
  const classStatusText = _.get(data, 'classStatusTH', '-')
  const learnerStatus = _.get(data, 'learnerStatus.status', '-')
  const learnerStatusTH = _.get(data, 'learnerStatus.statusTH', '-')
  const color =
    learnerStatus === 'CLASS_CLOSED_PASS' ? 'success.main' : 'error.main'

  return (
    <>
      <CardContainer>
        <StyledCard status={cStatus}>
          <TextContainer>
            <StyledImage src="/image/class/status-class-icon.png" />
            <Box>
              <Typography variant="h6" color="primary">
                สถานะคลาส
              </Typography>
            </Box>
            <IconButton
              color="primary"
              sx={{ marginLeft: 'auto' }}
              data-testid="status-menu"
              onClick={() =>
                setToggleProps((val) => ({ ...val, statusOpen: true }))
              }
            >
              <ArrowForwardIcon />
            </IconButton>
          </TextContainer>
          <TextContainer paddingLeft="40px">
            <StatusClass
              status={classStatus}
              classStatusText={classStatusText}
            />
          </TextContainer>
        </StyledCard>
        {cStatus === 'ELIGIBLE_ANNOUNCE' && (
          <StyledCard status={cStatus}>
            <TextContainer>
              <StyledImage src="/image/class/grade-icon.png" />
              <Box>
                <Typography variant="h6" color="primary">
                  ผลการเรียน
                </Typography>
              </Box>

              <IconButton
                color="primary"
                sx={{ marginLeft: 'auto' }}
                data-testid="grade"
                onClick={() =>
                  history?.push(
                    `/my-class/registration-detail/grades/${_.get(
                      data,
                      'trainingPlanUuid',
                      ''
                    )}`
                  )
                }
              >
                <ArrowForwardIcon />
              </IconButton>
            </TextContainer>
            <TextContainer paddingLeft="40px">
              {learnerStatusTH === 'ยังไม่มีผล' ? (
                <Typography variant="body1" color="text.lightGray">
                  {learnerStatusTH}
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
                >
                  <Dot color={color} />
                  {learnerStatusTH}
                </Typography>
              )}
            </TextContainer>
          </StyledCard>
        )}

        <StyledCard status={cStatus}>
          <TextContainer>
            <StyledImage src="/image/class/class-detail-icon.png" />
            <Box>
              <Typography variant="h6" color="primary">
                รายละเอียดคลาส
              </Typography>
            </Box>
            <IconButton
              color="primary"
              sx={{ marginLeft: 'auto' }}
              data-testid="class-detail"
              onClick={() =>
                history?.push(
                  `/course/${_.get(
                    data,
                    'courseVersionUuid',
                    ''
                  )}/class/${_.get(data, 'trainingPlanUuid', '')}`
                )
              }
            >
              <ArrowForwardIcon />
            </IconButton>
          </TextContainer>
          <TextContainer paddingLeft="40px">
            <Typography variant="body1" color="text.lightGray">
              {_.get(data, 'distribution', '')}
              {_.get(data, 'distribution', '') ? ' | ' : '-'}
              {_.get(data, 'roadmapAbbreviation', '')}
            </Typography>
          </TextContainer>
        </StyledCard>
        {cStatus === 'ELIGIBLE_ANNOUNCE' && (
          <StyledCard status={cStatus}>
            <TextContainer>
              <StyledImage src="/image/class/document-icon.png" />
              <Box>
                <Typography variant="h6" color="primary">
                  เอกสารการเรียน
                </Typography>
              </Box>
              <IconButton
                color="primary"
                sx={{ marginLeft: 'auto' }}
                data-testid="studypaper-menu"
                onClick={() =>
                  setToggleProps((val) => ({ ...val, studyPapersOpen: true }))
                }
              >
                <ArrowForwardIcon />
              </IconButton>
            </TextContainer>
            <TextContainer paddingLeft="40px">
              <Typography variant="body1" color="text.lightGray">
                {_.get(data, 'courseDocumentDatas', []).length} ไฟล์
              </Typography>
            </TextContainer>
          </StyledCard>
        )}
      </CardContainer>
      <StatusDrawer
        isOpen={toggleProps.statusOpen}
        onCloseDrawer={() =>
          setToggleProps((val) => ({ ...val, statusOpen: false }))
        }
      />
      <StudyPapersDrawer
        isOpen={toggleProps.studyPapersOpen}
        onCloseDrawer={() =>
          setToggleProps((val) => ({ ...val, studyPapersOpen: false }))
        }
      />
    </>
  )
}

export default ClassMenu
