import _ from 'lodash'
import { store } from 'src/App'
import { handleResizeFile } from 'src/utils/lib'
import { setChangeCamera } from 'src/redux/slices/eLearning/enroll'
import { ENROLL_INPUT_TYPE } from 'src/constants/eLearning'
import { handleSubmitIdCard } from '../events'

export const handleUploadFile = async (e) => {
  let file = e.target.files[0]
  if (_.isNil(file)) return

  const sizeFile = _.get(file, 'size', 0)
  if (sizeFile > 2000000) {
    file = await handleResizeFile(file)
  }
  store.dispatch(handleSubmitIdCard(file, ENROLL_INPUT_TYPE.UPLOAD))
  store.dispatch(setChangeCamera({ key: 'idCardImage', value: file }))
}
