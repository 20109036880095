import React from 'react'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  '& .MuiBox-root#header': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '24px',
    paddingBottom: '8px',
    margin: `0 ${theme.spacing(3)}`,
  },
  '& .MuiDivider-root#divider': {
    marginBottom: theme.spacing(2),
    borderColor: theme.palette?.other?.divider,
  },
  '& .MuiPaper-root': {
    width: 360,
    height: '100vh',
    '::-webkit-scrollbar': { display: 'none' },
  },
  '& ::-webkit-scrollbar': { display: 'none' },
}))
