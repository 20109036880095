import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const BoxContainer = styled(Column)(({ theme }) => ({
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(5.5),
  gap: theme.spacing(4),
  alignItems: 'center',
}))

export const BoxImg = styled(Box)(() => ({
  width: 72,
  height: 48,
  img: { width: 72, height: 48, objectFit: 'cover' },
}))

export const BoxCard = styled(Card)(({ theme }) => ({
  width: 800,
  marginTop: theme.spacing(3),
  border: `1px solid ${theme?.palette?.blue?.blueBorder}`,
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
  borderRadius: '2px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const BoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2),
  gap: theme.spacing(2),
}))

export const BoxDetail = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.text?.secondary,
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const BoxDetailLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const BoxRemark = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(-2),
}))
