const {
  setFilterValue,
  setIsFetchSearch,
} = require('src/redux/slices/eContent')

export const applyFilter =
  ({ filter, filterChecked, setOpen }) =>
  (dispatch) => {
    if (!filterChecked.category) {
      dispatch(setFilterValue({ key: 'category', value: '' }))
    }
    if (!filterChecked.subCategory) {
      dispatch(setFilterValue({ key: 'subCategory', value: '' }))
    }
    if (filterChecked.tagSelected) {
      dispatch(
        setFilterValue({
          key: 'tag',
          value: filter.tagSelected.map((ele) => ele.value),
        })
      )
    } else {
      dispatch(setFilterValue({ key: 'tag', value: [] }))
    }
    if (!filterChecked.startPublishDate) {
      dispatch(setFilterValue({ key: 'startPublishDateFrom', value: '' }))
      dispatch(setFilterValue({ key: 'startPublishDateTo', value: '' }))
    }
    dispatch(setFilterValue({ key: 'page', value: 1 }))
    dispatch(setIsFetchSearch(true))
    setOpen(false)
  }
