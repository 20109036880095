import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledErrorDetail = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: 380,
  '& .MuiTypography-root': {
    cursor: 'pointer',
    fontWeight: '500',
  },
  '& .MuiBox-root': {
    display: 'flex',
    gap: theme.spacing(1),
  },
  textarea: { width: '100%' },
}))
