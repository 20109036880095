import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { LoadingButton } from '@mui/lab'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Template from 'src/components/Template'
import { StyledContainer, StyledHeadContent } from '../Styled'
import { Column, Row } from 'src/components/Layout/Styled'
import DocumentInput from '../../DocumentInput'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  handleConsent,
  handleSubmitDocument,
  fetchAddtionalConsentAndDocs,
} from './events'
import CourseCard from '../../CourseCard'
import { checkIdCard, getQueryParams } from 'src/utils/lib'
import InputMask from 'src/components/Input/InputMask'
import DatePicker from 'src/components/DatePicker'
import dayjs from 'dayjs'
import { setDateOfBirth, setIdCardNo } from 'src/redux/slices/document'

const AdditionalInfoConsentRegister = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()

  const id = getQueryParams('id')
  const managerId = getQueryParams('managerId')

  const [isEnable, setEnable] = useState(false)
  const [isDateError, setDateError] = useState(false)
  const isLoading = useSelector(
    (state) => state.loading.isLoading,
    shallowEqual
  )
  const { isSuccess, data, dateOfBirth, idCardNo, error } = useSelector(
    (state) => state.document,
    shallowEqual
  )
  const documentList = _.get(data, 'documentList', [])
  const payload = { uuid, id, managerId }

  useEffect(() => {
    dispatch(fetchAddtionalConsentAndDocs(payload))
  }, [])

  const formatDate = dayjs(dateOfBirth).format('YYYY-MM-DD')
  const formatIdCard = _.defaultTo(idCardNo, '')?.replace(/ /g, '')
  const canSubmit = _.get(data, 'canSubmit', false)
  const checkError = _.get(error, 'constraints.key', '') || !canSubmit
  const required = _.get(data, 'required', false)
  const isIdCard = checkIdCard(formatIdCard)
  const isFormFilled =
    dateOfBirth === null || formatIdCard === '' || isSuccess || !isIdCard
  const isIdCardError = formatIdCard.length === 0 ? false : !isIdCard
  const notRequireDisable = !isEnable || isIdCardError || isDateError

  return (
    <Template topLine={40}>
      <StyledContainer>
        <StyledHeadContent>
          <Typography variant="h4">ขอข้อมูลส่วนตัวเพิ่มเติม</Typography>
          <Typography>
            กรุณาอัปโหลดเอกสารให้ครบถ้วน เพื่อประกอบการพิจารณาสิทธิ์เข้าอบรม
          </Typography>
        </StyledHeadContent>

        {data && (
          <>
            <CourseCard data={data} />

            {documentList.map((item, index) => (
              <DocumentInput
                key={index}
                index={index}
                label={item.title}
                isAllow={item.allow}
              />
            ))}

            <InputMask
              labelText="หมายเลขบัตรประจำตัวประชาชน"
              required={required}
              mask="9 9999 99999 99 9"
              placeholder="1 0010 11011 01 0"
              testId="input-id-card"
              onChange={(e) => dispatch(setIdCardNo(e.target.value))}
              sx={{ border: '1px solid #CCCCCC', padding: '0px 12px' }}
              isShowTextError={isIdCardError}
              textError="กรุณากรอกหมายเลขบัตรประจำตัวประชาชนที่ถูกต้อง*"
              disabled={checkError}
            />

            <DatePicker
              labelText="วัน เดือน ปี เกิด"
              required={required}
              onChange={(e) => dispatch(setDateOfBirth(e))}
              placeholder="00/00/0000"
              onError={(e) => setDateError(!_.isEmpty(e))}
              hideTextError={!isDateError}
              textError={
                isDateError ? 'กรุณาใส่วัน เดือน ปีเกิดให้ถูกต้อง*' : ''
              }
              value={dateOfBirth}
              disabledInput={checkError}
              data-testid="date-picker"
            />
          </>
        )}

        <Column sx={{ gap: 3, alignItems: 'center' }}>
          <Row sx={{ justifyContent: 'center' }}>
            <Checkbox
              defaultChecked={false}
              onChange={(e) => setEnable(e.target.checked)}
              disabled={checkError}
              inputProps={{ 'data-testid': `checkbox` }}
            />
            <Typography>
              ผู้เรียนยินยอมที่จะเผยแพร่เอกสารที่มีข้อมูลส่วนบุคคลเพื่อใช้ในการสมัคร
            </Typography>
            <Link
              component="button"
              variant="body1"
              data-testid="confirm"
              onClick={() => dispatch(handleConsent())}
              sx={{ ml: 1 }}
            >
              อ่านเพิ่มเติม
            </Link>
          </Row>
          <LoadingButton
            variant="contained"
            size="l"
            loadingPosition="end"
            loading={isLoading}
            endIcon={<></>}
            disabled={(isFormFilled && required) || notRequireDisable}
            data-testid="agree"
            onClick={() =>
              dispatch(
                handleSubmitDocument(
                  uuid,
                  formatIdCard,
                  formatDate,
                  id,
                  managerId
                )
              )
            }
          >
            ยอนรับเงื่อนไขและส่งข้อมูล
          </LoadingButton>
        </Column>
      </StyledContainer>
    </Template>
  )
}

export default AdditionalInfoConsentRegister
