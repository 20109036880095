import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledFilterContainer = styled(Row)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(2),
  },
}))

export const StyledToggleButtons = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: `0 ${theme.spacing(1.5)}`,
    border: '1px solid',
    borderColor: 'transparent',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    color: theme.palette.text?.primary,
    fontWeight: 400,
    '&:hover': {
      borderColor: theme.palette?.primary?.outlinedResting,
      background: theme.palette?.primary?.outlinedHoverBackground,
      color: theme.palette.primary?.main,
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      borderColor: theme.palette.primary?.main,
      background: theme.palette.primary?.main,
      color: theme.palette.text?.white,
    },
    '&:first-of-type, &:not(:first-of-type)': { borderRadius: 2 },
  },
}))

export const StyledSearchDateRow = styled(Row)(({ theme }) => ({
  width: 'auto',
  gap: theme.spacing(2.5),
  '& #DateRangeBox': { width: 307 },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '& #DateRangeBox': { width: '100%' },
  },
}))
