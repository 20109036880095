import IconButton from '@mui/material/IconButton'
import styled from '@mui/system/styled'

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isLikeLoading',
})(({ isLikeLoading }) => ({
  width: 43,
  height: 43,
  pointerEvents: isLikeLoading ? 'none' : 'auto',
}))
