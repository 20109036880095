import { configureStore } from '@reduxjs/toolkit'
import rootReducer from '../slices/rootReducer'
import { listenerMiddlewareDialog } from '../slices/alertDialog/listener'
import { listenerMiddlewareELearning } from '../slices/eLearning/learning/listener'
import { listenerMiddlewareEExam } from '../slices/eExamination/listener'
import { listenerMiddlewareMyClassList } from '../slices/class/list/listener'

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat([
        listenerMiddlewareDialog.middleware,
        listenerMiddlewareELearning.middleware,
        listenerMiddlewareEExam.middleware,
        listenerMiddlewareMyClassList.middleware,
      ]),
    preloadedState,
  })
}
