import React from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Close from '@mui/icons-material/Close'
import InputText from 'src/components/Input/TextInput'
import { closeDialog } from 'src/redux/slices/alertDialog'
import { handleInputFormat, handlePasteFormat, handleSubmit } from './events'
import { StyledFormWrapper } from './Styled'
import { schama } from './schema'

const IdNoDialog = ({ path }) => {
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: { idCardNo: '' },
    validationSchema: schama,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => dispatch(handleSubmit(values, path)),
  })
  return (
    <StyledFormWrapper>
      <IconButton
        sx={{ position: 'absolute', top: 18, right: 18 }}
        onClick={() => dispatch(closeDialog())}
      >
        <Close />
      </IconButton>
      <img src="/icon/e-learning/ic_dot_edit.svg" />
      <Typography variant="h6">ลงทะเบียนหลักสูตร OIC</Typography>
      <Typography color="text.gray">
        กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก
      </Typography>
      <InputText
        boxSx={{ mt: 2, mb: '18px' }}
        type="basic"
        id="idCardNo"
        placeholder="กรอกเลขบัตรประจำตัวประชาชน"
        value={formik.values.idCardNo}
        textError={formik.errors.idCardNo}
        isShowTextError={!!formik.errors.idCardNo}
        inputProps={{ onInput: handleInputFormat, onPaste: handlePasteFormat }}
        onChange={formik.handleChange}
      />
      <Button
        variant="contained"
        size="l"
        sx={{ width: '100%' }}
        onClick={formik.submitForm}
      >
        ยืนยัน
      </Button>
    </StyledFormWrapper>
  )
}

export default IdNoDialog
