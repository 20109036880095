import { Box, styled } from '@mui/material'

export const TagBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  marginTop: '24px',
  padding: '24px',
  gap: '24px',
  backgroundColor: '#F8FAFC',
  alignItems: 'center',
}))

export const ChipBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '4px',
  flexWrap: 'wrap',
}))

export const StyledChip = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  background: theme.palette?.grey?.grey300,
  borderRadius: '16px',
  padding: '3px 4px',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}))
