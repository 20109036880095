import React, { Fragment, useMemo } from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import People from '@mui/icons-material/People'
import { Column, Row } from 'src/components/Layout/Styled'
import { BATCH_QUESTION_STATUS } from 'src/constants/eLearning'
import { calculateDateDiffToText } from 'src/utils/lib'
import { StyledAnswerBox, StyledDivider } from './Styled'

const QuestionDisplay = ({ questions }) => {
  return (
    <Column gap="10px">
      <Row gap={1}>
        <People color="primary" />
        <Typography variant="body1b">ดูบทสนทนา</Typography>
      </Row>
      <Column sx={{ pl: 4, gap: 2 }}>
        {questions.length === 0 && (
          <Typography color="text.gray">ไม่มีบทสนทนาในตอนนี้</Typography>
        )}
        {questions.map((item, index) => (
          <Fragment key={index}>
            {index !== 0 && <StyledDivider sx={{ borderStyle: 'dashed' }} />}
            <QuestionCard data={item} />
          </Fragment>
        ))}
      </Column>
    </Column>
  )
}

export default QuestionDisplay

export const QuestionLabel = ({ data }) => {
  const name = `${_.get(data, 'questionBy.firstNameTH', '')} ${_.get(
    data,
    'questionBy.lastNameTH',
    ''
  )}`
  const createdAt = _.get(data, 'createdAt', '')
  const timestampObj = useMemo(
    () => calculateDateDiffToText(createdAt),
    [createdAt]
  )

  return (
    <Row gap={1}>
      <Typography variant="body1b">{name}</Typography>
      <li style={{ marginLeft: 12, color: '#868686' }}>
        {timestampObj.value} {timestampObj.prefix}ที่แล้ว
      </li>
    </Row>
  )
}

export const AnswerLabel = ({ data }) => {
  const name = `${_.get(data, 'answerBy.firstNameTH', '')} ${_.get(
    data,
    'answerBy.lastNameTH',
    ''
  )}`
  const updatedAt = _.get(data, 'updatedAt', '')
  const timestampObj = useMemo(
    () => calculateDateDiffToText(updatedAt),
    [updatedAt]
  )

  return (
    <Row sx={{ gap: 1, alignItems: 'start' }}>
      <img src="/icon/e-learning/ic_arrow_back.svg" />
      <Row flexWrap="wrap" columnGap={1}>
        <Typography variant="body1b">{name}</Typography>
        <Typography sx={{ mr: 1.5 }} variant="body1b" color="primary">
          ผู้สอน
        </Typography>
        <li style={{ color: '#868686' }}>
          {timestampObj.value} {timestampObj.prefix}ที่แล้ว
        </li>
      </Row>
    </Row>
  )
}

export const QuestionCard = ({ data }) => {
  const question = _.get(data, 'question', '')
  const status = _.get(data, 'status', '')
  return (
    <Column gap={1}>
      <QuestionLabel data={data} />
      <Typography variant="text.secondary">{question}</Typography>
      <Column sx={{ pl: { lg: 3, md: 3 } }}>
        {status === BATCH_QUESTION_STATUS.WAITING ? (
          <Row gap={1}>
            <img src="/icon/e-learning/ic_sand_clock.svg" />
            <Typography color="text.lightGray">รอการตอบกลับ</Typography>
          </Row>
        ) : (
          <Column gap={1}>
            <AnswerLabel data={data} />
            <StyledAnswerBox>
              <Typography
                variant="text.secondary"
                dangerouslySetInnerHTML={{ __html: _.get(data, 'answer', '') }}
              />
            </StyledAnswerBox>
          </Column>
        )}
      </Column>
    </Column>
  )
}
