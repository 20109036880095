import React from 'react'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import People from '@mui/icons-material/People'
import { Column, Row } from 'src/components/Layout/Styled'
import InputText from 'src/components/Input/TextInput'
import QuestionDisplay from '../../../../components/QuestionDisplay'
import { StyledDivider } from '../../../../components/QuestionDisplay/Styled'
import { handlePreventEmoji, handleSubmit } from './events'
import { StyledWrapper } from './Styled'

const QuestionContent = () => {
  const dispatch = useDispatch()
  const questions = useSelector(
    (state) => state.eLearningLearning.questions,
    shallowEqual
  )
  const formik = useFormik({
    initialValues: { question: '' },
    onSubmit: (values, { resetForm }) =>
      dispatch(handleSubmit(values, resetForm)),
  })
  return (
    <StyledWrapper id="question-section">
      <Column gap="10px" alignItems="end">
        <Row gap={1}>
          <People color="primary" />
          <Typography variant="body1b">ตั้งคำถาม / พูดคุย</Typography>
        </Row>
        <InputText
          multiline
          rows={2}
          isShowTextError={false}
          type="basic"
          id="question"
          placeholder="พิมพ์ข้อความที่นี่..."
          value={formik.values.question}
          inputProps={{ onInput: handlePreventEmoji }}
          onChange={formik.handleChange}
        />
        <Button
          variant="contained"
          onClick={formik.submitForm}
          disabled={_.isEmpty(formik.values.question)}
        >
          ส่งข้อความ
        </Button>
      </Column>

      <StyledDivider />

      <QuestionDisplay questions={questions} />
    </StyledWrapper>
  )
}

export default QuestionContent
