import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  handleFilterBody,
  handleFulfilled,
  handlePending,
  handleRejected,
} from './events'
import { initialState } from './model'
import { getAnnounceFilter } from 'src/services/eExamination'

const announceExaminerList = createSlice({
  name: 'announceExaminerList',
  initialState,
  reducers: {
    startLoadMore: (state) => {
      state.isLoadMore = true
    },
    stopLoadMore: (state) => {
      state.isLoadMore = false
    },
    setTab: (state, { payload }) => {
      state.tab = payload
      state.filter = []
      state.filterBody = { ...initialState.filterBody }
      handleFilterBody(state, payload)
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
      state.filterBody.page = 1
      if (payload.length == 0) handleFilterBody(state, state.tab)
      else state.filterBody.classStatus = payload
    },
    setSearch: (state, { payload }) => {
      state.filterBody.search = payload
      state.filterBody.page = 1
    },
    setPeriod: (state, { payload }) => {
      const { startDate, endDate } = payload
      state.filterBody.startDate = startDate
      state.filterBody.endDate = endDate
      state.filterBody.page = 1
    },
    setLoadMore: (state) => {
      const nextPage = state.filterBody.page + 1
      const maxPage = _.ceil(state.totalannounceList / 12)
      if (nextPage <= maxPage) state.filterBody.page = nextPage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnnounceFilter.fulfilled, handleFulfilled)
      .addCase(getAnnounceFilter.pending, handlePending)
      .addCase(getAnnounceFilter.rejected, handleRejected)
  },
})

export const {
  startLoadMore,
  stopLoadMore,
  setTab,
  setFilter,
  setSearch,
  setPeriod,
  setLoadMore,
} = announceExaminerList.actions

export default announceExaminerList.reducer
