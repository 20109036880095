import { Box, Button, Divider, Typography } from '@mui/material'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledTitleBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}))

export const StyledCardBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: 954,
  minWidth: 954,
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: 'auto',
  marginRight: 'auto',
  gap: theme.spacing(3),
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: { width: '100%', minWidth: '100%' },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

export const StyledGridContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: `24px ${theme.spacing(10)} 24px`,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
}))

export const StyledRowContainer = styled(Row)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: '0px',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '40px 40px 0',
}))

export const StyledRowHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: '0px',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StyledButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  padding: 0,
  height: 40,
  width: 81,
  borderRadius: 4,
}))

export const StyledDivider = styled(Divider)((theme) => ({
  height: 0,
  borderBottom: `solid 1px ${theme.palette?.other?.divider}`,
}))

export const SlideContainer = styled('div')(() => ({
  marginBottom: '32px',
}))

export const CardContainter = styled('div')(() => ({
  position: 'relative',
  borderRadius: 8,
  outline: 'none',
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.07)',
  boxShadow: '0px 12px 40px 0px rgba(73, 118, 186, 0.10)',
  cursor: 'pointer',
}))

export const CardImage = styled('img')(() => ({
  width: '100%',
  borderRadius: '8px 8px 0px 0px',
  maxHeight: '231px',
  objectFit: 'cover',
}))

export const ContentBox = styled('div')(() => ({
  width: '100%',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  height: '220px',
  justifyContent: 'space-between',
}))

export const TitleBox = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.primary.main,
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}))

export const FooterBox = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: '16px',
  position: 'absolute',
  fontSize: '20px',
  gap: '4px',
  bottom: 0,
  left: 0,
}))

export const StyledChip = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  maxWidth: 'calc(50% - 8px)',
  background: theme.palette?.grey?.grey300,
  borderRadius: '16px',
  padding: '3px 4px',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}))
