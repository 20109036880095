import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const MediaBoxStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isScorm',
})(({ theme, isScorm }) => ({
  position: 'relative',
  width: '100%',
  height: isScorm && 'calc(100vh - 96px)',
  minHeight: 'calc(100vh - 96px)',
  background: theme.palette?.background?.paper,
}))
