import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledContainer = styled(Box)(({ theme }) => ({
  '& #stepper': { padding: `${theme.spacing(4)} 0` },
  [theme.breakpoints.down('lg')]: { padding: `0 ${theme.spacing(5)}` },
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(2)}`,
    '& #stepper': { padding: `${theme.spacing(2)} 0` },
  },
}))
