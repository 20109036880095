import styled from '@mui/system/styled'
import { TableCell, Checkbox, TableRow } from '@mui/material'

export const StyledTableRow = styled(TableRow)(({ theme, $available }) => ({
  background: $available
    ? theme?.palette?.background?.paper
    : theme?.palette?.action?.disabledBackground,
  '&:nth-of-type(odd)': {
    background: $available
      ? '#7698CB1A'
      : theme?.palette?.action?.disabledBackground,
  },
}))

export const StyledTableCell = styled(TableCell)(
  ({ theme, $available, $head }) => ({
    padding: '11px 16px',
    borderColor: theme?.palette?.blue?.blueBorder,
    borderBottomWidth: 2,
    color: $available
      ? theme?.palette?.text?.primary
      : theme?.palette?.text?.lightGray,
    background: $head ? theme?.palette?.background?.paper : 'unset',
  })
)

export const StyledCheckbox = styled(Checkbox)(({ theme, $available }) => ({
  padding: 0,
  color: $available
    ? theme?.palette?.text?.secondary
    : theme?.palette?.text?.lightGray,
  cursor: $available ? 'auto' : 'not-allowed',
}))
