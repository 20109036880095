import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { postLikeLearningPath } from 'src/services/eLearning/learningPath'
import { getCourseDetail as getELearningDetail } from 'src/services/eLearning/detail'
import { getCourseDetail } from 'src/services/course/detail'
import { initialState } from './model'

const eLearningLearningPath = createSlice({
  name: 'eLearningLearningPath',
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    startCourseLoading: (state) => {
      state.isCourseLoading = true
    },
    stopCourseLoading: (state) => {
      state.isCourseLoading = false
    },
    startTrainingPlanLoading: (state) => {
      state.isTrainingPlanLoading = true
    },
    stopTrainingPlanLoading: (state) => {
      state.isTrainingPlanLoading = false
    },
    startLoadMore: (state) => {
      state.isLoadMore = true
    },
    stopLoadMore: (state) => {
      state.isLoadMore = false
    },
    setExpand: (state, { payload }) => {
      state.isExpanded = payload
    },
    setInitialPreview: (state, { payload }) => {
      state.data = payload
      state.data['eLearningLearningPathCourses'] = _.get(
        payload,
        'eLearningLearningPathCourses',
        []
      ).map((course) => ({
        ...course,
        course: course.eLearningCourse || course.courseVersion,
      }))
    },
    setCourseType: (state, { payload }) => {
      state.courseType = payload
    },
    setSelectedCourse: (state, { payload }) => {
      state.selectedCourse = payload
      state.filterBody = initialState.filterBody
    },
    setFilter: (state, { payload }) => {
      const { key, value } = payload
      state.filterBody[key] = value
      state.filterBody.page = 1
    },
    setLoadMore: (state) => {
      const nextPage = state.filterBody.page + 1
      const maxPage = _.ceil(state.totalClass / 12)
      if (nextPage <= maxPage) state.filterBody.page = nextPage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getELearningDetail.fulfilled, (state, { payload }) => {
        state.course = payload.data
      })
      .addCase(getELearningDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getCourseDetail.fulfilled, (state, { payload }) => {
        state.course = payload.data
      })
      .addCase(getCourseDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postLikeLearningPath.pending, (state) => {
        state.isLikeLoading = true
      })
      .addCase(postLikeLearningPath.fulfilled, (state, { payload }) => {
        state.data.isLike = payload.data.isLike
        state.isLikeLoading = false
      })
      .addCase(postLikeLearningPath.rejected, (state, action) => {
        state.error = action.meta?.message
        state.isLikeLoading = false
      })
  },
})

export const {
  startLoading,
  stopLoading,
  startCourseLoading,
  stopCourseLoading,
  startTrainingPlanLoading,
  stopTrainingPlanLoading,
  startLoadMore,
  stopLoadMore,
  setExpand,
  setInitialPreview,
  setCourseType,
  setSelectedCourse,
  setFilter,
  setLoadMore,
} = eLearningLearningPath.actions

export default eLearningLearningPath.reducer
