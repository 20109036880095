import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette?.other?.divider}`,
  width: '100%',
  padding: '25px 16px',
  gap: '16px',
}))

export const CellContainer = styled(Box)(({ status }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '9px',
  width: 130,
  paddingLeft: status ? '25px' : '',
}))
