import _ from 'lodash'
import { myClassFilter } from 'src/constants/course'

export const handleFilterBody = (state, tab) => {
  const classStatus = _.get(myClassFilter, tab, [])
  state.filterBody.classStatus = classStatus.map((item) => item.value)
}

export const handleFulfilled = (state, action) => {
  const { meta, payload } = action
  const announceList = _.get(payload, 'result', [])
  const isFilter = _.get(meta?.arg, 'page', 1) == 1
  if (isFilter) state.announceList = announceList
  else state.announceList = _.concat(state.announceList, announceList)
  state.totalannounceList = _.get(payload, 'totalCount', 0)
}

export const handlePending = (state, { meta }) => {
  const isFilter = _.get(meta?.arg, 'page', 1) == 1
  state.announceList = isFilter ? [] : state.announceList
}

export const handleRejected = (state, action) => {
  state.announceList = []
  state.error = action.meta?.message
}

export const handleDetailFulfilled = (state, action) => {
  const { payload } = action
  const announceDetail = _.defaultTo(payload, {})
  state.announceDetail = announceDetail
}

export const handleDetailPending = (state) => {
  state.announceDetail = {}
}

export const handleDetailRejected = (state, action) => {
  state.announceDetail = {}
  state.error = action.meta?.message
}
