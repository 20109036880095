import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { eConfiguration } from 'src/utils/apiPath'

export const getEConfiguration = createAsyncThunk(
  'eConfiguration/getEConfiguration',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(eConfiguration)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
