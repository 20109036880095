import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledRow = styled(Row)(({ theme }) => ({
  width: 'auto',
  gap: theme.spacing(2),
  img: { width: 76, height: 76 },
}))

export const StyledColumn = styled(Column)(({ theme }) => ({
  width: 315,
  gap: theme.spacing(0.5),
}))
