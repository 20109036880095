import React from 'react'
import Button from '@mui/material/Button'
import { StyledIconButton } from './Styled'

const LikeButton = ({ isLike, isLoading, onClick }) => {
  const icon = isLike ? 'heart_fill' : 'heart'
  return (
    <Button
      sx={{ pointerEvents: isLoading ? 'none' : 'auto', minWidth: 115 }}
      startIcon={<img src={`/icon/ic_${icon}.svg`} width={24} height={20} />}
      onClick={onClick}
    >
      รายการโปรด
    </Button>
  )
}

export default LikeButton

export const LikeIconButton = ({ isLike, isLoading, onClick }) => {
  const icon = isLike ? 'heart_fill' : 'heart'
  return (
    <StyledIconButton isLikeLoading={isLoading} onClick={onClick}>
      <img src={`/icon/ic_${icon}.svg`} />
    </StyledIconButton>
  )
}
