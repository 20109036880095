import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { StyledAccordion, StyledAccordionSummary } from './Styled'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Column } from 'src/components/Layout/Styled'
import Detail from './Detail'
import { StyledDivider } from './Styled'
import dayjs from 'dayjs'
import { getLabelFromOption } from './event'
import { genderTitle } from '../constant'

const ExaminerInfoItem = ({ index }) => {
  const {
    examiner,
    genderOptions,
    qualificationOptions,
    tambonOptions,
    amphureOptions,
    provinceOptions,
    otherDocConfig,
  } = useSelector(
    (state) => ({
      examiner: state.eExamination.examiners[index],
      genderOptions: state.eExamination.options.genderOptions,
      qualificationOptions: state.eExamination.options.qualificationOptions,
      tambonOptions: state.eExamination.options.tambonOptions[index],
      amphureOptions: state.eExamination.options.amphureOptions[index],
      provinceOptions: state.eExamination.options.provinceOptions[index],
      otherDocConfig: state.eExamination.otherDocConfig,
    }),
    shallowEqual
  )
  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon></ExpandMoreIcon>}>
        <Typography variant="body1b">
          {`${examiner.firstNameTH} ${examiner.lastNameTH}`}
        </Typography>
      </StyledAccordionSummary>
      <Column
        sx={{
          pt: 2,
          borderTop: '1px solid #DBE4F1',
          rowGap: '12px',
        }}
      >
        <Detail label="ชื่อหน่วยงาน" value={examiner.unitName} />
        <Detail label="รหัสหน่วยงาน" value={examiner.unitNumber} />
        <StyledDivider />
        <Detail
          label="ชื่อนามสกุลผู้เข้าสอบ"
          value={`${examiner.prefix} ${examiner.firstNameTH} ${examiner.lastNameTH}`}
        />
        <Detail label="เลขประจำตัวประชาชน 13 หลัก" value={examiner.idCardNo} />
        <Detail label="หมายเลขโทรศัพท์มือถือ" value={examiner.mobileNo} />
        <Detail label="E-mail" value={examiner.email} />
        <Detail
          label="วัน เดือน ปี เกิด"
          value={
            examiner.birthDate
              ? dayjs(examiner.birthDate).format('DD/MM/YYYY')
              : '-'
          }
        />
        <Detail
          label="เพศ"
          value={
            examiner?.gender
              ? genderTitle[
                  getLabelFromOption({
                    value: examiner.gender,
                    options: genderOptions,
                  })
                ]
              : '-'
          }
        />
        <StyledDivider />
        <Detail label="รายละเอียดที่อยู่" value={examiner.address} />
        <Detail
          label="ตำบล/แขวง"
          value={getLabelFromOption({
            value: examiner.tambon,
            options: tambonOptions,
          })}
        />
        <Detail
          label="อำเภอ/เขต"
          value={getLabelFromOption({
            value: examiner.amphure,
            options: amphureOptions,
          })}
        />
        <Detail
          label="จังหวัด"
          value={getLabelFromOption({
            value: examiner.province,
            options: provinceOptions,
          })}
        />
        <Detail label="รหัสไปรษณีย์" value={examiner.zipCode} />
        <StyledDivider />
        <Detail label="สถานศึกษา (มหาวิทยาลัย)" value={examiner.institution} />
        <Detail label="ระหว่างปี" value={examiner.between} />
        <Detail
          label="วุฒิการศึกษา"
          value={getLabelFromOption({
            value: examiner.qualification,
            options: qualificationOptions,
          })}
        />
        <Detail label="สาขา" value={examiner.branch} />
        <Detail label="เกรดเฉลี่ย" value={examiner.gpa} />
        <Detail
          label="อัปโหลดรูปหน้าบัตรประชาชน"
          value={examiner.imageName}
          image={examiner.cardImage?.cardImage}
          viewIcon={true}
        />
        { otherDocConfig && otherDocConfig?.config?.status === 'ACTIVE' ?
          (
            <Detail
              label={otherDocConfig?.config?.label}
              value={examiner.otherDocs}
              viewIcon={true}
            />
          )
          : ''
        }
      </Column>
    </StyledAccordion>
  )
}

export default ExaminerInfoItem
