import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Typography } from '@mui/material'
import { Column } from 'src/components/Layout/Styled'
import DateRange from './DateRange'
import FieldType from './FieldType'
import FieldSelect from './FieldSelect'
import { StyledAccordion, StyledAccordionSummary } from './Styled'
import SearchIcon from '@mui/icons-material/Search'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { handleClickSearch } from './event'

const FilterMobile = () => {
  const dispatch = useDispatch()
  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1b">ตัวกรอง</Typography>
      </StyledAccordionSummary>
      <Column sx={{ rowGap: 2 }}>
        <DateRange />
        <FieldType />
        <FieldSelect />
        <Button
          sx={{ height: 40, mt: 1 }}
          variant="contained"
          onClick={() => dispatch(handleClickSearch())}
        >
          <SearchIcon sx={{ fontSize: 20, mr: 1 }} />
          ค้นหา
        </Button>
      </Column>
    </StyledAccordion>
  )
}

export default FilterMobile
