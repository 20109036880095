import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import { HeadTable, StyledImage } from '../../Styled'
import TrainingCell from './Cell'

const TrainingTable = () => {
  return (
    <>
      <Box>
        <HeadTable>
          <StyledImage src="/image/class/table-icon.png" />
          <Typography variant="h6">ตารางเวลาอบรม</Typography>
        </HeadTable>
        <TrainingCell />
      </Box>
    </>
  )
}

export default TrainingTable
