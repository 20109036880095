import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DisallowContent from '../../../../components/DisallowContent'
import { handleCountdown } from './events'

const DisallowDialog = () => {
  const dispatch = useDispatch()
  const { mins, secs, failMaxTimes } = useSelector((state) => ({
    mins: state.eLearningEnroll.time.mins,
    secs: state.eLearningEnroll.time.secs,
    failMaxTimes: state.eLearningEnroll.failMaxTimes,
  }))

  useEffect(() => {
    let timer = null
    timer = setInterval(() => {
      dispatch(handleCountdown(timer))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  return <DisallowContent failMaxTimes={failMaxTimes} mins={mins} secs={secs} />
}

export default DisallowDialog
