import React from 'react'
import _ from 'lodash'
import { Typography } from '@mui/material'
import { Column, Row } from 'src/components/Layout/Styled'
import { type } from '../../constant'
import CheckBoxInput from './CheckBoxInput'

const FieldType = () => {
  return (
    <Column sx={{ rowGap: 1 }}>
      <Typography variant="body1b">สนามสอบ</Typography>
      <Row sx={{ columnGap: 1 }}>
        {_.map(type, (ele, ind) => {
          return (
            <Column key={ind} sx={{ width: 'auto' }}>
              <CheckBoxInput {...ele} />
            </Column>
          )
        })}
      </Row>
    </Column>
  )
}

export default FieldType
