export const initialState = {
  isLoading: false,
  data: {
    status: 'ERROR',
    errorCase: 'ERROR',
    isShowError: false,
    textError: '',
    course: {
      courseName: '',
      imageKey: '',
      lastCheckedBy: '',
      checkedRound: 0,
      checkedDate: '',
    },
  },
}
