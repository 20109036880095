import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'
import { StyledCard } from '../../RegistrationDetail/ClassMenu/Styled'

export const StyledContainer = styled(Row)(({ theme }) => ({
  alignItems: 'stretch',
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: { flexDirection: 'column' },
}))

export const StyledColumn = styled(Column)(({ theme }) => ({
  minWidth: 369,
  width: 369,
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    minWidth: '100%',
    flexDirection: 'row',
  },
  [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
}))

export const StyledMiniCard = styled(StyledCard)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('lg')]: { width: '100%' },
  [theme.breakpoints.down('sm')]: { width: '100%' },
}))
