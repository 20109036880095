import Button from '@mui/material/Button'
import styled from '@mui/system/styled'

export const StyledBigButton = styled((props) => (
  <Button
    {...props}
    variant={props.scrolled ? 'outlined' : 'contained'}
    color="secondary"
    size="l"
  />
))(({ theme, scrolled }) => ({
  width: '100%',
  padding: theme.spacing(1.5),
  '&.Mui-disabled': {
    border: scrolled
      ? `1px solid ${theme.palette?.background?.paper} !important`
      : 'unset',
    color: scrolled ? theme.palette?.text?.white : theme.palette?.text?.primary,
  },
}))
