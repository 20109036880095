import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid, Typography, IconButton, Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import { StyledInputHidden, StyledDashedBox, StyledFileInputBox } from './Styled'
import { Row } from 'src/components/Layout/Styled'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import { openDialog } from 'src/redux/slices/alertDialog'
import CardImageDialog from './CardImageDialog'
import { handleUploadOtherDoc } from './event'
import { removeOtherDocs } from 'src/redux/slices/eExamination'

const UploadMultipleFileInput = ({ index }) => {
  const dispatch = useDispatch()
  const { otherDocConfig, otherDocs } = useSelector(
    (state) => ({
      otherDocConfig: state.eExamination.otherDocConfig,
      otherDocs: state.eExamination.examiners[index].otherDocs,
    }),
    shallowEqual
  )

  return (
    otherDocConfig && otherDocConfig?.config?.status === 'ACTIVE' ?
      (<Grid item sm={6} xs={12}>
        <Row sx={{ justifyContent: 'space-between'}}>
          <Box sx={{ display: 'flex',}}>
            <Typography variant="body1">{otherDocConfig?.config?.label ?? 'อัพโหลดเอกสารเพิ่มเติม'}</Typography>
            <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
              *
            </Typography>
          </Box>
          <Box>
            <label htmlFor={`upload-multiple-file-${index}`}>
              <Box
                sx={{
                  background: (otherDocs && otherDocs?.length >= 5) ? '#FFF' : '#E5E5E5',
                  border: '1px solid #CCCCCC',
                  padding: '10.5px 20px',
                  cursor: 'pointer',
                  pointerEvents: (otherDocs && otherDocs?.length >= 5) ? 'none' : '',
                }}
              >
                + เพิ่มไฟล์
                <StyledInputHidden
                  accept=".jpg, .jpeg, .png, .pdf"
                  type="file"
                  id={`upload-multiple-file-${index}`}
                  disabled={(otherDocs && otherDocs?.length >= 5)}
                  onChange={(e) => {
                    dispatch(
                      handleUploadOtherDoc({ 
                        index: index, 
                        value: e.target.files[0], 
                        documentName: otherDocConfig?.config?.label,
                      })
                    )
                  }}
                />
              </Box>
            </label>
          </Box>
        </Row>
        { (!otherDocs || otherDocs?.length <= 0) && (
          <Row sx={{ my: 1 }}>
            <StyledDashedBox>
                <Typography variant="body1">กรุณาเพิ่มไฟล์</Typography>
            </StyledDashedBox>
          </Row>
        )}
        { (otherDocs && otherDocs?.length > 0) && (
          <Row sx={{ my: 1 }}>
            <Stack
              spacing={1}
              sx={{width: '100%'}}
            >
              { otherDocs.map((item, itemIndex) => {
                const imageName = item?.imageName ?? ''
                return (
                  <StyledFileInputBox key={itemIndex}>
                    <Typography variant="body1">{imageName}</Typography>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: '15px',}}
                    >
                      <RemoveRedEyeTwoToneIcon
                        sx={{ fontSize: 20, color: '#0000008A', cursor: 'pointer' }}
                        onClick={() => {
                          if (!item?.cardImage) return
                          dispatch(
                            openDialog({
                              type: 'custom',
                              customWidth: 900,
                              content: (
                                <CardImageDialog
                                  url={window.__env__.REACT_APP_FILE_URL + item?.cardImage}
                                  title={item.imageName ?? ''}
                                  label={otherDocConfig?.config?.label ?? 'เอกสารเพิ่มเติม'}
                                />
                              ),
                            })
                          )
                        }}
                      />
                      {item?.imageName && (
                        <IconButton
                          sx={{ ml: 1, p: 0 }}
                          onClick={() => {
                            // document.querySelector(`#upload-multiple-file-${index}`).value = ''
                            dispatch(removeOtherDocs({ index: index, imageIndex: itemIndex }))
                          }}
                        >
                          <CloseIcon sx={{ fontSize: 20, color: '#0000008A', cursor: 'pointer' }} />
                        </IconButton>
                      )}
                    </Box>
                </StyledFileInputBox>
                )
              })}
            </Stack>
          </Row>
        )}
        <Typography variant="body2" color="text.gray">
          ไฟล์ที่รองรับ .jpg, .jpeg, .png, .pdf ขนาดของไฟล์ที่แนะนำไม่เกิน 2 MB
        </Typography>
      </Grid>)
    : ""
  )
}

export default UploadMultipleFileInput
