import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { getQueryParams } from 'src/utils/lib'
import Background from 'src/components/Preview/Background'
import Header from './Header'
import Footer from './Footer'
import Questions from './Questions'
import Result from './Result'
import TimeUp from './TimeUp'
import { handleFetchData } from './events'

const Testing = ({ isReview, isModule }) => {
  const { id: uuid } = useParams()
  const trainingPlan = getQueryParams('class')
  const payload = { uuid, trainingPlan, onTesting: !isReview }
  const { section, startTime } = useSelector(
    (state) => ({
      section: state.eTesting.section,
      startTime: state.eTesting.startTime,
    }),
    shallowEqual
  )

  useEffect(() => {
    handleFetchData({ payload, isReview, isModule, startTime })
  }, [])

  return (
    <>
      <Header isModule={isModule} />
      <Background withHeader withFooter={isModule}>
        {section == 'TESTING' && <TestingForm />}
        {section == 'RESULT' && <Result isModule={isModule} />}
        {section == 'TIMEUP' && <TimeUp />}
      </Background>
    </>
  )
}

export default Testing

const TestingForm = () => {
  return (
    <>
      <Questions />
      <Footer />
    </>
  )
}
