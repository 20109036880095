import React from 'react'
import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { setClearErrorText, setErrorText } from 'src/redux/slices/document'
import {
  getLearnerDocument,
  getRegisterDocument,
  putLearnerDocument,
} from 'src/services/document'
import {
  openDialog,
  openErrorDialog,
  openSuccessDialog,
} from 'src/redux/slices/alertDialog'
import { MONTH_TH_SHORT_LIST } from 'src/constants/calendar'
import Consent from './Consent'
import { postEnrollClassQueue } from 'src/services/class/detail'

export const fetchLearnerDocument = (payload) => async (dispatch) => {
  dispatch(startLoading())
  if (payload.classLearner) await dispatch(getLearnerDocument(payload))
  else await dispatch(getRegisterDocument(payload.uuid))
  dispatch(stopLoading())
}

export const handleExpiredDate = () => {
  const date = new Date()
  const month = MONTH_TH_SHORT_LIST[date.getMonth()]
  const buddistYear = date.getFullYear() + 543
  return `${date.getDate()} ${month} ${buddistYear}`
}

export const handleConsent = () => (dispatch) => {
  dispatch(
    openDialog({ type: 'custom', customWidth: '85%', content: <Consent /> })
  )
}

export const handleSubmitDocument = (props, socketId) => async (dispatch) => {
  const { uuid, classLearner, requestData, secretTrainingPlan } = props
  const isNoUploaded = requestData.some((item) => _.isNil(item.fileName))
  if (isNoUploaded) {
    requestData.forEach((item) => {
      if (_.isNil(item.fileName)) {
        dispatch(
          setErrorText({ key: item.key, text: 'กรุณาเลือกไฟล์ที่จะอัปโหลด' })
        )
      } else {
        dispatch(setErrorText({ key: item.key }))
      }
    })
  } else {
    dispatch(setClearErrorText())
    dispatch(startLoading())

    if (classLearner) {
      const formData = new FormData()
      requestData.forEach((item) => {
        formData.append(item.key, item.file)
      })
      formData.append('classLearner', classLearner)
      const res = await dispatch(putLearnerDocument({ uuid, body: formData }))
      const courseUuid = _.get(res, 'payload.data.courseUuid', '')
      const trainingPlanUuid = _.get(res, 'payload.data.trainingPlanUuid', '')

      dispatch(
        handleSuccess({
          content: {
            courseUuid: courseUuid,
            trainingPlanUuid: trainingPlanUuid,
          },
        })
      )
      dispatch(stopLoading())
    } else if (socketId) {
      const payload = {
        trainingPlanUuid: uuid,
        learnerDocuments: requestData,
        secretTrainingPlan: secretTrainingPlan,
      }
      await dispatch(postEnrollClassQueue({ socketId, payload }))
    }
  }
}

export const handleSocketResponse = (res, error) => (dispatch) => {
  dispatch(stopLoading())
  if (_.isEmpty(res)) return
  if (res.success) dispatch(handleSuccess(res))
  else dispatch(handleError(error))
}

export const handleErrorSocket = (error, params) => (dispatch) => {
  dispatch(stopLoading())
  if (!error) return
  const title = _.get(error, 'title', 'ไม่สำเร็จ')
  const message = _.get(error, 'value', 'ดำเนินการไม่สำเร็จ')
  dispatch(
    openErrorDialog({
      title,
      message: message.split(' '),
      buttonRight: {
        handleClick: () => (window.location.href = params),
      },
    })
  )
}

export const handleError = (error) => (dispatch) => {
  if (!error) return
  const title = _.get(error, 'constraints.title', 'ไม่สำเร็จ')
  const message = _.get(error, 'constraints.value', 'ดำเนินการไม่สำเร็จ')
  dispatch(
    openErrorDialog({
      title,
      message: message.split(' '),
      buttonRight: {
        handleClick: () => (window.location.href = '/my-class'),
      },
    })
  )
}

export const handleSuccess = (res, isResend) => (dispatch) => {
  const courseUuid = _.get(res, 'content.courseUuid', '')
  const trainingPlanUuid = isResend
    ? _.get(res, 'uuid', '')
    : _.get(res, 'content.trainingPlanUuid', '')
  const href = isResend
    ? `/my-class/registration-detail/${trainingPlanUuid}`
    : `/course/${courseUuid}/class/${trainingPlanUuid}`
  dispatch(
    openSuccessDialog({
      title: 'ส่งเอกสารสำเร็จ',
      message: ['รอเจ้าหน้าที่ตรวจสอบเอกสาร'],
      handleClick: () => (window.location.href = href),
    })
  )
}
