import React, { useState, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { useTheme } from '@mui/material'
import ScrollFixedTopContainer from 'src/components/ScrollFixedTopContainer'
import Search from 'src/components/Search'
import { checkOnFilter } from 'src/components/Filter/events'
import FilterButton from '../../components/FilterButton'
import { BackButton } from '../../components'
import FilterDrawer from '../Filter/FilterDrawer'
import { fetchCourseList } from '../events'
import { StyledListHeader, StyledRightFlex } from './Styled'
import { handleChangeSearch } from './events'

const ListHeader = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const { filterBody, courseName } = useSelector(
    (state) => ({
      filterBody: _.get(state.eLearningList, 'filterBody', null),
      courseName: _.get(state.eLearningList.filterBody, 'courseName', []),
    }),
    shallowEqual
  )
  const [isOpen, setOpen] = useState(false)
  const isOnFilter = useMemo(() => checkOnFilter(filterBody), [filterBody])

  return (
    <ScrollFixedTopContainer
      sx={{ backgroundColor: theme.palette?.background?.blue }}
    >
      <StyledListHeader>
        <BackButton onClick={() => history.push('/e-learning')} />

        <StyledRightFlex>
          <Search
            boxSx={{ width: 302 }}
            placeholder="ค้นหาชื่อหลักสูตร"
            text={courseName}
            setText={(e) => dispatch(handleChangeSearch(e))}
            handleSearch={() => dispatch(fetchCourseList(filterBody))}
          />
          <FilterButton isOnFilter={isOnFilter} onClick={() => setOpen(true)} />
        </StyledRightFlex>
      </StyledListHeader>
      <FilterDrawer isOpen={isOpen} setOpen={setOpen} />
    </ScrollFixedTopContainer>
  )
}
export default ListHeader
