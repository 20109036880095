import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import { BadgeContainer, Image, BadgeName } from './Styled'
import { Column } from 'src/components/Layout/Styled'

const BadgeGrid = () => {
  const { isBadgeSeeMore, badgeData } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )
  return (
    <Column>
      <Grid container spacing={2}>
        {badgeData.map((badge, ind) => {
          if (ind <= 3) {
            return (
              <Grid
                key={ind}
                item
                xs={6}
                sm={3}
                sx={{
                  minHeight: '130px',
                }}
              >
                <BadgeImage badge={badge} />
              </Grid>
            )
          }
        })}
      </Grid>
      <Collapse in={isBadgeSeeMore} sx={{ mt: 2 }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {badgeData.map((badge, ind) => {
            if (ind > 3) {
              return (
                <Grid
                  key={ind}
                  item
                  xs={6}
                  sm={3}
                  sx={{
                    minHeight: '130px',
                  }}
                >
                  <BadgeImage badge={badge} />
                </Grid>
              )
            }
          })}
        </Grid>
      </Collapse>
    </Column>
  )
}

export default BadgeGrid

const BadgeImage = ({ badge }) => {
  return (
    <BadgeContainer>
      <Image
        src={`${window.__env__.REACT_APP_FILE_URL}/get${badge.learningPoint.badgeImage?.key}`}
        $disable={badge.learningPoint.badgeStatus === 'INACTIVE'}
      />
      <BadgeName variant="body1">{badge.learningPoint.badgeName}</BadgeName>
    </BadgeContainer>
  )
}
