import {
  resetDataList,
  setFilterValue,
  setIsFetchSearch,
} from 'src/redux/slices/eContent'

export const handleChangeTab = (tab) => (dispatch) => {
  dispatch(resetDataList())
  dispatch(setFilterValue({ key: 'type', value: [tab] }))
}

export const handleChangeDropDown = (order) => (dispatch) => {
  dispatch(resetDataList())
  dispatch(setFilterValue({ key: 'order', value: order }))
  dispatch(setIsFetchSearch(true))
}
