import React from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { StyledButton, StyledDrawer } from './Styled'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Filter from '..'
import { Row } from '../../../../components/Layout/Styled'
import { resetFilter, clearFilter } from '../../../../redux/slices/eContent'
import { applyFilter } from './events'

const FilterDrawer = ({ isOpen, setOpen }) => {
  const dispatch = useDispatch()
  const { filter, filterChecked } = useSelector(
    (state) => ({
      filter: state.eContent.filter,
      filterChecked: state.eContent.filterChecked,
    }),
    shallowEqual
  )
  return (
    <StyledDrawer
      open={isOpen}
      onClose={() => {
        dispatch(resetFilter())
        setOpen(false)
      }}
    >
      <Box id="header">
        <Typography variant="h6">ตัวกรอง</Typography>
        <IconButton
          data-testid="btn-close"
          color="primary"
          onClick={() => {
            dispatch(resetFilter())
            setOpen(false)
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box id="body">
        <Filter isDrawer={true} />
      </Box>
      <Row
        sx={{
          mt: 'auto',
          justifyContent: 'center',
          columnGap: '20px',
          px: 3,
          pb: 3,
        }}
      >
        <StyledButton
          sx={{ height: 50, width: 'calc(50% - 10px)' }}
          variant="outlined"
          onClick={() => {
            dispatch(clearFilter())
          }}
        >
          ล้าง
        </StyledButton>
        <Button
          sx={{ height: 50, width: 'calc(50% - 10px)' }}
          variant="contained"
          onClick={() =>
            dispatch(applyFilter({ filter, filterChecked, setOpen }))
          }
        >
          ยืนยัน
        </Button>
      </Row>
    </StyledDrawer>
  )
}

export default FilterDrawer
