import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Column } from 'src/components/Layout/Styled'
import InformationItem from './InformationItem'
import { StyledAccordion } from './Styled'
import CollapseTitle from './CollpaseTitle'

const MultipleForm = () => {
  const { examiners } = useSelector(
    (state) => ({
      examiners: state.eExamination.examiners,
    }),
    shallowEqual
  )
  return (
    <Column sx={{ rowGap: 3 }}>
      {examiners.map((ele, ind) => {
        return (
          <StyledAccordion key={ind} defaultExpanded>
            <CollapseTitle index={ind} />
            <InformationItem index={ind} />
          </StyledAccordion>
        )
      })}
    </Column>
  )
}

export default MultipleForm
