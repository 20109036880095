import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getEContentById, postEContentRelated } from 'src/services/eContent'
import {
  setContentData,
  setRelatedList,
  stopDownload,
} from 'src/redux/slices/eContent'
import { downloadFileTypeGet } from 'src/utils/lib'

export const fetchContentData = (uuid) => async (dispatch) => {
  try {
    dispatch(startLoading())
    const res = await dispatch(getEContentById(uuid))
    dispatch(setContentData(res.payload.data))
    dispatch(stopLoading())
  } catch (e) {
    dispatch(stopLoading())
  }
}

export const fetchRelatedContent = (content, excludeId) => async (dispatch) => {
  const body = {
    category: content.category.uuid,
    subCategory: content.subCategory.uuid,
    tag: content.eContentTag.map((tag) => tag.uuid),
    excludeId: excludeId,
  }
  const res = await dispatch(postEContentRelated(body))
  dispatch(setRelatedList(res.payload.data))
}

export const handleClickDownloadFile = (path, fileName) => async (dispatch) => {
  await dispatch(downloadFileTypeGet({ url: `/${path}`, fileName: fileName }))
  dispatch(stopDownload())
}
