import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledBackgroundLoading } from './Styled'

const DrawerLoading = () => {
  const isLoading = useSelector(
    (state) => state.eExamination.drawerIsLoading,
    shallowEqual
  )
  return (
    <StyledBackgroundLoading isLoading={isLoading}>
      <CircularProgress />
    </StyledBackgroundLoading>
  )
}

export default DrawerLoading
