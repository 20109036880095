import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'
import { getContentLocation } from 'src/services/location'

const locationSlice = createSlice({
  name: 'location',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getContentLocation.fulfilled, (state, { payload }) => {
        state.data = _.get(payload, 'data', null)
      })
      .addCase(getContentLocation.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export default locationSlice.reducer
