export const dialogIcon = {
  success: '/icon/main_success.svg',
  course: '/icon/main_course.svg',
  error: '/icon/main_error.svg',
  time: '/icon/main_axa_clock.svg',
  paid: '/icon/main_paid.svg',
  warning: '/icon/dialog/warning.svg',
  info: '/icon/main_warning_info.svg',
  fail: '/icon/main_cancel.svg',
  done: '/icon/main_done.svg',
}
