import React, { useEffect } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { StyledBox } from './Styled'
import FormGroup from '@mui/material/FormGroup'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Box, Checkbox, FormControl } from '@mui/material'
import { Row } from '../../../components/Layout/Styled'
import DateRangeInput from '../../../components/DateRangeInput'
import BasicSelect from '../../../components/Input/Select'
import {
  setChecked,
  setFilterMemo,
  setFilterValue,
} from '../../../redux/slices/eContent'
import SelectGroupChip from '../../../components/Input/SelectGroupChip'
import { getSubCategoryOptions } from '../../../services/eContent'
import { startLoading, stopLoading } from '../../../redux/slices/loading'

const Menu = [
  {
    id: 1,
    key: 'category',
    name: 'หมวดหมู่',
    type: 'dropdown',
    options: ['วิธีขายประกัน 2024'],
    checked: false,
  },
  {
    id: 2,
    key: 'subCategory',
    name: 'หมวดหมู่ย่อย',
    type: 'dropdown',
    options: ['Unit Link'],
    checked: false,
  },
  {
    id: 3,
    key: 'tagSelected',
    name: 'Tag',
    type: 'multidropdown',
    options: ['RAM', 'RAM2', 'ERD'],
    checked: false,
  },
  {
    id: 4,
    key: 'startPublishDate',
    name: 'วันที่เผยแพร่',
    type: 'datepicker',
    options: [],
    checked: false,
  },
]

const Filter = ({ isDrawer }) => {
  const dispatch = useDispatch()
  const { filter, filterChecked, options } = useSelector(
    (state) => ({
      filter: state.eContent.filter,
      filterChecked: state.eContent.filterChecked,
      options: state.eContent.options,
    }),
    shallowEqual
  )
  useEffect(() => {
    dispatch(setFilterMemo())
  }, [])
  useEffect(() => {
    if (filter.category) {
      dispatch(startLoading())
      dispatch(getSubCategoryOptions({ category: filter.category }))
      dispatch(stopLoading())
    }
  }, [filter.category])

  return (
    <StyledBox isDrawer={isDrawer}>
      <FormGroup
        sx={{
          gap: '16px',
          alignItems: 'start',
          '& .MuiTypography-root': { pt: 1 },
        }}
      >
        {Menu.map((item, itemindex) => {
          return (
            <Box
              sx={{
                border: 1,
                borderColor: '#DBE4F199',
                margin: 'auto',
                padding: '16px',
                width: 312,
              }}
              key={itemindex}
            >
              <Row>
                <Checkbox
                  checked={filterChecked[item.key]}
                  onClick={(e) =>
                    dispatch(
                      setChecked({ key: item.key, value: e.target.checked })
                    )
                  }
                />
                <Typography variant="body1">{item.name}</Typography>
              </Row>
              {filterChecked[item.key] && (
                <Row>
                  {item.type == 'dropdown' ? (
                    <FormControl fullWidth sx={{ minWidth: '90px' }}>
                      <BasicSelect
                        options={options[item.key]}
                        value={filter[item.key] || ''}
                        handleChange={(e) =>
                          dispatch(
                            setFilterValue({
                              key: item.key,
                              value: e.target.value,
                            })
                          )
                        }
                        disabled={
                          item.key === 'subCategory' && !filter.category
                        }
                        placeholder={
                          options[item.key].length == 0 ? 'ไม่พบข้อมูล' : ''
                        }
                        showTextError={false}
                      />
                    </FormControl>
                  ) : item.type == 'multidropdown' ? (
                    <FormControl fullWidth sx={{ minWidth: '90px' }}>
                      <SelectGroupChip
                        options={options.tag}
                        value={filter[item.key]}
                        handleChange={(e, value) => {
                          dispatch(
                            setFilterValue({ key: [item.key], value: value })
                          )
                        }}
                      />
                    </FormControl>
                  ) : (
                    item.type == 'datepicker' && (
                      <DateRangeInput
                        dateState={{
                          startDate: filter.startPublishDateFrom
                            ? new Date(filter.startPublishDateFrom)
                            : new Date(),
                          endDate: filter.startPublishDateTo
                            ? new Date(filter.startPublishDateTo)
                            : new Date(),
                          display: filter.startPublishDateFrom != '',
                          key: 'selection',
                        }}
                        onChange={(selecteDates) => {
                          dispatch(
                            setFilterValue({
                              key: 'startPublishDateFrom',
                              value: selecteDates.startDate
                                ? dayjs(selecteDates.startDate).format(
                                    'YYYY-MM-DD'
                                  )
                                : '',
                            })
                          )
                          dispatch(
                            setFilterValue({
                              key: 'startPublishDateTo',
                              value: selecteDates.endDate
                                ? dayjs(selecteDates.endDate).format(
                                    'YYYY-MM-DD'
                                  )
                                : '',
                            })
                          )
                        }}
                        onReset={() => {
                          dispatch(
                            setFilterValue({
                              key: 'startPublishDateFrom',
                              value: '',
                            })
                          )
                          dispatch(
                            setFilterValue({
                              key: 'startPublishDateTo',
                              value: '',
                            })
                          )
                        }}
                      ></DateRangeInput>
                    )
                  )}
                </Row>
              )}
            </Box>
          )
        })}
      </FormGroup>
      <Divider />
    </StyledBox>
  )
}

export default Filter
