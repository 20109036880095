import React, { useState } from 'react'
import _ from 'lodash'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { Row } from '../Layout/Styled'
import MediaBox from '../CourseCard/components/MediaBox'
import Content from '../CourseCard/components/Content'
import InProgressContent from '../CourseCard/components/InProgressContent'
import { StyledContainer, StyledContentBox } from '../CourseCard/Styled'
import Title from './components/Title'
import ChipContainer from './components/ChipContainer'
import { StyledWrapper, StyledELearningCard, StyledTitleBox } from './Styled'

const ELearningCard = ({ course, keySection }) => {
  const [isHover, setHover] = useState(false)
  const isNotSm = isMediaWidthMoreThan('sm')
  const isInProgress = _.get(course, 'isInProgress', false)
  return (
    <StyledWrapper>
      {!isNotSm && <MediaBox course={course} />}
      <StyledELearningCard
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <StyledContainer>
          {isNotSm && <MediaBox course={course} isHover={isHover} />}
          <StyledContentBox id="content">
            <StyledTitleBox id="title" isInProgress={isInProgress}>
              <Title course={course} keySection={keySection} />

              {isInProgress ? (
                <Row id="progress">
                  <InProgressContent course={course} />
                </Row>
              ) : (
                <ChipContainer course={course} />
              )}

              <Content course={course} keySection={keySection} />
            </StyledTitleBox>
          </StyledContentBox>
        </StyledContainer>
      </StyledELearningCard>
    </StyledWrapper>
  )
}

export default ELearningCard
