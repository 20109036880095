import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import ContentCard from '../ContentCard'
import { StyledLoading } from './styled'
import { Row } from 'src/components/Layout/Styled'

export const SeeAlsoBar = () => {
  const { list, isFetch } = useSelector(
    (state) => ({
      list: state.eContent.related.list,
      isFetch: state.eContent.isFetch,
    }),
    shallowEqual
  )
  return (
    <div className="noPrint">
      <Box sx={{ py: 3 }}>
        <Typography variant="h5" sx={{ padding: '16px 0px' }}>
          ที่เกี่ยวข้อง
        </Typography>
        <Divider />
      </Box>
      {list.length > 0 ? (
        <Grid
          container
          sx={{ width: 'calc(100% + 24px)', pb: '24px' }}
          spacing={3}
        >
          {list?.map((item, index) => {
            return (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                lg={4}
                sx={{ height: 'auto' }}
              >
                <ContentCard item={item} />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Typography variant="body1" sx={{ textAlign: 'center', my: 10 }}>
          ไม่พบข้อมูล
        </Typography>
      )}
      {isFetch && (
        <Row sx={{ justifyContent: 'center', mb: 3 }}>
          <StyledLoading />
        </Row>
      )}
    </div>
  )
}
