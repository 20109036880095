import styled from '@mui/system/styled'
import { Accordion, AccordionSummary, Divider, Drawer } from '@mui/material'
import Box from '@mui/material/Box'

export const StyledExam = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1200,
  margin: '0 auto',
  marginTop: theme.spacing(9),
  [theme.breakpoints.down('lg')]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

export const StyledActionBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
  margin: 'auto',
  maxWidth: 1200,
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    padding: '0 16px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 8px',
  },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 640,
    padding: '60px',
    alignItems: 'center',
  },
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'unset',
  margin: 0,
  '&.Mui-expanded': { margin: '0 !important' },
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
  },
}))

export const Dot = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 8,
  left: 24,
  width: 8,
  height: 8,
  background: theme.palette.error.main,
  borderRadius: '50%',
  [theme.breakpoints.up(450)]: {
    top: 7,
    left: 23,
    width: 5,
    height: 5,
  },
}))

export const StyledBackgroundLoading = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ theme, isLoading }) => ({
  display: isLoading ? 'flex' : 'none',
  backgroundColor: theme.palette?.other?.backdropOverlay,
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
  '& .MuiCircularProgress-root': {
    color: theme.palette.text?.white,
    width: '48px !important',
    height: '48px !important',
  },
}))
