import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { IconButton, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Column, Row } from 'src/components/Layout/Styled'
import { setFilterValue } from 'src/redux/slices/eExamination'
import SelectGroupChip from 'src/components/Input/SelectGroupChip'

const FieldSelect = () => {
  const dispatch = useDispatch()
  const { fieldOptions, fields, type } = useSelector(
    (state) => ({
      fieldOptions: state.eExamination.options.fieldOptions,
      fields: state.eExamination.examinationFilter.fields,
      type: state.eExamination.examinationFilter.type,
    }),
    shallowEqual
  )
  return (
    <Column>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Typography variant="body1b">สนามสอบหรือสถานที่สอบ</Typography>
        <IconButton
          sx={{ columnGap: 0.5, borderRadius: 0 }}
          onClick={() =>
            dispatch(setFilterValue({ index: 'fields', value: [] }))
          }
          disabled={type.length <= 0}
        >
          <DeleteIcon
            sx={{
              fontSize: 20,
              color: type.length <= 0 ? '#999999' : '#00008F',
            }}
          />
          <Typography
            variant="body1"
            color={type.length <= 0 ? 'text.lightGray' : 'primary.main'}
          >
            ล้างค่าการค้นหา
          </Typography>
        </IconButton>
      </Row>
      <SelectGroupChip
        options={fieldOptions}
        value={fields}
        handleChange={(e, value) => {
          dispatch(setFilterValue({ index: 'fields', value: value }))
        }}
        disabled={type.length <= 0}
        renderOption={(props, option) => (
          <StyledOption props={props} option={option} />
        )}
        customChiplabel={(option) => <Chiplabel option={option} />}
      />
    </Column>
  )
}

export default FieldSelect

const StyledOption = ({ props, option }) => {
  return (
    <li {...props}>
      <Typography>{option.label}</Typography>
      {option.postFix && (
        <Typography sx={{ ml: 1 }} color="action.disabled">
          {option.postFix}
        </Typography>
      )}
    </li>
  )
}

const Chiplabel = ({ option }) => {
  return (
    <>
      <Typography>{option.label}</Typography>
      {option.postFix && (
        <Typography sx={{ ml: 1 }} color="action.disabled">
          {option.postFix}
        </Typography>
      )}
    </>
  )
}
