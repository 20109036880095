import { fetchELearningETestingAnswer } from 'src/modules/ELearning/Course/Learning/LeftPanel/MediaDisplay/ETesting/events'
import { setSectionTesting } from 'src/redux/slices/eTesting'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getETestingAnswer } from 'src/services/eTesting'

export const handleGetAnswer =
  (uuid, trainingPlan, isModule) => async (dispatch) => {
    dispatch(startLoading())
    if (isModule) await dispatch(fetchELearningETestingAnswer())
    else await dispatch(getETestingAnswer({ uuid, trainingPlan }))
    dispatch(setSectionTesting())
    dispatch(stopLoading())
  }
