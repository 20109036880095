import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import {
  StyledProgressCircular,
  StyledProgressNumber,
} from 'src/modules/ELearning/Course/Learning/RightPanel/ModuleLabel/Styled'

const CircularProgressWithLabel = ({
  value,
  size,
  primary,
  variant,
  fontSize,
}) => {
  return (
    <Box sx={{ position: 'relative', width: size, height: size }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={size}
        thickness={4}
        sx={{ color: '#CCCCCC' }}
      />
      <StyledProgressCircular
        variant="determinate"
        primary={primary}
        size={size}
        thickness={4}
        value={value}
      />
      <StyledProgressNumber variant={variant} sx={{ fontSize }}>
        {parseFloat(parseFloat(value.toFixed(2)))}%
      </StyledProgressNumber>
    </Box>
  )
}

export default CircularProgressWithLabel
