import { setFullScreen } from 'src/redux/slices/eLearning/learning'

export const handleHoverOnVideo = (ref) => {
  let timeout = 0

  const displayButtonOpa = () => {
    const backBtn = document.getElementById('back-lesson')
    const nextBtn = document.getElementById('next-lesson')
    if (backBtn) backBtn.style.opacity = 1
    if (nextBtn) nextBtn.style.opacity = 1

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (backBtn) backBtn.style.opacity = 0
      if (nextBtn) nextBtn.style.opacity = 0
    }, 1000)
  }

  ref.current.addEventListener('mousemove', displayButtonOpa)
  return () => {
    ref.current.removeEventListener('mousemove', displayButtonOpa)
  }
}

export const onKeyEsc = (e) => (dispatch) => {
  if (e.keyCode === 27) dispatch(setFullScreen(false))
}
