import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from './Card'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { fetchCatalog } from './event'
import { setReduxValue } from 'src/redux/slices/catalog'

const List = () => {
  const dispatch = useDispatch()
  const { catalogList, page, isFetch, totalCount } = useSelector(
    (state) => ({
      catalogList: state.catalog.catalogList,
      page: state.catalog.page,
      isFetch: state.catalog.isFetch,
      totalCount: state.catalog.totalCount,
    }),
    shallowEqual
  )
  useEffect(() => {
    page >= 2 && dispatch(fetchCatalog())
  }, [page])

  useEffect(() => {
    const handleWindowScroll = () => {
      const footer = document.getElementById('page-footer')

      if (catalogList.length < totalCount && !isFetch) {
        if (
          window.innerHeight + Math.round(window.scrollY) >
          document.body.offsetHeight - footer.offsetHeight
        ) {
          dispatch(setReduxValue({ index: 'page', value: page + 1 }))
          dispatch(setReduxValue({ index: 'isFetch', value: true }))
        }
      }
    }
    window.addEventListener('scroll', handleWindowScroll)
    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [isFetch, catalogList])
  return (
    <Grid
      container
      columnSpacing={{ md: 3, xs: 1 }}
      rowSpacing={{ md: 6, sm: 2, xs: 4 }}
    >
      {catalogList.length > 0 ? (
        catalogList.map((ele, ind) => {
          return <Card key={ind} index={ind} />
        })
      ) : (
        <Typography
          varaint="body1"
          color="text.secondary"
          sx={{ mt: 12, mx: 'auto' }}
        >
          ไม่พบข้อมูล
        </Typography>
      )}
    </Grid>
  )
}

export default List
