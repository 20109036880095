import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { postEContentFilter } from 'src/services/eContent'
import { ECONTENT_TYPE } from './model'
import { setLanding } from 'src/redux/slices/eContent'

export const fetchLandingPageContent = () => async (dispatch) => {
  dispatch(startLoading())
  try {
    const result = await Promise.all([
      dispatch(
        postEContentFilter({
          type: [ECONTENT_TYPE.E_CONTENT],
          page: 1,
          getPin: true,
        })
      ),
      dispatch(
        postEContentFilter({ type: [ECONTENT_TYPE.KB], page: 1, getPin: true })
      ),
    ])
    const contentData = _.get(result[0], 'payload.data.result', [])
    const knowledgeBaseData = _.get(result[1], 'payload.data.result', [])
    dispatch(setLanding({ E_CONTENT: contentData, KB: knowledgeBaseData }))
    dispatch(stopLoading())
  } catch (e) {
    dispatch(stopLoading())
  }
}
