import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getAddtionalConsent, postCancelSteal } from 'src/services/document'
import {
  openDialog,
  closeDialog,
  openErrorDialog,
} from 'src/redux/slices/alertDialog'
import {
  additionalConsentCreate,
  additionalConsentSteal,
} from 'src/utils/apiPath'
import Consent from '../../Consent'
import { ignoreMessage } from '../ConsentAndRegister/events'

export const fetchAddtionalConsent = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const response = await dispatch(getAddtionalConsent(payload))
  const resType = _.get(response, 'type', '')
  if (resType.includes('fulfilled')) {
    const canSubmit = _.get(response, 'payload.data.canSubmit', false)
    dispatch(handleErrorUser(canSubmit))
  }
  dispatch(stopLoading())
}

export const handleConsent = () => (dispatch) => {
  dispatch(
    openDialog({ type: 'custom', customWidth: '85%', content: <Consent /> })
  )
}

export const handleSubmitSteal =
  (uuid, idCard, date, managerId, userUuidOld, externalUuidOld) =>
  async (dispatch) => {
    dispatch(startLoading())
    const body = {
      idCardNo: idCard,
      dateOfBirth: date,
      userUuidOld: userUuidOld,
      externalUuidOld: externalUuidOld,
    }
    await axios
      .post(`${additionalConsentSteal}/${uuid}/${managerId}`, body)
      .then(() => {
        dispatch(
          openDialog({
            isCloseDialog: false,
            iconType: 'success',
            title: 'ส่งอีเมลสำเร็จแล้ว',
            message: ['ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว'],
            buttonRight: { handleClick: () => window.close() },
          })
        )
      })
      .catch(() =>
        dispatch(
          openDialog({
            iconType: 'error',
            title: 'ส่งอีเมลล้มเหลว',
            message: [
              'ระบบไม่สามารถส่งอีเมลได้ในขณะนี้',
              'โปรดลองใหม่อีกครั้ง',
            ],
          })
        )
      )
    dispatch(stopLoading())
  }

export const handleSubmitCancelSteal = (value) => async (dispatch) => {
  const result = await dispatch(
    postCancelSteal({
      tempUuid: value,
    })
  )
  const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
  if (requestStatus === 'fulfilled') {
    dispatch(closeDialog())
    window.close()
  }
}

export const handleSubmitDocument =
  (uuid, idCard, date, managerId) => async (dispatch) => {
    const body = {
      idCardNo: idCard,
      dateOfBirth: date,
    }
    const isMobile = /Mobi|Android/i.test(navigator.userAgent)
    dispatch(startLoading())
    await axios
      .post(`${additionalConsentCreate}/${uuid}/${managerId}`, body)
      .then(({ data }) => {
        const externalUuidOld = _.get(data, 'externalUuidOld', '')
        const userUuidOld = _.get(data, 'userUuidOld', '')
        const managerNameOld = _.get(data, 'managerNameOld', '')
        const managerUnitNameOld = _.get(data, 'managerUnitNameOld', '')
        const managerNameNew = _.get(data, 'managerNameNew', '')
        const managerUnitNameNew = _.get(data, 'managerUnitNameNew', '')

        if (_.get(data, 'steal', false) === true) {
          dispatch(
            openDialog({
              isCloseDialog: false,
              iconType: 'warning',
              title: 'ยืนยันการย้ายผู้ชักชวน',
              customWidth: '600px',
              message: [
                `ขณะนี้คุณได้เป็นสมาชิกของ คุณ${managerNameOld} หน่วย ${managerUnitNameOld} กรุณากดยอมรับหากต้องการย้ายไปเป็นสมาชิกของ`,
                `คุณ${managerNameNew} หน่วย ${managerUnitNameNew}`,
              ],
              buttonRight: {
                label: 'ยอมรับ',
                handleClick: () => {
                  dispatch(
                    handleSubmitSteal(
                      uuid,
                      idCard,
                      date,
                      managerId,
                      userUuidOld,
                      externalUuidOld
                    )
                  )
                },
                endIcon: <ArrowForwardIcon />,
              },
              buttonLeft: {
                label: 'ปฎิเสธ',
                handleClick: () => {
                  dispatch(handleSubmitCancelSteal(_.get(data, 'tempUuid', '')))
                },
              },
            })
          )
        } else {
          dispatch(
            openDialog({
              isCloseDialog: false,
              iconType: 'success',
              title: 'ส่งอีเมลสำเร็จแล้ว',
              message: ['ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว'],
              buttonRight: {
                handleClick: () => {
                  dispatch(closeDialog())
                  window.close()
                },
              },
            })
          )
        }
      })
      .catch((e) => {
        const constraints = _.get(e?.response, 'data.constraints', null)
        if (constraints) {
          dispatch(
            openErrorDialog({
              title: _.get(constraints, 'title', ''),
              message: [_.get(constraints, 'value', '')],
              buttonRight: {
                label: isMobile ? 'โทรหาเรา' : 'รับทราบ',
                handleClick: () => {
                  if (isMobile) {
                    window.open = 'tel:1159' // ใช้โทรออก
                    dispatch(closeDialog())
                  } else {
                    dispatch(closeDialog())
                  }
                },
              },
            })
          )
        } else {
          const message = ignoreMessage(e)
          dispatch(openDialog({ iconType: 'error', ...message }))
        }
      })
    dispatch(stopLoading())
  }

export const handleErrorUser = (canSubmit) => (dispatch) => {
  if (canSubmit) return
  const message = 'User นี้ได้ถูกสร้างแล้ว'
  dispatch(openErrorDialog({ title: message, message: ' ' }))
}
