import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Row } from 'src/components/Layout/Styled'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { setConsent } from 'src/redux/slices/eExamination'
import { handleConsent } from './event'

const ConsentCheckBox = () => {
  const dispatch = useDispatch()
  const { consent } = useSelector(
    (state) => ({
      consent: state.eExamination.consent,
    }),
    shallowEqual
  )
  return (
    <Row>
      <FormControlLabel
        control={
          <Checkbox
            checked={consent}
            onChange={(e) => dispatch(setConsent(e.target.checked))}
          />
        }
        sx={{ m: 0 }}
        label={
          <>
            <Typography display="inline" variant="body1">
              ผู้เรียนยินยอมที่จะเผยแพร่เอกสารที่มีข้อมูลส่วนบุคคลเพื่อใช้ในการสมัครสอบ
            </Typography>
            <Typography
              display="inline"
              sx={{
                textDecoration: 'underline 1px !important',
                ml: '3px',
                cursor: 'pointer',
                WebkitTextDecorationStyle: 'solid !important',
                WebkitTextDecorationThickness: '1px !important',
                WebkitTextDecorationLine: 'underline !important',
              }}
              variant="body1"
              color="primary.main"
              onClick={(e) => {
                e.preventDefault()
                dispatch(handleConsent())
              }}
            >
              อ่านเพิ่มเติม
            </Typography>
          </>
        }
      />
    </Row>
  )
}

export default ConsentCheckBox
