import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { StyledCard } from '../CourseCard/Styled'

export const StyledWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: '100%',
  minWidth: 214,
  height: '100%',
  [theme.breakpoints.up('lg')]: { width: 507 },
  [theme.breakpoints.down('sm')]: { height: 411 },
}))

export const StyledELearningCard = styled(StyledCard)(({ theme }) => ({
  height: '100%',
  minWidth: 296,
  '& #progress': {
    '& #in-progress': { display: 'flex' },
    '& #chip': { display: 'none' },
  },
  [theme.breakpoints.down('lg')]: { minWidth: '100%' },
  [theme.breakpoints.down('sm')]: { minWidth: 270 },
}))

export const StyledTitleBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isInProgress',
})(({ theme, isInProgress }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isInProgress ? 'space-between' : 'start',
  gap: '8px',
  [theme.breakpoints.down('lg')]: { height: 152 },
}))

export const StyledIconLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: theme.palette.text?.secondary,
  '& .MuiSvgIcon-root': { fontSize: '16px' },
  '& .MuiTypography-root': { fontSize: '20px', fontWeight: 700 },
}))

export const StyledFootBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  padding: '0 16px 16px',
  bottom: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: { padding: 0 },
}))
