import React from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'

import { useHistory } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const ContainedButton = ({ data }) => {
  const history = useHistory()

  const scheduleUuid = _.get(data, 'uuid', '')

  const handleToDetail = () => {
    history.push(`/announce-examiner/detail/${scheduleUuid}`)
  }

  return (
    <Button
      sx={{ px: '22px' }}
      endIcon={<ArrowForwardIosIcon />}
      data-testid="btn-class"
      onClick={handleToDetail}
    ></Button>
  )
}
export default ContainedButton
