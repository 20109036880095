import { COURSE_ORDER, COURSE_SORT } from 'src/constants/eLearning'

export const initialFilter = {
  order: COURSE_ORDER.DESC,
  sortBy: COURSE_SORT.POPULAR,
  myCollection: [],
  acquiredSkill: [],
  productType: [],
  name: '',
  pathHourFrom: 0,
  pathHourTo: 0,
  page: 1,
  limit: 9,
}

export const initialState = {
  collection: [],
  totalCount: 0,
  isFetch: true,
  data: {},
  filterData: null,
  filter: initialFilter,
  isLikeLoading: false,
}
