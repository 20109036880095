import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { StyledProgressCircular, StyledProgressNumber } from '../Styled'

const CircularProgressWithLabel = ({ value }) => {
  const progress = _.floor(value)
  return (
    <Box sx={{ position: 'relative', width: 30, height: 30 }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={30}
        thickness={4}
        sx={{ color: '#CCCCCC' }}
      />
      <StyledProgressCircular
        variant="determinate"
        size={30}
        thickness={4}
        value={progress}
      />
      <StyledProgressNumber>{progress}%</StyledProgressNumber>
    </Box>
  )
}

export default CircularProgressWithLabel
