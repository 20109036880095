import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { initialState } from './model'
import {
  getCollectionById,
  postCollectionFilter,
  postLikeCollection,
} from 'src/services/eLearning/collection'
import { getELearningFilter } from 'src/services/eLearning/list'

const eLearningCollection = createSlice({
  name: 'eLearningCollection',
  initialState,
  reducers: {
    setFilterValue: (state, { payload }) => {
      const { key, value } = payload
      state.filter[key] = value
    },
    setFilterBody: (state, { payload }) => {
      const { key, value } = payload
      state.filter[key] = value
      state.filter.page = 1
      state.isFetch = true
    },
    handleSearch: (state) => {
      state.filter.page = 1
      state.isFetch = true
    },
    LoadMore: (state) => {
      state.filter.page++
      state.isFetch = true
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getELearningFilter.fulfilled, (state, { payload }) => {
        const data = _.get(payload, 'data', {})
        state.filterData = {
          ...data,
          pathHourFrom: data.courseHourFrom,
          pathHourTo: data.courseHourTo,
          myCollection: [{ value: 'LIKE', label: 'Collection ที่ถูกใจ' }],
        }
      })
      .addCase(getELearningFilter.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getCollectionById.fulfilled, (state, { payload }) => {
        state.data = _.get(payload, 'data', {})
      })
      .addCase(getCollectionById.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postCollectionFilter.fulfilled, (state, { payload }) => {
        if (state.filter.page > 1) {
          state.collection = state.collection.concat(
            _.get(payload, 'data.collections', [])
          )
        } else {
          state.collection = _.get(payload, 'data.collections', [])
        }
        state.totalCount = _.get(payload, 'data.totalCollection', 0)
        state.isFetch = false
      })
      .addCase(postCollectionFilter.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postLikeCollection.pending, (state) => {
        state.isLikeLoading = true
      })
      .addCase(postLikeCollection.fulfilled, (state, { payload }) => {
        state.data.isLike = payload.data.isLike
        state.isLikeLoading = false
      })
      .addCase(postLikeCollection.rejected, (state, action) => {
        state.error = action.meta?.message
        state.isLikeLoading = false
      })
  },
})

export const { setFilterValue, setFilterBody, handleSearch, LoadMore } =
  eLearningCollection.actions

export default eLearningCollection.reducer
