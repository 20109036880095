import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../../../components/Breadcrumb'
import Table from '../../../components/Table'
import Template from '../../../components/Template'
import { BoxContent } from '../Styled'
import { listBreadcrumbs } from './model'
import DrawerFilter from './DrawerFilter'
import { setDefaultPage, handleFetchData } from './events'
import { getQueryParams } from 'src/utils/lib'

const Index = () => {
  const { type } = useParams()
  const standaloneType = getQueryParams('type')
  const dispatch = useDispatch()
  const { table, filter, search } = useSelector((state) => state.table)
  const { limit, order, page, sort } = table
  const { isFilterDrawer } = filter

  useEffect(() => {
    dispatch(setDefaultPage())
  }, [])

  useEffect(() => {
    dispatch(
      handleFetchData({ table, type, text: search.searchText, standaloneType })
    )
  }, [limit, order, page, sort])

  return (
    <Template>
      <BoxContent isColumn>
        <Box>
          <Typography variant="h4">รายการที่ต้องทำ</Typography>
          <Breadcrumb list={listBreadcrumbs} />
        </Box>
        <Card
          sx={{
            border: '1px solid #DBE4F1',
            borderRadius: '2px',
            boxShadow: 'unset ',
          }}
        >
          <Table />
        </Card>
        <DrawerFilter open={isFilterDrawer} />
      </BoxContent>
    </Template>
  )
}

export default Index
