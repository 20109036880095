import _ from 'lodash'
import { setFilter } from 'src/redux/slices/eLearning/list'

export const getFilterBody = (state, key, defaultValue) =>
  _.get(state.eLearningList.filterBody, key, defaultValue)

export const handleChangeSlider = (value) => (dispatch) => {
  dispatch(setFilter({ key: 'courseHourFrom', value: value[0] }))
  dispatch(setFilter({ key: 'courseHourTo', value: value[1] }))
}

export const handleChangePeriod = (formattedDate) => (dispatch) => {
  dispatch(setFilter({ key: 'startDate', value: formattedDate.startDate }))
  dispatch(setFilter({ key: 'finishDate', value: formattedDate.finishDate }))
}

export const handleResetPeriod = () => (dispatch) => {
  dispatch(setFilter({ key: 'startDate', value: '' }))
  dispatch(setFilter({ key: 'finishDate', value: '' }))
}

export const handleChangeSort = (e) => (dispatch) => {
  const name = _.get(e.target, 'name', '')
  const value = _.get(e.target, 'value', '')
  dispatch(setFilter({ key: name, value }))
}
