import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { FILE_TYPE } from 'src/constants/eLearning'
import { setFileRef } from 'src/redux/slices/eLearning/learning'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { handleDownloadFile } from '../../../RightPanel/ModuleLabel/events'
import { MediaBoxStyled } from '../Styled'
import {
  StyledFileRenderWrapper,
  StyledZipDisplay,
  StyledZipDownloadBtn,
  imageStyle,
} from './Styled'

const FileRender = ({ selectedLesson }) => {
  const fileList = _.get(selectedLesson, 'fileList', [])
  const selectedIndex = _.get(selectedLesson, 'selectedFile', 0)
  const selectedFile = _.get(fileList, selectedIndex, {})
  const ref = useRef(null)
  const dispatch = useDispatch()
  const isLg = isMediaWidthMoreThan('lg')

  useEffect(() => {
    if (ref) dispatch(setFileRef(ref))
  }, [ref])

  if (selectedFile.fileType === _.lowerCase(FILE_TYPE.ZIP)) {
    return (
      <MediaBoxStyled key={selectedFile}>
        <StyledZipDisplay src="/icon/e-learning/ic_zip.svg" />

        <StyledZipDownloadBtn
          startIcon={<img src="/icon/ic_download.svg" />}
          onClick={() => dispatch(handleDownloadFile(selectedFile))}
        >
          ดาวน์โหลด
        </StyledZipDownloadBtn>
      </MediaBoxStyled>
    )
  }

  return (
    <MediaBoxStyled>
      {isLg ? (
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          wheel={{ wheelDisabled: true }}
          ref={ref}
        >
          {() => (
            <TransformComponent
              wrapperStyle={{ ...imageStyle, height: '100%' }}
              contentStyle={imageStyle}
            >
              <FileList selectedFile={selectedFile} />
            </TransformComponent>
          )}
        </TransformWrapper>
      ) : (
        <FileList selectedFile={selectedFile} />
      )}
    </MediaBoxStyled>
  )
}

export default FileRender

export const FileList = ({ selectedFile }) => {
  const backendApiUrl = window.__env__.REACT_APP_SOCKET_URL
  return (
    <StyledFileRenderWrapper>
      {_.get(selectedFile, 'fileName', []).map((img, index) => (
        <img key={index} src={`${backendApiUrl}/file${img}`} />
      ))}
    </StyledFileRenderWrapper>
  )
}
