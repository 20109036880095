import _ from 'lodash'
import {
  setVideoState,
  resetTime,
  openClickDialog,
  setClickRandomDateTime,
  setClickRound,
  closeClickDialog,
} from 'src/redux/slices/eLearning/learning'
import { closeDialog } from 'src/redux/slices/alertDialog'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { postCourseLogClick } from 'src/services/eLearning/learning'
import {
  E_LEARNING_CLICK_LOG_RESULT,
  MODULE_TYPE,
} from 'src/constants/eLearning'
import { getLearningState } from '../events'
import { handleFormatNow } from '../CameraDialog/events'

export const handleOpenDialog = () => (dispatch) => {
  const { round } = getLearningState().clickDialog

  const nextRound = round + 1
  dispatch(setClickRound(nextRound))
  dispatch(resetTime({ isClick: true }))
  dispatch(setClickRandomDateTime(handleFormatNow()))
  dispatch(openClickDialog())

  const moduleType = _.get(getLearningState().selectedLesson, 'moduleType', '')
  if (moduleType === MODULE_TYPE.EMBED_CONTENT) {
    dispatch(setVideoState({ playing: false }))
  }
}

export const handleClickButton = () => (dispatch) => {
  dispatch(handleCreateLogClick(true))
}

export const handleCreateLogClick = (isClicked) => async (dispatch) => {
  const {
    eLearningCourseBatchLearner,
    clickDialog: { randomDateTime },
  } = getLearningState()

  const body = {
    eLearningCourseBatchLearner,
    randomDateTime,
    result: isClicked
      ? E_LEARNING_CLICK_LOG_RESULT.PASS
      : E_LEARNING_CLICK_LOG_RESULT.NOT_PASS,
  }

  dispatch(startLoading())
  const response = await dispatch(postCourseLogClick(body))
  dispatch(stopLoading())

  if (_.isEmpty(response.error) && isClicked) {
    dispatch(closeDialog())
    dispatch(closeClickDialog())
  }
}
