import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Template from 'src/components/Template'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { Column, Row } from 'src/components/Layout/Styled'
import { COURSE_TYPE_TH } from 'src/constants/eLearning'
import { StyledTypography } from 'src/components/CourseCard/Styled'
import { LikeIconButton } from '../../../components/LikeButton'
import { handleLikeCourse } from '../events'
import {
  StyledHeaderImage,
  StyledTextBtn,
  StyledHeaderWrapper,
  StyledHeaderContainer,
  StyledTooltip,
} from './Styled'
import EnrollButton from '../EnrollButton'
import { handleDisplayPrerequisite } from './events'

const Header = () => {
  const { data, isLikeLoading } = useSelector(
    (state) => ({
      data: state.eLearningDetail.data,
      isLikeLoading: state.eLearningDetail.isLikeLoading,
    }),
    shallowEqual
  )
  const coverImage = _.get(data, 'coverImage', '')
  const isNotSm = isMediaWidthMoreThan('sm')
  const isLike = _.get(data, 'isLike', false)
  const dispatch = useDispatch()

  if (isNotSm) {
    return <TabletDesktopHeader data={data} coverImage={coverImage} />
  }

  return (
    <StyledHeaderWrapper>
      <Row sx={{ justifyContent: 'space-between' }}>
        <StyledTextBtn
          onClick={() => (window.location.href = '/e-learning/course')}
        >
          กลับ
        </StyledTextBtn>
        <Typography variant="body1b">
          {COURSE_TYPE_TH[_.get(data, 'courseType', '')] || ''}
        </Typography>
        <LikeIconButton
          isLike={isLike}
          isLoading={isLikeLoading}
          onClick={() => dispatch(handleLikeCourse())}
        />
      </Row>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_FILE_URL}/get${coverImage}`}
        />
      )}
      <StyledTypography variant="h6">
        {_.get(data, 'nameForLearner', '') || _.get(data, 'name', '')}
      </StyledTypography>
    </StyledHeaderWrapper>
  )
}

export default Header

export const TabletDesktopHeader = ({ data, coverImage }) => {
  const isLg = isMediaWidthMoreThan('lg')
  const coursePrerequisite = _.get(data, 'coursePrerequisite', [])
  const name = _.get(data, 'nameForLearner', '') || _.get(data, 'name', '')
  return (
    <StyledHeaderWrapper>
      {coverImage && (
        <StyledHeaderImage
          src={`${window.__env__.REACT_APP_FILE_URL}/get${coverImage}`}
        />
      )}
      <Template noMinHeight>
        <StyledTextBtn
          onClick={() => (window.location.href = '/e-learning/course')}
        >
          กลับ
        </StyledTextBtn>

        <StyledHeaderContainer>
          <Box>
            <Typography variant="h6" color="text.alabaster">
              {COURSE_TYPE_TH[_.get(data, 'courseType', '')] || ''}
            </Typography>
            <StyledTooltip title={name}>
              <StyledTypography variant={isLg ? 'h2' : 'h4'} color="text.white">
                {name}
              </StyledTypography>
            </StyledTooltip>
          </Box>

          <Row sx={{ gap: 3, alignItems: 'start' }}>
            <EnrollButton />

            {!_.isEmpty(coursePrerequisite) && (
              <Box mt={1}>
                <StyledTooltip
                  title={<Prerequisite data={coursePrerequisite} />}
                >
                  <img src="/icon/e-learning/ic_info.svg" />
                </StyledTooltip>
              </Box>
            )}
          </Row>
        </StyledHeaderContainer>
      </Template>
    </StyledHeaderWrapper>
  )
}

export const Prerequisite = ({ data }) => {
  return (
    <Column gap={1.5}>
      {data.map((andPrerequisite, index) => {
        const prerequisiteList = handleDisplayPrerequisite(andPrerequisite)
        return (
          <Box key={index}>
            <Typography variant="body1b">
              {index !== 0 && `หรือ `}เงื่อนไขที่ {index + 1}
            </Typography>

            {prerequisiteList.map((andPrerequisite) =>
              andPrerequisite.map((and, aIndex) => (
                <Column key={aIndex} ml="14px">
                  {and.condition === 'OR' && <Typography>หรือ</Typography>}
                  <li>{and.label}</li>
                </Column>
              ))
            )}
          </Box>
        )
      })}
    </Column>
  )
}
