import { Box, Button, Typography, styled } from '@mui/material'

export const HeaderBox = styled(Box)(({ theme }) => ({
  padding: '24px 0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}))

export const DateBox = styled(Box)(() => ({
  width: '100%',
  fontSize: '20px',
  color: '#ff9800',
}))

export const TitleBox = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}))

export const StartFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  justifySelf: 'start',
}))

export const EndFlex = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '8px',
    marginLeft: 0,
    width: '100%',
  },
}))

export const StyledDownloadButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  padding: '8px 16px',
  gap: '8px',
  '@media print': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const TagBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '24px',
  gap: '24px',
}))

export const ChipBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '4px',
}))

export const StyledChip = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  background: theme.palette?.grey?.grey300,
  borderRadius: '16px',
  padding: '3px 4px',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}))

export const ContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '24px',
  gap: '24px',
  fontFamily: 'slick',
}))
