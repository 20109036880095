import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { StyledTooltip } from '../../Styled'
import ModuleLabel from '../ModuleLabel'
import { StyledListContainer, StyledOneLineText } from '../Styled'
import { StyledHeadLabel, StyledIconButton, StyledLessonLabel } from './Styled'

const LessonLabel = ({ data, index }) => {
  const isExpanded = useSelector(
    (state) => state.eLearningLearning.isExpanded,
    shallowEqual
  )
  const [isShow, setShow] = useState(true)
  const modules = _.get(data, 'modules', [])
  const progressNumber = useMemo(
    () => modules.filter((item) => item.progress === 100).length,
    [modules]
  )
  const name = _.get(data, 'name', '-')

  if (!isExpanded) return <></>

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <StyledLessonLabel onClick={() => setShow(!isShow)}>
        <Box sx={{ width: 'calc(100% - 40px)', display: 'flex', gap: 2 }}>
          <StyledHeadLabel>
            <Typography variant="body3b" color="primary">
              {progressNumber}/{modules.length}
            </Typography>
          </StyledHeadLabel>
          <StyledTooltip title={name}>
            <StyledOneLineText
              variant="body2b"
              sx={{ width: 'calc(100% - 56px)' }}
            >
              {name}
            </StyledOneLineText>
          </StyledTooltip>
        </Box>
        <StyledIconButton onClick={() => setShow(!isShow)}>
          {isShow ? <ExpandLess /> : <ExpandMore />}
        </StyledIconButton>
      </StyledLessonLabel>
      {isShow && (
        <StyledListContainer sx={{ overflowY: 'unset' }}>
          {modules.map((data, mIndex) => (
            <ModuleLabel
              key={mIndex}
              data={data}
              index={index}
              mIndex={mIndex}
            />
          ))}
        </StyledListContainer>
      )}
    </Box>
  )
}

export default LessonLabel
