import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { SECTION_OBJ } from 'src/components/CourseCard/model'
import { StyledLikeIcon } from 'src/components/CourseCard/components/Styled'
import { StyledTypography } from 'src/components/CourseCard/Styled'
import { Row } from 'src/components/Layout/Styled'

const Title = ({ course, keySection }) => {
  const isLg = isMediaWidthMoreThan('md')
  const isLike = _.get(course, 'isLike', false)
  return (
    <Box sx={{ textAlign: 'start', position: 'relative' }}>
      <Row sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <StyledTypography
          id="course-type"
          variant="body2"
          color="secondary"
          sx={{ fontSize: !isLg && 14 }}
        >
          {SECTION_OBJ[keySection]?.title || '-'}
        </StyledTypography>
        {isLike && <StyledLikeIcon src="/icon/ic_heart_fill.svg" />}
      </Row>
      <StyledTypography variant={isLg ? 'h5' : 'h6'} color="text.primary">
        {_.get(course, 'name', '')}
      </StyledTypography>
    </Box>
  )
}

export default Title
