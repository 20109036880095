import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'
import { setReduxValue } from 'src/redux/slices/catalog'
import { handleSearchCatalog } from './event'

const SearchBox = () => {
  const dispatch = useDispatch()
  const { searchText } = useSelector(
    (state) => ({ searchText: state.catalog.searchText }),
    shallowEqual
  )
  return (
    <BoxSearch>
      <SearchInput
        placeholder="ค้นหา"
        onChange={(e) => {
          dispatch(
            setReduxValue({
              index: 'searchText',
              value: e.target.value,
            })
          )
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            dispatch(handleSearchCatalog())
          }
        }}
        value={searchText}
      />
      <StyledSearchIcon
        sx={{ color: '#0000008A' }}
        onClick={() => dispatch(handleSearchCatalog())}
      />
    </BoxSearch>
  )
}

export default React.memo(SearchBox)
