import React from 'react'
import Typography from '@mui/material/Typography'
import { StyledResultContent } from 'src/components/Preview/Result/Styled'

const BannerFinish = () => {
  return (
    <StyledResultContent>
      <img src="/icon/main_success.svg" />
      <Typography variant="h3" color="primary">
        ทำแบบทดสอบสำเร็จ
      </Typography>
      <Typography variant="h6" color="text.secondary">
        ระบบจะแจ้งให้คุณทราบในภายหลัง
      </Typography>
    </StyledResultContent>
  )
}

export default BannerFinish
