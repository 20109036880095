import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { Row } from 'src/components/Layout/Styled'
import SearchBox from './SearchBox'
import { StyledCatalog, StyledRow } from './Styled'
import Score from './Score'
import List from './List'
import { useDispatch } from 'react-redux'
import { fetchInitial } from './event'

const Catalog = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchInitial())
  }, [])

  return (
    <StyledCatalog>
      <StyledRow>
        <Typography sx={{ typography: { sm: 'h4', xs: 'h6' } }}>
          ของรางวัลทั้งหมด
        </Typography>
        <SearchBox />
      </StyledRow>
      <Row>
        <Typography sx={{ typography: { sm: 'h5', xs: 'body1b' } }}>
          รายการของรางวัล
        </Typography>
        <Score />
      </Row>
      <List />
    </StyledCatalog>
  )
}
export default Catalog
