import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const downloadFile = createAsyncThunk(
  'downloadFile',
  async ({ url, body, fileName }, { rejectWithValue }) => {
    try {
      await axios.post(url, body, { responseType: 'blob' }).then((res) => {
        const resUrl = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = resUrl
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const downloadGetFile = createAsyncThunk(
  'downloadGetFile',
  async ({ url, fileName }, { rejectWithValue }) => {
    try {
      await axios.get(url, { responseType: 'blob' }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)