import React from 'react'
import axios from 'axios'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { openDialog, closeDialog } from 'src/redux/slices/alertDialog'
import { getAddtionalConsentAndDocs } from 'src/services/document'
import { additionalConsent, additionalConsentSteal } from 'src/utils/apiPath'
import * as sliceDialog from 'src/redux/slices/alertDialog'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import _ from 'lodash'
import Consent from '../../Consent'
import { handleSubmitCancelSteal } from '../Consent/events'

export const fetchAddtionalConsentAndDocs = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const response = await dispatch(getAddtionalConsentAndDocs(payload))
  const resType = _.get(response, 'type', '')
  if (resType.includes('fulfilled')) {
    const canSubmit = _.get(response, 'payload.data.canSubmit', false)
    dispatch(handleErrorUser(canSubmit))
  }
  dispatch(stopLoading())
}

export const handleConsent = () => (dispatch) => {
  dispatch(
    openDialog({ type: 'custom', customWidth: '85%', content: <Consent /> })
  )
}

export const handleSubmitSteal =
  (uuid, idCard, date, managerId, userUuidOld, externalUuidOld, id) =>
  async (dispatch) => {
    const body = {
      idCardNo: idCard,
      dateOfBirth: date,
      userUuidOld: userUuidOld,
      externalUuidOld: externalUuidOld,
    }
    dispatch(startLoading())

    await axios
      .post(`${additionalConsentSteal}/${id}/${uuid}/${managerId}`, body)
      .then(() => {
        dispatch(
          openDialog({
            isCloseDialog: false,
            iconType: 'success',
            title: 'ส่งอีเมลสำเร็จแล้ว',
            message: ['ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว'],
            buttonRight: {
              handleClick: () => window.close(),
            },
          })
        )
      })
      .catch(() =>
        dispatch(
          openDialog({
            isCloseDialog: false,
            iconType: 'error',
            title: 'ส่งอีเมลล้มเหลว',
            message: [
              'ระบบไม่สามารถส่งอีเมลได้ในขณะนี้',
              'โปรดลองใหม่อีกครั้ง',
            ],
          })
        )
      )
    dispatch(stopLoading())
  }

export const handleSubmitDocument =
  (uuid, idCard, date, id, managerId) => async (dispatch) => {
    const body = {
      idCardNo: idCard,
      dateOfBirth: date,
    }
    dispatch(startLoading())
    await axios
      .post(`${additionalConsent}/${id}/${uuid}/${managerId}`, body)
      .then(({ data }) => {
        const externalUuidOld = _.get(data, 'externalUuidOld', '')
        const userUuidOld = _.get(data, 'userUuidOld', '')
        const managerNameOld = _.get(data, 'managerNameOld', '')
        const managerUnitNameOld = _.get(data, 'managerUnitNameOld', '')
        const managerNameNew = _.get(data, 'managerNameNew', '')
        const managerUnitNameNew = _.get(data, 'managerUnitNameNew', '')
        if (_.get(data, 'steal', false) === true) {
          dispatch(
            sliceDialog.openDialog({
              iconType: 'warning',
              title: 'ยืนยันการย้ายผู้ชักชวน',
              customWidth: '600px',
              message: [
                `ขณะนี้คุณได้เป็นสมาชิกของ คุณ${managerNameOld} หน่วย ${managerUnitNameOld} กรุณากดยอมรับหากต้องการย้ายไปเป็นสมาชิกของ`,
                `คุณ${managerNameNew} หน่วย ${managerUnitNameNew}`,
              ],
              buttonRight: {
                label: 'ยอมรับ',
                handleClick: () => {
                  dispatch(closeDialog())
                  dispatch(
                    handleSubmitSteal(
                      uuid,
                      idCard,
                      date,
                      managerId,
                      userUuidOld,
                      externalUuidOld,
                      id
                    )
                  )
                },
                endIcon: <ArrowForwardIcon />,
              },
              buttonLeft: {
                label: 'ปฎิเสธ',
                handleClick: () => {
                  dispatch(handleSubmitCancelSteal(_.get(data, 'tempUuid', '')))
                },
              },
            })
          )
        } else {
          dispatch(
            sliceDialog.openDialog({
              isCloseDialog: false,
              iconType: 'success',
              title: 'ส่งอีเมลสำเร็จแล้ว',
              message: ['ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว'],
              buttonRight: { handleClick: () => window.close() },
            })
          )
        }
      })
      .catch((e) => {
        const message = ignoreMessage(e)
        dispatch(openDialog({ iconType: 'error', ...message }))
      })
    dispatch(stopLoading())
  }

export const ignoreMessage = (error) => {
  const constraints = _.get(error, 'response.data.constraints', undefined)
  const title = _.get(constraints, 'title', 'ส่งอีเมลล้มเหลว')
  const messageCase = 'กรุณาใส่วัน เดือน ปีเกิดให้ถูกต้อง'
  const message =
    _.get(constraints, 'value', '') !== messageCase
      ? _.get(constraints, 'value', '').split(' ')
      : [_.get(constraints, 'value', '')]

  return {
    title,
    message,
  }
}

export const handleErrorUser = (canSubmit) => (dispatch) => {
  if (canSubmit) return
  const message = 'User นี้ได้ถูกสร้างแล้ว'
  dispatch(sliceDialog.openErrorDialog({ title: message, message: ' ' }))
}
