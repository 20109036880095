import _ from 'lodash'
import { SECTION_KEY } from 'src/components/CourseCard/model'
import { COLLECTION_SECTION_KEY } from 'src/components/ELearningCard/model'

export const handleCourseValue = (course) => {
  let courseData = {}
  let sectionKey = ''
  const type = course.type.toUpperCase().replace(/ /g, '_')
  if (COLLECTION_SECTION_KEY[type] == SECTION_KEY.E_LEARNING) {
    courseData = {
      ...course.eLearningCourse,
      hours: course.eLearningCourse.totalHour || 0,
      minutes: course.eLearningCourse.totalMinute || 0,
      lessonAmount: course?.eLearningCourse?.eLearningCourseLesson?.length || 0,
    }
    sectionKey = SECTION_KEY.E_LEARNING
  } else if (
    COLLECTION_SECTION_KEY[type] == SECTION_KEY.LEARNING_PATH
  ) {
    courseData = {
      ...course.eLearningLearningPath,
      hours: course.eLearningLearningPath.totalHour || 0,
      minutes: course.eLearningLearningPath.totalMinute || 0,
      courseCount:
        course?.eLearningLearningPath?.eLearningLearningPathCourses?.length ||
        0,
    }
    sectionKey = SECTION_KEY.LEARNING_PATH
  } else {
    courseData = {
      ...course.courseVersion,
      trainingPlatformName: _.startCase(type),
      hasCertificate: course.courseVersion.eCertificationVersion !== null,
    }
    sectionKey = SECTION_KEY.COURSE
  }

  return {
    courseData: courseData,
    sectionKey,
  }
}
