import React, { useState, useEffect } from 'react'
import {
  BackCard,
  FlipCard,
  FrontCard,
  InnerCard,
  StyledImage,
  Name,
  Decription,
  ScoreChip,
  RedeemChip,
} from './Styled'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import StarsIcon from '@mui/icons-material/Stars'
import { Column, Row } from 'src/components/Layout/Styled'
import { convertNumberWithComma } from 'src/utils/lib'

const Card = ({ index }) => {
  const [isFlip, setIsFlip] = useState(false)
  const [width, setWidth] = useState(0)
  const { catalog, myPoint } = useSelector(
    (state) => ({
      catalog: state.catalog.catalogList[index],
      myPoint: state.catalog.myPoint,
    }),
    shallowEqual
  )
  const canRedeem = catalog.point <= myPoint

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth)
    }
    handleWindowSizeChange()

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  return (
    <FlipCard item lg={3} md={4} sm={6} xs={12}>
      <InnerCard
        $flip={isFlip}
        $ispc={width >= 1280}
        onClick={() => {
          if (width >= 1280) return
          setIsFlip(!isFlip)
        }}
      >
        <FrontCard>
          <StyledImage
            src={`${window.__env__.REACT_APP_FILE_URL}/get${catalog.image.key}`}
          />
          <Column sx={{ p: 2, gap: 1, height: 'calc(100% - 240px)' }}>
            <Name variant="h6" color="primary.main">
              {catalog.name}
            </Name>
            <Decription variant="body3">{catalog.description}</Decription>
            <Row sx={{ gap: 0.5, mt: 'auto' }}>
              <ScoreChip
                sx={{ typography: { md: 'body2', sm: 'body3', xs: 'body2' } }}
                $canRedeem={canRedeem}
              >
                <StarsIcon sx={{ fontSize: 18, mr: 0.5 }} />
                {convertNumberWithComma(catalog.point)}
              </ScoreChip>
              <RedeemChip
                sx={{ typography: { md: 'body2', sm: 'body3', xs: 'body2' } }}
                $canRedeem={canRedeem}
              >
                {canRedeem
                  ? 'แลกได้'
                  : `ขาดอีก ${convertNumberWithComma(
                      catalog.point - myPoint
                    )} คะแนน`}
              </RedeemChip>
            </Row>
          </Column>
        </FrontCard>
        <BackCard>
          <Column sx={{ gap: 1, py: 1.5, px: 2.25, height: '100%' }}>
            <Name variant="body2b" color="primary.main">
              {catalog.name}
            </Name>
            <Typography
              variant="body3"
              sx={{ height: '100%', overflow: 'auto' }}
            >
              {catalog.description}
            </Typography>
          </Column>
        </BackCard>
      </InnerCard>
    </FlipCard>
  )
}

export default Card
