import React from 'react'
import { StyledCircularBox, StyledCircularLoader } from '../Styled'

const CircularContent = ({ isLoading, color = 'primary' }) => {
  if (!isLoading) return <></>
  return (
    <StyledCircularBox>
      <StyledCircularLoader color={color} />
    </StyledCircularBox>
  )
}

export default CircularContent
