import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledCourseCard = styled(Row)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueBorder,
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  gap: theme.spacing(2),
  '& > #text': {
    gap: theme.spacing(1),
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: { gap: theme.spacing(2) },
  },
}))

export const StyledImg = styled(Box)(({ theme }) => ({
  width: 90,
  height: 60,
  img: { width: 90, height: 60, objectFit: 'cover' },
  '&#img-sm': { display: 'none' },
  [theme.breakpoints.down('sm')]: {
    '&#img': { display: 'none' },
    '&#img-sm': { display: 'block' },
  },
}))

export const StyledCourseName = styled(Typography)(({ theme }) => ({
  maxWidth: 482,
  whiteSpace: 'pre',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'break-spaces',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    maxWidth: 215,
  },
}))

export const StyledDetail = styled(Row)(({ theme }) => ({
  gap: theme.spacing(1),
  color: theme.palette.text?.secondary,
  flexWrap: 'wrap',
}))
