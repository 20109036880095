import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { tooltipClasses } from '@mui/material'
import styled from '@mui/system/styled'
import FilterListOutlined from '@mui/icons-material/FilterListOutlined'

export const BoxHeader = styled(Box)(({ theme, tapMenu }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  marginLeft: theme.spacing(-2),
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: tapMenu == 'MONTH' ? 'column' : 'row',
    alignItems: tapMenu == 'MONTH' ? 'flex-start' : 'center',
    marginLeft: tapMenu == 'MONTH' ? theme.spacing(-1) : theme.spacing(-2),
    gap: tapMenu == 'MONTH' ? theme.spacing(1) : theme.spacing(0),
  },
}))

export const BoxIcon = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

export const BoxGroupHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const ButtonCalendar = styled(Button)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0),
  border: '1px solid #00008F',
  minWidth: 36,
  height: 26,
}))

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    // leaveDelay={1000}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 150,
    wordBreak: 'break-word',
    lineHeight: '14px',
    backgroundColor: '#3032C1',
    '& .MuiTooltip-arrow': {
      color: '#3032C1',
    },
  },
}))

export const FilterListButton = styled((props) => (
  <Button
    data-testid="btn-download"
    variant="text"
    startIcon={<FilterListOutlined />}
    {...props}
  />
))(({ theme }) => ({
  height: 40,
  padding: theme.spacing(1),
}))
