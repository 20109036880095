export const arrayAccessTo = [
  {
    label: 'ยินยอม',
    name: 'acceptChecked',
  },
  {
    label: 'รอการยินยอม',
    name: 'WaintForAcceptChecked',
  },
]

export const arrayRepresentation = [
  {
    label: 'เป็นตัวแทน',
    name: 'isRepresentChecked',
  },
  {
    label: 'ยังไม่เป็นตัวแทน',
    name: 'notRepresentChecked',
  },
]

export const arrayApplication = [
  {
    label: 'สมัครแล้ว',
    name: 'appliedChecked',
  },
  {
    label: 'ยังไม่สมัคร',
    name: 'notApplyChecked',
  },
]

export const arrayCheckbox = [
  {
    label: 'ชื่อ',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'การให้เข้าถึงข้อมูล',
    nameCheckBox: 'accessToChecked',
    nameInput: 'accessTo',
    type: 'accessTo',
  },
  {
    label: 'การสมัครเรียน',
    nameCheckBox: 'applicationChecked',
    nameInput: 'application',
    type: 'application',
  },
  {
    label: 'การเป็นตัวแทน',
    nameCheckBox: 'representChecked',
    nameInput: 'represent',
    type: 'represent',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'status',
  },
]

export const arrayStatus = [
  {
    label: 'Inactive',
    name: 'inactiveChecked',
  },
  {
    label: 'Active',
    name: 'activeChecked',
  },
  {
    label: 'Deleted',
    name: 'deletedChecked',
  },
  {
    label: 'Terminated',
    name: 'terminatedChecked',
  },
  {
    label: 'โอนย้าย',
    name: 'stealChecked',
  },
]

export const defaultFilter = {
  nameChecked: false,
  accessToChecked: false,
  accessTo: [],
  representChecked: false,
  represent: [],
  applicationChecked: false,
  application: [],
  statusChecked: false,
  status: [],
}
