import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Template from 'src/components/Template'

import { fetchAnnounceDetail } from './events'
import Header from '../Header'
import {
  StyledCard,
  StyledLabelIcon,
  StyledRowFooter,
  StyledRow,
  StyledLits,
  StyledFooter,
  StyledContainer,
  StyledRowTable,
} from './Styled'
import { isMediaWidthMoreThan, convertDateToThaiString } from 'src/utils/lib'
import _ from 'lodash'
import { Column, Row } from 'src/components/Layout/Styled'
import Typography from '@mui/material/Typography'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'

import ContainedButton from './ContainedButton'
import Divider from '@mui/material/Divider'
import ContentNoClass from 'src/components/ContentNoClass'
import Box from '@mui/material/Box'
import { formatAnnounceDate } from '../event'

const AnnounceExaminerDetail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { detail } = useSelector(
    (state) => state.announceExaminerDetail,
    shallowEqual
  )

  useEffect(() => {
    dispatch(fetchAnnounceDetail(id))
  }, [])

  const isSm = isMediaWidthMoreThan('sm')

  const dateRange = `เวลา : ${_.get(detail, 'startTime', '')} - ${_.get(
    detail,
    'startEnd',
    ''
  )} น.`

  const fontWeight = isSm ? 'h4' : 'h6'
  const fontSize = isSm ? '38px' : '28px'
  const iconSize = isSm ? '24px' : '20px'

  const students = _.get(detail, 'students', [])
  const name = `ประกาศรายชื่อผู้มีสิทธิ์สอบวันที่ ${convertDateToThaiString(
    _.get(detail, 'examDate', '')
  )}`

  return (
    <>
      <Header />
      <Template noMinHeight maxWidth={'1280px'}>
        <StyledContainer>
          <StyledCard>
            <Row id="button">
              <ContainedButton />
              <Column sx={{ width: 'auto', gap: '16px' }}>
                <Typography
                  variant={fontWeight}
                  sx={{
                    fontSize: fontSize,
                  }}
                >
                  {name}
                </Typography>
              </Column>
            </Row>
          </StyledCard>
          <Divider />
        </StyledContainer>
        <StyledFooter>
          <Box
            sx={{
              paddingLeft: '16px',
              paddingRight: '16px',
              gap: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <StyledRowFooter>
              <StyledLabelIcon>
                <CampaignOutlinedIcon
                  color="text.secondary"
                  fontSize={iconSize}
                />
                <Typography
                  maxWidth={274}
                  variant="body1"
                  sx={{
                    fontSize: '20px',
                    color: '#333333',
                  }}
                >
                  {formatAnnounceDate(detail)}
                </Typography>
              </StyledLabelIcon>
              <StyledLabelIcon>
                <TimerOutlinedIcon color="text.secondary" fontSize={iconSize} />
                <Typography
                  maxWidth={274}
                  sx={{
                    fontSize: '20px',
                    color: '#333333',
                  }}
                >
                  {dateRange}
                </Typography>
              </StyledLabelIcon>
              <StyledLabelIcon>
                <BusinessOutlinedIcon
                  color="text.secondary"
                  fontSize={iconSize}
                />
                <Typography
                  maxWidth={274}
                  sx={{
                    fontSize: '20px',
                    color: '#333333',
                  }}
                >
                  {`สนามสอบ : ${_.get(detail, 'examinationField', '-')}`}
                </Typography>
              </StyledLabelIcon>
              {!_.isEmpty(_.get(detail, 'examinationRegion')) &&
                _.get(detail, 'examinationRegion') !== '-' && (
                  <StyledLabelIcon>
                    <MapOutlinedIcon
                      color="text.secondary"
                      fontSize={iconSize}
                    />
                    <Typography
                      maxWidth={274}
                      sx={{
                        fontSize: '20px',
                        color: '#333333',
                      }}
                    >
                      {`ภูมิภาค : ${_.get(detail, 'examinationRegion', '-')}`}
                    </Typography>
                  </StyledLabelIcon>
                )}
              <StyledLabelIcon>
                <LocationOnOutlinedIcon
                  color="text.secondary"
                  fontSize={iconSize}
                />
                <Typography
                  maxWidth={274}
                  sx={{
                    fontSize: '20px',
                    color: '#333333',
                  }}
                >
                  {`สถานที่ : ${_.get(detail, 'examinationLocation', '-')}`}
                </Typography>
              </StyledLabelIcon>
            </StyledRowFooter>
            <StyledRowFooter sx={{ flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', gap: 5 }}>
                <Typography sx={{ minWidth: '93px' }}>สถานที่สอบ</Typography>
                <Typography variant={'body1b'}>
                  {_.get(detail, 'scheduleName', '-')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 5 }}>
                <Typography sx={{ minWidth: '93px' }}>รายละเอียด</Typography>
                <Typography variant={'body1b'}>
                  {_.get(detail, 'annotation', '-')}
                </Typography>
              </Box>
            </StyledRowFooter>
          </Box>
          <StyledRowTable>
            <Typography
              variant={'h6'}
              sx={{
                fontSize: '24px',
                color: '#333333',
              }}
            >
              {'รายชื่อผู้มีสิทธิ์สอบ'}
            </Typography>
            <StyledRow>
              <StyledLabelIcon>
                <Typography
                  variant={'body1b'}
                  sx={{
                    fontSize: '20px',
                    color: '#333333',
                  }}
                >
                  {'ลำดับ'}
                </Typography>
              </StyledLabelIcon>
              <StyledLabelIcon>
                <Typography
                  variant={'body1b'}
                  sx={{
                    fontSize: '20px',
                    color: '#333333',
                  }}
                >
                  {'ชื่อผู้มีสิทธิ์สอบ'}
                </Typography>
              </StyledLabelIcon>
            </StyledRow>
            <Column>
              {students.length > 0 ? (
                students.map((item, index) => (
                  <>
                    <StyledLits index={index + 1}>
                      <StyledLabelIcon>
                        <Typography
                          variant={'body1'}
                          width={'33px'}
                          sx={{
                            fontSize: '20px',
                            color: '#333333',
                          }}
                        >
                          {_.get(item, 'id')}
                        </Typography>
                      </StyledLabelIcon>
                      <StyledLabelIcon>
                        <Typography
                          variant={'body1'}
                          sx={{
                            fontSize: '20px',
                            color: '#333333',
                          }}
                        >
                          {_.get(item, 'fullName', '-')}
                        </Typography>
                      </StyledLabelIcon>
                    </StyledLits>
                  </>
                ))
              ) : (
                <ContentNoClass />
              )}
            </Column>
            <Typography
              variant={'body1'}
              marginBottom={'64px'}
              marginTop={'16px'}
            >
              {
                'หากท่านมีข้อสงสัย/คำถามเพิ่มเติม สามารถติดต่อแอดมินได้ที่ Line: @axa-academy'
              }
            </Typography>
          </StyledRowTable>
        </StyledFooter>
      </Template>
    </>
  )
}

export default AnnounceExaminerDetail
