import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

import Dot from '../Dot'
import Truncate from './Truncate'
import Action from './Action'

import {
  setDefaultSort,
  setOrder,
  setChangeRowsPerPage,
  setChangePage,
  setSelected,
} from '../../redux/slices/table'

import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import ActionResendRequest from './Action/ActionResendRequest'
import ActionImage from './Action/ActionImage'
import ActionConsent from './Action/ActionManaegProspect/ActionConsent'
import ActionAgent from './Action/ActionManaegProspect/ActionIsAgent'
import ActionRegister from './Action/ActionManaegProspect/ActionIsRegister'

import ActionProspectForm from './Action/ActionManaegProspect/ActionForm'
import ActionPhoneNumber from './Action/ActionPhoneNumber'
import ActionCommand from './Action/ActionManaegProspect/ActionCommand'
import ActionStatusProspect from './Action/ActionManaegProspect/ActionStatusProspect'
import ActionTestButton from './Action/ActionTestButton'
import ActionStatusClass from './Action/ActionManaegProspect/ActionStatusClass'
import ActionViewPointHistory from './Action/ActionViewPointHistory'
import { StyledTableCell } from './Styled'

export const checkConditionView = (headCells, row, rowIndex, cellIndex) => {
  const columnKey = headCells[cellIndex].id
  const widthTable = _.get(headCells, `[${cellIndex}].width`, '')

  const minWidth = widthTable ? widthTable : formatMinWidth(columnKey)
  const sx = GetSxStyle(row, minWidth)

  if (columnKey === 'status') {
    return (
      <StyledTableCell key={columnKey} sx={{ width: 10 }} align="center">
        <Dot status={row[`${columnKey}`]} />
      </StyledTableCell>
    )
  } else if (columnKey === 'action') {
    return (
      <StyledTableCell key={columnKey}>
        <Action
          row={row}
          rowIndex={rowIndex}
          minWidth={headCells[cellIndex].minWidth}
          condition={headCells[cellIndex]}
        />
      </StyledTableCell>
    )
  } else if (columnKey === 'resendButton') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionResendRequest
          row={row}
          rowIndex={rowIndex}
          condition={headCells[cellIndex]}
        />
      </StyledTableCell>
    )
  } else if (columnKey === 'actionImage') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionImage row={row} />
      </StyledTableCell>
    )
  } else if (columnKey === 'actionCommandProspect') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionCommand row={row} />
      </StyledTableCell>
    )
  } else if (columnKey === 'consent') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionConsent row={row} sx={sx} />
      </StyledTableCell>
    )
  } else if (columnKey === 'agent') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionAgent row={row} sx={sx} />
      </StyledTableCell>
    )
  } else if (columnKey === 'statusProspect') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionStatusProspect row={row} sx={sx} />
      </StyledTableCell>
    )
  } else if (columnKey === 'register') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionRegister row={row} sx={sx} />
      </StyledTableCell>
    )
  } else if (columnKey === 'fullNameTH') {
    return (
      <StyledTableCell
        key={columnKey}
        sx={sx}
      >{`${row.firstNameTH} ${row.lastNameTH}`}</StyledTableCell>
    )
  } else if (columnKey === 'id') {
    return (
      <StyledTableCell key={columnKey} sx={{ minWidth: 10 }}>
        {row[`${columnKey}`]}
      </StyledTableCell>
    )
  } else if (columnKey === 'mobileNo') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionPhoneNumber row={row} sx={sx} />
      </StyledTableCell>
    )
  } else if (columnKey === 'RESULT') {
    return <MapResult columnKey={columnKey} sx={sx} row={row} />
  } else if (columnKey === 'UnitCode') {
    const value =
      _.get(row, `${columnKey}`, '-') === null
        ? '-'
        : _.get(row, `${columnKey}`, '-')
    return (
      <StyledTableCell key={columnKey} sx={sx}>
        <Truncate>{'' + value}</Truncate>
      </StyledTableCell>
    )
  } else if (columnKey === 'actionProspect') {
    return (
      <StyledTableCell key={columnKey} sx={sx}>
        <ActionProspectForm rowIndex={rowIndex} row={row} />
      </StyledTableCell>
    )
  } else if (columnKey === 'isShowButton') {
    return (
      <StyledTableCell>
        <ActionTestButton rowIndex={rowIndex} row={row} />{' '}
      </StyledTableCell>
    )
  } else if (columnKey === 'statusClass') {
    return (
      <StyledTableCell key={columnKey}>
        <ActionStatusClass row={row} sx={sx} />
      </StyledTableCell>
    )
  } else if (columnKey === 'historyPoint') {
    const color = row[columnKey].includes('+') ? 'success.dark' : 'error.dark'
    return (
      <StyledTableCell key={columnKey}>
        <Typography color={color}>{row[columnKey]}</Typography>
      </StyledTableCell>
    )
  } else if (columnKey === 'ActionViewPointHistory') {
    return (
      <StyledTableCell key={columnKey} sx={{ maxWidth: 50 }}>
        <ActionViewPointHistory row={row} />
      </StyledTableCell>
    )
  }
  return (
    <StyledTableCell key={columnKey} sx={sx}>
      <Truncate>{'' + _.get(row, `${columnKey}`, '-')}</Truncate>
    </StyledTableCell>
  )
}

export const GetSxStyle = (row, minWidth) => {
  const status = _.get(row, 'status', '')
  const isLearner = _.get(row, 'isLearner', false)
  const learnerCon = isLearner && status == 'CANCEL'
  if (
    status === 'DELETED' ||
    status === 'CANCELED' ||
    status === 'TERMINATED' ||
    learnerCon ||
    status === 'STEAL'
  ) {
    return { minWidth, color: 'text.gray' }
  } else {
    return { minWidth }
  }
}

export const MapResult = ({ columnKey, sx, row }) => {
  const label = _.get(row, `${columnKey}`, '-')
  if (label === 'ผ่าน') {
    return (
      <StyledTableCell key={columnKey} sx={sx}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Check color="success" sx={{ marginRight: 1 }} fontSize="small" />
          <Typography variant="body2">
            {'' + _.get(row, `${columnKey}`, '-')}
          </Typography>
        </Box>
      </StyledTableCell>
    )
  } else {
    return (
      <StyledTableCell key={columnKey} sx={sx}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Close color="error" sx={{ marginRight: 1 }} fontSize="small" />
          <Typography variant="body2">
            {'' + _.get(row, `${columnKey}`, '-')}
          </Typography>
        </Box>
      </StyledTableCell>
    )
  }
}

export const mapColorLabel = (label) => {
  if (label === 'ไม่มาเรียน') {
    return 'error.main'
  } else if (label === 'มาเรียน') {
    return 'success.main'
  } else {
    return 'warning.main'
  }
}

export const handleClick = (selected, name) => (dispatch) => {
  const selectedIndex = selected.indexOf(name)
  let newSelected = []
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name)
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1))
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1))
  } else {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    )
  }
  dispatch(setSelected(newSelected))
}

export const isSelected = (selected, name) => selected.indexOf(name) !== -1

export const handleLabelDisplay = (table) => {
  const realPage = table.tablePage <= 0 ? 1 : table.tablePage + 1
  let minRowsShowed =
    table.page < 0 ? 1 : table.rowsPerPage.value * table.tablePage + 1
  let maxRowsShowed = 0

  if (table.rowsPerPage.value * realPage > table.allCount) {
    maxRowsShowed = table.allCount
  } else {
    if (realPage === 1) {
      maxRowsShowed = table.rows.length + realPage - 1
    } else {
      maxRowsShowed = table.rows.length * realPage
    }
  }

  if (maxRowsShowed == 0) {
    minRowsShowed = 0
  } else if (maxRowsShowed < 0) {
    maxRowsShowed = table.allCount
  }

  if (minRowsShowed > maxRowsShowed && realPage > 1) minRowsShowed = 1 // fix bug - rows label

  return (
    <Typography variant="caption" color="text.secondary">
      {minRowsShowed}-{maxRowsShowed} of {table.allCount}
    </Typography>
  )
}

export const handleChangePage = (newPage) => (dispatch) => {
  const tablePage = newPage < 0 ? 1 : +newPage
  const page = newPage + 1

  dispatch(setChangePage({ page, tablePage }))
}

export const handleChangeRowsPerPage = (event) => (dispatch) => {
  const value = parseInt(event.target.value, 10)
  const rowsPerPageChange = value > 0 ? value : -1
  const rowsPerPage = {
    label: '' + rowsPerPageChange,
    value: rowsPerPageChange,
  }
  const limit = value

  dispatch(setChangeRowsPerPage({ rowsPerPage, limit }))
}

export const handleSelectAllClick = (event, rows) => (dispatch) => {
  if (event?.target?.checked) {
    const newSelecteds = rows.map((n) => n.uuid)
    dispatch(setSelected(newSelecteds))
    return
  }
  dispatch(setSelected([]))
}

export const handleRequestSort = (sortBy, table, headCell) => (dispatch) => {
  if (headCell.hideSortIcon) return

  const defaultSort = _.get(table, 'defaultSort', undefined)
  const defaultSortId = _.get(table, 'defaultSortId', 'updatedAt')
  const sort = _.get(table, 'sort', 'updatedAt')
  const order = _.get(table, 'order', 'desc')

  if (defaultSort) {
    let isAsc = sort === sortBy && order === 'asc'
    let stateSort = defaultSort.state + 1
    let activeSort = true

    if (defaultSort.state >= 2) {
      stateSort = 0
      activeSort = false
    }
    let valueSort = !defaultSort.id ? sortBy : defaultSort.id
    if (!!defaultSort.id && defaultSort.id !== sortBy) {
      stateSort = 1
      valueSort = sortBy
      activeSort = true
    }

    let orderBy = isAsc ? 'desc' : 'asc'
    if (!activeSort) {
      sortBy = defaultSortId
      orderBy = 'desc'
    }

    dispatch(
      setDefaultSort({
        state: stateSort,
        id: valueSort,
        active: activeSort,
      })
    )

    dispatch(setOrder({ order: orderBy, sort: sortBy }))
  }
}

export const formatMinWidth = (field) => {
  let width = 0
  switch (field) {
    case 'name':
      width = 380
      break
    case 'codeId':
      width = 110
      break
    case 'mobileNo':
      width = 100
      break
    case 'consent':
      width = 100
      break
    default:
      width = 80
      break
  }
  return width
}
