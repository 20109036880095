import React from 'react'
import _ from 'lodash'
import { COURSE_TYPE } from 'src/constants/eLearning'
import { openDialog } from 'src/redux/slices/alertDialog'
import {
  setChangeCamera,
  openDialog as openDetailDialog,
} from 'src/redux/slices/eLearning/detail'
import { handleRelationObj } from 'src/redux/slices/eLearning/learning/events'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { postEnrollCourse } from 'src/services/eLearning/enroll'
import {
  postCourseLogLearner,
  postCourseLogLearnerPerLesson,
} from 'src/services/eLearning/learning'
import { getDetailState } from '../events'
import IdNoDialog from './IdNoDialog'

export const handleEnrollment = (uuid) => async (dispatch) => {
  const data = getDetailState().data
  const courseType = _.get(data, 'courseType', '')

  if (courseType === COURSE_TYPE.OIC) {
    const user = JSON.parse(localStorage.getItem('user'))
    const path = `/e-learning/course/enroll/${uuid}`
    if (_.isEmpty(user.idCardNo)) {
      dispatch(
        openDialog({
          content: <IdNoDialog path={path} />,
          type: 'content',
        })
      )
    } else {
      window.location.href = path
    }
  } else {
    dispatch(
      openDialog({
        title: 'ยืนยันการลงทะเบียน',
        message: ['คุณต้องการลงทะเบียนหลักสูตรนี้?'],
        buttonRight: {
          label: 'ยืนยัน',
          handleClick: () => dispatch(handleSubmitEnrollGeneral()),
        },
        buttonLeft: { label: 'ยกเลิก' },
      })
    )
  }
}

export const handleSubmitEnrollGeneral = () => async (dispatch) => {
  const eLearningCourse = handleRelationObj(getDetailState().data)
  dispatch(startLoading())
  const response = await dispatch(postEnrollCourse({ eLearningCourse }))

  if (_.isEmpty(response.error)) {
    const eLearningCourseBatchLearner = handleRelationObj(response.payload.data)
    // create log after enroll by default value
    const resLog = await dispatch(
      postCourseLogLearnerPerLesson({ eLearningCourseBatchLearner })
    )
    dispatch(stopLoading())

    if (_.isEmpty(resLog.error)) dispatch(handleSuccess())
  } else dispatch(stopLoading())
}

export const handleSuccess = () => (dispatch) => {
  dispatch(
    openDialog({
      iconType: 'success',
      title: 'ลงทะเบียนเข้าอบรมสำเร็จ',
      buttonRight: {
        handleClick: () => window.location.reload(),
      },
    })
  )
}

export const handleGetInClass = (history) => async (dispatch) => {
  const data = getDetailState().data
  const courseType = _.get(data, 'courseType', '')

  if (courseType === COURSE_TYPE.OIC) {
    dispatch(setChangeCamera({ key: 'isOpen', value: false }))
    dispatch(openDetailDialog())
  } else {
    const eLearningCourse = handleRelationObj(data)
    const payload = { eLearningCourse, eLearningCourseBatch: null }
    dispatch(startLoading())
    const response = await dispatch(postCourseLogLearner(payload))
    dispatch(stopLoading())

    if (_.isEmpty(response.error)) {
      const uuid = _.get(eLearningCourse, 'uuid', '')
      history.push(`/e-learning/course/learning/${uuid}`)
    }
  }
}
