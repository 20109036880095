import dayjs from 'dayjs'
import _ from 'lodash'
import { setTapMenu, setCurrentDate } from '../../../redux/slices/myProfile'

import { getDateInWeek } from './Header/events'
export const mapIdEvents = (events = []) => {
  if (!events.length) return {}
  let result = {}
  events.forEach((item) => {
    result = {
      ...result,
      [item.uuid]: false,
    }
  })
  return result
}

export const mapColor = (type, defaultValue) => {
  return type == defaultValue ? 'contained' : 'outlined'
}

export const handleChangeMenu = (value) => (dispatch) => {
  dispatch(setTapMenu(value))
  const currentDate = dayjs(new Date()).format(window.__env__.REACT_APP_DATE_DB)

  let startDate = currentDate
  let toDate = currentDate
  if (value == 'WEEKLY') {
    startDate = getDateInWeek(currentDate)[0]
    toDate = getDateInWeek(currentDate)[6]
  } else if (value === 'MONTH') {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth()
    const firstDay = new Date(y, m, 1)
    const lastDay = new Date(y, m + 1, 0)
    startDate = dayjs(new Date(firstDay)).format(
      window.__env__.REACT_APP_DATE_DB
    )
    toDate = dayjs(new Date(lastDay)).format(window.__env__.REACT_APP_DATE_DB)
  }
  dispatch(setCurrentDate({ currentDate, startDate, toDate }))
}
export const mapEventTypeCalendar = (list = []) => {
  if (!list.length) return []
  return list.map((item, index) => {
    const workType = _.get(item, 'workType.workType', 'Training')

    return {
      title: _.get(item, 'courseName', '-') || '-',
      start: _.get(item, 'startDate', ''),
      end: _.get(item, 'finishDate', ''),
      description: _.get(item, 'courseName', '-') || '-',
      dateTime: _.get(item, 'startDateTh', '-'),
      allDay: workType === 'Holiday' ? true : false,
      status: _.get(item, 'status', 'CANCELED'),
      workType,
      userImg: _.isNull(item.userImg)
        ? '/logo/staff_profile_small.png'
        : item.userImg,
      uuid: item.uuid,
      trainingPlanUuid: item.trainingPlanUuid,
      id: index + 1,
    }
  })
}
