import _ from 'lodash'
import { store } from 'src/App'
import { ERROR_TYPE } from 'src/components/FaceLiveness/model'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { closeDialog } from 'src/redux/slices/alertDialog'
import { setChangeBody, setDisableSubmit } from 'src/redux/slices/eLearning/enroll'
import {
  handleEnrollRekog,
  handleVerifyFail,
  handleVerifySuccess,
} from '../events'

export const handleChangeImage = () => {
  store.dispatch(closeDialog())
  store.dispatch(setChangeBody({ key: 'faceImage', value: null }))
  store.dispatch(setDisableSubmit(false))
}

export const handleFinalSubmit = async (data) => {
  store.dispatch(startLoading())
  const resEnroll = await handleEnrollRekog(data)
  store.dispatch(stopLoading())

  if (_.isEmpty(resEnroll.error)) {
    handleVerifySuccess(data)
  } else {
    store.dispatch(handleVerifyFail(ERROR_TYPE.VERIFY, data))
  }
}
