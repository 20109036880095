import React from 'react'
import _ from 'lodash'
import { Row } from 'src/components/Layout/Styled'
import { BADGE_MODEL } from '../model'
import Chip from './Chip'

const ChipContainer = ({ course }) => {
  const hasCertificate = _.get(course, 'hasCertificate', false)
  if (!hasCertificate) return <></>
  return (
    <Row id="chip" sx={{ gap: 1.5, flexWrap: 'wrap' }}>
      <Chip variant="outlined" color="primary" field="certificate">
        {BADGE_MODEL.certificate.th}
      </Chip>
    </Row>
  )
}

export default ChipContainer
