import React from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'

const getDefSizeSX = (size) => {
  const sizeSx = {
    boxShadow: 'none',
    borderRadius: 10,
    px: 4,
  }
  if (size === 'small') {
    sizeSx.py = '1px'
    sizeSx.px = 2
    sizeSx.pb = '0px'
  } else if (size === 'large') {
    sizeSx.py = '12px'
    sizeSx.pb = '12px'
    sizeSx.fontSize = '20px'
  }
  return sizeSx
}

const CustomButton = (props) => {
  return (
    <Button
      variant="contained"
      color="info"
      {...props}
      sx={{
        minWidth: '100px',
        alignItems: 'center',
        verticalAlign: 'middle',
        ':hover': {
          boxShadow: 'none',
        },
        ':active': {
          boxShadow: 'none',
        },
        ...getDefSizeSX(props.size),
        ..._.get(props, 'sx', {}),
      }}
    >
      {props.children}
    </Button>
  )
}

export default CustomButton
