import React from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import { StyledAlert } from './Styled'

const AlertInfo = ({ children, disabled }) => {
  return (
    <StyledAlert disabled={disabled} severity="info">
      <AlertTitle>ข้อควรปฏิบัติ</AlertTitle>
      <ul style={{ margin: 0, padding: '0 0 0 28px' }}>{children}</ul>
    </StyledAlert>
  )
}

export default AlertInfo
