import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import CameraAlt from '@mui/icons-material/CameraAlt'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { ENROLL_INPUT_TYPE } from 'src/constants/eLearning'
import { Column, Row } from 'src/components/Layout/Styled'
import ExampleImage from '../components/ExampleImage'
import StepNumber from '../components/StepNumber'
import AlertInfo from '../components/AlertInfo'
import { StyledDashedBox } from '../components/ExampleImage/Styled'
import { SectionLabelIcon } from '../..'
import {
  StyledButton,
  StyledContainer,
  StyledPreview,
  StyledRowWrapper,
  StyledWrapper,
} from '../Styled'
import UploadFileInput from './UploadFileInput'
import { handleChangeImage, handleOpenCameraDialog } from './events'

const IdCardSection = () => {
  const idCardImage = useSelector(
    (state) => state.eLearningEnroll.body.idCardImage,
    shallowEqual
  )
  return (
    <Column sx={{ alignItems: 'start', gap: 2 }}>
      <StyledRowWrapper>
        <StepNumber value={1} />
        <SectionLabelIcon
          isWidthAuto
          label="อัปโหลดรูปบัตรประจำตัวประชาชน / ใบขับขี่"
          icon="ic_address_card"
        />
      </StyledRowWrapper>

      <StyledWrapper>
        <AlertInfo>
          <li>สามารถมองเห็นตัวอักษรและรายละเอียดได้อย่างชัดเจน</li>
          <li>ไม่มีแสงสะท้อนบนบัตร</li>
          <li>ไม่มีวัตถุอื่นรวมอยู่ในกรอบรูป</li>
          <li>บัตรยังไม่หมดอายุ</li>
        </AlertInfo>

        <StyledContainer>
          {idCardImage ? <PreviewAndChangeImage /> : <ExampleAndInsertImage />}
        </StyledContainer>
      </StyledWrapper>
    </Column>
  )
}

export default IdCardSection

export const ExampleAndInsertImage = () => {
  return (
    <>
      <ExampleImage src="example_id_card.svg" />

      <Typography color="text.gray">เลือกวิธีเพิ่มรูป</Typography>
      <Row sx={{ width: 'auto', gap: 2, alignItems: 'center' }}>
        <StyledButton
          variant="contained"
          startIcon={<CameraAlt />}
          onClick={handleOpenCameraDialog}
        >
          ถ่ายรูป
        </StyledButton>
        <Typography>หรือ</Typography>
        <UploadFileInput />
      </Row>
    </>
  )
}

export const PreviewAndChangeImage = () => {
  const { idCardImage, typeInput } = useSelector(
    (state) => ({
      idCardImage: state.eLearningEnroll.body.idCardImage,
      typeInput: state.eLearningEnroll.body.typeInput,
    }),
    shallowEqual
  )
  const imgSrc = `${window.__env__.REACT_APP_REKOGNITION_URL}${idCardImage}`
  const isLg = isMediaWidthMoreThan('lg')
  return (
    <>
      <StyledDashedBox>
        <StyledPreview src={imgSrc} />
        <Typography
          variant="body2"
          color="success.main"
          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
        >
          <img src="/icon/ic_check_success.svg" />
          {typeInput === ENROLL_INPUT_TYPE.UPLOAD
            ? 'อัปโหลดบัตรแล้ว'
            : 'ถ่ายรูปบัตรแล้ว'}
        </Typography>
      </StyledDashedBox>

      <StyledButton
        variant="outlined"
        sx={{ mt: isLg ? 5 : 0 }}
        onClick={handleChangeImage}
      >
        เปลี่ยนรูป
      </StyledButton>
    </>
  )
}
