import { combineReducers } from '@reduxjs/toolkit'

import user from './utils/userSlice'
import loading from './loading'
import eEvaluation from './eEvaluation'
import eTesting from './eTesting'
import document from './document'
import checkIn from './checkIn'
import home from './home'
import login from './login'
import table from './table'
import table2 from './table2'

import courseList from './course/list'
import courseDetail from './course/detail'

import classDetail from './class/detail'
import classList from './class/list'
import registration from './class/registration'

import manageProspect from './manageProspect'
import alertDialog from './alertDialog'
import manageProspectUpload from './manageProspectUpload'
import manageProspectEnrollment from './manageProspectEnrollment'
import location from './location'
import basicTable from './basicTable'
import myProfile from './myProfile'
import profile from './profile'

// e-learning
import eLearningList from './eLearning/list'
import eLearningDetail from './eLearning/detail'
import eLearningEnroll from './eLearning/enroll'
import eLearningLearning from './eLearning/learning'
import eLearningLearningPathList from './eLearning/learningPath/list'
import eLearningLearningPath from './eLearning/learningPath/detail'
import eLearningCollection from './eLearning/collection/'

import eExamination from './eExamination'
import classLearnerList from './learner/list'
import courseBatchDetail from './learner/detail'
import eLearningCourseList from './eLearning'

import announceExaminerList from './eExamination/announceExaminer/list'
import announceExaminerDetail from './eExamination/announceExaminer/detail'

import catalog from './catalog'

import eConfiguration from './eConfig'

import eContent from './eContent'
import eLearningRegistration from './class/eLearningRegistration'

const rootReducer = combineReducers({
  user,
  loading,
  eEvaluation,
  eTesting,
  document,
  checkIn,
  home,
  table,
  table2,
  courseList,
  courseDetail,
  classDetail,
  classList,
  manageProspect,
  alertDialog,
  manageProspectUpload,
  manageProspectEnrollment,
  location,
  basicTable,
  registration,
  myProfile,
  login,
  profile,
  eLearningList,
  eLearningCourseList,
  eLearningDetail,
  eLearningEnroll,
  eLearningLearning,
  eLearningLearningPathList,
  eLearningLearningPath,
  eExamination,
  classLearnerList,
  courseBatchDetail,
  announceExaminerList,
  announceExaminerDetail,
  catalog,
  eConfiguration,
  eContent,
  eLearningCollection,
  eLearningRegistration,
})

export default rootReducer
