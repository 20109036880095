import styled from '@mui/system/styled'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import Search from '@mui/icons-material/Search'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledCatalog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1200,
  margin: '0 auto',
  marginTop: theme.spacing(16),
  marginBottom: theme.spacing(9.5),
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}))

export const StyledRow = styled(Row)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 1,
    alignItems: 'flex-start',
  },
}))

export const BoxSearch = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme?.shape?.borderRadius,
  border: `1px solid ${theme.palette.text?.silver}`,
  backgroundColor: theme?.palette?.text?.white,
  width: '280px',
  minWidth: '250px',
  '&:hover': {
    backgroundColor: theme?.palette?.text?.wildSand,
  },
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const SearchInput = styled(InputBase)(({ theme }) => ({
  maxHeight: '37.5px',
  width: 'calc(100% - 36px)',
  marginLeft: theme.spacing(1),
}))

export const StyledSearchIcon = styled(Search)(() => ({
  position: 'absolute',
  top: 'calc(50% - 10px)',
  width: 20,
  height: 20,
  cursor: 'pointer',
}))

export const ScoreBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
  padding: '8px 16px',
  borderRadius: 240,
  background: theme.palette.primary.main,
}))

export const FlipCard = styled(Grid)(() => ({
  width: '100%',
  height: 443,
}))

export const InnerCard = styled(Box)(({ $flip, $ispc }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.8s',
  transform: $flip ? 'rotateY(180deg)' : 'unset',
  ':hover': {
    transform: $ispc ? 'rotateY(180deg)' : $flip ? 'rotateY(180deg)' : 'unset',
  },
}))

export const FrontCard = styled(Column)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  border: '1px solid #00000012',
  boxShadow: '0px 12px 40px 0px #4976BA1A',
  backfaceVisibility: 'hidden',
  borderRadius: theme.spacing(1),
}))

export const StyledImage = styled('img')(() => ({
  width: '100%',
  height: 240,
  borderRadius: '8px 8px 0 0',
  objectFit: 'cover',
}))

export const Name = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

export const Decription = styled(Typography)(() => ({
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': '3',
  '-webkit-box-orient': 'vertical',
}))

export const ScoreChip = styled(Box)(({ theme, $canRedeem }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '3px 8px',
  borderRadius: theme.spacing(2),
  background: $canRedeem ? theme.palette.primary.main : 'unset',
  border: $canRedeem ? 'unset' : '1px solid #0000003B',
  color: $canRedeem
    ? theme.palette.primary.contrast
    : theme.palette.text.primary,
}))

export const RedeemChip = styled(Box)(({ theme, $canRedeem }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '3px 8px',
  borderRadius: theme.spacing(2),
  background: $canRedeem
    ? theme.palette.primary.main
    : theme.palette.grey.grey300,
  color: $canRedeem
    ? theme.palette.primary.contrast
    : theme.palette.text.primary,
}))

export const BackCard = styled(Column)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  border: '1px solid #00000012',
  boxShadow: '0px 12px 40px 0px #4976BA1A',
  backfaceVisibility: 'hidden',
  borderRadius: theme.spacing(1),
  transform: 'rotateY(180deg)',
}))
