import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { Column } from 'src/components/Layout/Styled'
import { StyledDialog } from 'src/components/AlertDialog/Styled'
import DialogCountdown from '../components/DialogCountdown'
import { handleClickButton, handleOpenDialog } from './events'
import { CLICK_ALERT_TIME } from './model'

const ClickDialog = () => {
  const { isDialogOpen } = useSelector(
    (state) => state.eLearningLearning.clickDialog
  )
  const dispatch = useDispatch()

  useEffect(() => {
    let timeout
    document.addEventListener('mousemove', () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        dispatch(handleOpenDialog())
      }, CLICK_ALERT_TIME * 60 * 1000)
    })
  }, [])

  return (
    <Box>
      <StyledDialog
        maxWidth="sm"
        fullWidth={true}
        customWidth={532}
        sx={{ zIndex: '1298 !important' }}
        open={isDialogOpen}
      >
        <Column sx={{ p: '18px', gap: '18px', alignItems: 'center' }}>
          <Column gap={1} alignItems="center">
            <ErrorOutline color="warning" sx={{ width: 54, height: 54 }} />
            <Column gap={0.5} alignItems="center">
              <Typography variant="h6">
                กรุณาคลิกปุ่มยืนยันภายใน <DialogCountdown /> นาที
              </Typography>
              <Typography variant="body2b" color="text.gray">
                ไม่อย่างนั้นคุณจะถูกตัดออกจากระบบ
              </Typography>
            </Column>
          </Column>
          <Button
            variant="contained"
            onClick={() => dispatch(handleClickButton())}
          >
            ยืนยัน
          </Button>
        </Column>
      </StyledDialog>
    </Box>
  )
}

export default ClickDialog
