import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { BoxSearch, SearchInput, StyledSearchIcon } from './Styled'
import { setReduxValue } from 'src/redux/slices/eExamination'
import { handleSearchExaminationUser } from './event'

const SearchBox = () => {
  const dispatch = useDispatch()
  const { examinerSearchText } = useSelector(
    (state) => ({ examinerSearchText: state.eExamination.examinerSearchText }),
    shallowEqual
  )
  return (
    <BoxSearch>
      <SearchInput
        placeholder="ค้นหา"
        onChange={(e) => {
          dispatch(
            setReduxValue({
              index: 'examinerSearchText',
              value: e.target.value,
            })
          )
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            dispatch(handleSearchExaminationUser())
          }
        }}
        value={examinerSearchText}
      />
      <StyledSearchIcon
        color="primary"
        onClick={() => dispatch(handleSearchExaminationUser())}
      />
    </BoxSearch>
  )
}

export default React.memo(SearchBox)
