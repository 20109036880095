import _ from 'lodash'
import { startLoading, stopLoading } from '../../../../../redux/slices/loading'
import { addListCells } from '../../model'
import { setInitialTable } from '../../../../../redux/slices/table'
import {
  setFetchProspect,
  setChecked,
  setProspectAddDrawerList,
} from '../../../../../redux/slices/manageProspectEnrollment'

import {
  postProspectCheckAddList,
  postProspectCheckProspect,
} from '../../../../../services/manageProspect'
import { USER_TYPE } from 'src/modules/EExamination/constant'

export const filterUuid = (list) => {
  if (!list.length) return []
  return list.map((item) => item.uuid)
}

export const fetchData = (prospects) => async (dispatch) => {
  const body = {
    list: filterUuid(prospects),
  }

  dispatch(startLoading())

  const result = await dispatch(postProspectCheckAddList(body))
  const resData = mapData(_.get(result, 'payload', []))
  dispatch(
    setInitialTable({
      isShowPagination: false,
      rows: resData,
      allCount: resData.length,
      headCells: addListCells,
      handleSearch: (text) => dispatch(handleQuickSearch(text, resData)),
    })
  )
  dispatch(setProspectAddDrawerList(resData))
  dispatch(stopLoading())
}

export const mapData = (list) => {
  if (!list.length) return []

  const result = list.map((item) => {
    const fullName = item.firstNameTH + ' ' + item.lastNameTH
    const statusText = item.isRegister ? 'สมัครแล้ว' : '-'
    return {
      ...item,
      fullName,
      statusText,
    }
  })

  return result.sort((a, b) => -b.fullName.localeCompare(a.fullName))
}

export const handleSubmit =
  ({
    selected,
    prospectAddDrawerList,
    prospects,
    course,
    prospectDetail,
    setProspectDetail,
  }) =>
  async (dispatch) => {
    dispatch(startLoading())
    const listUuid = prospects.map((item) => item.uuid)
    const result = await dispatch(
      postProspectCheckProspect({
        trainingPlanUuid: _.get(course, 'trainingPlan.value', ''),
        prospects: _.uniq([...listUuid, ...selected]),
      })
    )
    localStorage.setItem(
      'prospect',
      JSON.stringify(_.uniq([...listUuid, ...selected]))
    )

    dispatch(setFetchProspect(_.get(result, 'payload', [])))
    dispatch(setChecked(true))

    const selectedProspectDetail = []
    _.map(prospectAddDrawerList, (ele) => {
      if (_.includes(selected, ele.uuid)) {
        const userImageUrl = ele?.userImage
          ? `${window.__env__.REACT_APP_FILE_URL}${prospectDetail?.image}`
          : '/logo/staff_profile_small.png'
        const type = ele?.type || 'TEMP'
        selectedProspectDetail.push({
          ...ele,
          uuid: ele?.uuid || '',
          type: type,
          unitName: ele?.unitName || '',
          unitNumber: ele?.unitNumber || '',
          image: userImageUrl,
          firstNameTH: ele?.firstNameTH || '',
          lastNameTH: ele?.lastNameTH || '',
          prefix: ele?.prefixTH || '',
          mobileNo: ele?.mobileNo?.replace(/-/g, '') || '',
          email: ele?.email || '',
          idCardNo: ele?.idCardNo || '',
          isEdit: true,
          birthDate: ele?.dateOfBirth || '',
          institution: ele?.institution || '',
          between: ele?.between || '',
          qualification: ele?.qualification || '',
          branch: ele?.branch || '',
          gpa: ele?.GPA || ele?.gpa || '',
          editable: {
            isEditName: true,
            isEditSurname: true,
            isEditIdCardNo: type !== USER_TYPE.TEMP,
            isEditMobile: true,
            isEditBirthDate: type !== USER_TYPE.TEMP,
          },
        })
      }
    })
    setProspectDetail([...prospectDetail, ...selectedProspectDetail])
    dispatch(stopLoading())
  }

export const handleQuickSearch = (text, list) => (dispatch) => {
  dispatch(
    setInitialTable({
      isShowPagination: false,
      rows: filterData(text, list),
      allCount: filterData(text, list).length,
      headCells: addListCells,
      handleSearch: (searchText) =>
        dispatch(handleQuickSearch(searchText, list)),
    })
  )
}

export const filterData = (text, list) => {
  if (!list.length) return []
  const result = list.filter((item) => _.includes(item.fullName, text))
  return result.sort((a, b) => -b.fullName.localeCompare(a.fullName))
}
