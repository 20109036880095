import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container } from '@mui/material'
import Template from 'src/components/Template'
import RecommendArticle from './RecommendArticle'
import ListHeader from './ListHeader'
import { isMediaWidth, isMediaWidthMoreThan } from 'src/utils/lib'
import { fetchLandingPageContent } from './events'

const EContent = () => {
  const dispatch = useDispatch()
  const isMobile = isMediaWidth('xs')
  const isLaptop = isMediaWidthMoreThan('lg')

  useEffect(() => {
    dispatch(fetchLandingPageContent())
  }, [])

  return (
    <>
      <Container maxWidth="lg">
        <ListHeader isMobile={isMobile} />
        <Template maxWidth="100%">
          <RecommendArticle
            type="E_CONTENT"
            isMobile={isMobile}
            isLaptop={isLaptop}
          />
          <RecommendArticle type="KB" isMobile={isMobile} isLaptop={isLaptop} />
        </Template>
      </Container>
    </>
  )
}

export default EContent
