import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { LabelHeaderCard } from '../'
import { BoxCard, BoxCardList, BoxCardItem, BoxScore } from './Styled'

export const Score = ({ score = 0, isBg, label }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            flex: '0 0 42px',
            background: isBg ? 'rgba(0, 0, 0, 0.38)' : '#00008F',
            borderRadius: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: isBg ? 34 : 42,
          }}
        >
          <Typography variant="h4" color="text.white" sx={{ px: 1 }}>
            {score}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" color="primary.main">
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

const Index = () => {
  const history = useHistory()
  const details = useSelector((state) => state.myProfile.details, shallowEqual)
  const testAndEva = _.get(details, 'testAndEva', {})
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <BoxCard>
        <LabelHeaderCard
          label="รายการที่ต้องทำ"
          isIcon={true}
          handleClick={() => history.push(`/my-profile/history`)}
        />

        <BoxCardList>
          <BoxCardItem>
            <LabelHeaderCard
              label="แบบทดสอบในคลาสอบรม"
              handleClick={() => history.push(`/my-profile/online-test`)}
            />
            <BoxScore>
              <Score
                label="กำลังเปิดให้ทำ"
                score={_.get(testAndEva, 'test.onActive', 0)}
              />
              <Score
                isBg
                label="ไม่ได้ทำ"
                score={_.get(testAndEva, 'test.notToDo', 0)}
              />
            </BoxScore>
          </BoxCardItem>
          <BoxCardItem>
            <LabelHeaderCard
              label="แบบทดสอบทั่วไป"
              handleClick={() => history.push(`/my-profile/standalone`)}
            />
            <BoxScore>
              <Score
                label="ทำแล้ว"
                score={_.get(testAndEva, 'standalone.toDo', 0)}
              />
            </BoxScore>
          </BoxCardItem>
          <BoxCardItem>
            <LabelHeaderCard
              label="แบบฝึกหัด"
              handleClick={() => history.push(`/my-profile/assessment`)}
            />
            <BoxScore>
              <Score
                label="ทำแล้ว"
                score={_.get(testAndEva, 'assessment.toDo', 0)}
              />
              <Score
                isBg
                label="ไม่ได้ทำ"
                score={_.get(testAndEva, 'assessment.notToDo', 0)}
              />
            </BoxScore>
          </BoxCardItem>
          <BoxCardItem>
            <LabelHeaderCard
              label="แบบประเมินการอบรม"
              handleClick={() => history.push(`/my-profile/evaluation`)}
            />
            <BoxScore>
              <Score
                label="กำลังเปิดให้ทำ"
                score={_.get(testAndEva, 'evaluation.onActive', 0)}
              />
              <Score
                score={_.get(testAndEva, 'evaluation.notToDo', 0)}
                label="ไม่ได้ทำ"
                isBg
              />
            </BoxScore>
          </BoxCardItem>
        </BoxCardList>
      </BoxCard>
    </Box>
  )
}

export default Index
