import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/system/styled'

export const StyledLessonLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  background: '#D7D7D7',
  cursor: 'pointer',
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 24,
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  borderRadius: theme.spacing(2),
  background: '#E9EFF7',
}))

export const StyledIconButton = styled(IconButton)(() => ({
  width: 24,
  height: 24,
}))
