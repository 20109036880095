import {
  setLoadMore,
  startLoadMore,
  stopLoadMore,
} from 'src/redux/slices/learner/list'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { postELearningLearnerList } from 'src/services/eLearning/list'
import { isScrolledIntoElement } from 'src/utils/lib'

export const handleFetchClassLearner = (body) => async (dispatch) => {
  await dispatch(postELearningLearnerList(body))
}

export const fetchClassLearnerList = (body, id) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(handleFetchClassLearner({ ...body, courseBatchUuid: id }))
  dispatch(stopLoading())
}

export const handleLoadMore = () => (dispatch) => {
  const footer = document.getElementById('page-footer')
  const isFooterAppeared = isScrolledIntoElement(footer)
  if (isFooterAppeared) dispatch(setLoadMore())
}

export const handleSeeMore = (filterBody) => async (dispatch) => {
  if (filterBody.page == 1) return

  dispatch(startLoadMore())
  await dispatch(handleFetchClassLearner(filterBody))
  dispatch(stopLoadMore())
}
