import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getContentLogin } from 'src/services/login'
import { initialState } from './model'

const login = createSlice({
  name: 'login',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getContentLogin.fulfilled, (state, { payload }) => {
        state.data = _.orderBy(_.get(payload, 'data', null), [
          'sequence',
          ['asc'],
        ])
      })
      .addCase(getContentLogin.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export default login.reducer
