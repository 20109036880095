import React, { useState, useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import { setToggleFilterDrawer } from '../../../redux/slices/table'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
  StyledListResult,
} from './Styled'
import {
  arrayCheckbox,
  defaultFilter,
  arrayAccessTo,
  arrayRepresentation,
  arrayApplication,
  arrayStatus,
} from './model'
import * as events from './events'

export const ListCheckbox = ({ arrayCheckboxList, stateFilter, setFilter }) => {
  const [user] = useLocalStorage('user')
  const agentDistribution = _.get(user, 'distributionChannel.abbreviation', '')
  const isCS = agentDistribution === 'CS'

  return arrayCheckboxList.map((item, index) => {
    if (isCS && item.type === 'represent') return <></>
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            label={item.label}
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {item.type === 'accessTo' && (
                <StyledListResult>
                  <ListResult
                    status={stateFilter.accessTo}
                    listKey={item.type}
                    filter={stateFilter}
                    setFilter={setFilter}
                    arrayResult={arrayAccessTo}
                  />
                </StyledListResult>
              )}

              {item.type === 'represent' && (
                <StyledListResult>
                  <ListResult
                    status={stateFilter.represent}
                    listKey={item.type}
                    filter={stateFilter}
                    setFilter={setFilter}
                    arrayResult={arrayRepresentation}
                  />
                </StyledListResult>
              )}
              {item.type === 'application' && (
                <StyledListResult>
                  <ListResult
                    status={stateFilter.application}
                    listKey={item.type}
                    filter={stateFilter}
                    setFilter={setFilter}
                    arrayResult={arrayApplication}
                  />
                </StyledListResult>
              )}
              {item.type === 'status' && (
                <StyledListResult>
                  <ListResult
                    status={stateFilter.status}
                    listKey={item.type}
                    filter={stateFilter}
                    setFilter={setFilter}
                    arrayResult={arrayStatus}
                    isCS={isCS}
                  />
                </StyledListResult>
              )}
              {item.type === 'text' && (
                <InputBase
                  sx={{ borderRadius: 0 }}
                  type="basic"
                  inputProps={{ 'data-testid': `input-${item.nameInput}` }}
                  name={item.nameInput}
                  disabled={!stateFilter[item.nameCheckBox]}
                  placeholder={item.placeholder}
                  value={stateFilter[item.nameInput]}
                  onChange={(e) => {
                    events.handleChange({
                      key: e.target.name,
                      value: e.target.value,
                      filter: stateFilter,
                      setFilter,
                    })
                  }}
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListResult = ({
  status,
  listKey,
  filter,
  setFilter,
  arrayResult,
  isCS,
}) => {
  return arrayResult.map((item, index) => {
    if (
      isCS &&
      (item.name === 'stealChecked' ||
        item.name === 'inactiveChecked' ||
        item.name === 'terminatedChecked')
    )
      return <></>
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={status[item.name]}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: listKey,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = ({ open, table }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setToggleFilterDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckbox
        arrayCheckboxList={arrayCheckbox}
        stateFilter={filter}
        setFilter={setFilter}
      />

      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => setFilter(defaultFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(table, filter))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
