import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'
import { height } from '../Styled'

export const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? 421 : 0,
  flexShrink: 0,
  transition: theme.transitions.create(['width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  '& .MuiDrawer-paper': {
    width: open ? 421 : 0,
    boxShadow:
      '0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
  },
  [theme.breakpoints.down('lg')]: {
    width: 421,
    '& .MuiDrawer-paper': { width: 421 },
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& .MuiDrawer-paper': { width: '100%' },
  },
}))

export const StyledRightContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height,
  background: theme.palette?.background?.paper,
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: `29px ${theme.spacing(2)} 0`,
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

export const StyledHeaderText = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 192px)',
}))

export const StyledDoubleDivider = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 7,
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  marginTop: '18px',
  marginBottom: '18px',
  [theme.breakpoints.down('sm')]: { marginBottom: 0 },
}))

export const StyledListContainer = styled(Box)(() => ({
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
}))

export const StyledListColumn = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const StyledOneLineText = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

export const StyledProgressPercent = styled(Row)(({ theme }) => ({
  width: 50,
  minWidth: 50,
  height: 50,
  background: theme.palette?.primary?.main,
  borderRadius: 65,
  alignItems: 'center',
  justifyContent: 'center',
}))
