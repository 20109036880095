import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledDetail = styled(Row)(({ theme }) => ({
  gap: theme.spacing(1),
  color: theme.palette.text?.secondary,
  flexWrap: 'wrap',
  marginTop: '12px',
}))

export const StyledDateTime = styled(Row)(({ theme }) => ({
  width: 'auto',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const StyledTypography = styled(Typography)(({ theme, maxWidth }) => ({
  maxWidth: 'unset',
  [theme.breakpoints.down('sm')]: {
    maxWidth,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-all',
  },
}))
