import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import SortMenu from 'src/components/Filter/SortMenu'
import ExpandedMenu from './ExpandedMenu'
import PeriodSliderMenu from 'src/components/Filter/PeriodSliderMenu'
import DateRangeMenu from 'src/components/Filter/DateRangeMenu'
import { StyledBox } from './Styled'
import {
  handleChangePeriod,
  handleChangeSort,
  handleChangeSlider,
  handleResetPeriod,
  getFilterBody,
} from './events'
import { fetchCourseList } from '../events'

const Filter = ({ isDrawer }) => {
  const { filterBody, sortByList } = useSelector(
    (state) => ({
      filterBody: _.get(state.eLearningList, 'filterBody', {}),
      sortByList: _.get(state.eLearningList.filterData, 'sortBy', []),
      sortBy: getFilterBody(state, 'sortBy', ''),
      courseHourFrom: getFilterBody(state, 'courseHourFrom', []),
      courseHourTo: getFilterBody(state, 'courseHourTo', []),
      startDate: getFilterBody(state, 'startDate', ''),
      finishDate: getFilterBody(state, 'finishDate', ''),
    }),
    shallowEqual
  )
  const { sortBy, courseHourFrom, courseHourTo, startDate, finishDate } =
    filterBody
  const dispatch = useDispatch()
  return (
    <StyledBox isDrawer={isDrawer}>
      <SortMenu
        sortBy={sortBy}
        list={sortByList}
        onChange={(e) => dispatch(handleChangeSort(e))}
      />

      <ExpandedMenu field="courseType" label="กลุ่มหลักสูตร" />
      <ExpandedMenu field="courseStatus" label="สถานะหลักสูตร" />
      <ExpandedMenu field="myCourse" label="หลักสูตรของฉัน" />

      <ExpandedMenu field="courseHourTo" label="ความยาวหลักสูตร">
        <PeriodSliderMenu
          hourFrom={courseHourFrom}
          hourTo={courseHourTo}
          onChange={(e, value) => dispatch(handleChangeSlider(value))}
          onChangeCommitted={() => dispatch(fetchCourseList(filterBody))}
        />
      </ExpandedMenu>

      <ExpandedMenu field="acquiredSkill" label="ระดับหลักสูตร" />
      <ExpandedMenu field="productType" label="ทักษะที่ได้จากหลักสูตร" />

      <ExpandedMenu field="startDate" label="วันที่เริ่มอบรม">
        <DateRangeMenu
          startDate={startDate}
          finishDate={finishDate}
          onChange={(formattedDate) =>
            dispatch(handleChangePeriod(formattedDate))
          }
          onReset={() => dispatch(handleResetPeriod())}
        />
      </ExpandedMenu>
    </StyledBox>
  )
}

export default Filter
