import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  StyledButtonLeft,
  StyledButtonRight,
  StyledContent,
  StyledDialog,
  StyledDialogActions,
} from 'src/components/AlertDialog/Styled'
import { dialogIcon } from 'src/components/AlertDialog/model'

const LearningAlertDialog = ({ alertDialog, setAlertDialog }) => {
  const {
    isDialogOpen,
    title,
    message,
    content,
    iconType,
    buttonLeft,
    buttonRight,
  } = alertDialog
  const isHasTitle = !_.isEmpty(iconType) || !_.isEmpty(title)

  const handleClose = () => {
    setAlertDialog((prev) => ({ ...prev, isDialogOpen: false }))
  }

  return (
    <Box>
      <StyledDialog
        maxWidth="sm"
        fullWidth={true}
        customWidth={421}
        sx={{ zIndex: '1298 !important' }}
        open={isDialogOpen}
      >
        <Box>
          <Box>
            {isHasTitle && (
              <StyledContent sx={{ p: '18px' }}>
                {iconType && <img alt="icon" src={dialogIcon[iconType]} />}
                <Typography
                  variant="h5"
                  color="primary.main"
                  sx={{ mt: iconType ? 3 : 0 }}
                >
                  {title}
                </Typography>
              </StyledContent>
            )}
            {message && (
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {message.map((item, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </Box>
            )}
            {content}
          </Box>
          <StyledDialogActions sx={{ p: '18px' }}>
            {!_.isEmpty(buttonLeft) && (
              <StyledButtonLeft
                data-testid="btn-disagree"
                size="l"
                variant="outlined"
                sx={{ width: '100%' }}
                onClick={_.get(buttonLeft, 'handleClick', handleClose)}
                endIcon={_.get(buttonLeft, 'endIcon', undefined)}
              >
                {_.get(buttonLeft, 'label', 'ย้อนกลับ')}
              </StyledButtonLeft>
            )}
            <StyledButtonRight
              data-testid="btn-agree"
              size="l"
              variant="contained"
              sx={{ width: '100%' }}
              onClick={_.get(buttonRight, 'handleClick', handleClose)}
              endIcon={_.get(buttonRight, 'endIcon', undefined)}
            >
              <Typography
                variant="button"
                color={_.get(buttonRight, 'colorText', 'auto')}
              >
                {_.get(buttonRight, 'label', 'ตกลง')}
              </Typography>
            </StyledButtonRight>
          </StyledDialogActions>
        </Box>
      </StyledDialog>
    </Box>
  )
}

export default LearningAlertDialog
