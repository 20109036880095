import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import InputText from 'src/components/Input/TextInput'
import { setExaminerDetail } from 'src/redux/slices/eExamination'
import { getError } from './event'

const TextField = ({
  index,
  obj,
  label,
  isDisabled = false,
  sm = 6,
  md = 'unset',
  maxlength = 'unset',
  required = true,
}) => {
  const dispatch = useDispatch()
  const { value, formError, showFormError, duplicatedError } = useSelector(
    (state) => ({
      value: state.eExamination.examiners[index][obj],
      formError: state.eExamination.formError[index],
      showFormError: state.eExamination.showFormError,
      duplicatedError: state.eExamination.duplicatedError,
    }),
    shallowEqual
  )
  return (
    <Grid item md={md} sm={sm} xs={12}>
      <InputText
        sx={{
          height: 50,
          background: '#FFFFFF',
          border: '1px solid #CCCCCC',
          px: '12px',
          '&.Mui-disabled': { background: '#F5F5F5' },
          '&.Mui-disabled input': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.70)',
          },
        }}
        id={`${obj}-${index}`}
        placeholder="กรุณากรอก"
        labelText={label}
        value={value || ''}
        required={required}
        isShowTextError={showFormError}
        textError={getError(formError, obj) || duplicatedError?.[index]?.[obj]}
        disabled={isDisabled}
        onChange={(e) =>
          dispatch(setExaminerDetail({ index, obj, value: e.target.value }))
        }
        maxlength={maxlength}
      />
    </Grid>
  )
}

export default TextField
