import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { Row } from 'src/components/Layout/Styled'
import { handleUploadDocument } from './events'
import { StyledColumn, StyledInputDisplay, StyledInputHidden } from './Styled'
import { getQueryParams } from 'src/utils/lib'

const DocumentInput = ({ index, label, isAllow }) => {
  const classLearner = getQueryParams('id')
  const { requestData, errorTexts } = useSelector(
    (state) => ({
      requestData: state.document.requestData,
      errorTexts: state.document.errorText,
    }),
    shallowEqual
  )
  const document = requestData.find((item) => item.key == index)
  const errorText = errorTexts.find((item) => item.key == index)
  const fileName =
    _.get(document, 'displayName', undefined) ||
    _.get(document, 'fileName', 'ยังไม่เลือกไฟล์').replace(
      '/user/learner_documents/',
      ''
    )

  const dispatch = useDispatch()
  return (
    <StyledColumn>
      <Row sx={{ justifyContent: 'space-between', alignItems: 'start' }}>
        <Typography>
          {label}{' '}
          <Typography variant="span" color="error.main">
            *
          </Typography>
        </Typography>
        <Typography id="info" variant="body2" color="text.gray">
          ไฟล์ที่รองรับ .jpg, .jpeg, .png, .pdf ขนาดของไฟล์ที่แนะนำคือ 2
          เมกะไบต์ (MB)
        </Typography>
      </Row>
      <label htmlFor={`import-doc-${index}`}>
        <StyledInputDisplay isAllow={isAllow}>
          <Button disabled={!isAllow} component="span">
            เลือกไฟล์
          </Button>
          {isAllow && (
            <Typography color="text.secondary">{fileName}</Typography>
          )}
        </StyledInputDisplay>
        <StyledInputHidden
          data-testid={`import-doc-${index}`}
          id={`import-doc-${index}`}
          accept=".jpg, .jpeg, .png, .pdf"
          type="file"
          disabled={!isAllow}
          onChange={(e) =>
            dispatch(handleUploadDocument(e, index, classLearner))
          }
        />
        {errorText && (
          <Typography sx={{ mt: 0.5 }} variant="body2" color="error">
            {_.get(errorText, 'text', '')}*
          </Typography>
        )}
      </label>
    </StyledColumn>
  )
}

export default DocumentInput
