import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'
import Template from 'src/components/Template'
import { Row } from 'src/components/Layout/Styled'
import { Content as ELearningContent } from '../../../Course/Detail'
import { LoadingCircular } from '../Styled'
import { COURSE_TYPE } from '../model'
import CourseList from './CourseList'
import CourseContent from './CourseContent'
import { StyledColumn, StyledContentWrapper } from './Styled'

const Content = () => {
  const isCourseLoading = useSelector(
    (state) => state.eLearningLearningPath.isCourseLoading,
    shallowEqual
  )
  return (
    <StyledContentWrapper>
      <Template noMinHeight>
        <StyledColumn>
          <CourseList />

          {isCourseLoading ? (
            <LoadingCircular sx={{ position: 'relative' }} isLoading />
          ) : (
            <SelectedContent />
          )}
        </StyledColumn>
      </Template>
    </StyledContentWrapper>
  )
}

export default Content

export const SelectedContent = () => {
  const { courseType, course } = useSelector(
    (state) => ({
      courseType: state.eLearningLearningPath.courseType,
      course: state.eLearningLearningPath.course,
    }),
    shallowEqual
  )
  const isELearning = courseType === COURSE_TYPE.E_LEARNING
  const uuid = _.get(course, 'uuid', '')
  const coursePath = `${isELearning ? '/e-learning' : ''}/course/${uuid}`

  return (
    <>
      <Row
        sx={{ alignItems: 'start', justifyContent: 'space-between', gap: 1 }}
      >
        <Typography variant="h5" color="primary">
          {_.get(course, 'name', '') || _.get(course, 'englishName', '')}
        </Typography>
        <Button
          variant="contained"
          endIcon={<ArrowForwardIos />}
          sx={{ minWidth: 126, px: '14px' }}
          onClick={() =>
            window.open(`${window.__env__.REACT_APP_APP_URL}${coursePath}`)
          }
        >
          ไปยังหลักสูตร
        </Button>
      </Row>

      {isELearning ? (
        <ELearningContent
          sx={{ my: { lg: 0, md: 0, sm: 0 }, p: { lg: 0, md: 0, sm: 0 } }}
        />
      ) : (
        <CourseContent />
      )}
    </>
  )
}
