import {
  getMyPoint,
  postCatalogFilter,
} from 'src/services/learningPoint/catalog'
import _ from 'lodash'
const { createSlice } = require('@reduxjs/toolkit')
const { initialState } = require('./model')

const catalog = createSlice({
  name: 'catalog',
  initialState: initialState,
  reducers: {
    setReduxValue: (state, { payload }) => {
      const { index, value } = payload
      state[index] = value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCatalogFilter.fulfilled, (state, { payload }) => {
        if (state.page > 1) {
          state.catalogList = state.catalogList.concat(
            _.get(payload, 'result', [])
          )
        } else {
          state.catalogList = _.get(payload, 'result', [])
        }
        state.totalCount = _.get(payload, 'totalCount', [])
      })
      .addCase(postCatalogFilter.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getMyPoint.fulfilled, (state, { payload }) => {
        state.myPoint = _.get(payload, 'point', 0)
      })
      .addCase(getMyPoint.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})
export const { setReduxValue } = catalog.actions

export default catalog.reducer
