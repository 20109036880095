import React from 'react'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'

const DisallowContent = ({ failMaxTimes, mins, secs }) => {
  const minStr = mins.toString().padStart(2, '0')
  const secStr = secs.toString().padStart(2, '0')
  return (
    <Column sx={{ p: '18px', gap: 1, alignItems: 'center' }}>
      <ErrorOutline color="warning" sx={{ width: 54, height: 54 }} />
      <Column gap={0.5} alignItems="center">
        <Typography variant="h6">
          คุณยืนยันตัวตนไม่ผ่านครบ {failMaxTimes} ครั้ง
        </Typography>
        <Typography variant="h6">
          สามารถยืนยันตัวตนอีกครั้ง หลัง {minStr}:{secStr} นาที
        </Typography>
      </Column>
    </Column>
  )
}

export default DisallowContent
