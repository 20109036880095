import React, { useEffect } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'

import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Template from 'src/components/Template'
import CircularProgress from 'src/components/Progress/CircularProgress'
import { useHistory, useParams } from 'react-router-dom'

import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'
import ListAltOutlined from '@mui/icons-material/ListAltOutlined'
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined'

import * as Styled from './Styled'
import { mapStatus, fetchCheckIn } from './events'
import { getQueryParams } from '../../utils/lib'

const Index = ({ isTest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classQrCodeUuid = getQueryParams('u')
  const { id: trainingPlanUuid } = useParams()

  const { isLoading, data } = useSelector(
    (state) => state.checkIn,
    shallowEqual
  )

  const status = _.get(data, 'status', 'ERROR') //success
  const isShowError = _.get(data, 'isShowError', false)
  const errorCase = _.get(data, 'errorCase', 'ERROR')

  const urlImage = `${window.__env__.REACT_APP_FILE_URL}${_.get(
    data,
    'course.imageKey',
    ''
  )}`
  let labelCourse = _.get(data, 'course.courseName', '')
  let isMobile = true
  if (!isTest) isMobile = window?.matchMedia('(pointer:coarse)')?.matches
  if (isMobile && labelCourse.length > 70) {
    labelCourse = labelCourse.substring(0, 70) + '...'
  }

  useEffect(() => {
    dispatch(
      fetchCheckIn({
        classQrCodeUuid,
        trainingPlanUuid,
      })
    )
  }, [])

  const checkedDate = `${_.get(
    data,
    'course.dateRangeMonthlyPlan.startDate',
    ''
  )} - ${_.get(data, 'course.dateRangeMonthlyPlan.finishDate', '')}`

  return (
    <Template topLine={40}>
      <Styled.BoxContainer>
        <img src={mapStatus(status).icon}></img>
        <Typography
          variant="h5"
          sx={{ mt: -1 }}
          color={mapStatus(status).color}
        >
          {mapStatus(status, errorCase).label}
        </Typography>
        {isShowError && (
          <Styled.BoxRemark>
            <Typography variant="body1">
              {_.get(data, 'textError', '')}
            </Typography>
            <Typography variant="body1">
              โปรดติดต่อ แอดมิน ประจำคลาสเรียน
            </Typography>
          </Styled.BoxRemark>
        )}
        {errorCase !== 'ERROR500' && (
          <Styled.BoxCard>
            <Styled.BoxContent>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Styled.BoxImg>
                  <img src={urlImage}></img>
                </Styled.BoxImg>
                <Typography variant="body1b">{labelCourse}</Typography>
              </Box>

              <Styled.BoxDetail>
                <Styled.BoxDetailLabel>
                  <InsertInvitationOutlined fontSize="small" />
                  <Typography variant="body2">{checkedDate}</Typography>
                  <Divider orientation="vertical" flexItem />

                  <ListAltOutlined fontSize="small" />
                  <Typography variant="body2">
                    ครั้งที่ {_.get(data, 'course.checkedRound', 0)}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                </Styled.BoxDetailLabel>
                <Box>
                  <Typography variant="body2">
                    {_.get(data, 'course.lastCheckedBy', '')}
                  </Typography>
                </Box>
              </Styled.BoxDetail>
            </Styled.BoxContent>
          </Styled.BoxCard>
        )}
        <Button
          size="l"
          color="primary"
          variant="contained"
          id="btn-next-course"
          sx={{ mt: 3 }}
          endIcon={<ArrowForwardOutlined />}
          onClick={() => {
            history.push('/course')
          }}
        >
          <Typography variant="buttonl">ไปที่หลักสูตร</Typography>
        </Button>
      </Styled.BoxContainer>

      <></>
      <CircularProgress isLoading={isLoading} />
    </Template>
  )
}
export default Index
