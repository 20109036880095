import React, { useState } from 'react'
import MuiTabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { COURSE_TYPE } from 'src/constants/eLearning'
import { TAB_VALUE } from '../../model'
import ContentTab from '../ContentTab'
import GetReadyTab from '../GetReadyTab'
import { StyledTabContainer, StyledTabWrapper } from './Styled'

const Tabs = () => {
  const [tab, setTab] = useState(TAB_VALUE.CONTENT)
  const data = useSelector((state) => state.eLearningDetail.data, shallowEqual)
  const courseType = _.get(data, 'courseType', '')
  return (
    <StyledTabContainer>
      <StyledTabWrapper>
        <MuiTabs
          variant="fullWidth"
          value={tab}
          onChange={(e, tab) => setTab(tab)}
        >
          <Tab label="เนื้อหาหลักสูตร" value={TAB_VALUE.CONTENT} />
          {courseType === COURSE_TYPE.OIC && (
            <Tab label="เตรียมพร้อมก่อนอบรม" value={TAB_VALUE.GET_READY} />
          )}
        </MuiTabs>
      </StyledTabWrapper>
      {tab === TAB_VALUE.CONTENT && <ContentTab />}
      {tab === TAB_VALUE.GET_READY && <GetReadyTab />}
    </StyledTabContainer>
  )
}

export default Tabs
