import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledTopWrapper = styled(Column)(({ theme }) => ({
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: { padding: `0 ${theme.spacing(5)}` },
  [theme.breakpoints.down('sm')]: { padding: `0 ${theme.spacing(2)}` },
}))

export const StyledContainer = styled(Row)(({ theme }) => ({
  flexWrap: 'wrap',
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: { width: '100%' },
  [theme.breakpoints.down('sm')]: { gap: 10, alignItems: 'start' },
}))

export const StyledDetailCard = styled(Row)(({ theme }) => ({
  width: 226,
  maxHeight: 82,
  minHeight: 82,
  background: theme.palette?.background?.paper,
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueBorder,
  borderRadius: theme.spacing(0.5),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  gap: theme.spacing(3),
  justifyContent: 'start',
  '& > .MuiTypography-root': { textAlign: 'center' },
  [theme.breakpoints.down('lg')]: { width: 'calc(33% - 14px)' },
  [theme.breakpoints.down('sm')]: {
    width: 'calc(50% - 5px)',
    maxHeight: 52,
    minHeight: 52,
    padding: `6px ${theme.spacing(1)}`,
    gap: 10,
    '&#timeSpan': { paddingRight: 0 },
    img: { width: 20, height: 20 },
    '& > .MuiTypography-root': { textAlign: 'start' },
  },
}))
