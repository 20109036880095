import React, { useEffect } from 'react'
import { Table, TableBody, TableContainer, TableHead } from '@mui/material'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { setInitialAddExaminerDrawer } from 'src/redux/slices/eExamination'
import {
  StyledCheckbox,
  StyledExaminerImage,
  StyledTableCell,
  StyledTableRow,
} from './Styled'
import {
  handleSelect,
  handleSelectAll,
  loadExaminationUserFilter,
} from './event'
import _ from 'lodash'
import SearchBox from './SearchBox'

const AddExaminerTable = () => {
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('user'))
  const {
    examinerList,
    examinerSearchText,
    selectedExaminerList,
    hasMore,
    page,
    isFetch,
    examiners,
  } = useSelector(
    (state) => ({
      examinerList: state.eExamination.examinerList,
      examinerSearchText: state.eExamination.examinerSearchText,
      selectedExaminerList: state.eExamination.selectedExaminerList,
      hasMore: state.eExamination.examinationUserFilter.hasMore,
      page: state.eExamination.examinationUserFilter.page,
      isFetch: state.eExamination.examinationUserFilter.isFetch,
      examiners: state.eExamination.examiners,
    }),
    shallowEqual
  )
  useEffect(() => {
    if (user) {
      dispatch(
        loadExaminationUserFilter({ user, page: 1, search: examinerSearchText })
      )
    }
    return () => {
      dispatch(setInitialAddExaminerDrawer())
    }
  }, [])

  return (
    <TableContainer
      onScroll={(e) => {
        if (!hasMore || isFetch) return
        if (
          e.target.offsetHeight + e.target.scrollTop + 50 >
          e.target.scrollHeight
        ) {
          dispatch(
            loadExaminationUserFilter({
              user,
              page: page + 1,
              search: examinerSearchText,
            })
          )
        }
      }}
    >
      <Table>
        <TableHead>
          <StyledTableRow sx={{ borderBottom: 0 }}>
            <StyledTableCell colSpan={4} sx={{ paddingTop: 2.5 }}>
              <SearchBox />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow sx={{ borderTop: 0 }}>
            <StyledTableCell sx={{ width: 50, fontWeight: '500' }}>
              <StyledCheckbox
                checked={
                  examinerList.length > 0 &&
                  _.isEqual(
                    _.sortBy(selectedExaminerList, ['uuid']),
                    _.sortBy(examinerList, ['uuid'])
                  )
                }
                onChange={(e) => dispatch(handleSelectAll(e.target.checked))}
              />
            </StyledTableCell>
            <StyledTableCell
              sx={{ width: 50, fontWeight: '500' }}
            ></StyledTableCell>
            <StyledTableCell sx={{ fontWeight: '500' }}>
              ชื่อ - นามสกุล
            </StyledTableCell>
            <StyledTableCell sx={{ width: 120, fontWeight: '500' }}>
              เบอร์โทรศัพท์
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {examinerList.map((ele, ind) => {
            if (examiners.some((e) => e.uuid === ele.uuid)) {
              return ''
            } else {
              return (
                <StyledTableRow key={ind}>
                  <StyledTableCell>
                    <StyledCheckbox
                      checked={Boolean(
                        _.find(selectedExaminerList, (selected) => {
                          return selected.uuid == ele.uuid
                        })
                      )}
                      onChange={(e) =>
                        dispatch(
                          handleSelect({ check: e.target.checked, value: ele })
                        )
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledExaminerImage src={ele.image} />
                  </StyledTableCell>
                  <StyledTableCell>{`${ele.firstNameTH} ${ele.lastNameTH}`}</StyledTableCell>
                  <StyledTableCell>{ele.mobileNo || '-'}</StyledTableCell>
                </StyledTableRow>
              )
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AddExaminerTable
