import _ from 'lodash'
import { store } from 'src/App'
import { setFilterValue, setReduxValue } from 'src/redux/slices/eExamination'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { postScheduleFiter } from 'src/services/eExamination'

export const handleFieldTypeChange =
  ({ key, value }) =>
  (dispatch) => {
    const state = store.getState()
    let type = [..._.get(state.eExamination.examinationFilter, 'type', [])]
    if (value) {
      type.push(key)
    } else {
      var index = type.indexOf(key)
      if (index !== -1) {
        type.splice(index, 1)
      }
    }
    dispatch(setFilterValue({ index: 'type', value: type }))
  }

export const handleClickSearch = () => async (dispatch) => {
  dispatch(startLoading())
  const state = store.getState()
  const startDate = _.get(state.eExamination.examinationFilter, 'startDate', '')
  const endDate = _.get(state.eExamination.examinationFilter, 'endDate', '')
  const fields = _.get(state.eExamination.examinationFilter, 'fields', [])
  const type = _.get(state.eExamination.examinationFilter, 'type', [])
  if ((startDate && endDate) || fields.length > 0 || type.length > 0) {
    dispatch(setFilterValue({ index: 'isFetch', value: true }))
    await dispatch(
      postScheduleFiter({
        startDate: startDate,
        endDate: endDate,
        options: fields.map((ele) => ele.value),
        type: type,
        limit: 10,
        order: 'DESC',
        page: 1,
        sort: 'updatedAt',
      })
    )
  } else {
    dispatch(setReduxValue({ index: 'roundList', value: [] }))
  }
  dispatch(stopLoading())
}
