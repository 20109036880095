import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Close from '@mui/icons-material/Close'
import {
  ProgressContainer,
  ProgressDateBox,
  ProgressCircle,
  ProgressDivider,
  ProgressCircleContainer,
} from './Styled'
import _ from 'lodash'
import { StyledContent, StyledDrawer, StyledHeadLabel } from '../Styled'

const StatusDrawer = ({ isOpen, onCloseDrawer }) => {
  const data = useSelector((state) => state.registration.data, shallowEqual)

  const getData = _.get(data, 'classStatusHistory', [])
    .slice()
    .sort((a, b) => _.get(b, 'index', '') - _.get(a, 'index', ''))

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <StyledContent>
        <StyledHeadLabel>
          <Box id="header">
            <Typography variant="h5">สถานะคลาส</Typography>
            <IconButton
              data-testid="btn-close-drawer"
              color="primary"
              component="span"
              onClick={onCloseDrawer}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </StyledHeadLabel>
        {getData.map((item, index) => {
          const dateRange = `${_.get(item, 'dateRange.dateDay', '')} ${_.get(
            item,
            'dateRange.dateMonth',
            ''
          )} ${_.get(item, 'dateRange.dateYear', '')}`
          const time = _.get(item, 'dateRange.dateTime', '')
          const detail = _.get(item, 'detail', '')

          return (
            <ProgressContainer key={index}>
              <ProgressDateBox>
                <Typography variant="body2">{dateRange}</Typography>
                <Typography variant="body2">{time}</Typography>
              </ProgressDateBox>
              <ProgressCircleContainer>
                {index !== 0 ? <ProgressDivider /> : ''}

                <ProgressCircle isLatest={index === 0} />
              </ProgressCircleContainer>

              <Typography variant={index === 0 ? 'body1b' : 'body1'}>
                {detail}
              </Typography>
            </ProgressContainer>
          )
        })}
      </StyledContent>
    </StyledDrawer>
  )
}

export default StatusDrawer
