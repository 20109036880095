import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import {
  getELearningBanner,
  getELearningCoursesRecommend,
  postELearningFilter,
} from 'src/services/eLearning'
import _ from 'lodash'
import { getMainLearningPath } from 'src/services/eLearning/learningPath'
import { getCollectionHomePage } from 'src/services/eLearning/collection'

const eLearningCourseList = createSlice({
  name: 'eLearningCourseList',
  initialState,
  reducers: {
    setFilter: (state, { payload }) => {
      const { key, value } = payload
      state.filterBody[key] = value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postELearningFilter.fulfilled, (state, { payload }) => {
        const courses = _.get(payload, 'data', [])
        state.filterCourse = courses
      })
      .addCase(postELearningFilter.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getELearningCoursesRecommend.fulfilled, (state, { payload }) => {
        state.courses = _.get(payload, 'data', [])
      })
      .addCase(getELearningCoursesRecommend.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getELearningBanner.fulfilled, (state, { payload }) => {
        const bannerSection = _.get(payload, 'data', [])
        state.bannerSection = _.concat(state.bannerSection, bannerSection)
      })
      .addCase(getELearningBanner.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getMainLearningPath.fulfilled, (state, { payload }) => {
        state.learningPaths = _.get(payload, 'data', [])
      })
      .addCase(getMainLearningPath.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getCollectionHomePage.fulfilled, (state, { payload }) => {
        state.collections = _.get(payload, 'data', [])
      })
      .addCase(getCollectionHomePage.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const { setFilter } = eLearningCourseList.actions

export default eLearningCourseList.reducer
