import styled from '@mui/system/styled'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

export const StyledCard = styled(Card)(({ theme }) => ({
  overflow: 'auto',
  margin: theme.spacing(3),
  padding: theme.spacing(3),
  paddingTop: theme.spacing(1.5),
  boxShadow: '0px 12px 40px 0px #4976BA1A',
  border: '1px solid rgb(219, 228, 241)',
  '&.MuiCard-root': {
    maxWidth: 'unset',
    borderRadius: theme.spacing(1),
  },
}))

export const HeadLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
}))

export const Row = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

export const StyledImage = styled('img')(({ theme }) => ({
  width: 42,
  height: 42,
  marginRight: theme.spacing(1.5),
  objectFit: 'cover',
  border: '2px solid #DBE4F1',
  borderRadius: '8px',
  boxShadow:
    '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033',
}))
