import _ from 'lodash'
import { changeText } from 'src/modules/Class/Content/ContentTab/Condition/events'

export const handleDisplayPrerequisite = (data) => {
  return data.map((dataItem, dataIndex) => {
    const result = []
    const groupData = _.groupBy(dataItem, 'type')
    let mainIndex = 0
    let newIndex = 0

    for (let type in groupData) {
      const value = groupData[type]
      let label = ''

      value.forEach((item, index) => {
        const orCondition = ' หรือ '
        const labelText = handleLabelText(item)
        const typeTh = type === 'OTHER' ? '' : changeText(type)

        label = label + typeTh + labelText

        if (index < value.length - 1) label = label + orCondition
      })

      if (mainIndex == 0) {
        if (newIndex >= dataIndex) {
          newIndex = dataIndex
          result.push({
            condition: dataIndex == 0 ? '' : 'AND',
            label,
          })
        } else result.push({ condition: 'AND', label })
      } else result.push({ condition: 'OR', label })

      mainIndex++
    }

    return result
  })
}

export const handleLabelText = (item) => {
  const type = _.get(item, 'type', 'license')
  let labelText = 'ตัวแทนที่ใบอนุญาตผู้แนะนำการลงทุนหมดอายุในปีที่อบรมนั้น' // for อื่นๆ

  if (type == 'COURSE') {
    const nameForLearner = _.get(item, 'course.nameForLearner', '')
    const englishName = _.get(item, 'course.englishName', '')
    labelText = nameForLearner || englishName || _.get(item, 'course.name', '')
  } else if (type == 'LICENSE') {
    labelText = _.get(item, 'license[0].description', '')
  } else if (type == 'SERVICEYEAR') {
    const year = _.get(item, 'year', '')
    const month = _.get(item, 'month', '')
    labelText = `${year} ปี ${month} เดือน`
  }

  return labelText
}
