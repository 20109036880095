import React from 'react'
import Box from '@mui/material/Box'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import NextAndPrev from './NextAndPrev'
import DatePicker from './DatePicker'
import SelectMonthYear from './SelectMonthYear'
import DrawerFilter from './DrawerFilter'
import { FilterListButton, BoxIcon, BoxHeader } from '../Styled'
import { handleFilter } from './events'

const Index = ({ calendarRef }) => {
  const tapMenu = useSelector((state) => state.myProfile.tapMenu, shallowEqual)
  const dispatch = useDispatch()
  return (
    <BoxHeader tapMenu={tapMenu}>
      <Box sx={{ display: 'flex' }}>
        <NextAndPrev calendarRef={calendarRef} />
      </Box>
      <BoxIcon>
        {tapMenu !== 'MONTH' && <DatePicker calendarRef={calendarRef} />}
        {tapMenu === 'MONTH' && <SelectMonthYear calendarRef={calendarRef} />}
        <Box>
          <FilterListButton onClick={() => dispatch(handleFilter())}>
            ตัวกรอง
          </FilterListButton>
        </Box>
      </BoxIcon>
      <DrawerFilter />
    </BoxHeader>
  )
}

export default Index
