import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette?.other?.divider}`,
  width: '100%',
  padding: '15px 16px',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const CellContainer = styled(Box)(({ theme, status }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '9px',
  paddingLeft: status ? '' : '25px',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-end',
    width: '100%',
  },
}))

export const LeftCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
  },
}))
