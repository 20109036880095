import _ from 'lodash'
import { initialState } from './model'

const { createSlice } = require('@reduxjs/toolkit')

const alertDialog = createSlice({
  name: 'alertDialog',
  initialState,
  reducers: {
    openDialog: (state, { payload }) => {
      state.isOpen = true
      state.isSystem = false
      state.type = _.get(payload, 'type', 'default')
      state.isLoading = _.get(payload, 'isLoading', false)
      state.title = _.get(payload, 'title', '')
      state.message = _.get(payload, 'message', [])
      state.content = _.get(payload, 'content', null)
      state.maxWidth = _.get(payload, 'maxWidth', null)
      state.disableButton = _.get(payload, 'disableButton', false)
      state.isCloseDialog = _.get(payload, 'isCloseDialog', true)
      state.fullWidth = _.get(payload, 'fullWidth', true)
      state.handleCancel = _.get(payload, 'handleCancel', null)

      state.isClose = _.get(payload, 'isClose', false)
      state.iconType = _.get(payload, 'iconType', null)
      state.buttonLeft = _.get(payload, 'buttonLeft', {})
      state.buttonRight = _.get(payload, 'buttonRight', {})
      state.buttonCenter = _.get(payload, 'buttonCenter', {})
      state.customWidth = _.get(payload, 'customWidth', null)
      state.isHiddenFooter = _.get(payload, 'isHiddenFooter', false)
      state.isHiddenButton = _.get(payload, 'isHiddenButton', false)
      state.zIndex = _.get(payload, 'zIndex', 1299)
      state.px = _.get(payload, 'px', null)
    },
    openErrorDialog: (state, { payload }) => {
      state.isCloseDialog = false
      state.isOpen = true
      state.type = 'default'
      state.iconType = 'error'
      state.title = _.get(payload, 'title', 'ไม่สำเร็จ')
      const message = _.get(payload, 'message', 'ดำเนินการไม่สำเร็จ')
      state.message = typeof message == 'string' ? [message] : message
      state.buttonRight = _.get(payload, 'buttonRight', {})
      state.buttonLeft = null
      state.isHiddenFooter = false
    },
    openSuccessDialog: (state, { payload }) => {
      state.isCloseDialog = false
      state.isOpen = true
      state.type = 'default'
      state.iconType = 'success'
      state.title = _.get(payload, 'title', 'สำเร็จ')
      state.message = [_.get(payload, 'message', 'ดำเนินการสำเร็จ')]
      state.buttonLeft = {}
      state.isHiddenFooter = false
      const handleClick = _.get(payload, 'handleClick', null)
      _.set(state, 'buttonRight.handleClick', handleClick)
    },
    openErrorWithCurlDialog: (state, { payload }) => {
      state.isOpen = true
      state.type = 'default'
      state.iconType = 'error'
      state.title = 'ผิดพลาด'
      state.message = [
        _.get(payload, 'response.data.message', 'ดำเนินการไม่สำเร็จ'),
      ]
      state.errorCurl = _.get(payload, 'config.curlCommand', null)
      state.isHiddenFooter = false
    },
    closeDialog: (state) => {
      state.isOpen = false
    },
    resetDialog: (state) => {
      Object.assign(state, initialState)
      state.isHiddenFooter = false
    },
    loadingDialog: (state) => {
      state.isLoading = true
    },
    stopLoadingDialog: (state) => {
      state.isLoading = false
    },
    setEnableButton: (state) => {
      state.disableButton = false
    },
    setDisableButton: (state) => {
      state.disableButton = true
    },
  },
})

export const {
  openDialog,
  openErrorDialog,
  openSuccessDialog,
  openErrorWithCurlDialog,
  closeDialog,
  resetDialog,
  loadingDialog,
  stopLoadingDialog,
  setEnableButton,
  setDisableButton,
} = alertDialog.actions

export default alertDialog.reducer
