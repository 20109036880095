import _ from 'lodash'
import { store } from 'src/App'
import { closeDialog, openErrorDialog } from 'src/redux/slices/alertDialog'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getETestingScore } from 'src/services/eTesting'

export const handleGetScore = (props, isModule) => async (dispatch) => {
  const { uuid, trainingPlan, error, history } = props

  if (_.isNil(error)) {
    let body = {
      courseTestUuid: uuid,
      trainingPlanUuid: trainingPlan || '',
    }
    if (isModule) {
      const { selectedLesson, eLearningCourseBatchLearner } =
        store.getState().eLearningLearning
      body = {
        eTestingUuid: _.get(selectedLesson, 'eTestingUuid', ''),
        eLearningModule: _.get(selectedLesson, 'eLearningModule', {}),
        eLearningCourseBatchLearner,
      }
    }
    dispatch(startLoading())
    await dispatch(getETestingScore({ body, isModule }))
    dispatch(stopLoading())
  } else {
    dispatch(
      openErrorDialog({
        title: _.get(error, 'title', 'ไม่สามารถทำข้อสอบได้'),
        message: [_.get(error, 'value', '')],
        buttonRight: {
          handleClick: () => {
            dispatch(closeDialog())
            if (!isModule) history.push('/my-class')
          },
        },
      })
    )
  }
}
