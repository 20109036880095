import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React from 'react'
import { formatData, changeColor } from './events'
import { startTest } from '../../../../../../utils/lib'
import { Container, CellContainer, LeftCellContainer } from './Styled'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import HourglassBottomTwoToneIcon from '@mui/icons-material/HourglassBottomTwoTone'
import _ from 'lodash'
const TestTableCell = (props) => {
  const {
    name,
    openStatus,
    openStatusTH,
    dateRange,
    isShowButton,
    sentStatus,
    canSent,
    type,
    uuid,
    courseTestAndEvaluateDataUuid,
  } = props.data

  const { trainingPlanUuid } = props
  const startDate = `${_.get(dateRange, 'startDateDay', '')} ${_.get(
    dateRange,
    'startDateMonth',
    ''
  )} ${_.get(dateRange, 'startDateYear', '')} (${_.get(
    dateRange,
    'startDateTime',
    ''
  )} น.)`

  const endDate = `${_.get(dateRange, 'expiredDateDay', '')} ${_.get(
    dateRange,
    'expiredDateMonth',
    ''
  )} ${_.get(dateRange, 'expiredDateYear', '')} (${_.get(
    dateRange,
    'expiredDateTime',
    ''
  )} น.)`

  const getDate = `${startDate} ${
    dateRange.expiredDateDay ? '- ' + endDate : ''
  }`
  const typeOfETesting = _.get(props, 'data.typeOfETesting', '')

  return (
    <>
      <Container>
        <LeftCellContainer>
          <Typography variant="body1">{name}</Typography>
          {typeOfETesting != 'ASSESSMENT' && (
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ span: { color: changeColor(openStatus) } }}
              >
                {openStatus == 'WAS_OPEN' ? (
                  `${openStatusTH} : `
                ) : (
                  <>
                    สถานะ : <span>{openStatusTH}</span>
                  </>
                )}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {openStatus === 'NOT_OPEN_YET' ? '' : getDate}
              </Typography>
            </Box>
          )}
        </LeftCellContainer>
        {isShowButton ? (
          <CellContainer status={isShowButton}>
            <Button
              color="secondary"
              variant="contained"
              size="l"
              sx={{ width: '130px' }}
              disabled={!canSent}
              onClick={() =>
                startTest(
                  uuid,
                  trainingPlanUuid,
                  type,
                  courseTestAndEvaluateDataUuid
                )
              }
            >
              เริ่มทำ
            </Button>
          </CellContainer>
        ) : (
          <CellContainer sx={{ minWidth: 130 }} color={formatData(sentStatus, true, type)}>
            {handleRenderIcon(sentStatus)}

            <Typography variant="body1" sx={{ width: 'auto' }}>
              {formatData(sentStatus, false, type)}
            </Typography>
          </CellContainer>
        )}
      </Container>
    </>
  )
}

export default TestTableCell

export const handleRenderIcon = (sentStatus) => {
  switch (sentStatus) {
    case 'SENT':
    case 'PASS':
      return <CheckCircleOutlineOutlinedIcon />
    case 'WAITING':
      return <HourglassBottomTwoToneIcon />
    case 'NOT_PASS':
    case 'NOT_SENT':
      return <CancelOutlinedIcon />
    default:
      return <></>
  }
}
