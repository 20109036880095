import { Box, styled } from '@mui/material'

export const VideoWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  background: theme.palette?.action?.disabled,
  cursor: 'pointer',
  opacity: 0,
  transition: 'ease 0.3s opacity',
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette?.common?.white,
    fontSize: '5rem',
  },
}))
