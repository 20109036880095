import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import SortMenu from 'src/components/Filter/SortMenu'
import PeriodSliderMenu from 'src/components/Filter/PeriodSliderMenu'
import { fetchLearningPathList } from '../events'
import ExpandedMenu from './ExpandedMenu'
import { StyledBox } from './Styled'
import { handleChangeSort, handleChangeSlider } from './events'

const Filter = ({ isDrawer }) => {
  const { filterBody, sortByList } = useSelector(
    (state) => ({
      filterBody: _.get(state.eLearningLearningPathList, 'filterBody', {}),
      sortByList: _.get(
        state.eLearningLearningPathList.filterData,
        'sortBy',
        []
      ),
    }),
    shallowEqual
  )
  const { sortBy, pathHourFrom, pathHourTo } = filterBody
  const dispatch = useDispatch()
  return (
    <StyledBox isDrawer={isDrawer}>
      <SortMenu
        sortBy={sortBy}
        list={sortByList}
        onChange={(e) => dispatch(handleChangeSort(e))}
      />

      <ExpandedMenu field="learningPathStatus" label="สถานะ" />
      <ExpandedMenu field="myLearningPath" label="เส้นทางการเรียนรู้ของฉัน" />

      <ExpandedMenu field="pathHourTo" label="ความยาวหลักสูตร">
        <PeriodSliderMenu
          hourFrom={pathHourFrom}
          hourTo={pathHourTo}
          onChange={(e, value) => dispatch(handleChangeSlider(value))}
          onChangeCommitted={() => dispatch(fetchLearningPathList(filterBody))}
        />
      </ExpandedMenu>

      <ExpandedMenu field="acquiredSkill" label="ระดับหลักสูตร" />
      <ExpandedMenu field="productType" label="ทักษะที่ได้จากหลักสูตร" />
    </StyledBox>
  )
}

export default Filter
