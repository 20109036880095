import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { setExaminerDetail } from 'src/redux/slices/eExamination'
import DatePicker from 'src/components/DatePicker'
import { getError } from './event'

const DateSelect = ({ index, obj, label, disabled }) => {
  const dispatch = useDispatch()
  const { value, formError, showFormError } = useSelector(
    (state) => ({
      value: state.eExamination.examiners[index][obj],
      formError: state.eExamination.formError[index],
      showFormError: state.eExamination.showFormError,
    }),
    shallowEqual
  )
  return (
    <Grid item sm={6} xs={12}>
      <DatePicker
        id={`${obj}-${index}`}
        labelText={label}
        value={value || null}
        required
        placeholder="กรุณาเลือก"
        hideTextError={!showFormError}
        textError={getError(formError, obj)}
        boxSx={{
          '& .MuiTextField-root': {
            background: '#FFFFFF',
            '& input.Mui-disabled': {
              WebkitTextFillColor: 'rgba(0, 0, 0, 0.70)',
            },
            '& svg': {
              color: '#00008F',
            },
          },
        }}
        onChange={(e) => {
          dispatch(
            setExaminerDetail({
              index,
              obj,
              value: e || '',
            })
          )
        }}
        disabledInput={disabled}
      />
    </Grid>
  )
}

export default DateSelect
