import _ from 'lodash'
import { startLoading, stopLoading } from '../../../redux/slices/loading'
import { setRegistrationData } from '../../../redux/slices/class/registration'
import { getRegistrationDetail } from '../../../services/class/e-learning-registration'

import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledLayoutBox } from './Styled'
import axios from 'axios'
import { eLearnintCertificateDownload } from 'src/utils/apiPath'
import { replaceNewLine } from '../../../utils/lib'

export const LINE_OPTION = {
  MULTILINE: 'multiline',
}

export const fetchDataRegistration = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  const result = await dispatch(getRegistrationDetail({ uuid }))
  dispatch(setRegistrationData(_.get(result, 'payload.data', null)))
  dispatch(stopLoading())
}

export const LayoutPreview = ({ version }) => {
  let num = 0
  return (
    <StyledLayoutBox
      url={`${window.__env__.REACT_APP_FILE_URL}${version.eCertificationBackground?.imageKey}`}
    >
      {_.map(version.layout.layout, (l, key) => {
        if (l.type === LINE_OPTION.MULTILINE) {
          return (
            <div key={`layout_box_${key}`} style={{ display: 'flex' }}>
              {_.map(l.layout, (li, n) => {
                if (l.courseName) {
                  const inline =
                    num <= 1
                      ? { ...l.style, marginTop: '120px' }
                      : { ...l.style, marginTop: '40px' }
                  return (
                    <Typography
                      key={`layout_box_n_${n}`}
                      sx={{
                        ...inline,
                        ...li.style,
                        lineHeight: '100% !important',
                      }}
                    >
                      {li.text}
                    </Typography>
                  )
                }
                return (
                  <Typography
                    key={`layout_box_n_${n}`}
                    sx={{
                      ...l.style,
                      ...li.style,
                      lineHeight: '100% !important',
                    }}
                  >
                    {li.text}
                  </Typography>
                )
              })}
            </div>
          )
        }

        let loopFor = 0
        if (!_.isEmpty(l.text)) {
          for (const replace of replaceNewLine(l.text).split('\\n')) {
            if (!_.isEmpty(replace)) loopFor++
          }
        }
        num = loopFor

        return (
          <Box
            key={`layout_list_${key}-${version.id}`}
            sx={{ ...l.style, lineHeight: '100% !important' }}
          >
            {l.text}
          </Box>
        )
      })}
    </StyledLayoutBox>
  )
}

export const onDownload = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  await axios
    .get(`${eLearnintCertificateDownload}/${uuid}`, { responseType: 'blob' })
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `e-certification.pdf`)
      document.body.appendChild(link)
      link.click()
    })
    .catch(() => dispatch(stopLoading()))
    .finally(() => dispatch(stopLoading()))
}
