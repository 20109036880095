import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'
import { StyledDetailCard } from '../../../components/Styled'

export const StyledRow = styled(Row)(({ theme }) => ({
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: { gap: 10 },
}))

export const StyledCard = styled(StyledDetailCard, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ theme, fullWidth }) => ({
  flexDirection: 'column',
  gap: 0,
  [theme.breakpoints.down('lg')]: {
    width: fullWidth ? '100%' : 'calc(50% - 8px)',
  },
  [theme.breakpoints.down('sm')]: {
    gap: 0,
    padding: fullWidth ? theme.spacing(2) : `6px ${theme.spacing(1)}`,
    '& *': { height: 24, lineHeight: '24px' },
  },
}))
