import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ArrowForward from '@mui/icons-material/ArrowForward'
import {
  E_LEARNING_LEARNER_RESULT,
  E_LEARNING_LEARNER_RESULT_TH,
} from 'src/constants/eLearning'
import { path } from 'src/constants/path'
import StatusClass, {
  Dot,
} from '../../RegistrationDetail/ClassMenu/StatusClass'
import { TextContainer } from '../../RegistrationDetail/ClassMenu/Styled'
import { StyledImage } from '../../RegistrationDetail/Styled'
import { StyledColumn, StyledContainer, StyledMiniCard } from './Styled'
import ClassProgress from './ClassProgress'

const ClassDetailCard = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const data = useSelector((state) => state.registration.data, shallowEqual)
  const cStatus = _.get(data, 'classLearnerStatus', '')
  const classStatus = _.get(data, 'classStatus', '')
  const classStatusText = _.get(data, 'classStatusTH', '-')
  const learnerResult = _.get(
    data,
    'eLearningCourseBatchLearner.learnerResult',
    ''
  )
  const isPass =
    learnerResult === E_LEARNING_LEARNER_RESULT.COMPLETE ||
    learnerResult === E_LEARNING_LEARNER_RESULT.PASS
  const color = isPass ? 'success.main' : 'error.main'
  const canGoToResult =
    _.get(data, 'isHaveCert', false) && classStatus === 'TRAINING_CLOSED_PASS'

  return (
    <StyledContainer>
      <ClassProgress />

      <StyledColumn>
        <MiniCard
          status={cStatus}
          img="status-class-icon.png"
          title="สถานะคลาส"
        >
          <StatusClass status={classStatus} classStatusText={classStatusText} />
        </MiniCard>
        <MiniCard
          status={cStatus}
          img="grade-icon.png"
          title="ผลการเรียน"
          onClick={
            canGoToResult
              ? () => history.push(`${path.myClassELearning}/grades/${uuid}`)
              : undefined
          }
        >
          {_.isEmpty(learnerResult) ? (
            <Typography color="text.lightGray">ยังไม่มีผล</Typography>
          ) : (
            <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Dot color={color} />
              {E_LEARNING_LEARNER_RESULT_TH[learnerResult]}
            </Typography>
          )}
        </MiniCard>
      </StyledColumn>
    </StyledContainer>
  )
}

export default ClassDetailCard

export const MiniCard = ({ status, img, title, onClick, children }) => {
  return (
    <StyledMiniCard status={status}>
      <TextContainer>
        <StyledImage src={`/image/class/${img}`} />
        <Typography variant="h6" color="primary">
          {title}
        </Typography>

        {onClick && (
          <IconButton
            color="primary"
            sx={{ marginLeft: 'auto' }}
            data-testid="grade"
            onClick={onClick}
          >
            <ArrowForward />
          </IconButton>
        )}
      </TextContainer>
      <TextContainer paddingLeft="40px">{children}</TextContainer>
    </StyledMiniCard>
  )
}
