import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { DisabledInput, StepTitle } from '..'
import BatchCalendar from './BatchCalendar'
import { StyledContent, StyledDivider, StyledWrapper } from './Styled'
import { handleBatchPeriod } from './events'

const Step1 = () => {
  const batch = useSelector(
    (state) => state.eLearningEnroll.body.batch,
    shallowEqual
  )
  const periodStartStr = useMemo(
    () => handleBatchPeriod(batch, 'periodStart'),
    [batch]
  )
  const periodEndStr = useMemo(
    () => handleBatchPeriod(batch, 'periodEnd'),
    [batch]
  )
  const isLg = isMediaWidthMoreThan('lg')

  return (
    <StyledContent>
      <StepTitle label="เลือกรอบอบรมที่ต้องการ" />
      {!isLg && <StyledDivider />}

      <StyledWrapper>
        <BatchCalendar />
        {isLg && <Divider orientation="vertical" flexItem />}
        <Column sx={{ alignItems: 'space-between', gap: 2 }}>
          <Column gap={2}>
            <DisabledInput
              label="วันที่เริ่มอบรม"
              placeholder="กรุณาเลือกจากปฏิทิน"
              value={periodStartStr}
            />
            <DisabledInput
              label="วันที่จบอบรม"
              placeholder="กรุณาเลือกจากปฏิทิน"
              value={periodEndStr}
            />
            <Typography variant="body2" color="error">
              ต้องเรียนให้จบภายในช่วงวันที่จบอบรม
            </Typography>
          </Column>
        </Column>
      </StyledWrapper>
    </StyledContent>
  )
}

export default Step1
