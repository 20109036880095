import { closeDialog, setTime } from 'src/redux/slices/eLearning/learning'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { putResetCourseLogLearnerPerLesson } from 'src/services/eLearning/learning'
import { handleCreateLogClick } from '../../ClickDialog/events'
import { getLearningState } from '../../events'

export const handleCountdown = (uuid, timer) => async (dispatch) => {
  const {
    time,
    clickDialog: { isDialogOpen },
  } = getLearningState()
  const { mins, secs } = time
  let newTime = { mins, secs }
  if (mins === 0 && secs === 0) {
    clearInterval(timer)
  } else if (secs === 0) {
    newTime.mins = mins - 1
    newTime.secs = 59
  } else {
    newTime.secs = secs - 1
  }
  dispatch(setTime(newTime))

  // time out
  if (mins === 0 && secs === 1) {
    if (isDialogOpen) await dispatch(handleCreateLogClick(false))
    dispatch(startLoading())
    await dispatch(handleResetLogPerLesson(uuid))
    dispatch(stopLoading())
    dispatch(closeDialog())

    window.location.href = `/e-learning/course/${uuid}`
  }
}

export const handleResetLogPerLesson = () => async (dispatch) => {
  const { eLearningCourseBatchLearner } = getLearningState()
  const body = { eLearningCourseBatchLearner }
  await dispatch(putResetCourseLogLearnerPerLesson(body))
}
