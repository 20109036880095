import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import styled from '@mui/system/styled'

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDrawer',
})(({ theme, isDrawer }) => ({
  width: '100%',
  maxWidth: 286,
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    display: isDrawer ? 'block' : 'none',
  },
}))

export const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-valueLabel': {
    borderRadius: theme.spacing(0.5),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
  },
  '& .MuiSlider-valueLabelLabel': { fontSize: '18px' },
}))
