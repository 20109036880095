import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import styled from '@mui/system/styled'

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(({ theme }) => ({
  height: '100vh',
  '& .MuiDrawer-paper': {
    width: 640,
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}))

export const BoxTableContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 285px)',
  overflowY: 'auto',
  overflowX: 'hidden',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '2px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.text?.secondary,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.secondary?.contrast,
    boxShadow: 'inset 1px 0px 0px #DEDEDE',
  },
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 200px)',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 270px)',
  },
}))

export const BoxHeader = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: 60,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 20,
  },
}))

export const BoxButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  padding: 60,
  paddingTop: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(4),
    padding: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    padding: 20,
  },
}))
