import _ from 'lodash'

export const handleSelector = (state, keyProps) => {
  const { keyQuestion, keySub } = keyProps
  const question = state.eEvaluation.questions.find(
    (item) => item.key == keyQuestion
  )

  if (!keySub) {
    return {
      url: _.get(question, 'url', null),
      sequence: _.get(question, 'sequence', 0),
      title: _.get(question, 'title', ''),
      type: _.get(question, 'type', 'SINGLE'),
      setTypeOfAnswers: _.get(question, 'setTypeOfAnswers', false),
      typeOfAnswers: _.get(question, 'typeOfAnswers', 'EQUAL'),
      numberOfAnswers: _.get(question, 'numberOfAnswers', 0),
      mediaType: _.get(question, 'mediaType', null),
    }
  }

  const choice = question.choices.find(
    (item) => 
      item.nextQuestionType === 'NEXT_SUB' &&
      item.nextSubQuestionSequence == keySub
  )
  const subQuestion = _.get(choice, 'subQuestions', {})

  return {
    url: _.get(subQuestion, 'url', null),
    sequence: _.get(question, 'sequence', 0),
    subSequence: _.get(subQuestion, 'subSequence', 0),
    title: _.get(subQuestion, 'title', ''),
    type: _.get(subQuestion, 'type', 'SINGLE'),
    setTypeOfAnswers: _.get(subQuestion, 'setTypeOfAnswers', false),
    typeOfAnswers: _.get(subQuestion, 'typeOfAnswers', 'EQUAL'),
    numberOfAnswers: _.get(subQuestion, 'numberOfAnswers', 0),
    mediaType: _.get(subQuestion, 'mediaType', null),
    isSub: true,
  }
}
