import { store } from 'src/App'
import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  getCollectionById,
  postLikeCollection,
} from 'src/services/eLearning/collection'

export const fetchCollectionDetail = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(getCollectionById(uuid))
  dispatch(stopLoading())
}

export const handleLikeCollection = () => (dispatch) => {
  const { data } = store.getState().eLearningCollection
  const payload = {
    eLearningCollection: { id: data.id, uuid: data.uuid },
    isLike: !_.get(data, 'isLike', false),
  }
  dispatch(postLikeCollection(payload))
}
