import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Grid } from '@mui/material'
import TextField from './TextField'
import Select from './Select'
import { prefixOptions } from '../constant'
import { StyledDivider } from './Styled'
import DateSelect from './DateSelect'
import { Column } from 'src/components/Layout/Styled'
import UploadFileInput from './UploadFileInput'
import ZipInput from './ZipInput'
import UploadMultipleFileInput from './UploadMultipleFileInput'

const InformationItem = ({ index }) => {
  const {
    editable,
    genderOptions,
    qualificationOptions,
    tambonOptions,
    amphureOptions,
    provinceOptions,
    otherDocConfig,
  } = useSelector(
    (state) => ({
      editable: state.eExamination.examiners[index].editable,
      genderOptions: state.eExamination.options.genderOptions,
      qualificationOptions: state.eExamination.options.qualificationOptions,
      tambonOptions: state.eExamination.options.tambonOptions[index],
      amphureOptions: state.eExamination.options.amphureOptions[index],
      provinceOptions: state.eExamination.options.provinceOptions[index],
      otherDocConfig: state.eExamination.otherDocConfig,
    }),
    shallowEqual
  )
  return (
    <Column sx={{ rowGap: 3 }}>
      <Grid container spacing={3}>
        <TextField
          index={index}
          obj="unitName"
          label="ชื่อหน่วยงาน"
          isDisabled={true}
        />
        <TextField
          index={index}
          obj="unitNumber"
          label="รหัสหน่วยงาน"
          isDisabled={true}
        />
        <StyledDivider />
        <Select
          index={index}
          obj="prefix"
          label="คำนำหน้าชื่อ"
          options={prefixOptions}
          md={1.5}
          sm={12}
        />
        <TextField
          index={index}
          obj="firstNameTH"
          label="ชื่อผู้เข้าสอบ"
          isDisabled={!editable.isEditName}
          md={4.5}
          sm={6}
        />
        <TextField
          index={index}
          obj="lastNameTH"
          label="นามสกุลผู้เข้าสอบ"
          isDisabled={!editable.isEditSurname}
          sm={6}
        />
        <TextField
          index={index}
          obj="idCardNo"
          label="เลขประจำตัวประชาชน 13 หลัก"
          isDisabled={!editable.isEditIdCardNo}
        />
        <TextField
          index={index}
          obj="mobileNo"
          label="หมายเลขโทรศัพท์มือถือ"
          isDisabled={!editable.isEditMobile}
        />
        <TextField index={index} obj="email" label="E-mail" isDisabled={true} />
      </Grid>
      <Grid container spacing={3}>
        <DateSelect
          index={index}
          obj="birthDate"
          label="วัน เดือน ปี เกิด"
          disabled={!editable.isEditBirthDate}
        />
        <Select
          index={index}
          obj="gender"
          label="เพศ"
          options={genderOptions}
          isDisabled={true}
        />
        <StyledDivider />
      </Grid>
      <Grid container spacing={3}>
        <ZipInput index={index} />
        <Select
          index={index}
          obj="province"
          label="จังหวัด"
          isDisabled={true}
          options={provinceOptions}
        />
        <Select
          index={index}
          obj="amphure"
          label="อำเภอ/เขต"
          isDisabled={true}
          options={amphureOptions}
        />
        <Select
          index={index}
          obj="tambon"
          label="ตำบล/แขวง"
          isDisabled={true}
          options={tambonOptions}
        />
        <TextField
          index={index}
          obj="address"
          label="รายละเอียดที่อยู่"
          sm={12}
        />
        <StyledDivider />
      </Grid>
      <Grid container spacing={3}>
        <TextField
          index={index}
          obj="institution"
          label="สถานศึกษา (มหาวิทยาลัย)"
          sm={12}
        />
        <TextField
          index={index}
          obj="between"
          label="ระหว่างปี"
          required={false}
        />
        <Select
          index={index}
          obj="qualification"
          label="วุฒิการศึกษา"
          options={qualificationOptions}
        />
        <TextField index={index} obj="branch" label="สาขา" />
        <TextField
          index={index}
          obj="gpa"
          label="เกรดเฉลี่ย"
          required={false}
        />
      </Grid>
      <Grid container spacing={3}>
        <UploadFileInput index={index} />
      </Grid>
      {otherDocConfig && otherDocConfig?.config?.status === 'ACTIVE' ? (
        <Grid container spacing={3}>
          <UploadMultipleFileInput index={index} />
        </Grid>
      ) : (
        ''
      )}
    </Column>
  )
}

export default InformationItem
