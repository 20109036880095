import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import SortMenu from 'src/components/Filter/SortMenu'
import ExpandedMenu from './ExpandedMenu'
import PeriodSliderMenu from 'src/components/Filter/PeriodSliderMenu'
import { StyledBox } from './styled'
import { handleChangeSort, handleChangeSlider, getFilter } from './events'
import { handleSearch } from 'src/redux/slices/eLearning/collection'

const Filter = ({ isDrawer }) => {
  const { filter, sortByList } = useSelector(
    (state) => ({
      filter: _.get(state.eLearningCollection, 'filter', {}),
      sortByList: _.get(state.eLearningCollection.filterData, 'sortBy', []),
      sortBy: getFilter(state, 'sortBy', ''),
      pathHourFrom: getFilter(state, 'pathHourFrom', []),
      pathHourTo: getFilter(state, 'pathHourTo', []),
    }),
    shallowEqual
  )
  const { sortBy, pathHourFrom, pathHourTo } = filter
  const dispatch = useDispatch()
  return (
    <StyledBox isDrawer={isDrawer}>
      <SortMenu
        sortBy={sortBy}
        list={sortByList}
        onChange={(e) => dispatch(handleChangeSort(e))}
      />

      <ExpandedMenu field="myCollection" label="Collection ของฉัน" />
      <ExpandedMenu field="pathHourTo" label="ความยาวหลักสูตร">
        <PeriodSliderMenu
          hourFrom={pathHourFrom}
          hourTo={pathHourTo}
          onChange={(e, value) => dispatch(handleChangeSlider(value))}
          onChangeCommitted={() => dispatch(handleSearch())}
        />
      </ExpandedMenu>
      <ExpandedMenu field="acquiredSkill" label="ระดับหลักสูตร" />
      <ExpandedMenu field="productType" label="ทักษะที่ได้รับจากหลักสูตร" />
    </StyledBox>
  )
}

export default Filter
