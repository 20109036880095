import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { Column } from 'src/components/Layout/Styled'
import Template from 'src/components/Template'
import ContentNoClass from 'src/components/ContentNoClass'
import ELearningCard from 'src/components/ELearningCard'
import { getELearningFilter } from 'src/services/eLearning/list'
import { StyledGrid } from '../Collection/List/styled'
import Header from '../components/Header'
import {
  StyledCardBox,
  StyledContainer,
  StyledLoading,
  StyledRowContainer,
  StyledWrapper,
} from '../components/Styled'
import { MobileHeader, TabletDesktopHeader } from '../components'
import { SECTION_KEY } from '../model'
import Filter from './Filter'
import ListHeader from './ListHeader'
import { fetchLearningPathList, handleLoadMore, handleSeeMore } from './events'

const LearningPath = () => {
  const dispatch = useDispatch()
  const { isLoadMore, learningPaths, filterBody, totalLearningPath } =
    useSelector((state) => state.eLearningLearningPathList, shallowEqual)
  const triggerProps = [
    filterBody.order,
    filterBody.sortBy,
    filterBody.learningPathStatus,
    filterBody.myLearningPath,
    filterBody.acquiredSkill,
    filterBody.productType,
    filterBody.startDate,
    filterBody.finishDate,
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getELearningFilter())

    window.addEventListener('scroll', () => dispatch(handleLoadMore()))
    return () => {
      window.removeEventListener('scroll', () => dispatch(handleLoadMore()))
    }
  }, [])

  useEffect(() => {
    dispatch(fetchLearningPathList(filterBody))
  }, [...triggerProps])

  useEffect(() => {
    dispatch(handleSeeMore(filterBody))
  }, [filterBody.page])

  return (
    <StyledWrapper>
      <Template maxWidth={1440}>
        <MobileHeader
          keySection={SECTION_KEY.LEARNING_PATH}
          total={totalLearningPath}
        />
        <Header title="เส้นทางการเรียนรู้ทั้งหมด" />
        <StyledContainer>
          <ListHeader />
          <StyledRowContainer>
            <Filter />
            <Column sx={{ gap: 3 }}>
              <TabletDesktopHeader
                keySection={SECTION_KEY.LEARNING_PATH}
                total={totalLearningPath}
              />
              <StyledCardBox>
                <Grid container spacing={2}>
                  {learningPaths.length > 0 ? (
                    learningPaths.map((course, index) => (
                      <StyledGrid key={index}>
                        <ELearningCard
                          course={course}
                          keySection={SECTION_KEY.LEARNING_PATH}
                        />
                      </StyledGrid>
                    ))
                  ) : (
                    <ContentNoClass />
                  )}
                </Grid>
                {isLoadMore && <StyledLoading />}
              </StyledCardBox>
            </Column>
          </StyledRowContainer>
        </StyledContainer>
      </Template>
    </StyledWrapper>
  )
}

export default LearningPath
