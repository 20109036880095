import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    padding: '0 36px',
  },
}))
