import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { eLearningBanner, eLearningCourseRecommend, eLearningList } from 'src/utils/apiPath'

export const postELearningFilter = createAsyncThunk(
  'eLearningList/postELearningFilter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningList, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getELearningCoursesRecommend = createAsyncThunk(
  'eLearningList/getELearningCourse',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningCourseRecommend)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getELearningBanner = createAsyncThunk(
  'eLearningList/getELearningBanner',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningBanner)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)