import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import useLocalStorage from '@rehooks/local-storage'
import { useDispatch } from 'react-redux'
import { isMediaWidthMoreThan } from '../../utils/lib'
import { setLogin } from '../../redux/slices/utils/userSlice'
import {
  StyledContainer,
  StyledCard,
  StyledCardLeftContainer,
  StyledCardRightContainer,
  TextContainer,
  StyledStaffContainer,
  RightContainer,
  LeftContainer,
  BoxDisplay,
  BoxImage,
  LoginStyledContainer,
} from './Styled'
import { fetchLoginContent } from './events'
import { useLocation } from 'react-router-dom'
import { themeColor } from 'src/utils/themeData'
import CustomButton from '../../components/Button'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LoginSlider from './LoginSlider'

const Login = () => {
  const [, setType] = useLocalStorage('type')
  const dispatch = useDispatch()
  const isNotSm = isMediaWidthMoreThan('sm')
  const headSize = isNotSm ? 'h3' : 'h4'
  const titleSize = isNotSm ? 'h4' : 'h5'
  const descSize = isNotSm ? 'body1' : 'body2'

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const param = searchParams.get('previousPath')

  const [stateDms, setStateDms] = useState(null)

  useEffect(() => {
    if (param && param.includes('/dms/')) {
      setStateDms(true)
    } else {
      setStateDms(false)
    }
  }, [param])

  useEffect(() => {
    if (param) {
      window.localStorage.setItem('previousPath', `${param}`)
    }
  }, [param])

  const saveCurrentRealm = (type) => {
    dispatch(setLogin())
    setType(type)
    setTimeout(() => {
      location.href = '/logging-in'
    }, 100)
  }

  useEffect(() => {
    dispatch(fetchLoginContent())
  }, [])

  return (
    <>
      {stateDms === true && (
        <LoginStyledContainer>
          <Box
            display={{ xs: 'inline-block', sm: 'flex' }}
            gap={4}
            sx={{
              width: { xs: '100%', sm: 'auto' },
            }}
            justifyContent={'space-between'}
          >
            <Box
              display={'flex'}
              alignItems="center"
              justifyContent={'normal'}
              sx={{
                backgroundImage:
                  'linear-gradient(to bottom right, #4B4DEB 0%, #9FBEAF 100%)',
                color: '#FFFFFF',
                width: '100%',
                p: { xs: 1, sm: 3 },
                height: { xs: '50vh', sm: 'calc(110vh - 160px)' },
              }}
            >
              <Box sx={{ width: '100%' }} textAlign={'center'}>
                <Box
                  sx={{
                    backgroundColor: themeColor.primary.secondary,
                    height: { xs: '75px', sm: '100px' },
                    width: { xs: '75px', sm: '100px' },
                    p: 2,
                    borderRadius: '50%',
                    margin: 'auto',
                    mb: 2,
                    pt: 2.5,
                  }}
                >
                  <img
                    alt={'AMS'}
                    width={'100%'}
                    src={`/image/home/step/2/done.svg`}
                  />
                </Box>

                <Typography variant="h4" sx={{ width: 'auto' }}>
                  เข้าสู่ระบบแบบผู้สมัครใหม่
                </Typography>
                <Typography
                  sx={{ pt: 1, pb: 2, height: '60px' }}
                  variant="body1"
                >
                  สำหรับผู้สมัครที่ประสงค์เป็นตัวแทนบริษัทกรุงไทย-แอกซ่า
                  ประกันชีวิต
                </Typography>
                <CustomButton
                  data-testid={'btn-login-prospect'}
                  variant="contained"
                  sx={{ width: '100%', maxWidth: '250px' }}
                  onClick={() => saveCurrentRealm('Prospect')}
                  size="large"
                >
                  เข้าสู่ระบบแบบผู้สมัครใหม่
                </CustomButton>
                <Box sx={{ mt: 2 }}>
                  <Link
                    target="_blank"
                    href={`${window.__env__.REACT_APP_KEY_URL}/auth/realms/ams/login-actions/reset-credentials?client_id=ams&previousPath=/dms`}
                    sx={{ color: '#FFFFFF' }}
                  >
                    ลืมรหัสผ่าน?
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box
              display={'flex'}
              alignItems="center"
              sx={{
                width: '100%',
                p: 3,
                height: { xs: '50vh', sm: 'calc(110vh - 200px)' },
              }}
            >
              <Box sx={{ width: '100%' }} textAlign={'center'}>
                <Box
                  sx={{
                    backgroundColor: themeColor.primary.secondary,
                    height: { xs: '75px', sm: '100px' },
                    width: { xs: '75px', sm: '100px' },
                    p: 2,
                    borderRadius: '50%',
                    margin: 'auto',
                    mb: 2,
                    pt: 2.5,
                  }}
                >
                  <img
                    alt={'AMS'}
                    width={'100%'}
                    src={`/image/home/step/5/done.svg`}
                  />
                </Box>
                <Typography color={'primary.secondary'} variant="h4">
                  เข้าสู่ระบบแบบตัวแทน
                </Typography>
                <Typography
                  sx={{ pt: 1, pb: 2, height: '60px' }}
                  variant="body1"
                >
                  สำหรับตัวแทนบริษัทกรุงไทย แอกซ่า ประกันชีวิต เท่านั้น
                </Typography>
                <CustomButton
                  data-testid={'btn-login-ma'}
                  variant="contained"
                  sx={{ width: '100%', maxWidth: '200px' }}
                  onClick={() => saveCurrentRealm('Agent')}
                  size="large"
                >
                  เข้าสู่ระบบแบบตัวแทน
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </LoginStyledContainer>
      )}
      {stateDms === false && (
        <StyledContainer>
          <LeftContainer>
            <LoginSlider />
          </LeftContainer>
          <RightContainer>
            <Box>
              <Typography variant={headSize} color="primary.main">
                เข้าสู่ระบบ
              </Typography>
              <Typography variant={headSize} color="primary.main">
                KTAXA Academy (AMS)
              </Typography>
            </Box>

            <StyledCard gap={2}>
              <StyledCardLeftContainer>
                <img
                  alt="agent-icon"
                  src="/icon/agent-icon.svg"
                  style={{ height: '50px', width: 'auto' }}
                />
                <TextContainer>
                  <Typography variant={titleSize} color="primary.main">
                    สำหรับตัวแทน KTAXA
                  </Typography>
                  <Box>
                    <Typography variant={descSize} color="primary.main">
                      ทุกช่องทางการจัดจำหน่าย
                    </Typography>
                    <Typography variant={descSize} color="primary.main">
                      (ใช้ username / password ของ AZ)
                    </Typography>
                  </Box>
                </TextContainer>
              </StyledCardLeftContainer>
              <StyledCardRightContainer content="flex-end">
                <Button
                  size="s"
                  variant="contained"
                  color="primary"
                  onClick={() => saveCurrentRealm('Agent')}
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    padding: '8px 16px',
                    width: 113,
                    height: '40px',
                  }}
                >
                  <Typography variant="button">เข้าสู่ระบบ</Typography>
                </Button>
              </StyledCardRightContainer>
            </StyledCard>

            <StyledCard sx={{ marginTop: '20px', gap: 2 }}>
              <StyledCardLeftContainer>
                <BoxImage>
                  <img
                    alt="prospect-icon"
                    src="/icon/prospect-icon.svg"
                    style={{ height: '50px', width: 'auto' }}
                  />
                  <BoxDisplay isMobile>
                    <Link
                      target="_blank"
                      href={`${window.__env__.REACT_APP_KEY_URL}/auth/realms/ams/login-actions/reset-credentials?client_id=ams`}
                      variant="body1"
                      color="primary.main"
                    >
                      ลืมรหัสผ่าน?
                    </Link>
                  </BoxDisplay>
                </BoxImage>
                <TextContainer>
                  <Typography variant={titleSize} color="primary.main">
                    สำหรับตัวแทน<u>รอรหัส</u>
                  </Typography>
                </TextContainer>
              </StyledCardLeftContainer>
              <StyledCardRightContainer content="space-between">
                <BoxDisplay>
                  <Link
                    target="_blank"
                    href={`${window.__env__.REACT_APP_KEY_URL}/auth/realms/ams/login-actions/reset-credentials?client_id=ams`}
                    variant="body1"
                    color="primary.main"
                  >
                    ลืมรหัสผ่าน?
                  </Link>
                </BoxDisplay>

                <Button
                  size="s"
                  variant="outlined"
                  color="primary"
                  onClick={() => saveCurrentRealm('Prospect')}
                  endIcon={<ArrowForwardIcon />}
                  sx={{ padding: '8px 16px', width: '111px', height: '40px' }}
                >
                  <Typography variant="button">เข้าสู่ระบบ</Typography>
                </Button>
              </StyledCardRightContainer>
            </StyledCard>
            <StyledStaffContainer>
              <Link
                variant="body1"
                color="primary.main"
                onClick={() => saveCurrentRealm('Staff')}
              >
                STAFF คลิกที่นี่
              </Link>
            </StyledStaffContainer>
          </RightContainer>
        </StyledContainer>
      )}
    </>
  )
}

Login.templateLayout = 'login'

export default Login
