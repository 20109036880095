import React from 'react'
import Typography from '@mui/material/Typography'
import {
  StyledContainer,
  StyledResultContent,
} from 'src/components/Preview/Result/Styled'

const TimeUp = () => {
  return (
    <StyledContainer>
      <StyledResultContent>
        <img src="/icon/main_error.svg" />
        <Typography variant="h3" color="error">
          หมดเวลาทำแบบทดสอบ
        </Typography>
        <Typography
          variant="h5"
          color="text.secondary"
          sx={{ width: 246, textAlign: 'center' }}
        >
          หมดเวลาในการทำแบบทดสอบ โปรดรอซักครู่
        </Typography>
      </StyledResultContent>
    </StyledContainer>
  )
}

export default TimeUp
