import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { BADGE_MODEL } from '../model'
import { StyledChip } from './Styled'

const Chip = ({ variant, color, field, children }) => {
  const textColor = variant === 'contained' ? 'text.white' : color
  return (
    <StyledChip
      variant={variant}
      color={color}
      label={
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          <img src={`/icon/${BADGE_MODEL[field].icon}`} />
          <Typography
            variant="body3b"
            color={textColor}
            sx={{ lineHeight: '18px', minWidth: '69px' }}
          >
            {children}
          </Typography>
        </Box>
      }
    />
  )
}

export default Chip
