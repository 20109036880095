import styled from '@mui/system/styled'
import { LoadingButton } from '@mui/lab'

export const StyledButton = styled(LoadingButton)(({ theme }) => ({
  height: 40,
  borderRadius: theme.spacing(0.5),
  padding: '8px 16px',
  width: '100%',
  [theme.breakpoints.down('sm')]: { width: '100% !important' },
}))
