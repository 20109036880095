import React from 'react'
import _ from 'lodash'
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined'
import { store } from 'src/App'
import { dialogContentObj } from 'src/modules/ELearning/components/DialogContent/model'
import { setStep } from 'src/redux/slices/eLearning/enroll'
import { postEnrollCourse } from 'src/services/eLearning/enroll'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import { path } from 'src/constants/path'
import { getEnrollState } from '../events'

export const handleClickBack = (isVerification) => {
  if (isVerification) {
    const uuid = _.get(getEnrollState().data, 'uuid', '')
    window.location.href = `${path.eLearning}/course/${uuid}`
  } else {
    const activeStep = getEnrollState().activeStep
    store.dispatch(setStep(activeStep - 1))
  }
}

export const handleClickNext = () => {
  const activeStep = getEnrollState().activeStep
  store.dispatch(setStep(activeStep + 1))
}

export const handleDisableBtn = () => {
  const state = getEnrollState()
  const activeStep = state.activeStep
  const body = state.body
  if (activeStep === 0) return _.isNil(body.batch)
  if (activeStep === 1)
    return _.isNil(body.idCardImage) || _.isNil(body.faceImage)
}

export const handleSubmitEnroll = async () => {
  store.dispatch(startLoading())
  const state = getEnrollState()
  const body = {
    eLearningCourse: state.data,
    eLearningCourseBatch: state.body.batch,
  }
  const response = await store.dispatch(postEnrollCourse(body))
  if (_.isEmpty(response.error)) {
    handleEnrollSuccess(response)
  }
  store.dispatch(stopLoading())
}

export const handleEnrollSuccess = (response) => {
  const { data, body } = getEnrollState()
  store.dispatch(
    openDialog({
      ...dialogContentObj,
      title: 'คุณยังเหลืออีก 1 ขั้นตอน',
      message: ['ขณะนี้ท่านสามารถยืนยันตัวตนเพื่อรับสิทธิ์เข้าอบรม'],
      buttonLeft: {
        label: 'ไว้ภายหลัง',
        handleClick: () => {
          store.dispatch(closeDialog())
          window.location.href = `/e-learning/course/${data.uuid}`
        },
      },
      buttonRight: {
        label: 'ยืนยันตัวตน',
        endIcon: <ArrowForwardOutlined />,
        handleClick: () => {
          store.dispatch(closeDialog())
          const batchLearnerUuid = _.get(response.payload, 'data.uuid', '')
          window.location.href = `${path.eLearningVerification}/${body.batch.uuid}?learner=${batchLearnerUuid}`
        },
      },
    })
  )
}
