import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Slider from '@mui/material/Slider'
import styled from '@mui/system/styled'

export const ControlWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  height: '100%',
  padding: `0 ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  zIndex: 1,
  opacity: 0,
  transition: 'ease 0.3s opacity',
}))

export const FloatingControlStyled = styled(Box)(() => ({
  position: 'absolute',
  top: 'calc(50% - 48px)',
  left: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1,
  opacity: 1,
  '& svg': { fontSize: '5rem' },
}))

export const ControlContainerStyled = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(45, 45, 45, 0.9)',
  position: 'absolute',
  bottom: '10%',
  right: 0,
  left: '50%',
  transform: 'translate(-50%)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(3),
  padding: `0 ${theme.spacing(2)}`,
  borderRadius: theme.spacing(2),
  zIndex: 1,
  width: '95%',
  height: 72,
  span: { color: theme.palette?.text?.white },
  svg: { fontSize: '1.5rem' },
  '::backdrop': {
    position: 'fixed',
    zIndex: 99999999,
  },
  [theme.breakpoints.down('sm')]: { height: 45, gap: theme.spacing(1) },
}))

export const ProgressBoxStyled = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}))

export const VideoProgressStyled = styled(Slider)(({ theme }) => ({
  height: 20,
  color: theme.palette?.text?.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiSlider-thumb': {
    height: 14,
    width: 14,
    backgroundColor: theme.palette?.text?.white,
    '&:focus, &:hover, &:active': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-track': {
    height: 5,
    borderRadius: 4,
    width: '100%',
  },
  '& .MuiSlider-rail ': {
    height: 5,
    borderRadius: 4,
  },
}))

export const ControlBoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const InnerControlStyled = styled(Box)(({ theme }) => ({
  width: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  color: theme.palette?.text?.white,
}))

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette?.text?.white,
  [theme.breakpoints.down('sm')]: { img: { width: 18, height: 18 } },
}))

export const VolumeStyled = styled(Slider)(({ theme }) => ({
  width: 100,
  color: theme.palette?.text?.white,
}))

export const FloatingButtonStyled = styled(IconButtonStyled)(
  ({ left, right }) => ({
    position: 'absolute',
    left: left ? left : 'unset',
    right: right ? right : 'unset',
  })
)

export const FloatingBoxStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}))

export const VideoWrapper = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}))

export const StyledReloadButton = styled((props) => (
  <Button {...props} variant="contained" color="white" size="sm" />
))(({ theme }) => ({
  minWidth: 82,
  color: theme.palette?.primary?.main,
  borderRadius: theme.spacing(0.5),
}))
