import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getCheckBatchFailScanCount } from 'src/services/eLearning/detail'
import { setScanAllow, setTime } from 'src/redux/slices/eLearning/detail'
import { getDetailState } from '../../events'

export const handleCountdown = (timer) => async (dispatch) => {
  const { time, data } = getDetailState()
  const { mins, secs } = time
  let newTime = { mins, secs }
  if (mins === 0 && secs === 0) {
    clearInterval(timer)
  } else if (secs === 0) {
    newTime.mins = mins - 1
    newTime.secs = 59
  } else {
    newTime.secs = secs - 1
  }
  dispatch(setTime(newTime))

  // time out
  if (mins === 0 && secs === 1) {
    dispatch(startLoading())
    setTimeout(async () => {
      const uuid = _.get(data, 'eLearningCourseBatchLearner.uuid', '')
      await dispatch(getCheckBatchFailScanCount(uuid))
      dispatch(setScanAllow(true))
      dispatch(stopLoading())
    }, 1500)
  }
}
