import _ from 'lodash'
import { store } from 'src/App'
import { openDialog } from 'src/redux/slices/alertDialog'
import {
  clearAddress,
  setExaminerDetail,
  setImage,
  setOtherDocs,
} from 'src/redux/slices/eExamination'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  getAddress,
  uploadIdCard,
  uploadOtherDoc,
} from 'src/services/eExamination'

function isNumber(value) {
  return /^[0-9]+$/.test(value)
}

export const handleZipInputChange =
  ({ index, value }) =>
  async (dispatch) => {
    const state = store.getState()
    const addressOptions = _.get(
      state.eExamination.options,
      'addressOptions',
      []
    )
    if (isNumber(value) || value === '') {
      if (value.length == 5) {
        dispatch(startLoading())
        await dispatch(getAddress({ index: index, value: value }))
        dispatch(
          setExaminerDetail({ index: index, obj: 'zipCode', value: value })
        )
        dispatch(stopLoading())
      } else {
        if (addressOptions.length > 0) {
          dispatch(clearAddress({ index: index, value: value }))
        }
        dispatch(
          setExaminerDetail({ index: index, obj: 'zipCode', value: value })
        )
      }
    }
  }

export const handleUploadIdCard =
  ({ index, value }) =>
  async (dispatch) => {
    if (value) {
      dispatch(startLoading())
      const body = new FormData()
      let elementId = document.getElementById(`upload-file-${index}`)

      body.append('file', value)
      const getNameImage = _.get(value, 'name', undefined)
      if (!getNameImage) {
        elementId.value = null
        return
      }

      const getExtensionImage = getNameImage.split('.').pop()
      if (!checkTypeFile(getExtensionImage.toString().toLowerCase())) {
        elementId.value = null
        dispatch(openDialog(findObjDialog('uploadProfileErrorType')))
        dispatch(stopLoading())
        return
      }

      const sizeFile = _.get(value, 'size', 0)
      if (sizeFile > 2000000) {
        elementId.value = null
        dispatch(openDialog(findObjDialog('uploadProfileErrorSize')))
        dispatch(stopLoading())
        return
      }

      const result = await dispatch(uploadIdCard(body))
      const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
      if (requestStatus == 'fulfilled') {
        dispatch(
          setImage({
            index: index,
            cardImage: _.get(result, 'payload.key', ''),
            imageName: _.get(value, 'name', undefined),
            fileName: _.get(value, 'name', undefined),
            key: _.get(result, 'payload.key', ''),
            fileSize: (sizeFile / (1024 * 1024)).toFixed(2),
            fileType: getExtensionImage.toString().toLowerCase(),
          })
        )
      }
      dispatch(stopLoading())
    }
  }

export const checkTypeFile = (type) => {
  return type === 'jpg' || type === 'jpeg' || type === 'png' || type === 'pdf'
}

export const findObjDialog = (caseType) => {
  switch (caseType) {
    case 'uploadProfileErrorType':
      return {
        iconType: 'error',
        title: 'นามสกุลไฟล์ไม่ถูกต้อง',
        message: ['ไม่สามารถ Upload ได้', 'เนื่องจากนามสกุลไฟล์ไม่ถูกต้อง'],
        buttonRight: {
          label: 'ตกลง',
        },
      }
    case 'uploadProfileErrorSize':
      return {
        iconType: 'error',
        title: 'ขนาดไฟล์ใหญ่เกินไป',
        message: ['ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB', 'กรุณาลองใหม่อีกครั้ง'],
        buttonRight: {
          label: 'ตกลง',
        },
      }
  }
}

export const getError = (formError, obj) => {
  const error = _.find(formError, (ele) => {
    return ele.path == obj
  })
  return error?.message || ''
}

export const handleDownload = async (title, url, type) => {
  if (type === 'pdf') {
    fetch(url, {
      method: 'get',
      xhrFields: {
        responseType: 'blob',
      },
    })
      .then((res) => res.blob())
      .then((data) => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(data)
        a.href = url
        a.download = `${title}`
        document.body.append(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(url)
      })
  } else {
    const a = document.createElement('a')
    a.href = await toDataURL(url)
    a.download = `${title}`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export const toDataURL = async (url) => {
  const blob = await fetch(url).then((res) => res.blob())
  return URL.createObjectURL(blob)
}

export const handleUploadOtherDoc =
  ({ index, value, documentName }) =>
  async (dispatch) => {
    if (value) {
      dispatch(startLoading())
      const body = new FormData()
      let elementId = document.getElementById(`upload-multiple-file-${index}`)

      body.append('file', value)
      const getNameImage = _.get(value, 'name', undefined)
      if (!getNameImage) {
        elementId.value = null
        return
      }

      const getExtensionImage = getNameImage.split('.').pop()
      if (!checkTypeFile(getExtensionImage.toString().toLowerCase())) {
        elementId.value = null
        dispatch(openDialog(findObjDialog('uploadProfileErrorType')))
        dispatch(stopLoading())
        return
      }

      const sizeFile = _.get(value, 'size', 0)
      if (sizeFile > 2000000) {
        elementId.value = null
        dispatch(openDialog(findObjDialog('uploadProfileErrorSize')))
        dispatch(stopLoading())
        return
      }

      const result = await dispatch(uploadOtherDoc(body))
      const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
      if (requestStatus == 'fulfilled') {
        dispatch(
          setOtherDocs({
            index: index,
            cardImage: _.get(result, 'payload.key', ''),
            imageName: _.get(value, 'name', undefined),
            documentName: documentName,
            fileName: _.get(value, 'name', undefined),
            key: _.get(result, 'payload.key', ''),
            fileSize: (sizeFile / (1024 * 1024)).toFixed(2),
            fileType: getExtensionImage.toString().toLowerCase(),
          })
        )
        elementId.value = null
      }
      dispatch(stopLoading())
    }
  }
