import _ from 'lodash'
import { setPeriod } from 'src/redux/slices/eExamination/announceExaminer/list'
import { convertFormatDateTime } from 'src/utils/lib'

export const handleChangePeriod = (selectedDates) => (dispatch) => {
  const startDate = convertFormatDateTime({
    value: _.get(selectedDates, 'startDate', ''),
    type: 'dateDb',
  })
  const endDate = convertFormatDateTime({
    value: _.get(selectedDates, 'endDate', ''),
    type: 'dateDb',
  })
  dispatch(setPeriod({ startDate, endDate }))
}

export const handleResetPeriod = () => (dispatch) => {
  dispatch(setPeriod({ startDate: '', endDate: '' }))
}
