import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { StyledContainer } from '../../../components/Layout/Styled'
import Breadcrumb from '../../../components/Breadcrumb'
import Template from '../../../components/Template'

import Header from '../Header'
import ClassContent from '../ELearningRegistrationDetail/ClassContent'
import AttendanceHistory from '../ELearningRegistrationDetail/AttendanceHistory'
import {
  CertContainer,
  CertDetailContainer,
  StyledCardBoxPreview,
} from './Styled'
import { fetchDataRegistration, LayoutPreview, onDownload } from './events'

const Grades = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const data = useSelector((state) => state.registration.data, shallowEqual)
  //const rowData = _.get(data, 'courseResult', [])
  const eCertification = _.get(data, 'eCertificationVersion', '')

  const eCertExpireDate = _.get(data, 'expireCertDate', '')
  const isCanDownloadCert = _.get(data, 'isCanDownloadCert', false)
  const eCertText = isCanDownloadCert ? 'ดาวน์โหลดประกาศนียบัตร' : 'หมดอายุแล้ว'

  useEffect(() => {
    dispatch(fetchDataRegistration({ uuid }))
  }, [uuid])

  const listBreadcrumbs = [
    {
      isLink: true,
      color: 'text.secondary',
      label: 'คลาสของฉัน',
      link: '/my-class',
    },
    {
      isLink: false,
      color: 'primary',
      label: 'ประกาศนียบัตร',
    },
  ]

  return (
    <Template>
      <StyledContainer>
        <Breadcrumb list={listBreadcrumbs} />
        <Header isGradeLearning />
        {!_.isEmpty(eCertification) && (
          <CertContainer>
            <StyledCardBoxPreview>
              <LayoutPreview version={eCertification} />
            </StyledCardBoxPreview>
            <CertDetailContainer>
              <Typography variant="h6" color="primary">
                ยินดีด้วย! คุณได้รับประกาศนียบัตร
              </Typography>
              <Button
                startIcon={<CloudDownloadIcon />}
                variant="contained"
                disabled={!isCanDownloadCert}
                onClick={() => dispatch(onDownload(uuid))}
              >
                {eCertText}
              </Button>
              {eCertExpireDate && (
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ margin: '0px auto' }}
                >
                  หมดอายุ: {eCertExpireDate}
                </Typography>
              )}
            </CertDetailContainer>
          </CertContainer>
        )}
        <ClassContent isGradeLearning />
        <AttendanceHistory isGradeLearning />
      </StyledContainer>
    </Template>
  )
}

export default Grades
