export const listStatusText = {
  SUCCESS: '#1CC54E',
  DUPLICATE: '#C91432',
  NOT_AVAILABLE: '#C91432',
  NO_SEAT: '#ED6C02',
  CANNOT_REGISTER: '#C91432',
}

export const addListCells = [
  {
    id: 'fullName',
    label: 'ชื่อ - นามสกุล',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'statusText',
    label: 'สมัครเรียน / สอบ',
    disablePadding: false,
    hideSortIcon: true,
  },
]
