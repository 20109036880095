import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import styled from '@mui/system/styled'

export const InputUpload = styled('input')({
  display: 'none',
})

export const BoxTemplate = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

export const BoxContent = styled(Box)(({ theme }) => ({
  paddingLeft: 60,
  paddingRight: 60,
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: 0,
  },
}))

export const BoxContainer = styled(Box)(({ theme }) => ({
  paddingLeft: 60,
  paddingRight: 60,
  maxHeight: 576,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const BoxFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const BoxTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 576,
  overflowY: 'auto',
  overflowX: 'hidden',
  border: '1px solid rgba(219, 228, 241, 0.6)',
  borderRadius: '2px',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.text?.secondary,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.secondary?.contrast,
    boxShadow: 'inset 1px 0px 0px #DEDEDE',
  },

  [theme.breakpoints.down('sm')]: {
    overflowX: 'auto',
  },
}))

export const BoxTableRow = styled(TableRow)(({ status }) => ({
  background: !status
    ? 'rgba(255, 255, 255, 0.002)'
    : ' rgba(28, 197, 78, 0.08)',
}))
