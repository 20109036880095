import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { setInitialTable } from 'src/redux/slices/table'
import { postPointHistory } from 'src/services/myProfile'
import { headCells, historyEvent } from './model'
import { convertFormatDateTime, convertNumberWithComma } from 'src/utils/lib'

export const handleFetchHistory = (props) => async (dispatch) => {
  dispatch(startLoading())
  const { table } = props
  const { page, limit } = table
  const newPage = page <= 0 ? 1 : page
  const result = await dispatch(
    postPointHistory({ page: newPage, limit: limit })
  )
  const data = _.get(result, 'payload.data.results', [])
  const totalCount = _.get(result, 'payload.data.totalCount', 0)

  dispatch(
    setInitialTable({
      rows: prepareRowsData(data),
      allCount: totalCount,
      headCells: headCells,
      isCheckBox: false,
      isHideSearch: true,
    })
  )
  dispatch(stopLoading())
}

const prepareRowsData = (data) => {
  if (data.length <= 0) return []
  return data.map((item) => {
    let eventData = ''
    let pointData = convertNumberWithComma(item.point)
    if (item.event == historyEvent.INCREASE_FROM_ADMIN) {
      eventData = 'ผู้ดูแลระบบ'
      pointData = '+' + pointData
    } else if (item.event == historyEvent.DECREASE_FROM_ADMIN) {
      eventData = 'ผู้ดูแลระบบ'
      pointData = '-' + pointData
    } else if (item.event == historyEvent.INCREASE_FROM_COURSE) {
      eventData = item?.course?.englishName || item?.eLearningCourse?.name
      pointData = '+' + pointData
    } else {
      eventData = 'แลกรางวัล'
      pointData = '-' + pointData
    }
    return {
      event: eventData,
      historyPoint: pointData,
      updatedAt: convertFormatDateTime({
        value: item.updatedAt,
        type: 'dateTime',
      }),
      point: item.point,
      learningPointRedeemHistory: item.learningPointRedeemHistory || null,
    }
  })
}
