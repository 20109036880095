export const initialState = {
  isSuccess: false,
  isResend: false,
  data: null,
  error: null,
  status: false,
  isApproval: false,
  requestData: [],
  errorText: [],
  idCardNo: null,
  dateOfBirth: null,
  callbackUrl: '',
}
