import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { handleBatchPeriod } from '../Step1/events'
import { SectionLabelIcon, StepTitle } from '..'
import { StyledColumn, StyledDivider } from './Styled'

const Step2 = () => {
  const [user] = useLocalStorage('user')
  const { data, batch } = useSelector(
    (state) => ({
      data: state.eLearningEnroll.data,
      batch: state.eLearningEnroll.body.batch,
    }),
    shallowEqual
  )
  const isNotSm = isMediaWidthMoreThan('md')

  return (
    <Column gap={2} pb={2}>
      <Column gap={2}>
        <StepTitle
          label="กรุณาตรวจสอบข้อมูลสำหรับการสมัครเข้าอบรมให้ครบถ้วน"
          description={
            <>
              หากไม่ถูกต้อง กรุณาคลิก{' '}
              <Link
                href={`${window.__env__.REACT_APP_APP_URL}/location`}
                target="_blank"
              >
                ติดต่อเจ้าหน้าที่
              </Link>
            </>
          }
        />
        {!isNotSm && <StyledDivider />}
      </Column>

      <StyledColumn>
        <SectionLabelIcon label="ข้อมูลผู้เข้าอบรม" icon="ic_person_group" />
        <LabelAndData
          label="ชื่อผู้เข้าอบรม"
          value={`${user.firstNameTH} ${user.lastNameTH}`}
        />
        <LabelAndData label="เลขประจำตัวประชาชน" value={user.idCardNo} />
        <LabelAndData label="อีเมล" value={user.email} />
        <LabelAndData label="หมายเลขโทรศัพท์มือถือ" value={user.mobileNo} />
      </StyledColumn>

      <StyledDivider />

      <StyledColumn>
        <SectionLabelIcon label="รอบอบรมหลักสูตร" icon="ic_graduate" />
        <LabelAndData
          label="ชื่อหลักสูตร"
          value={data.nameForLearner || data.name}
        />
        <LabelAndData
          label="วันที่เริ่มอบรม"
          value={handleBatchPeriod(batch, 'periodStart')}
        />
        <LabelAndData
          label="วันที่จบอบรม"
          value={handleBatchPeriod(batch, 'periodEnd')}
        />
      </StyledColumn>
    </Column>
  )
}

export default Step2

export const LabelAndData = ({ label, value }) => {
  return (
    <Row gap={3}>
      <Typography variant="body1b" color="text.secondary" width={220}>
        {label}
      </Typography>
      <Typography>{value}</Typography>
    </Row>
  )
}
