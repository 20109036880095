import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Grid, TextField } from '@mui/material'
import BasicSelect from './BasicSelect'
import { setZip } from 'src/redux/slices/eExamination'
import { getError, handleZipInputChange } from './event'

const ZipInput = ({ index }) => {
  const dispatch = useDispatch()
  const { value, addressOptions, formError } = useSelector(
    (state) => ({
      value: state.eExamination.examiners[index].zipCode,
      addressOptions: state.eExamination.options.addressOptions[index],
      formError: state.eExamination.formError[index],
    }),
    shallowEqual
  )
  return (
    <Grid item sm={6} xs={12}>
      <BasicSelect
        sx={{
          '& .MuiAutocomplete-inputRoot': { height: 50, background: '#FFFFFF' },
        }}
        labelText="รหัสไปรษณีย์"
        required
        ShowTextError={getError(formError, 'zipCode')}
        textError={getError(formError, 'zipCode')}
        options={addressOptions}
        getOptionLabel={(option) => option.zipCode}
        handleChange={(e, element) =>
          dispatch(setZip({ ...element, index: index }))
        }
        handleInputChange={(e, value) =>
          dispatch(
            handleZipInputChange({ index: index, obj: 'zipCode', value: value })
          )
        }
        inputValue={value || ''}
        renderInput={(params) => (
          <TextField {...params} placeholder="กรุณากรอก" />
        )}
        isHideSort={true}
        noOptionsText={'กรุณากรอกรหัสไปรษณีย์'}
      />
    </Grid>
  )
}

export default ZipInput
