import styled from '@mui/system/styled'
import Card from '@mui/material/Card'
import AccordionSummary from '@mui/material/AccordionSummary'
import Accordion from '@mui/material/Accordion'
import Box from '@mui/material/Box'

export const ColumnCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '14px',
  border: '1px solid #DBE4F1',
  borderRadius: '4px',
  boxShadow: '0px 12px 40px rgba(73, 118, 186, 0.1)',
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'unset',
  margin: 0,
  background: theme.palette.white.main,
  '&.Mui-expanded': { margin: '0 !important' },
  '::before': { opacity: 0 },
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: '22px 16px',
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
  },
}))

export const RowContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '16px 24px',
  borderBottom: '1px solid #DBE4F1',
  alignItems: 'center',
}))

export const Image = styled('img')(({ theme }) => ({
  width: 42,
  height: 42,
  objectFit: 'cover',
  borderRadius: theme.spacing(1),
  marginRight: theme.spacing(1.5),
  border: '2px solid #DBE4F1',
}))
