import React, { useEffect } from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { setExpand } from 'src/redux/slices/eLearning/learning'
import { COURSE_TYPE_TH } from 'src/constants/eLearning'
import { StyledExpandButton, StyledTooltip } from '../Styled'
import LessonLabel from './LessonLabel'
import MobileTabDisplay from './MobileTabDisplay'
import {
  StyledHeader,
  StyledListContainer,
  StyledRightContent,
  StyledDoubleDivider,
  StyledHeaderText,
  StyledListColumn,
  StyledOneLineText,
  StyledProgressPercent,
  StyledDrawer,
} from './Styled'
import { handleProgressChange } from './events'

const RightPanel = ({ setAlertDialog }) => {
  const dispatch = useDispatch()
  const { isExpanded, course, lessons } = useSelector(
    (state) => ({
      isExpanded: state.eLearningLearning.isExpanded,
      course: state.eLearningLearning.course,
      lessons: state.eLearningLearning.lessons,
    }),
    shallowEqual
  )
  const courseType = _.get(course, 'courseType', '')
  const courseName = _.get(course, 'name', '') || 'ชื่อหลักสูตร'
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    dispatch(handleProgressChange(lessons, setAlertDialog))
  }, [lessons])

  return (
    <StyledDrawer
      anchor="right"
      variant={isLg ? 'persistent' : 'temporary'}
      open={isExpanded}
      onClose={() => dispatch(setExpand(false))}
    >
      <StyledRightContent>
        <StyledHeader>
          <ProgressPercent />
          <StyledHeaderText>
            <Typography
              variant="body2"
              color="text.gray"
              sx={{ lineHeight: '20px' }}
            >
              {COURSE_TYPE_TH[courseType]}
            </Typography>
            <StyledTooltip title={courseName}>
              <StyledOneLineText variant="body1b" sx={{ lineHeight: '24px' }}>
                {courseName}
              </StyledOneLineText>
            </StyledTooltip>
          </StyledHeaderText>
          <StyledExpandButton
            startIcon={<img src="/icon/e-learning/player/hide_menu.svg" />}
            onClick={() => dispatch(setExpand(false))}
          >
            ซ่อนบทเรียน
          </StyledExpandButton>
        </StyledHeader>
        <StyledDoubleDivider />
        {isNotSm ? <LessonList /> : <MobileTabDisplay />}
      </StyledRightContent>
    </StyledDrawer>
  )
}

export default RightPanel

export const ProgressPercent = () => {
  const progressPercent = useSelector(
    (state) => state.eLearningLearning.progressPercent,
    shallowEqual
  )

  return (
    <StyledProgressPercent>
      <Typography variant="h6" color="text.white">
        {progressPercent}%
      </Typography>
    </StyledProgressPercent>
  )
}

export const LessonList = () => {
  const lessons = useSelector(
    (state) => state.eLearningLearning.lessons,
    shallowEqual
  )

  return (
    <StyledListContainer>
      <StyledListColumn>
        {lessons.map((data, index) => (
          <LessonLabel key={index} data={data} index={index} />
        ))}
      </StyledListColumn>
    </StyledListContainer>
  )
}
