import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const textColor = 'text.wildSand'

export const StyledTypography = styled(Typography)(() => ({
  color: '#FF1721',
  fontSize: '52px',
  lineHeight: '56px',
  fontWeight: 700,
}))
