import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { FileDownloadOutlined } from '@mui/icons-material'
import {
  EndFlex,
  HeaderBox,
  StartFlex,
  StyledDownloadButton,
  TitleBox,
} from './styled'
import { ECONTENT_TYPE_TH_TEXT } from '../model'
import { convertDateShortTh } from 'src/utils/lib'
import { startPrint } from 'src/redux/slices/eContent'

const Header = ({ type, handlePrint }) => {
  const dispatch = useDispatch()
  const { contentData, printLoading } = useSelector(
    (state) => ({
      contentData: state.eContent.contentData,
      printLoading: state.eContent.printLoading,
    }),
    shallowEqual
  )
  return (
    <HeaderBox>
      <StartFlex>
        <Typography variant="body1" color="secondary.main">
          {ECONTENT_TYPE_TH_TEXT[type]}
        </Typography>
        <TitleBox>{contentData?.name}</TitleBox>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Typography variant="body1" color="text.secondary">
            วันที่เผยแพร่
          </Typography>
          <Typography variant="body1b">
            {convertDateShortTh(contentData?.startPublishDate)}
          </Typography>
        </Box>
      </StartFlex>
      {contentData?.isDownload && (
        <EndFlex>
          <StyledDownloadButton
            variant="contained"
            startIcon={
              printLoading ? (
                <CircularProgress color="white" size={20} />
              ) : (
                <FileDownloadOutlined />
              )
            }
            onClick={async () => {
              if (printLoading) return
              dispatch(startPrint())
              handlePrint()
            }}
          >
            <Typography>ดาวน์โหลดหน้านี้</Typography>
          </StyledDownloadButton>
        </EndFlex>
      )}
    </HeaderBox>
  )
}

export default Header
