import _ from 'lodash'
import { store } from 'src/App'
import { path } from 'src/constants/path'
import { fetchETesting } from '../events'

export const handleFetchData = ({ payload, isReview, isModule, startTime }) => {
  if ( !isReview && !isModule && _.isNil(startTime)) {
    const pathWithUuid = window.location.href.split('/').pop()
    window.location.href = `${path.eTesting}/${pathWithUuid}`
    return
  }

  store.dispatch(fetchETesting({ payload, isReview, isModule }))
}
