import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import { BoxLabel as BoxHeader } from '../Styled'
import { BoxListContent, BoxCard } from '../Details/Styled'

import { handleClick, calculateDate, checkEmpty } from './events'

export const BoxLabel = ({ labelTop, labelBottom }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="body1" color="text.secondary" sx={{ width: 280 }}>
        {labelTop}
      </Typography>
      <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
        {labelBottom}
      </Typography>
    </Box>
  )
}

const Index = () => {
  const dispatch = useDispatch()
  const [user] = useLocalStorage('user')
  const { toggle, details } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )

  const abbreviation = _.get(user, 'distributionChannel.abbreviation', '')

  return (
    <BoxCard>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', p: 3, width: '100%' }}
      >
        <BoxHeader>
          <Typography variant="h6" color="primary.main" sx={{ mb: 1 }}>
            ข้อมูลการทำงาน
          </Typography>
          {!toggle ? (
            <KeyboardArrowUp
              sx={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => dispatch(handleClick(!toggle))}
            />
          ) : (
            <KeyboardArrowDown
              sx={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => dispatch(handleClick(!toggle))}
            />
          )}
        </BoxHeader>
        {!toggle && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <BoxListContent>
              <BoxLabel
                labelTop="วันที่เริ่มงาน"
                labelBottom={_.get(details, 'joinDateTH', '-')}
              />
              <BoxLabel
                labelTop="อายุการทำงาน"
                labelBottom={calculateDate(
                  _.get(details, 'joinDate', new Date())
                )}
              />
            </BoxListContent>

            {abbreviation === 'CS' && (
              <>
                <BoxListContent>
                  <BoxLabel
                    labelTop="รหัส 6 หลัก"
                    labelBottom={checkEmpty(details, 'agentCode')}
                  />
                  <BoxLabel
                    labelTop="แผนก / ฝ่าย"
                    labelBottom={checkEmpty(user.agentProfile, 'division')}
                  />
                </BoxListContent>
                <BoxListContent>
                  <BoxLabel
                    labelTop="ชื่อบริษัท"
                    labelBottom={checkEmpty(details, 'agentUnitName')}
                  />
                  <BoxLabel
                    labelTop="สถานะการทำงาน"
                    labelBottom={checkEmpty(details, 'agentStatusCode')}
                  />
                </BoxListContent>
              </>
            )}
            {(abbreviation === 'BAC' || abbreviation === 'KTB') && (
              <>
                <BoxListContent>
                  <BoxLabel
                    labelTop="รหัส 6 หลัก"
                    labelBottom={checkEmpty(details, 'agentCode')}
                  />
                  <BoxLabel
                    labelTop="สาขา"
                    labelBottom={checkEmpty(details, 'branch')}
                  />
                </BoxListContent>
                <BoxListContent>
                  <BoxLabel
                    labelTop="พื้นที่"
                    labelBottom={checkEmpty(details, 'areaUser')}
                  />
                  <BoxLabel
                    labelTop="กลุ่ม"
                    labelBottom={checkEmpty(details, 'groupUser')}
                  />
                </BoxListContent>
                <BoxListContent>
                  <BoxLabel
                    labelTop="สถานะการทำงาน"
                    labelBottom={checkEmpty(details, 'agentStatusCode')}
                  />
                </BoxListContent>
              </>
            )}
            {abbreviation === 'AGC' && (
              <>
                <BoxListContent>
                  <BoxLabel
                    labelTop="รหัส 6 หลัก"
                    labelBottom={checkEmpty(details, 'agentCode')}
                  />
                  <BoxLabel
                    labelTop="รหัสสังกัด"
                    labelBottom={checkEmpty(details, 'unitNumber')}
                  />
                </BoxListContent>
                <BoxListContent>
                  <BoxLabel
                    labelTop="สำนักงาน"
                    labelBottom={checkEmpty(details, 'agentUnitName')}
                  />
                  <BoxLabel
                    labelTop="ทีม / RAM"
                    labelBottom={checkEmpty(details, 'RAMCode')}
                  />
                </BoxListContent>
                <BoxListContent>
                  <BoxLabel
                    labelTop="สถานะการทำงาน"
                    labelBottom={checkEmpty(details, 'agentStatusCode')}
                  />
                </BoxListContent>
              </>
            )}
          </Box>
        )}
      </Box>
    </BoxCard>
  )
}
export default Index
