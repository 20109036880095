import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningCollectionDetail,
  eLearningCollectionFilter,
  eLearningCollectionRecommend,
  eLearningLikeCollection,
} from 'src/utils/apiPath'

export const getCollectionHomePage = createAsyncThunk(
  'collection/getCollectionHomePage',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(eLearningCollectionRecommend)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getCollectionById = createAsyncThunk(
  'collection/getCollectionById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCollectionDetail}/${id}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postCollectionFilter = createAsyncThunk(
  'collection/postCollectionFilter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCollectionFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const postLikeCollection = createAsyncThunk(
  'collection/postLikeCollection',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningLikeCollection, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
