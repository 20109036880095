import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Checkbox, Typography } from '@mui/material'
import { Row } from 'src/components/Layout/Styled'
import { handleFieldTypeChange } from './event'

const CheckBoxInput = ({ index, label }) => {
  const dispatch = useDispatch()
  const { type } = useSelector(
    (state) => ({
      type: state.eExamination.examinationFilter.type,
    }),
    shallowEqual
  )
  return (
    <Row>
      <Checkbox
        checked={_.includes(type, index)}
        onClick={(e) => {
          dispatch(
            handleFieldTypeChange({ key: index, value: e.target.checked })
          )
        }}
      />
      <Typography variant="body1">{label}</Typography>
    </Row>
  )
}

export default CheckBoxInput
