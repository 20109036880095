import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Row } from 'src/components/Layout/Styled'
import { IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { removeExaminer } from 'src/redux/slices/eExamination'

const ExaminerItem = ({ index }) => {
  const dispatch = useDispatch()
  const { examiner, selfRegister } = useSelector(
    (state) => ({
      examiner: state.eExamination.examiners[index],
      selfRegister: state.eExamination.selfRegister,
    }),
    shallowEqual
  )
  return (
    <Row
      sx={{
        background: '#7698CB1A',
        px: 2,
        py: 1,
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="body1b">{`${examiner.firstNameTH} ${examiner.lastNameTH}`}</Typography>
      {!selfRegister && (
        <IconButton
          sx={{ p: 0 }}
          onClick={() => dispatch(removeExaminer({ index: index }))}
        >
          <CloseIcon sx={{ color: '#00008F' }} />
        </IconButton>
      )}
    </Row>
  )
}

export default ExaminerItem
