import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledFloatBg = styled(Box)(({ theme }) => ({
  position: 'fixed',
  zIndex: 2,
  left: 0,
  top: 0,
  background: theme.palette?.background?.paper,
  width: '100%',
  height: 96,
}))

export const StyledButtonWrapper = styled(Row)(({ theme }) => ({
  position: 'fixed',
  zIndex: 2,
  bottom: 0,
  left: 0,
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  background: theme.palette?.background?.paper,
  boxShadow: '0px -12px 12px 0px rgba(0, 0, 0, 0.12)',
}))

export const StyledToolWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 175,
  top: 26,
  zIndex: 99,
  display: 'flex',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: { left: 152, gap: theme.spacing(0.5) },
}))
