import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Column } from 'src/components/Layout/Styled'
import Detail from './Detail'
import { convertDateToThaiString } from 'src/utils/lib'

const SummaryItem = ({ index }) => {
  const { selectedRounds } = useSelector(
    (state) => ({
      selectedRounds: state.eExamination.selectedRounds[index],
    }),
    shallowEqual
  )
  return (
    <Column sx={{ background: '#FFFFFF', p: 2, rowGap: '12px' }}>
      <Detail
        label={'วันและเวลาที่สอบ'}
        value={`${convertDateToThaiString(selectedRounds.date)} ${
          selectedRounds.startTime
        } - ${selectedRounds.endTime}`}
      />
      <Detail label={'สนามสอบ'} value={selectedRounds.examinationField} />
      <Detail label={'สถานที่สอบ'} value={selectedRounds.examinationLocation} />
      <Detail label={'อื่นๆ'} value={selectedRounds.examinationOther} />
    </Column>
  )
}

export default SummaryItem
