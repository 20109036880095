import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { InsertInvitationOutlined } from '@mui/icons-material'
import {
  CardContainter,
  CardImage,
  ContentBox,
  StyledChip,
  TitleBox,
} from './styled'
import { ECONTENT_TYPE_PATH, ECONTENT_TYPE_TH_TEXT } from '../model'
import { convertDateShortTh } from 'src/utils/lib'

const ContentCard = ({ item }) => {
  const [cursorPosition, setCursorPosition] = useState({
    positionX: null,
    positionY: null,
  })
  const history = useHistory()
  const handleOnMouseDown = (e) => {
    setCursorPosition({
      positionX: e.clientX,
      positionY: e.clientY,
    })
    e.preventDefault() // prevent link click
  }

  const handleOnClick = (e) => {
    e.stopPropagation()
    if (
      cursorPosition.positionX !== e.clientX ||
      cursorPosition.positionY !== e.clientY
    ) {
      // prevent link click if the element was dragged
      e.preventDefault()
    } else {
      history.push(`${ECONTENT_TYPE_PATH[item.contentType]}${item.uuid}`)
    }
  }
  return (
    <CardContainter
      onMouseDown={(e) => handleOnMouseDown(e)}
      onClick={(e) => handleOnClick(e)}
    >
      <CardImage
        src={`${window.__env__.REACT_APP_FILE_URL}${item.coverImage?.key}`}
      />
      <ContentBox>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography variant="body1" color="secondary.main">
            {ECONTENT_TYPE_TH_TEXT[item.contentType]}
          </Typography>
          <TitleBox>{item.name}</TitleBox>
        </Box>
        <div>
          <Box
            sx={{
              gap: '8px',
              display: 'flex',
              flexWrap: 'wrap',
              marginBottom: '10px',
            }}
          >
            {item.eContentTag.slice(0, 4).map((data, index) => (
              <StyledChip key={index}>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    padding: '0px 6px',
                  }}
                >
                  {data.name}
                </Typography>
              </StyledChip>
            ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InsertInvitationOutlined
              fontSize="small"
              sx={{ mr: 1, color: '#5F5F5F' }}
            />
            <Typography variant="body1" color="text.secondary">
              {convertDateShortTh(item.startPublishDate)}
            </Typography>
          </Box>
        </div>
      </ContentBox>
    </CardContainter>
  )
}

export default ContentCard
