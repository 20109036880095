import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { postUpdateIdCardNo } from 'src/services/profile'

const regex = /^[0-9]+$/

export const handleInputFormat = (e) => {
  const key = e.nativeEvent.data

  if (_.isNil(key)) return e.target.value

  if (!regex.test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex.test(e.target.value)) e.target.value = ''
  e.target.value = e.target.value.toString().slice(0, 13)
  return e.target.value
}

export const handlePasteFormat = (e) => {
  e.preventDefault()
  return false
}

export const handleSubmit = (values, path) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const body = { ...values, uuid: user.uuid }
  dispatch(startLoading())
  const response = await dispatch(postUpdateIdCardNo(body))
  dispatch(stopLoading())

  if (_.isEmpty(response.error)) {
    const idCardNo = _.get(response.payload, 'data.idCardNo', '')
    const newUser = { ...user, idCardNo }
    localStorage.setItem('user', JSON.stringify(newUser))
    window.location.href = path
  }
}
