import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Select from '../../../../../components/Input/Select'
import {
  optionYear,
  MONTH_LIST,
  handleSelectMonthYear,
  checkDate,
} from '../events'

const Index = ({ calendarRef }) => {
  const { currentDate } = useSelector((state) => state.myProfile, shallowEqual)
  const dispatch = useDispatch()

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Select
          boxSx={{ width: 115 }}
          id="prefixTH"
          name="prefixTH"
          type="basic"
          options={MONTH_LIST}
          handleChange={(e) =>
            dispatch(
              handleSelectMonthYear({
                currentDate,
                calendarRef,
                type: 'month',
                value: _.get(e, 'target.value', 0),
              })
            )
          }
          value={checkDate(currentDate, 'month')}
          showTextError={false}
        />
        <Select
          boxSx={{ width: 90 }}
          id="prefixTH"
          name="prefixTH"
          type="basic"
          options={optionYear()}
          handleChange={(e) =>
            dispatch(
              handleSelectMonthYear({
                currentDate,
                calendarRef,
                type: 'year',
                value: _.get(e, 'target.value', 0),
              })
            )
          }
          value={checkDate(currentDate, 'year')}
          showTextError={false}
        />
      </Box>
    </>
  )
}

export default Index
