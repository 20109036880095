import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Template from 'src/components/Template'
import { Column } from 'src/components/Layout/Styled'

import ContentNoClass from 'src/components/ContentNoClass'

import FilterHeader from './FilterHeader'
import ClassCard from './ClassCard'
import Header from './Header'
import { StyledContainer, StyledLoading } from './Styled'
import { fetchClassLearnerList, handleLoadMore, handleSeeMore } from './event'
import Divider from '@mui/material/Divider'
import { useParams } from 'react-router-dom'

const ClassLearner = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { isLoadMore, batchs, filterBody } = useSelector(
    (state) => state.classLearnerList,
    shallowEqual
  )
  const triggerProps = [
    filterBody.name,
    filterBody.startDate,
    filterBody.finishDate,
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', () => dispatch(handleLoadMore()))
    return () => {
      window.removeEventListener('scroll', () => dispatch(handleLoadMore()))
    }
  }, [])

  useEffect(() => {
    dispatch(fetchClassLearnerList(filterBody, id))
  }, [...triggerProps])

  useEffect(() => {
    dispatch(handleSeeMore(filterBody))
  }, [filterBody.page])

  return (
    <>
      <Header />
      <Template>
        <StyledContainer>
          <Column gap={3}>
            <FilterHeader />
            <Column>
              {batchs.length > 0 ? (
                batchs.map((item, index) => (
                  <>
                    <Divider />
                    <ClassCard key={index} data={item} index={index} />
                  </>
                ))
              ) : (
                <ContentNoClass />
              )}
              {isLoadMore && <StyledLoading />}
            </Column>
          </Column>
        </StyledContainer>
      </Template>
    </>
  )
}

export default ClassLearner
