import React from 'react'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../../redux/slices/alertDialog'

import {
  StyledDialogActions,
  StyledButtonLeft,
  StyledButtonRight,
} from '../Styled'
import _ from 'lodash'
const Index = (props) => {
  const { isLoading, disableButton, buttonLeft, buttonRight } = props
  const dispatch = useDispatch()

  return (
    <StyledDialogActions>
      {!_.isEmpty(buttonLeft) && (
        <StyledButtonLeft
          data-testid="btn-disagree"
          size="m"
          variant="outlined"
          // disabled={disableButton}
          sx={_.get(buttonLeft, 'style', { width: '100%' })}
          onClick={_.get(buttonLeft, 'handleClick', () =>
            dispatch(dispatch(closeDialog()))
          )}
          startIcon={_.get(buttonLeft, 'startIcon', null)}
          endIcon={_.get(buttonLeft, 'endIcon', null)}
        >
          <Typography
            variant="button"
            color={_.get(buttonLeft, 'colorText', 'auto')}
          >
            {_.get(buttonLeft, 'label', 'ยกเลิก')}
          </Typography>
        </StyledButtonLeft>
      )}
      <StyledButtonRight
        data-testid="btn-agree"
        size="m"
        variant="contained"
        loading={isLoading}
        disabled={disableButton}
        sx={_.get(buttonRight, 'style', { width: '100%' })}
        color={_.get(buttonRight, 'color', 'primary')}
        onClick={_.get(buttonRight, 'handleClick', () =>
          dispatch(dispatch(closeDialog()))
        )}
        startIcon={_.get(buttonRight, 'startIcon', null)}
        endIcon={_.get(buttonRight, 'endIcon', null)}
      >
        <Typography
          variant="button"
          sx={{
            color:
              !disableButton && !isLoading ? 'auto' : 'rgba(0, 0, 0, 0.26)',
          }}
          color={_.get(buttonRight, 'colorText', 'auto')}
        >
          {_.get(buttonRight, 'label', 'ตกลง')}
        </Typography>
      </StyledButtonRight>
    </StyledDialogActions>
  )
}

export default Index
