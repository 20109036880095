import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import { StyledBox, StyledButton, StyledMenu } from './Styled'
import {
  handleClick,
  handleClose,
  handleLogout,
  redirectToBackend,
  redirectToLearningPath,
  redirectToDMS,
} from './events'
import { useAuth } from 'react-oidc-context'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { handleDialogSentMail } from '../RightAppMenu/events'
import { checkSentMailPosoect } from '../'
import { permitMenu } from '../model'

const AppMenu = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [type] = useLocalStorage('type')
  const [user] = useLocalStorage('user')
  const [anchorEl, setAnchorEl] = useState(null)
  const alertProspect = useSelector(
    (state) => state.manageProspectEnrollment.alertProspect,
    shallowEqual
  )
  const isOpen = Boolean(anchorEl)
  const image = _.get(user, 'staffProfile.image', '')
  const imageSrc = `${window.__env__.REACT_APP_FILE_URL}${image}`
  const userType = _.get(user, 'userType', 'STAFF')
  const agentRole = _.get(user, 'agentRole', '-')
  const abbreviation = _.get(user, 'distributionChannel.abbreviation', '')
  const auth = useAuth()
  const checked = checkSentMailPosoect(alertProspect)
  const staffType = _.get(user, 'staffProfile.type', null)
  const nonAcademy = staffType === 'NON_ACADEMY'
  const isStaff = userType === 'STAFF' && !nonAcademy
  const isDMS = _.get(user, 'prospectProfile.isDMS', false)

  return (
    <StyledBox>
      <StyledButton
        data-testid="btn-profile"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => {
          checked
            ? dispatch(handleDialogSentMail())
            : handleClick(event, setAnchorEl)
        }}
      >
        {image !== '' ? (
          <img alt="image-src" src={imageSrc} width="20px" height="20px" />
        ) : (
          <img
            alt="staff_profile_small"
            src="/logo/staff_profile_small.png"
            width="20px"
            height="20px"
          />
        )}
        <Typography variant="button">
          {_.get(user, 'firstNameTH', '')} {_.get(user, 'lastNameTH', '')}
        </Typography>
      </StyledButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => handleClose(setAnchorEl)}
      >
        <MenuItem
          data-testid="profile-menu"
          onClick={() => {
            checked
              ? dispatch(handleDialogSentMail())
              : history.push('/my-profile')
          }}
        >
          โปรไฟล์
        </MenuItem>
        <Divider />
        {isDMS === true && (
          <>
            <MenuItem
              data-testid="dms-fullform-menu"
              onClick={() => {
                checked
                  ? dispatch(handleDialogSentMail())
                  : dispatch(redirectToDMS())
              }}
            >
              กรอกใบสมัคร
            </MenuItem>
            <Divider />
          </>
        )}
        {userType === permitMenu.agent &&
          agentRole === permitMenu.manager &&
          (abbreviation === permitMenu.agc ||
            abbreviation === permitMenu.cs) && (
            <MenuItem
              data-testid="profile-team-menu"
              onClick={() => {
                checked
                  ? dispatch(handleDialogSentMail())
                  : history.push('/manage-prospect')
              }}
            >
              โปรไฟล์ทีม
            </MenuItem>
          )}
        <Divider />
        <MenuItem
          data-testid="my-learning-path-menu"
          onClick={() => dispatch(redirectToLearningPath(history))}
        >
          เส้นทางการเรียนรู้ของฉัน
        </MenuItem>
        <MenuItem
          data-testid="catalog-menu"
          onClick={() => history.push('/catalog')}
        >
          ของรางวัลทั้งหมด
        </MenuItem>
        <Divider />
        {isStaff && (
          <>
            <MenuItem
              data-testid="switch-to-admin-menu"
              onClick={() => redirectToBackend()}
            >
              Switch to Admin View
            </MenuItem>
            <Divider />
          </>
        )}
        <MenuItem
          data-testid="logout-menu"
          onClick={() => {
            checked
              ? dispatch(handleDialogSentMail())
              : dispatch(handleLogout(auth, type))
          }}
        >
          ออกจากระบบ
        </MenuItem>
      </StyledMenu>
    </StyledBox>
  )
}

export default AppMenu
