import { store } from 'src/App'
import { setReduxValue } from 'src/redux/slices/catalog'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  getMyPoint,
  postCatalogFilter,
} from 'src/services/learningPoint/catalog'

export const fetchInitial = () => async (dispatch) => {
  dispatch(startLoading())
  dispatch(getMyPoint())
  dispatch(fetchCatalog())
}

export const fetchCatalog = () => async (dispatch) => {
  dispatch(startLoading())
  const { page } = store.getState().catalog
  await dispatch(
    postCatalogFilter({
      name: '',
      page: page,
    })
  )
  dispatch(setReduxValue({ index: 'isFetch', value: false }))
  dispatch(stopLoading())
}

export const handleSearchCatalog = () => async (dispatch) => {
  dispatch(startLoading())
  const { searchText } = store.getState().catalog

  dispatch(setReduxValue({ index: 'page', value: 1 }))
  await dispatch(
    postCatalogFilter({
      name: searchText,
      page: 1,
    })
  )
  dispatch(stopLoading())
}
