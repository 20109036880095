import _ from 'lodash'
import {
  setChangeInfo,
  setChangeEducational,
  setDeleteEducational,
  setAddEducational,
} from '../../../../redux/slices/manageProspect'

export const handleChangeInfo = (props) => (dispatch) => {
  if (props.value === 'Invalid Date') {
    props.value = null
  }
  dispatch(setChangeInfo(props))
}

export const checkHeightLeft = (type) => {
  return type == 'view' ? 290 : '100%'
}

export const handleEducational = (props) => (dispatch) => {
  dispatch(setChangeEducational(props))
}
export const handleDelete = (index) => (dispatch) => {
  dispatch(setDeleteEducational(index))
}
export const handleAdd = () => (dispatch) => {
  dispatch(setAddEducational())
}

export const checkDataEmpty = (value) => {
  return _.isEmpty(value) ? '-' : value
}
