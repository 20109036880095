import React from 'react'
import { Column } from 'src/components/Layout/Styled'
import { StyledDivider } from '../Styled'
import SelectedRound from './SelectedRound'
import Examiner from './Examiner'

const DetailSideBar = () => {
  return (
    <Column>
      <Examiner />
      <StyledDivider />
      <SelectedRound />
    </Column>
  )
}

export default DetailSideBar
