import React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'src/components/Layout/Styled'
import Template from 'src/components/Template'
import { COURSE_TYPE_TH } from 'src/constants/eLearning'
import { StyledTypography } from 'src/components/CourseCard/Styled'
import { LikeIconButton } from '../../../components/LikeButton'
import { StyledTooltip } from '../Header/Styled'
import { handleLikeCourse } from '../events'
import EnrollButton from '../EnrollButton'
import { StyledContainer, StyledFooterWrapper } from './Styled'

const ScrolledFooter = () => {
  const dispatch = useDispatch()
  const { data, isLikeLoading } = useSelector(
    (state) => ({
      data: state.eLearningDetail.data,
      isLikeLoading: state.eLearningDetail.isLikeLoading,
    }),
    shallowEqual
  )
  const coverImage = _.get(data, 'coverImage', '')
  const isLike = _.get(data, 'isLike', false)
  const name = _.get(data, 'nameForLearner', '') || _.get(data, 'name', '')

  return (
    <StyledFooterWrapper>
      <Template noMinHeight>
        <StyledContainer>
          <Row>
            <LikeIconButton
              isLike={isLike}
              isLoading={isLikeLoading}
              onClick={() => dispatch(handleLikeCourse())}
            />

            {coverImage && (
              <img
                id="cover-image"
                src={`${window.__env__.REACT_APP_FILE_URL}/get${coverImage}`}
              />
            )}

            <Column sx={{ width: 'auto' }}>
              <Typography variant="body1b" color="text.silver">
                {COURSE_TYPE_TH[_.get(data, 'courseType', '')] || ''}
              </Typography>
              <StyledTooltip title={name}>
                <StyledTypography variant="h5" color="text.white">
                  {name}
                </StyledTypography>
              </StyledTooltip>
            </Column>
          </Row>

          <Box>
            <EnrollButton scrolled />
          </Box>
        </StyledContainer>
      </Template>
    </StyledFooterWrapper>
  )
}

export default ScrolledFooter
