export const initialState = {
  error: null,
  isLoading: false,
  isDisableSubmit: true,
  flag: false,
  height: 325,
  month: '',
  activeStep: 0,
  data: null,
  batches: [],
  eLearningIdCardEnroll: null,
  body: {
    selectedDate: null,
    batch: null,
    idCardImage: null,
    faceImage: null,
    typeInput: null,
  },
  camera: {
    isOpen: false,
    idCardImage: null,
    faceImage: null,
  },
  dialog: {
    isOpen: false,
    canClickOutside: true,
    content: null,
  },
  failMaxTimes: 0,
  time: { mins: 0, secs: 0 },
  failLogUuid: null,
}
