import { createSlice } from '@reduxjs/toolkit'

const registration = createSlice({
  name: 'registration',
  initialState: { data: {} },
  reducers: {
    resetData: (state) => {
      state.data = {}
    },
    setRegistrationData: (state, { payload }) => {
      state.data = payload
    },
  },
})

export const { resetData, setRegistrationData } = registration.actions

export default registration.reducer
