import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Grid, Typography } from '@mui/material'
import { themeColor } from '../../utils/themeData'

const FooterDMS = () => {
  return (
    <Box
      sx={{
        py: 3,
        backgroundColor: themeColor.white.main,
        borderTop: { xs: 1, sm: 0 },
        borderTopColor: { xs: 'grey.300', sm: 'none' },
      }}
    >
      <Container sx={{ px: { xs: 2 } }} maxWidth="lg">
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'flex-start' },
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{ width: { xs: '100%', md: 'auto' } }}
                textAlign="center"
              >
                <Typography variant="h5">ติดตามเรา</Typography>
              </Box>
              <Box sx={{ mx: 1 }}>
                <Box
                  alt="facebook"
                  component="img"
                  sx={{
                    width: '27px',
                    height: 'auto',
                    display: 'block',
                    margin: 'auto',
                  }}
                  src="/logo/facebook.png"
                />
              </Box>
              <Box sx={{ mx: 1 }}>
                <Box
                  alt="line"
                  component="img"
                  sx={{
                    width: '27px',
                    height: 'auto',
                    display: 'block',
                    margin: 'auto',
                  }}
                  src="/logo/line.png"
                />
              </Box>
              <Box sx={{ mx: 1 }}>
                <Box
                  alt="twitter"
                  component="img"
                  sx={{
                    width: '27px',
                    height: 'auto',
                    display: 'block',
                    margin: 'auto',
                  }}
                  src="/logo/twitter.png"
                />
              </Box>
              <Box sx={{ mx: 1 }}>
                <Box
                  alt="linkedin"
                  component="img"
                  sx={{
                    width: '27px',
                    height: 'auto',
                    display: 'block',
                    margin: 'auto',
                  }}
                  src="/logo/linkedin.png"
                />
              </Box>
              <Box sx={{ mx: 1 }}>
                <Box
                  alt="youtube"
                  component="img"
                  sx={{
                    width: '27px',
                    height: 'auto',
                    display: 'block',
                    margin: 'auto',
                  }}
                  src="/logo/youtube.png"
                />
              </Box>
              <Box sx={{ mx: 1 }}>
                <Box
                  alt="ig"
                  component="img"
                  sx={{
                    width: '27px',
                    height: 'auto',
                    display: 'block',
                    margin: 'auto',
                  }}
                  src="/logo/ig.png"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{ pt: 0.5, textAlign: { xs: 'center', md: 'right' } }}
              variant="body1"
            >
              ลิขสิทธิ์ถูกต้อง © 2024 บริษัท กรุงไทย-แอกซ่า ประกันชีวิต
              จำกัด(มหาชน)
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default FooterDMS
