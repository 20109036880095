import React from 'react'
import _ from 'lodash'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { setClearErrorText, setErrorText } from 'src/redux/slices/document'
import {
  openDialog,
  openErrorDialog,
  openSuccessDialog,
  closeDialog,
} from 'src/redux/slices/alertDialog'
import { getAddtionalConsentAndDocs } from 'src/services/document'
import { additionalConsent, additionalConsentSteal } from 'src/utils/apiPath'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import axios from 'axios'
import Consent from '../../Consent'
import { handleSubmitCancelSteal } from '../Consent/events'
import { ignoreMessage } from '../ConsentAndRegister/events'

export const fetchAddtionalConsentAndDocs = (payload) => async (dispatch) => {
  dispatch(startLoading())
  const response = await dispatch(getAddtionalConsentAndDocs(payload))
  const resType = _.get(response, 'type', '')
  if (resType.includes('fulfilled')) {
    const canSubmit = _.get(response, 'payload.data.canSubmit', false)
    dispatch(handleErrorUser(canSubmit))
  }
  dispatch(stopLoading())
}

export const handleConsent = () => (dispatch) => {
  dispatch(
    openDialog({ type: 'custom', customWidth: '85%', content: <Consent /> })
  )
}

export const handleSubmitDocument = (payload) => async (dispatch) => {
  const { uuid, managerId, id, formatDate, formatIdCard, requestData } = payload
  const isNoUploaded = requestData.some((item) => _.isNil(item.file))
  if (isNoUploaded) {
    requestData.forEach((item) => {
      if (_.isNil(item.file)) {
        dispatch(setErrorText({ key: item.key, text: 'กรุณาอัปโหลดไฟล์นี้' }))
      } else {
        dispatch(setErrorText({ key: item.key }))
      }
    })
  } else {
    dispatch(setClearErrorText())
    dispatch(startLoading())

    const formData = new FormData()
    formData.append('id', id)
    requestData.forEach((item) => {
      formData.append(item.key, item.file)
    })
    formData.append('idCardNo', formatIdCard)
    formData.append('dateOfBirth', formatDate)
    const postPayload = {
      uuid,
      body: formData,
      managerId,
      id,
      formatDate,
      formatIdCard,
      requestData,
    }
    await dispatch(handlePostDocument(postPayload))
  }
}

export const handleSubmitSteal = (props) => async (dispatch) => {
  const {
    uuid,
    idCard,
    date,
    managerId,
    userUuidOld,
    externalUuidOld,
    id,
    requestData,
  } = props
  let formData = new FormData()
  formData.append('id', id)
  requestData.forEach((item) => {
    formData.append(item.key, item.file)
  })
  formData.append('idCardNo', idCard)
  formData.append('dateOfBirth', date)
  formData.append('userUuidOld', userUuidOld)
  formData.append('externalUuidOld', externalUuidOld)

  dispatch(startLoading())
  await axios
    .post(`${additionalConsentSteal}/${id}/${uuid}/${managerId}`, formData)
    .then(() => {
      dispatch(
        openDialog({
          isCloseDialog: false,
          iconType: 'success',
          title: 'ส่งอีเมลสำเร็จแล้ว',
          message: ['ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว'],
          buttonRight: {
            handleClick: () => window.close(),
          },
        })
      )
    })
    .catch(() =>
      dispatch(
        openDialog({
          iconType: 'error',
          title: 'ส่งอีเมลล้มเหลว',
          message: ['ระบบไม่สามารถส่งอีเมลได้ในขณะนี้', 'โปรดลองใหม่อีกครั้ง'],
        })
      )
    )
  dispatch(stopLoading())
}

export const handlePostDocument = (postPayload) => async (dispatch) => {
  const { uuid, body, managerId, id, formatDate, formatIdCard, requestData } =
    postPayload

  dispatch(startLoading())
  await axios
    .post(`${additionalConsent}/${id}/${uuid}/${managerId}`, body)
    .then(({ data }) => {
      const externalUuidOld = _.get(data, 'externalUuidOld', '')
      const userUuidOld = _.get(data, 'userUuidOld', '')
      const managerNameOld = _.get(data, 'managerNameOld', '')
      const managerUnitNameOld = _.get(data, 'managerUnitNameOld', '')
      const managerNameNew = _.get(data, 'managerNameNew', '')
      const managerUnitNameNew = _.get(data, 'managerUnitNameNew', '')
      if (_.get(data, 'steal', false) === true) {
        dispatch(
          openDialog({
            iconType: 'warning',
            title: 'ยืนยันการย้ายผู้ชักชวน',
            customWidth: '600px',
            message: [
              `ขณะนี้คุณได้เป็นสมาชิกของ คุณ${managerNameOld} หน่วย ${managerUnitNameOld} กรุณากดยอมรับหากต้องการย้ายไปเป็นสมาชิกของ`,
              `คุณ${managerNameNew} หน่วย ${managerUnitNameNew}`,
            ],
            buttonRight: {
              label: 'ยอมรับ',
              handleClick: () => {
                dispatch(closeDialog())
                dispatch(
                  handleSubmitSteal({
                    uuid,
                    idCard: formatIdCard,
                    date: formatDate,
                    managerId,
                    userUuidOld,
                    externalUuidOld,
                    id,
                    requestData,
                  })
                )
              },
              endIcon: <ArrowForwardIcon />,
            },
            buttonLeft: {
              label: 'ปฎิเสธ',
              handleClick: () => {
                dispatch(handleSubmitCancelSteal(_.get(data, 'tempUuid', '')))
              },
            },
          })
        )
      } else {
        dispatch(
          openDialog({
            isCloseDialog: false,
            iconType: 'success',
            title: 'ส่งอีเมลสำเร็จแล้ว',
            message: ['ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว'],
            buttonRight: { handleClick: () => window.close() },
          })
        )
      }
    })
    .catch((e) => {
      const message = ignoreMessage(e)
      dispatch(openDialog({ iconType: 'error', ...message }))
    })
  dispatch(stopLoading())
}

export const handleErrorUser = (canSubmit) => (dispatch) => {
  if (canSubmit) return
  const message = 'User นี้ได้ถูกสร้างแล้ว'
  dispatch(openErrorDialog({ title: message, message: ' ' }))
}

export const handleError = (error) => (dispatch) => {
  if (
    !error ||
    _.get(error, 'property', '').includes('Prospect is already created')
  )
    return
  const title = _.get(error, 'constraints.title', 'ไม่สำเร็จ')
  const message = _.get(error, 'constraints.value', 'ดำเนินการไม่สำเร็จ')
  dispatch(openErrorDialog({ title, message }))
}

export const handleSuccess = (isSuccess) => (dispatch) => {
  if (isSuccess) {
    dispatch(openSuccessDialog({ handleClick: () => window.close() }))
  }
}
