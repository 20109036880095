import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import ClassList from 'src/modules/Course/Detail/ClassList'
import { StyledContainer } from '../Styled'
import RightContent from './RightContent'

const CourseContent = () => {
  const selectedCourse = useSelector(
    (state) => state.eLearningLearningPath.selectedCourse,
    shallowEqual
  )

  return (
    <StyledContainer>
      <ClassList uuid={selectedCourse} />
      <RightContent />
    </StyledContainer>
  )
}

export default CourseContent
