import ArrowBack from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@mui/system/styled'

export const StyledHeaderWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(9),
  width: '100%',
  height: 450,
  position: 'relative',
  background: theme.palette?.blue?.deepDapphire,
  [theme.breakpoints.down('lg')]: { height: 352 },
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginTop: theme.spacing(10),
    padding: `0 ${theme.spacing(2)}`,
  },
}))

export const StyledHeaderImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  filter: 'brightness(0.5)',
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    height: 152,
  },
}))

export const StyledTextBtn = styled((props) => (
  <Button {...props} startIcon={<ArrowBack />} />
))(({ theme }) => ({
  marginTop: 29,
  color: theme.palette?.text?.white,
  ':hover': { color: theme.palette?.text?.white },
  [theme.breakpoints.down('lg')]: {
    marginTop: 36,
    marginLeft: theme.spacing(5.5),
  },
  [theme.breakpoints.down('sm')]: {
    color: theme.palette?.primary?.main,
    marginTop: 0,
    marginLeft: 0,
  },
}))

export const StyledHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  right: 0,
  top: 83,
  width: 504,
  maxWidth: 504,
  gap: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    top: '50%',
    transform: 'translate(0, -50%)',
    marginRight: theme.spacing(5.5),
    width: 440,
    maxWidth: 440,
  },
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette?.text?.primary,
    background: theme.palette?.background?.paper,
    boxShadow: theme.palette?.boxShadow?.menu,
  },
}))
