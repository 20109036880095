import React, { useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import { useSelector, shallowEqual } from 'react-redux'

import { CustomTooltip } from '../Styled'
import { ContentTooltip } from '../'

import { mapIdEvents, mapEventTypeCalendar } from '../events'

const Index = ({ calendarRef, initialView }) => {
  const calendar = useSelector(
    (state) => state.myProfile.calendar,
    shallowEqual
  )

  const [open, setOpen] = useState(_.defaultTo(mapIdEvents(calendar), []))
  const events = mapEventTypeCalendar(calendar)

  const handleTooltip = (id) => {
    setOpen({
      ...open,
      [id]: !open[id],
    })
  }

  return (
    <Box
      id="my-profile-calendar"
      onMouseDown={(evt) => {
        evt.stopPropagation()
        evt.preventDefault()
      }}
    >
      <FullCalendar
        ref={calendarRef}
        timeZone="GMT+7"
        height={536}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView={initialView} //timeGridWeek //timeGridDay
        displayEventTime={false}
        headerToolbar={false}
        events={events}
        allDayText="GMT+07"
        eventMaxStack={2} // for all non-TimeGrid views
        views={{
          timeGridWeek: {
            dayHeaderContent: ({ date }) => {
              const numDay = date.getDay()
              const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
              return {
                html: `<div style="line-height: 6px;">
                  <div>${weekdays[numDay]}<div>
                  <div>${date.getDate()}</div>
                  </div>`,
              }
            },
          },
          timeGridDay: {
            dayHeaderContent: ({ date }) => {
              const numDay = date.getDay()
              const weekdays = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ]
              return {
                html: `<div>${weekdays[numDay]}  ${date.getDate()}</div>`,
              }
            },
          },
        }}
        eventContent={(info) => {
          return (
            <CustomTooltip
              enterTouchDelay={0}
              title={<ContentTooltip event={info.event} />}
            >
              {handleMouseEnter(info, handleTooltip, initialView)}
            </CustomTooltip>
          )
        }}
      />
    </Box>
  )
}

export const handleMouseEnter = (innerProps, handleTooltip, initialView) => {
  const allDay = innerProps.event.allDay
  const status = innerProps.event.extendedProps.status
  const workType = innerProps.event.extendedProps.workType
  let checkType = 'event-content-default'
  if (allDay) {
    checkType = `event-calendar-view  event-work-type-${
      workType.toLowerCase() != 'holiday' ? 'default' : 'holiday'
    }`
  }

  const dayClass = !allDay ? 'day-default' : 'day-off'
  const className =
    initialView === 'timeGridWeek'
      ? `event-timeGridWeek  ${dayClass}`
      : `event-timeGridDay  ${dayClass}`

  return (
    <Box
      className={`fc-event-main-frame event-main-box event-status-${status.toLowerCase()}
      type-${workType.toLowerCase() != 'holiday' ? 'default' : 'holiday'}
      `}
    >
      <Box className="fc-event-title-container">
        <Box className={`fc-event-title fc-sticky ${checkType}`}>
          {workType.toLowerCase() == 'holiday' ? (
            <Typography variant="tooltip">{innerProps.event.title}</Typography>
          ) : (
            <Box className="text-default">
              <Typography variant="tooltip" className={className}>
                {innerProps.event.title}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Index
