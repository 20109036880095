import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import StarsIcon from '@mui/icons-material/Stars'
import { Row } from 'src/components/Layout/Styled'
import { ColumnCard, BlueBgHeader, PointBox } from './Styled'
import { convertNumberWithComma } from 'src/utils/lib'
import { setOpenHistory } from 'src/redux/slices/myProfile'
import HistoryDrawer from './HistoryDrawer'

const Point = () => {
  const dispatch = useDispatch()
  const { point } = useSelector(
    (state) => state.myProfile.details,
    shallowEqual
  )
  return (
    <ColumnCard
      sx={{
        mx: 3,
      }}
    >
      <BlueBgHeader>
        <Typography variant="h6" color="text.white">
          คะแนนการอบรม
        </Typography>
        <Box sx={{ ml: 'auto' }}>
          <IconButton
            sx={{ gap: 1 }}
            onClick={() => dispatch(setOpenHistory())}
          >
            <HistoryTwoTone sx={{ color: 'text.white' }} />
            <Typography variant="body1b" color="text.white">
              ประวัติ
            </Typography>
          </IconButton>
        </Box>
      </BlueBgHeader>
      <Row sx={{ px: 1.5, py: 3, gap: 3 }}>
        <Typography variant="body1">จำนวนคะแนนสะสม</Typography>
        <PointBox>
          <StarsIcon sx={{ color: 'primary.main' }} />
          <Typography variant="body1b">
            {convertNumberWithComma(point)} คะแนน
          </Typography>
        </PointBox>
      </Row>
      <HistoryDrawer />
    </ColumnCard>
  )
}

export default Point
