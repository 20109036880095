import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { draftQuestion } from 'src/redux/slices/eTesting/selectors'
import { StyledTestingContent } from './Styled'
import QuestionCard from './QuestionCard'

export const Questions = () => {
  const listQuestion = useSelector(draftQuestion, shallowEqual)
  const { displayQuestion, page } = useSelector(
    (state) => ({
      displayQuestion: state.eTesting.displayQuestion,
      page: state.eTesting.page,
    }),
    shallowEqual
  )

  if (_.isUndefined(listQuestion[0])) return <></>

  switch (displayQuestion) {
    case 'ALL':
      return (
        <StyledTestingContent>
          {listQuestion.map((item, index) => (
            <QuestionCard key={`question-no-${index + 1}`} keyQuestion={item} />
          ))}
        </StyledTestingContent>
      )
    case 'ONE':
      return (
        <StyledTestingContent>
          <QuestionCard
            key={`question-preview`}
            keyQuestion={listQuestion[page - 1]}
          />
        </StyledTestingContent>
      )
    default:
      return <></>
  }
}
export default Questions
