import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import { handleConfirmLogout } from '../../Appbar/AppMenu/events'
import useLocalStorage from '@rehooks/local-storage'
import { useAuth } from "react-oidc-context"

const Error401 = () => {
  const [type] = useLocalStorage('type')
  const auth = useAuth()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: '60px',
      }}
    >
      <svg
        width={96}
        height={96}
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M45.4108 20.47L14.6208 73.49C13.4608 75.49 14.9008 78 17.2108 78H78.7908C81.1008 78 82.5508 75.49 81.3808 73.49L50.5908 20.47C49.4308 18.48 46.5608 18.48 45.4008 20.47H45.4108Z"
          stroke="#C91432"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51 41C51 39.3431 49.6569 38 48 38C46.3431 38 45 39.3431 45 41V54C45 55.6569 46.3431 57 48 57C49.6569 57 51 55.6569 51 54V41Z"
          stroke="#C91432"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48 70C50.2091 70 52 68.2091 52 66C52 63.7909 50.2091 62 48 62C45.7909 62 44 63.7909 44 66C44 68.2091 45.7909 70 48 70Z"
          stroke="#C91432"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <Typography
        variant="h5"
        sx={{
          mb: '8px',
          color: 'rgba(0, 0, 143, 1)',
          fontSize: '28px',
          fontWeight: 700,
        }}
      >
        เข้าสู่ระบบไม่ได้
      </Typography>
      <Typography sx={{ color: 'rgba(51, 51, 51, 1)' }}>
        ไม่สามารถเข้าสู่ระบบด้วยบัญชีนี้ได้
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ color: 'rgba(51, 51, 51, 1)', mr: 1 }}>
          กรุณา{' '}
        </Typography>
        <Link data-testid="btn-link" onClick={() => handleConfirmLogout(auth, type, '/location')}>
          <Typography
            sx={{ color: 'rgba(0, 0, 143, 1)', textDecoration: 'underline' }}
          >
            ติดต่อเจ้าหน้าที่
          </Typography>
        </Link>
      </Box>
      <Button
        sx={{ width: '100%', mt: '24px', py: '12px' }}
        data-testid="btn-logout"
        variant="contained"
        onClick={() => handleConfirmLogout(auth, type)}
      >
        ตกลง
      </Button>
    </Box>
  )
}

export default Error401
