import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Add from '@mui/icons-material/Add'
import useLocalStorage from '@rehooks/local-storage'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../../redux/slices/alertDialog'

import Template from '../../../components/Template'
import { setSelected } from '../../../redux/slices/table'
import { setAlertProspect } from '../../../redux/slices/manageProspectEnrollment'
import Course from './Course'
import ListRegister from './ListRegister'
import Result from './Result'
import AddList from './ListRegister/AddList'

import * as Styled from './Styled'
import { fetchData } from './events'

export const CardSection = ({
  section,
  labelSection,
  labelCard,
  isViewCourse,
  isAddName,
  children,
  disabled,
  course,
}) => {
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)

  const courseUuid = _.get(course, 'course.value', '')
  const trainingPlanUuid = _.get(course, 'trainingPlan.value', '')

  return (
    <Box sx={{ mt: 3, my: 3 }}>
      <Styled.TypographyIcon variant="h6" color="primary">
        <Styled.BlueCircle>
          <Typography variant="caption">{section}</Typography>
        </Styled.BlueCircle>
        {labelSection}
      </Styled.TypographyIcon>

      <Styled.Section>
        <Styled.SectionContent>
          <Box sx={{ m: 3 }}>
            <Styled.HeaderSection>
              <Typography variant="h6" color="primary">
                {labelCard}
              </Typography>
              {isViewCourse && (
                <Button
                  variant="outlined"
                  sx={{ padding: '10px 20px' }}
                  endIcon={<ArrowForward />}
                  disabled={_.isEmpty(trainingPlanUuid)}
                  onClick={() =>
                    window.open(
                      `/course/${courseUuid}/class/${trainingPlanUuid}`
                    )
                  }
                >
                  ดูรายละเอียดคลาส
                </Button>
              )}
              {isAddName && (
                <Button
                  onClick={() => {
                    dispatch(setSelected([]))
                    setOpen(true)
                  }}
                  disabled={disabled}
                  variant="outlined"
                  sx={{ padding: '10px 20px' }}
                  startIcon={<Add />}
                >
                  เพิ่มรายชื่อ
                </Button>
              )}
            </Styled.HeaderSection>
            {children}
          </Box>
        </Styled.SectionContent>
      </Styled.Section>

      <AddList isOpen={isOpen} onCloseDrawer={() => setOpen(false)} />
    </Box>
  )
}

const Enrollment = () => {
  const dispatch = useDispatch()
  const [prospect] = useLocalStorage('prospect')

  useEffect(() => {
    dispatch(setAlertProspect(true))
    dispatch(fetchData(prospect))
  }, [prospect])
  useEffect(() => {
    dispatch(closeDialog())
  }, [])

  return (
    <Template>
      <Styled.Content>
        <Typography variant="h4">สมัครเข้าอบรม</Typography>
        <Course />
        <ListRegister />
        <Result />
      </Styled.Content>
    </Template>
  )
}

export default Enrollment
