import React from 'react'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'
import { Loader, ThemeProvider } from '@aws-amplify/ui-react'
import axios from 'axios'
import _ from 'lodash'

import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'
import awsexports from '../../amplifyconfiguration.json'

Amplify.configure(awsexports)

export function LivenessQuickStartReact() {
  const credentialProvider = async () => {
    // Fetch the credentials
    return {
      accessKeyId: 'AKIARCSK5YW5P22ECEW5',
      secretAccessKey: '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',
    }
  }
  const [loading, setLoading] = React.useState(true)
  const [createLivenessApiData, setCreateLivenessApiData] = React.useState(null)

  React.useEffect(() => {
    const fetchCreateLiveness = async () => {
      /*
       * This should be replaced with a real call to your own backend API
       */

      const createSession = await axios.get(`/queue/session`)
      const sessionId = _.get(createSession, 'data', null)

      setCreateLivenessApiData({ sessionId: sessionId })
      setLoading(false)
    }

    fetchCreateLiveness()
  }, [])

  const handleAnalysisComplete = async () => {
    /*
     * This should be replaced with a real call to your own backend API
     */

    console.log('handleAnalysisComplete ->')

    const response = await axios.get(
      `/queue/session/${createLivenessApiData.sessionId}`
    )

    const data = _.get(response, 'data', null)

    console.log('data ->', data)
    /*
     * Note: The isLive flag is not returned from the GetFaceLivenessSession API
     * This should be returned from your backend based on the score that you
     * get in response. Based on the return value of your API you can determine what to render next.
     * Any next steps from an authorization perspective should happen in your backend and you should not rely
     * on this value for any auth related decisions.
     */
    if (data.isLive) {
      console.log('User is live')
    } else {
      console.log('User is not live')
    }
  }

  return (
    <ThemeProvider>
      {loading ? (
        <Loader />
      ) : (
        <FaceLivenessDetector
          sessionId={createLivenessApiData?.sessionId ?? 'test_liveness'}
          region={window.__env__.REACT_APP_AWS_REGION}
          onAnalysisComplete={handleAnalysisComplete}
          config={{ credentialProvider }}
          onError={(error) => {
            console.error(error)
          }}
        />
      )}
    </ThemeProvider>
  )
}
