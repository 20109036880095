import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 236,
  background: `url('/image/background/all-course-banner.svg')`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  gap: theme.spacing(6),
  margin: `${theme.spacing(3)} ${theme.spacing(10)} 0`,
  borderRadius: theme.spacing(0.5),
  [theme.breakpoints.down('lg')]: {
    margin: `${theme.spacing(3)} ${theme.spacing(4)} 0`,
  },
  [theme.breakpoints.down('sm')]: {
    margin: `0 ${theme.spacing(2)}`,
    height: 132,
  },
}))

export const StyledHeaderText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  margin: 'auto 30px',
  '& .MuiTypography-root#body': {
    width: '620px',
    wordBreak: 'break-all',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiTypography-root#body': { width: '335px' },
  },
}))
