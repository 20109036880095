import Link from '@mui/material/Link'
import styled from '@mui/system/styled'
import { MediaBoxStyled } from '../Styled'

export const StyledLinkBox = styled(MediaBoxStyled)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: `0 ${theme.spacing(6)}`,
  [theme.breakpoints.down('lg')]: { padding: `0 ${theme.spacing(3)}` },
  [theme.breakpoints.down('sm')]: { padding: `0 ${theme.spacing(2)}` },
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  color: '#5027C8',
  width: '70%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: { width: '100%' },
}))
