import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { classLearner } from '../../../utils/apiPath'

export const getRegistrationDetail = createAsyncThunk(
  'getRegistrationDetail',
  async ({ uuid, classLearnerUuid }, { rejectWithValue }) => {
    try {
      if (!classLearnerUuid) classLearnerUuid = false
      const response = await axios.get(
        `${classLearner}/${uuid}/${classLearnerUuid}`
      )

      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
