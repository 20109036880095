import React from 'react'
import _ from 'lodash'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CameraOutdoor from '@mui/icons-material/CameraOutdoor'
import InsertInvitationOutlined from '@mui/icons-material/InsertInvitationOutlined'
import TimerOutlined from '@mui/icons-material/TimerOutlined'
import WidgetsOutlined from '@mui/icons-material/WidgetsOutlined'
import { Column, Row } from 'src/components/Layout/Styled'
import { convertDateRange } from 'src/utils/lib'
import {
  StyledCourseCard,
  StyledCourseName,
  StyledDetail,
  StyledImg,
} from './Styled'

const CourseCard = ({ data }) => {
  const imageKey = _.get(data, 'imageKey', '-')
  const imgUrl = `${window.__env__.REACT_APP_FILE_URL}${imageKey}`
  const dateRange = convertDateRange(_.get(data, 'dateRangeMonthlyPlan', {}))
  const courseName =
    _.get(data, 'course.nameForLearner', '') || _.get(data, 'courseName', '-')
  return (
    <StyledCourseCard>
      <StyledImg id="img">
        <img src={imgUrl} />
      </StyledImg>
      <Column id="text">
        <Row gap={2}>
          <StyledImg id="img-sm">
            <img src={imgUrl} />
          </StyledImg>
          <StyledCourseName>{courseName}</StyledCourseName>
        </Row>
        <StyledDetail>
          <InsertInvitationOutlined fontSize="small" />
          <Typography variant="body2">{dateRange}</Typography>
          <Divider orientation="vertical" flexItem />
          <TimerOutlined fontSize="small" />
          <Typography variant="body2">
            {_.get(data, 'hour', 0)} ชั่วโมง {_.get(data, 'minute', 0)} นาที
          </Typography>
          <Divider orientation="vertical" flexItem />
          <CameraOutdoor fontSize="small" />
          <Typography variant="body2">
            {_.get(data, 'classRoomType', '-')}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <WidgetsOutlined fontSize="small" />
          <Typography variant="body2">{_.get(data, 'roadMap', '-')}</Typography>
        </StyledDetail>
      </Column>
    </StyledCourseCard>
  )
}

export default CourseCard
