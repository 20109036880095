import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { handleCountdown } from './events'
import { StyledTypography } from './Styled'

const DialogCountdown = ({ isCamera }) => {
  const { id: uuid } = useParams()
  const { mins, secs } = useSelector((state) => state.eLearningLearning.time)
  const dispatch = useDispatch()

  useEffect(() => {
    let timer = null
    timer = setInterval(() => {
      dispatch(handleCountdown(uuid, timer))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  const minStr = mins.toString().padStart(2, '0')
  const secStr = secs.toString().padStart(2, '0')

  if (isCamera) {
    return <StyledTypography>{`${minStr}:${secStr}`}</StyledTypography>
  } else {
    return <Typography variant="span">{`${minStr}:${secStr}`}</Typography>
  }
}

export default DialogCountdown
