import React, { useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Preview from 'src/modules/EEvaluation'
import Evaluate from 'src/modules/EEvaluation/Evaluate'
import { MediaBoxStyled } from '../Styled'
import { fetchEEvaluation } from './events'

const EEvaluation = ({ selectedLesson }) => {
  const { id: eLearningCourse } = useParams()
  const uuid = _.get(selectedLesson, 'eEvaluationUuid', '')
  const eLearningLessonRefModule = _.get(
    selectedLesson,
    'eLearningLessonRefModule',
    ''
  )
  const payload = { eLearningCourse, uuid }
  const isInProgress = _.get(selectedLesson, 'isInProgress', false)
  const isSubmitted = _.get(selectedLesson, 'isSubmitted', false)
  const endTime = _.get(selectedLesson, 'endTime', '')
  const isReview = isSubmitted && !_.isEmpty(endTime)
  // isReview = true คือทำแล้วและไม่มีการเปลี่ยนแปลงโมดูลมาจากหลังบ้าน
  // ถ้าทำแล้ว แต่มีการเปลี่ยนแปลงที่หลังบ้าน endTime จะเป็น null
  const isLessonLoading = useSelector(
    (state) => state.eLearningLearning.isLessonLoading,
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchEEvaluation(payload, isReview))
  }, [uuid, eLearningLessonRefModule])

  if (isLessonLoading) return <MediaBoxStyled />

  if (isInProgress || isSubmitted) return <Evaluate isModule />
  return <Preview isModule />
}

export default EEvaluation
