import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Template from 'src/components/Template'
import { Column, Row } from 'src/components/Layout/Styled'
import InputText from 'src/components/Input/TextInput'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import Stepper from './Stepper'
import Step1 from './Step1'
import Step2 from './Step2'
import Consent from './Consent'
import Footer from './Footer'
import { StyledContainer } from './Styled'
import { fetchCourseDetail } from './events'

const Enroll = () => {
  const { id: uuid } = useParams()
  const activeStep = useSelector(
    (state) => state.eLearningEnroll.activeStep,
    shallowEqual
  )
  const dispatch = useDispatch()
  const isShowConsent = activeStep === 1

  useEffect(() => {
    dispatch(fetchCourseDetail(uuid))
  }, [])

  return (
    <>
      <Template sx={{ marginTop: 9 }} noMinHeight={isShowConsent}>
        <StyledContainer>
          <Stepper activeStep={activeStep} />
          {StepContent[activeStep]}
        </StyledContainer>
      </Template>
      {isShowConsent && <Consent />}
      <Footer />
    </>
  )
}

export default Enroll

export const StepContent = [<Step1 key="step-1" />, <Step2 key="step-2" />]

export const StepTitle = ({ label, description, isWidthAuto }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <Column
      gap={1}
      pb={isNotSm ? 0 : isWidthAuto ? 0 : 1}
      sx={{ width: isWidthAuto ? 'auto' : '100%' }}
    >
      <Typography variant={isNotSm ? 'h4' : 'h6'}>{label}</Typography>
      {description && (
        <Typography variant={isNotSm ? 'body1' : 'body2'}>
          <Typography variant="span" color="error">
            *
          </Typography>{' '}
          {description}
        </Typography>
      )}
    </Column>
  )
}

export const SectionLabelIcon = ({ label, icon, disabled, isWidthAuto }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <Row
      id="section-label"
      sx={{ gap: 1, width: isWidthAuto ? 'auto' : '100%' }}
    >
      <img src={`/icon/e-learning/${icon}${disabled ? '_disable' : ''}.svg`} />
      <Typography
        variant={isNotSm ? 'h6' : 'body1b'}
        color={disabled ? 'action.disabled' : 'text.primary'}
      >
        {label}
      </Typography>
    </Row>
  )
}

export const DisabledInput = ({ label, value, placeholder }) => {
  return (
    <InputText
      required
      disabled
      type="basic"
      labelText={label}
      placeholder={placeholder}
      isShowTextError={false}
      value={value}
    />
  )
}
