import dayjs from 'dayjs'
import _ from 'lodash'

import {
  setCurrentDate,
  setDrawerFilter,
} from '../../../../redux/slices/myProfile'

export const MONTH_LIST = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
]

export const optionYear = () => {
  const newDate = new Date()
  let year = newDate.getFullYear()
  const start = year - 10
  const end = year + 10
  let options = []
  for (let index = start; index <= end; index++) {
    options = [...options, { label: `${index}`, value: index }]
  }
  return options
}

export const handleNextAndPrev = (props) => (dispatch) => {
  const { type, currentDate, calendarRef, tapMenu } = props
  let result = ''
  const numDay = tapMenu === 'WEEKLY' ? 7 : 1
  const typeDay = tapMenu === 'MONTH' ? 'month' : 'day'
  if (type === 'prev') {
    const dataPrev = dayjs(currentDate).subtract(numDay, typeDay)
    result = dayjs(new Date(dataPrev)).format(window.__env__.REACT_APP_DATE_DB)
  } else if (type === 'next') {
    const dataNext = dayjs(currentDate).add(numDay, typeDay)
    result = dayjs(new Date(dataNext)).format(window.__env__.REACT_APP_DATE_DB)
  }

  let startDate
  let toDate

  if (tapMenu === 'WEEKLY') {
    startDate = getDateInWeek(result)[0]
    toDate = getDateInWeek(result)[6]
  } else if (tapMenu === 'MONTH') {
    const date = new Date(result),
      y = date.getFullYear(),
      m = date.getMonth()
    const firstDay = new Date(y, m, 1)
    const lastDay = new Date(y, m + 1, 0)
    startDate = dayjs(new Date(firstDay)).format(window.__env__.REACT_APP_DATE_DB)
    toDate = dayjs(new Date(lastDay)).format(window.__env__.REACT_APP_DATE_DB)
  } else {
    startDate = result
    toDate = result
  }

  gotoDateCalendar(calendarRef, result)
  dispatch(
    setCurrentDate({
      currentDate: result,
      startDate,
      toDate,
    })
  )
}

export const fetchCalendar = (props) => {
  const { bodyCalendar, calendarRef } = props
  gotoDateCalendar(calendarRef, bodyCalendar.startDate)
}

export const gotoDateCalendar = (calendarRef, result) => {
  if (calendarRef.current) {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.gotoDate(result)
  }
}

export const getDateInWeek = (currentDate) => {
  const current = new Date(currentDate)
  const first = current.getDate() - current.getDay()
  let result = []
  for (let i = 0; i < 7; i++) {
    let next = new Date(current.getTime())
    next.setDate(first + i)
    result.push(dayjs(next).format(window.__env__.REACT_APP_DATE_DB))
  }
  return result
}

export const handleDatePicker =
  ({ value, calendarRef, tapMenu }) =>
  (dispatch) => {
    const currentDate = dayjs(new Date(value)).format(
      window.__env__.REACT_APP_DATE_DB
    )
    gotoDateCalendar(calendarRef, currentDate)

    dispatch(
      setCurrentDate({
        currentDate,
        startDate:
          tapMenu === 'DAILY' ? currentDate : getDateInWeek(currentDate)[0],
        toDate:
          tapMenu === 'DAILY' ? currentDate : getDateInWeek(currentDate)[6],
      })
    )
  }

export const checkDate = (currentDate, type) => {
  const result = _.defaultTo(
    currentDate,
    dayjs(new Date()).format(window.__env__.REACT_APP_DATE_DB)
  )
  const year = result.split('-')[0]
  const month = result.split('-')[1]
  return type === 'month' ? Number(month) : Number(year)
}

export const convertMonth = (value) =>
  `${value}`.length === 1 ? `0${value}` : value

export const handleSelectMonthYear = (props) => (dispatch) => {
  const { type, value, currentDate, calendarRef } = props
  let year = currentDate.split('-')[0]
  let month = currentDate.split('-')[1]
  if (type === 'month') month = value
  if (type === 'year') year = value

  const result = `${year}-${convertMonth(month)}-01`
  gotoDateCalendar(calendarRef, result)

  const date = new Date(result),
    y = date.getFullYear(),
    m = date.getMonth()
  const firstDay = new Date(y, m, 1)
  const lastDay = new Date(y, m + 1, 0)
  const startDate = dayjs(new Date(firstDay)).format(
    window.__env__.REACT_APP_DATE_DB
  )
  const toDate = dayjs(new Date(lastDay)).format(window.__env__.REACT_APP_DATE_DB)

  dispatch(
    setCurrentDate({
      currentDate: result,
      startDate,
      toDate,
    })
  )
}

export const handleFilter = () => (dispatch) => {
  dispatch(setDrawerFilter())
}
