import React, { useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getQueryParams } from '../../../utils/lib'
import { StyledContainer } from '../../../components/Layout/Styled'
import Breadcrumb from '../../../components/Breadcrumb'
import Template from '../../../components/Template'

import ClassMenu from './ClassMenu'
import ClassStatus from './ClassStatus'
import ClassTable from './ClassTable'
import Header from '../Header'

import { listBreadcrumbs } from './model'
import { fetchDataRegistration } from './events'

const RegistrationDetail = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const classLearnerUuid = getQueryParams('classLearnerUuid')
  const data = useSelector((state) => state.registration.data, shallowEqual)
  const cStatus = _.get(data, 'classLearnerStatus', '')

  useEffect(() => {
    dispatch(fetchDataRegistration(uuid, classLearnerUuid))
  }, [uuid, classLearnerUuid])

  return (
    <Template>
      <StyledContainer>
        <Breadcrumb list={listBreadcrumbs} />
        <Header />
        <ClassStatus />

        <ClassMenu />
        {cStatus === 'ELIGIBLE_ANNOUNCE' && <ClassTable />}
      </StyledContainer>
    </Template>
  )
}

export default RegistrationDetail
