export const formatData = (field, isColor, type) => {
  let color = ''
  let text = ''
  if (type === 'E_TESTING') {
    switch (field) {
      case 'PASS':
        text = 'ผ่าน'
        color = 'success.main'
        break
      case 'NOT_PASS':
        text = 'ไม่ผ่าน'
        color = 'error.main'
        break
      case 'WAITING':
        text = 'รอตรวจ'
        color = 'warning.main'
        break
      case 'NOT_SENT':
        text = 'ไม่ได้ทำ'
        color = 'action.disabled'
        break
      case 'WAITING_ASSIGNEE_CALL':
        text = 'รอวิทยากรเรียก'
        color = 'action.disabled'
        break
      default:
        text = '-'
        break
    }
  } else {
    switch (field) {
      case 'SENT':
        text = 'ทำแล้ว'
        color = 'success.main'
        break
      case 'NOT_SENT':
        text = 'ไม่ได้ทำ'
        color = 'action.disabled'
        break
      default:
        text = '-'
        break
    }
  }

  return isColor ? color : text
}

export const changeColor = (field) => {
  let color = ''
  switch (field) {
    case 'OPENING':
      color = 'success.main'
      break
    case 'NOT_OPEN_YET':
      color = 'error.main'
      break
  }
  return color
}
