import React from 'react'
import { useTheme } from '@mui/material/styles'
import { StyledActionBar } from '../Styled'
import { Box, Button, Typography } from '@mui/material'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { setProgress } from 'src/redux/slices/eExamination'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { progressTitle } from '../constant'
import { handleSubmit, handlenextStep } from './events'

const ActionBar = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { progress, nextButtonDisabled } = useSelector(
    (state) => ({
      progress: state.eExamination.progress,
      nextButtonDisabled: state.eExamination.nextButtonDisabled,
    }),
    shallowEqual
  )
  return (
    <Box
      sx={{
        height: 82,
        background: theme.palette.primary.main,
        marginTop: 8,
      }}
    >
      <StyledActionBar>
        <Box
          sx={{
            minWidth: { sm: 128, xs: 68 },
          }}
        >
          {progress !== 0 && (
            <Button
              sx={{
                height: { sm: 50 },
                fontSize: { sm: 19, xs: 17 },
                padding: { sm: '12px 35px', xs: '4px 10px' },
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              }}
              variant="outlined"
              color="white"
              size="m"
              onClick={() => dispatch(setProgress(progress - 1))}
            >
              ย้อนกลับ
            </Button>
          )}
        </Box>
        <Box>
          <Typography
            sx={{
              typography: { lg: 'h6', sm: 'body1b', xs: 'body2b' },
              textAlign: 'center',
            }}
            color="white.main"
          >
            {progressTitle[progress]}
          </Typography>
        </Box>
        <Box>
          {progress !== 2 ? (
            <Button
              sx={{
                height: { sm: 50 },
                fontSize: { sm: 19, xs: 17 },
                padding: { sm: '12px 35px', xs: '4px 10px' },
              }}
              variant="contained"
              color="secondary"
              size="m"
              disabled={nextButtonDisabled}
              onClick={() => dispatch(handlenextStep(progress))}
            >
              ถัดไป
              <ArrowForwardIcon sx={{ ml: 1 }} />
            </Button>
          ) : (
            <Button
              sx={{
                height: { sm: 50 },
                fontSize: { sm: 19, xs: 17 },
                padding: { sm: '12px 35px', xs: '4px 10px' },
              }}
              variant="contained"
              color="secondary"
              size="m"
              disabled={nextButtonDisabled}
              onClick={() => dispatch(handleSubmit())}
            >
              ส่งเอกสารและสมัครสอบ
            </Button>
          )}
        </Box>
      </StyledActionBar>
    </Box>
  )
}

export default ActionBar
