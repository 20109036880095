export const manageProspectCells = [
  {
    id: 'actionImage',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'fullNameTH',
    disablePadding: false,
    label: 'ชื่อ',
    width: '190px',
  },
  {
    id: 'mobileNo',
    label: 'เบอร์โทรศัพท์',
  },
  {
    id: 'email',
    label: 'อีเมล',
    width: '210px',
  },
  {
    id: 'consent',
    label: 'การให้เข้าถึงข้อมูล',
  },
  {
    id: 'register',
    label: 'สมัครเข้าอบรม',
    minWidth: '120px',
  },
  {
    id: 'agent',
    label: 'ตัวแทน',
  },
  {
    id: 'statusProspect',
    label: 'สถานะ',
    width: '70px',
  },
  {
    id: 'resendButton',
    label: '',
  },
  {
    id: 'actionCommandProspect',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    viewPath: `/manage-prospect/view`,
    editPath: `/manage-prospect`,
  },
]
