import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styled from '@mui/system/styled'

export const StyledTable = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: { width: '100%' },
}))

export const StyledTableHead = styled(Box)(({ theme }) => ({
  color: theme.palette?.primary?.main,
  display: 'flex',
  gap: '24px',
  padding: '12px 24px',
  marginBottom: theme.spacing(1),
  height: '48px',
  borderBottom: '1px solid',
  borderColor: theme.palette?.primary?.main,
}))

export const StyledTableCell = styled(Box)(
  ({ theme, width, align, isHead, isResult }) => ({
    width: width,
    textAlign: align ? align : 'center',
    height: isHead ? '' : '82px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: align,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      flexDirection: isResult ? 'column' : 'row',
      alignItems: isResult ? 'flex-end' : 'flex-start',
      '& > .MuiBox-root': {
        justifyContent: isResult ? 'flex-end' : 'center',
      },
    },
  })
)

export const StyledTableRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  borderBottom: `1px solid ${theme.palette?.other?.divider}`,
  padding: '16px 24px',
}))

export const CertContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '40px',
  justifyContent: 'space-between',
  backgroundColor: theme.palette?.background?.paper,
  border: `1px solid ${theme.palette?.blue?.blueBorder}`,
  borderRadius: '2px',
  boxShadow: `0px 12px 40px rgba(73, 118, 186, 0.1)`,
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: '552px',
  margin: '0px auto',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const CertDetailContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const StyledLayoutBox = styled(Grid)(({ url }) => ({
  width: '3508px',
  height: '2480px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundImage: `url(${url})`,
  'transform': 'scale(0.057)',
  'transform-origin': '0 0',
  '-webkit-text-size-adjust': 'auto',
  '& .MuiTypography-root': { lineHeight: 1.5 },
}))

export const StyledCardBoxPreview = styled(Box)(() => ({
  width: '200px',
  height: '140px'
}))
