import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledNotiDot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 5,
  top: 7,
  width: '6px !important',
  height: 6,
  background: theme.palette.secondary?.main,
  borderRadius: '100%',
}))
