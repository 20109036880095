import _ from 'lodash'
import { path } from 'src/constants/path'
import { TRAINING_TYPE } from '../../constant'

export const handleToDetail = (data, history, isCheck) => {
  const trainingType = _.get(data, 'trainingType', '')

  if (trainingType !== TRAINING_TYPE.E_LEARNING) {
    const classLearnerUuid = _.get(data, 'classLearnerUuid', '')
    const uuid = _.get(data, 'trainingPlanUuid', '')
    const registrationPage = `/my-class/registration-detail/${uuid}?classLearnerUuid=${classLearnerUuid}`
    history.push(registrationPage)
    return
  }

  if (isCheck) {
    const courseBatchLearnerUuid = _.get(data, 'courseBatchLearnerUuid', '')
    history.push(`${path.myClassELearning}/${courseBatchLearnerUuid}`)
  } else {
    const eLearningCourseUuid = _.get(data, 'eLearningCourseUuid', '')
    history.push(`/e-learning/course/${eLearningCourseUuid}`)
  }
}
