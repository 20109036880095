import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledDetailCard = styled(Row, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ theme, fullWidth }) => ({
  width: fullWidth ? '100%' : 'calc(50% - 8px)',
  maxHeight: 82,
  minHeight: 82,
  background: theme.palette?.background?.paper,
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueBorder,
  borderRadius: theme.spacing(0.5),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  gap: theme.spacing(3),
  justifyContent: fullWidth ? 'center' : 'start',
  '& > .MuiTypography-root': { textAlign: 'center' },
  svg: { width: 34, height: 34, color: theme.palette?.primary?.main },
  [theme.breakpoints.down('lg')]: {
    width: fullWidth ? '100%' : 'calc(33% - 9px)',
  },
  [theme.breakpoints.down('sm')]: {
    width: fullWidth ? '100%' : 'calc(50% - 5px)',
    minHeight: 65,
    padding: `6px ${theme.spacing(1)}`,
    gap: 10,
    '&#timeSpan': { paddingRight: 0 },
    img: { width: 20, height: 20 },
    '& > .MuiTypography-root': { textAlign: 'start' },
  },
}))
