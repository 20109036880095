import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const StyledContainer = styled(Column)(({ theme }) => ({
  paddingTop: theme.spacing(16),
  paddingBottom: theme.spacing(16),
  gap: theme.spacing(5),
  alignItems: 'center',
  '& > .MuiBox-root': { maxWidth: 640 },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const StyledHeadContent = styled(Column)(({ theme }) => ({
  gap: theme.spacing(1),
  textAlign: 'center',
  span: { color: theme.palette.warning?.main },
}))
