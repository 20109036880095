import _ from 'lodash'
import { fetchDataList, fetchDataListStandalone } from '../events'
import {
  DEFAULT_STATUS,
  DEFAULT_STATUS_STANDALONE,
  DEFAULT_TYPE,
  DEFAULT_TYPE_STANDALONE,
  objStatus,
  objStatusStandalone,
  objType,
  objTypeStandalone,
} from '../model'
import {
  setSearchText,
  resetTablePage,
  setToggleFilterDrawer,
} from '../../../../redux/slices/table'
import { convertFormatDateTime } from '../../../../utils/lib'
import { toDoType } from '../../../../constants/toDo'

export const handleChange = (props) => {
  const { value, key, stateFilter, setFilter, listKey } = props
  let objState = {}
  if (listKey) {
    objState = {
      ...stateFilter,
      [listKey]: {
        ...stateFilter[listKey],
        [key]: value,
      },
    }
  } else {
    objState = {
      ...stateFilter,
      [key]: value,
    }
  }
  setFilter(objState)
}

export const handleSelectDate = ({
  item,
  selectedDates,
  stateFilter,
  setFilter,
}) => {
  let objState = {
    ...stateFilter,
    [item.startDate]: selectedDates.startDate,
    [item.endDate]: selectedDates.endDate,
    [item.display]: selectedDates.display,
  }
  setFilter(objState)
}

export const handleResetPeriod = ({ item, stateFilter, setFilter }) => {
  let objState = {
    ...stateFilter,
    [item.startDate]: new Date(),
    [item.endDate]: new Date(),
    [item.display]: false,
  }
  setFilter(objState)
}

export const onFilterClick = (filter, type, table) => (dispatch) => {
  dispatch(setSearchText(''))

  let body = handleFilterBody(filter)
  if (type === toDoType.standalone) body = handleFilterBodyStandalone(filter)

  dispatch(resetTablePage())
  dispatch(setToggleFilterDrawer(false))
  if (type === toDoType.standalone) {
    dispatch(fetchDataListStandalone({ body, type, table }))
  } else {
    dispatch(fetchDataList({ body, type, table }))
  }
}

export const handleFilterBody = (filter) => {
  const courseNameChecked = _.get(filter, 'courseNameChecked', false)
  const courseName = _.get(filter, 'courseName', false)
  const typeChecked = _.get(filter, 'typeChecked', false)
  const typeFilter = _.get(filter, 'type', {})
  const handlingDateChecked = _.get(filter, 'handlingDateChecked', false)
  const startDateHandlingDate = convertFormatDateTime({
    type: 'dateDb',
    value: _.get(filter, 'startDateHandlingDate', ''),
  })
  const toDateHandlingDate = convertFormatDateTime({
    type: 'dateDb',
    value: _.get(filter, 'toDateHandlingDate', ''),
  })

  const trainingDateChecked = _.get(filter, 'trainingDateChecked', false)
  const startDateTraining = convertFormatDateTime({
    type: 'dateDb',
    value: _.get(filter, 'startDateTraining', ''),
  })
  const toDateTraining = convertFormatDateTime({
    type: 'dateDb',
    value: _.get(filter, 'toDateTraining', ''),
  })
  const statusChecked = _.get(filter, 'statusChecked', false)
  const status = _.get(filter, 'status', {})
  const body = {
    sort: 'courseName',
    order: 'DESC',
    limit: 20,
    page: 1,
    courseName: courseNameChecked ? courseName : '',
    type: typeChecked ? filterChecked(typeFilter, 'type') : DEFAULT_TYPE,
    monthlyPlanStartDate: trainingDateChecked ? startDateTraining : '',
    monthlyPlanFinishDate: trainingDateChecked ? toDateTraining : '',
    classStartDate: handlingDateChecked ? startDateHandlingDate : '',
    classFinishDate: handlingDateChecked ? toDateHandlingDate : '',
    status: statusChecked ? filterChecked(status, 'status') : DEFAULT_STATUS,
  }

  return body
}

export const handleFilterBodyStandalone = (filter) => {
  const nameChecked = _.get(filter, 'nameChecked', false)
  const name = _.get(filter, 'name', false)
  const typeChecked = _.get(filter, 'typeChecked', false)
  const typeFilter = _.get(filter, 'type', {})
  const statusChecked = _.get(filter, 'statusChecked', false)
  const status = _.get(filter, 'status', {})
  const body = {
    sort: 'createdAt',
    order: 'DESC',
    limit: 20,
    page: 1,
    name: nameChecked ? name : '',
    type: typeChecked
      ? filterChecked(typeFilter, 'type', true)
      : DEFAULT_TYPE_STANDALONE,
    status: statusChecked
      ? filterChecked(status, 'status', true)
      : DEFAULT_STATUS_STANDALONE,
  }
  return body
}

export const filterChecked = (obj, type, isStandalone) => {
  let result = []
  let defaultValue = isStandalone ? DEFAULT_STATUS_STANDALONE : DEFAULT_STATUS
  let objItem = isStandalone ? objStatusStandalone : objStatus
  if (type != 'status') {
    defaultValue = isStandalone ? DEFAULT_STATUS_STANDALONE : DEFAULT_TYPE
    objItem = isStandalone ? objTypeStandalone : objType
  }
  for (let key in obj) {
    const value = obj[key]
    if (value) result.push(objItem[key])
  }

  return result.length ? result : defaultValue
}
