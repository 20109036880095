import React from 'react'
import Typography from '@mui/material/Typography'
import { StyledUnderlinedRow } from './Styled'

const ContentTitle = ({ icon, title }) => {
  return (
    <StyledUnderlinedRow>
      {icon}
      <Typography variant="h6" color="primary.main">
        {title}
      </Typography>
    </StyledUnderlinedRow>
  )
}

export default ContentTitle
