import React, { useState, useEffect } from 'react'
import { io } from 'socket.io-client'
import _ from 'lodash'
import { LoadingButton } from '@mui/lab'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Template from 'src/components/Template'
import { StyledContainer, StyledHeadContent } from './Styled'
import { Column, Row } from 'src/components/Layout/Styled'
import DocumentInput from './DocumentInput'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getQueryParams, isMediaWidthMoreThan } from 'src/utils/lib'
import {
  fetchLearnerDocument,
  handleConsent,
  handleSubmitDocument,
  handleExpiredDate,
  handleSocketResponse,
  handleError,
  handleSuccess,
  handleErrorSocket,
} from './events'
import CourseCard from './CourseCard'

const { __env__: env } = window

const socketUrl = env.REACT_APP_SOCKET_URL

const socket = io.connect(`${socketUrl}`, {
  autoConnect: false,
  transports: ['websocket', 'polling'],
  auth: {
    Authorization: localStorage.getItem('token'),
  },
})

const Document = () => {
  const { id: uuid } = useParams()
  const classLearner = getQueryParams('id')
  const secretTrainingPlan = getQueryParams('secret')
  const [isEnable, setEnable] = useState(false)
  const [socketId, setSocketId] = useState('')
  const [socketError, setSocketError] = useState({})

  const isLoading = useSelector(
    (state) => state.loading.isLoading,
    shallowEqual
  )
  const { isSuccess, isResend, data, error, requestData, callbackUrl } =
    useSelector((state) => state.document, shallowEqual)
  const documentList = _.get(data, 'documentList', [])
  const dispatch = useDispatch()
  const payload = { uuid, classLearner, requestData, secretTrainingPlan }
  const date = handleExpiredDate()
  const isNotSm = isMediaWidthMoreThan('sm')
  socket.connect()

  useEffect(() => {
    function onConnect() {
      if (socket.connected) {
        setSocketId(socket.id)

        socket.on(socket.id, (res) => {
          if (!res.success) setSocketError(res.content)
          else dispatch(handleSocketResponse(res, error))
        })
      }
    }

    function onDisconnect() {
      socket.disconnect()
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
    }
  }, [])

  useEffect(() => {
    dispatch(fetchLearnerDocument(payload))
  }, [])

  useEffect(() => {
    if (!_.isEmpty(socketError)) {
      dispatch(handleErrorSocket(socketError, callbackUrl))
    }
  }, [socketError, callbackUrl])

  useEffect(() => {
    dispatch(handleError(error))
  }, [error])

  useEffect(() => {
    isSuccess && isResend && dispatch(handleSuccess({ uuid }, isResend))
  }, [isSuccess])

  return (
    <Template>
      <StyledContainer>
        {data && (
          <>
            <StyledHeadContent>
              <Typography variant="h4">
                อัปโหลดเอกสาร{isResend && 'ใหม่'}
              </Typography>

              {isResend ? (
                <Column>
                  <Typography>
                    กรุณาอัปโหลดเอกสารให้ครบถ้วน คุณสามารถอัปโหลดเอกสารได้อีก{' '}
                    <Typography component="span" variant="h6">
                      1 ครั้ง
                    </Typography>{' '}
                  </Typography>
                  <Typography>
                    ภายในวันที่ {date} เวลา 23:59 น. หากเลยวันเวลาที่กำหนด
                    กรุณาลงทะเบียนใหม่อีกครั้ง
                  </Typography>
                </Column>
              ) : (
                <Typography>
                  กรุณาอัปโหลดเอกสารให้ครบถ้วน
                  เพื่อประกอบการพิจารณาสิทธิ์เข้าอบรม
                </Typography>
              )}
            </StyledHeadContent>

            <CourseCard data={data} />

            {documentList.map((item, index) => (
              <DocumentInput
                key={index}
                index={index}
                label={item.title}
                isAllow={item.allow}
              />
            ))}

            <Column sx={{ gap: 3, alignItems: 'center' }}>
              <Row sx={{ justifyContent: 'center' }}>
                <Checkbox
                  data-testid="checkbox-consent"
                  defaultChecked={false}
                  onChange={(e) => setEnable(e.target.checked)}
                />
                <Typography>
                  ผู้เรียนยินยอมที่จะเผยแพร่เอกสารที่มีข้อมูล
                  {!isNotSm && <br />}ส่วนบุคคลเพื่อใช้ในการสมัคร
                  <Link
                    data-testid="btn-consent"
                    component="span"
                    onClick={() => dispatch(handleConsent())}
                    sx={{ ml: 1, cursor: 'pointer' }}
                  >
                    อ่านเพิ่มเติม
                  </Link>
                </Typography>
              </Row>
              <LoadingButton
                data-testid="btn-send"
                variant="contained"
                size="l"
                loadingPosition="end"
                loading={isLoading}
                endIcon={<></>}
                disabled={!isEnable || isSuccess}
                onClick={() =>
                  dispatch(handleSubmitDocument(payload, socketId))
                }
              >
                ส่งเอกสาร{!isResend && 'และสมัครอบรม'}
              </LoadingButton>
            </Column>
          </>
        )}
      </StyledContainer>
    </Template>
  )
}

export default Document
