import styled from '@mui/system/styled'
import { Row } from 'src/components/Layout/Styled'

export const StyledProgressCard = styled(Row)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  justifyContent: 'start',
  alignItems: 'center',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
  gap: theme.spacing(10),
  boxShadow: '0px 12px 40px 0px rgba(73, 118, 186, 0.10)',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(4),
    justifyContent: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}))
