import React, { useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'

import Footer from '../../../components/AlertDialog/Footer'
import * as Styled from './Styled'

import {
  handleChecked,
  handleDownloadTemplate,
  handleUploadTemplate,
  handleLeft,
  handleRight,
} from './events'

export const TableUpload = () => {
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)
  const { resultUpload } = useSelector(
    (state) => state.manageProspectUpload,
    shallowEqual
  )

  const rows = _.get(resultUpload, 'result', [])
  const findSuccess = rows.find((item) => item.status === 'SUCCESS')

  return (
    <>
      <Styled.BoxContainer>
        <Styled.BoxTableContainer>
          <Table aria-label="table-manage-prospect" sx={{ minWidth: 520 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">No.</TableCell>
                <TableCell align="left">ชื่อ - นามสกุล</TableCell>
                <TableCell align="left">สถานะการเพิ่มผู้เรียน</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item) => (
                <Styled.BoxTableRow
                  status={_.get(item, 'status', 'ERROR') == 'SUCCESS'}
                  key={item.index}
                >
                  <TableCell align="left">
                    {item.index < 10 ? `0${item.index}` : item.index}
                  </TableCell>
                  <TableCell align="left">{`${_.get(
                    item,
                    'firstNameTH',
                    ''
                  )} ${_.get(item, 'lastNameTH', '')}`}</TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color:
                        _.get(item, 'status', 'ERROR') != 'SUCCESS'
                          ? '#C91432'
                          : '#1CC54E',
                    }}
                  >
                    {_.get(item, 'statusText', '')}
                  </TableCell>
                </Styled.BoxTableRow>
              ))}
            </TableBody>
          </Table>
        </Styled.BoxTableContainer>
      </Styled.BoxContainer>
      {findSuccess && (
        <Styled.BoxFooter>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e) =>
                  dispatch(handleChecked(e.target.checked, setChecked))
                }
                name="check"
              />
            }
            label={<Typography variant="body1">เพิ่มรายชื่อสมาชิก</Typography>}
          />
        </Styled.BoxFooter>
      )}
    </>
  )
}

export const ButtonUpload = ({ htmlFor, fileName, onUpload }) => {
  return (
    <label htmlFor={htmlFor}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button variant="contained" size="s" component="span" htmlFor={htmlFor}>
          <Styled.InputUpload
            accept=".excel, .xlsx, .xls, .csv"
            type="file"
            id={htmlFor}
            onChange={onUpload}
          />
          <Box sx={{ display: 'flex' }}>
            <Typography variant="buttons">เลือกไฟล์</Typography>
          </Box>
        </Button>
        <Typography variant="body2" color="text.secondary">
          {fileName ? fileName : 'ยังไม่ได้เลือกไฟล์'}
        </Typography>
      </Box>
    </label>
  )
}

const Index = () => {
  const dispatch = useDispatch()
  const [, setProspect] = useLocalStorage('prospect')
  const [, setProspectDetail] = useLocalStorage('prospectDetail')

  const [isClick, setIsClick] = useState(false)
  const [user] = useLocalStorage('user')

  const { resultUpload } = useSelector(
    (state) => state.manageProspectUpload,
    shallowEqual
  )
  const abbreviation = _.get(user, 'distributionChannel.abbreviation', '')
  return (
    <Box>
      <Styled.BoxContent>
        <Styled.BoxTemplate>
          <Button
            size="s"
            variant="contained"
            onClick={() => dispatch(handleDownloadTemplate(abbreviation))}
          >
            <Typography variant="buttons">ดาวน์โหลด Template</Typography>
          </Button>
          <Typography variant="body2">
            เพิ่มได้ไม่เกิน 50 รายชื่อ/ครั้ง
          </Typography>
        </Styled.BoxTemplate>

        <Box sx={{ mt: 4 }}>
          <Typography variant="body2">อัปโหลดรายชื่อผู้เรียน</Typography>
          <Box sx={{ mt: 1 }}>
            <ButtonUpload
              htmlFor="btn-upload"
              testId="btn-upload"
              fileName={_.get(resultUpload, 'fileName', '')}
              onUpload={(files) => dispatch(handleUploadTemplate(files))}
            />
          </Box>
        </Box>
      </Styled.BoxContent>

      <Footer
        disableButton={isClick}
        buttonLeft={{
          handleClick: () => dispatch(handleLeft()),
        }}
        buttonRight={{
          handleClick: () => {
            if (_.get(resultUpload, 'fileName', '')) {
              dispatch(
                handleRight(
                  _.get(resultUpload, 'files', {}),
                  setProspect,
                  setProspectDetail
                )
              )
              setIsClick(true)
            }
          },
        }}
      />
    </Box>
  )
}

export default Index
