import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  learninPointCatalogFilter,
  learninPointMyPoint,
} from 'src/utils/apiPath'

export const postCatalogFilter = createAsyncThunk(
  'catalog/postCatalogFilter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(learninPointCatalogFilter, body)
      const { data } = response
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const getMyPoint = createAsyncThunk(
  'catalog/getMyPoint',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(learninPointMyPoint)
      const { data } = response
      return data
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
