import React from 'react'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { ClassStatusContainer } from 'src/modules/MyClass/RegistrationDetail/ClassStatus/Styled'
import {
  E_LEARNING_LEARNER_RESULT,
  E_LEARNING_LEARNER_RESULT_TH,
} from 'src/constants/eLearning'

const LearnerResult = () => {
  const data = useSelector((state) => state.registration.data, shallowEqual)
  const learnerResult = _.get(
    data,
    'eLearningCourseBatchLearner.learnerResult',
    ''
  )
  const isPass =
    learnerResult === E_LEARNING_LEARNER_RESULT.COMPLETE ||
    learnerResult === E_LEARNING_LEARNER_RESULT.PASS
  const color = isPass ? 'success' : 'error'

  return (
    <ClassStatusContainer sx={{ height: 'auto' }} color={`${color}.background`}>
      <img src={`/image/class/detail/${isPass ? 'success' : 'fail'}.svg`} />
      <Typography variant="h4" color={`${color}.main`}>
        {E_LEARNING_LEARNER_RESULT_TH[learnerResult]}
      </Typography>
    </ClassStatusContainer>
  )
}

export default LearnerResult
