import React, { useEffect } from 'react'
import useLocalStorage from '@rehooks/local-storage'
import { useAuth } from 'react-oidc-context'
import _ from 'lodash'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'

const LoggingIn = () => {
  const [type] = useLocalStorage('type')
  const auth = useAuth()
  const user = localStorage.getItem('user')
  const [previousPath] = useLocalStorage('previousPath')
  let previous = !_.isEmpty(previousPath) ? previousPath : '/'

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const param = searchParams.get('previousPath')

  useEffect(() => {
    if (param) {
      window.localStorage.setItem('previousPath', `${param}`)
      window.localStorage.setItem('type', 'Prospect')
    }

    if (!user) {
      if (previousPath?.indexOf('/?state') === 0) {
        previous = '/'
      } else {
        previous = '/logging-in'
      }

      if (type === 'Staff' && window.__env__.ENV !== 'DEV') {
        previous = ''
      } else if (type === 'Agent' && window.__env__.ENV !== 'DEV') {
        previous = ''
      }

      const redirectTo = `${window.__env__.REACT_APP_APP_URL}${previous}`
      auth.signinRedirect({ redirect_uri: redirectTo })
    } else {
      window.location = previous
    }
  }, [param])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: '#FFFFFF',
        zIndex: '99999',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={30} />
      <Typography sx={{ mt: 2 }}>กรุณารอสักครู่</Typography>
    </Box>
  )
}

export default LoggingIn
