export const listBreadcrumbs = [
  {
    isLink: true,
    color: 'text.secondary',
    label: 'คลาสของฉัน',
    link: '/my-class',
  },
  {
    isLink: false,
    color: 'primary',
    label: 'คลาส',
  },
]
