import React from 'react'
import Progress from '@mui/material/CircularProgress'
import { StyledBackgroundLoading } from './Styled'

const CircularProgress = ({ isLoading }) => {
  return (
    <StyledBackgroundLoading isloading={isLoading}>
      <Progress />
    </StyledBackgroundLoading>
  )
}

export default CircularProgress
