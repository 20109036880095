import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { HeadTable, StyledImage } from '../../Styled'
import NameCheckTableCell from './Cell'
import { StyledRow } from './Styled'

const NameCheckTable = () => {
  const data = useSelector((state) => state.registration.data, shallowEqual)
  const getData = _.get(data, 'returnDateAttendArray', [])

  return (
    <>
      <Box>
        <HeadTable>
          <StyledRow>
            <StyledImage src="/image/class/name-check-icon.png" />
            <Typography variant="h6">เช็คชื่อ</Typography>
          </StyledRow>
          {getData.length == 0 && (
            <Typography color="text.gray">ไม่มีการเช็คชื่อ</Typography>
          )}
        </HeadTable>
        {getData.map((item) => (
          <>
            <NameCheckTableCell
              startDateDay={item.startDateDay}
              startDateMonth={item.startDateMonth}
              startDateYear={item.startDateYear}
              status={item.dayAttendanceStatus}
            />
          </>
        ))}
      </Box>
    </>
  )
}

export default NameCheckTable
