import React from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { Row } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { EXPIRATION_TYPE, TIME_SPAN_TYPE } from 'src/constants/eLearning'
import LikeButton from '../../../components/LikeButton'
import { StyledSuccessIcon } from '../Content/CourseList/Styled'
import { DetailCard } from '../components'
import { handleLikeCourse } from '../events'
import { StyledContainer, StyledTopWrapper } from './Styled'
import { handleEnroll } from './events'

const TopSection = () => {
  const { data, isLikeLoading } = useSelector(
    (state) => ({
      data: state.eLearningLearningPath.data,
      isLikeLoading: state.eLearningLearningPath.isLikeLoading,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const isLike = _.get(data, 'isLike', false)
  const isRegister = _.get(data, 'isRegister', false)
  const hasCertificate = _.get(data, 'hasCertificate', false)
  const isComplete = _.get(data, 'isComplete', false)
  const isNotSm = isMediaWidthMoreThan('sm')
  const history = useHistory()
  return (
    <StyledTopWrapper>
      <Row sx={{ justifyContent: 'space-between', gap: 1 }}>
        <Row gap={0.5}>
          {isComplete && <StyledSuccessIcon isCard />}
          <Typography variant="h5" color="primary">
            {_.get(data, 'name', '')}
          </Typography>
        </Row>
        {isNotSm && (
          <LikeButton
            isLike={isLike}
            isLoading={isLikeLoading}
            onClick={() => dispatch(handleLikeCourse())}
          />
        )}
      </Row>
      <BoxContents data={data} />
      {isComplete && hasCertificate ? (
        <Button
          variant="outlined"
          sx={{ ':hover': { img: { filter: 'invert(1) grayscale(1)' } } }}
          startIcon={<img src="/icon/e-learning/ic_cert_fill.svg" />}
          onClick={() =>
            history.push(`/my-learning-path/grades/${_.get(data, 'uuid', '')}`)
          }
        >
          ดาวน์โหลดประกาศนียบัตร
        </Button>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          disabled={isRegister}
          onClick={() => dispatch(handleEnroll())}
        >
          {isRegister ? 'ลงทะเบียนเรียบร้อยแล้ว' : 'ลงทะเบียน'}
        </Button>
      )}
    </StyledTopWrapper>
  )
}

export default TopSection

export const BoxContents = ({ data }) => {
  const courses = _.get(data, 'eLearningLearningPathCourses', [])
  const courseAccessExpiration = _.get(data, 'courseAccessExpiration', '')
  return (
    <StyledContainer>
      <DetailCard sx={{ maxWidth: 226 }} icon="ic_book_open.svg">
        เนื้อหาทั้งหมด
        <br />
        {courses.length} บทเรียน
      </DetailCard>
      {courseAccessExpiration === EXPIRATION_TYPE.TIME_SPAN && (
        <DetailCard
          id="timeSpan"
          icon="ic_screen_time.svg"
          sx={{ maxWidth: 226, px: 1.5, gap: '14px' }}
        >
          เรียนให้จบภายใน {_.get(data, 'timeSpan', 0)}{' '}
          {TIME_SPAN_TYPE[_.get(data, 'timeSpanType', '')]}
          <br />
          <Typography variant="span" color="secondary">
            นับจากวันที่เริ่มอบรม
          </Typography>
        </DetailCard>
      )}
      {!_.isEmpty(data.eLearningLearningPathRefCertificate) && (
        <DetailCard sx={{ maxWidth: 226 }} icon="ic_cert.svg">
          ได้รับประกาศนียบัตร
        </DetailCard>
      )}
    </StyledContainer>
  )
}
