import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const mayProfile = createSlice({
  name: 'mayProfile',
  initialState,
  reducers: {
    setTapMenu: (state, { payload }) => {
      state.tapMenu = payload
    },
    setCurrentDate: (state, { payload }) => {
      state.currentDate = payload.currentDate
      state.bodyCalendar = {
        ...state.bodyCalendar,
        startDate: payload.startDate,
        toDate: payload.toDate,
      }
    },
    setChangeState: (state, { payload }) => {
      _.set(state, payload.key, payload.value)
    },
    setBodyCalendar: (state, { payload }) => {
      _.set(state, `bodyCalendar.${payload.key}`, payload.value)
    },
    setFetchData: (state, { payload }) => {
      state.details = payload.details
      state.calendar = payload.calendar
      state.badgeData = payload.badgeData
      state.catalogData = payload.catalogData
      state.currentDate = payload.currentDate
    },
    setFetchCalendar: (state, { payload }) => {
      state.calendar = payload
    },
    setDrawerFilter: (state) => {
      state.isDrawer = !state.isDrawer
    },
    setOpenHistory: (state) => {
      state.isOpenHistory = !state.isOpenHistory
    },
    setBadgeSeeMore: (state) => {
      state.isBadgeSeeMore = !state.isBadgeSeeMore
    },
    setCatalogSeeMore: (state) => {
      state.isCatalogSeeMore = !state.isCatalogSeeMore
    },
  },
})

export const {
  setTapMenu,
  setCurrentDate,
  setChangeState,
  setBodyCalendar,
  setFetchData,
  setFetchCalendar,
  setDrawerFilter,
  setOpenHistory,
  setBadgeSeeMore,
  setCatalogSeeMore,
} = mayProfile.actions

export default mayProfile.reducer
