import _ from 'lodash'
import axios from 'axios'
import { startLoading, stopLoading } from '../../../redux/slices/loading'
import { getLearningPathGrade } from '../../../services/class/e-learning-registration'
import { setRegistrationData } from '../../../redux/slices/class/eLearningRegistration'
import { eLearningLearningPathCertification } from '../../../utils/apiPath'

export const onDownload = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  await axios
    .get(`${eLearningLearningPathCertification}/${uuid}`, {
      responseType: 'blob',
    })
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'e-certification.pdf')
      document.body.appendChild(link)
      link.click()
    })
    .catch(() => dispatch(stopLoading()))
    .finally(() => dispatch(stopLoading()))
}

export const fetchLearningPathGrade = (uuid) => async (dispatch) => {
  dispatch(startLoading())
  const result = await dispatch(getLearningPathGrade(uuid))
  dispatch(setRegistrationData(_.get(result, 'payload.data', {})))
  dispatch(stopLoading())
}
