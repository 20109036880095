import _ from 'lodash'
import { convertDateToThaiString } from 'src/utils/lib'

export const handleELearningDateRange = (data) => {
  const eLearningCourseBatch = _.get(
    data,
    'eLearningCourseBatchLearner.eLearningCourseBatch',
    {}
  )

  if (_.isEmpty(eLearningCourseBatch)) {
    const eLearningCourse = _.get(
      data,
      'eLearningCourseBatchLearner.eLearningCourse',
      {}
    )
    const periodStart = _.get(eLearningCourse, 'periodStart', '')
    return convertDateToThaiString(periodStart)
  }

  const periodStart = _.get(eLearningCourseBatch, 'periodStart', '')
  const periodEnd = _.get(eLearningCourseBatch, 'periodEnd', '')
  const periodStartStr = convertDateToThaiString(periodStart)
  const periodEndStr = convertDateToThaiString(periodEnd)
  if (periodStart === periodEnd) return periodStartStr
  else return `${periodStartStr} - ${periodEndStr}`
}
