import styled from '@mui/system/styled'
import AccordionSummary from '@mui/material/AccordionSummary'
import Accordion from '@mui/material/Accordion'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'unset',
  margin: 0,
  background: theme.palette.white.main,
  '&.Mui-expanded': { margin: '0 !important' },
  '::before': { opacity: 0 },
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: '4px 12px',
  background: theme.palette.primary.main,
  minHeight: 48,
  '&.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 48,
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
  },
}))

export const BadgeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  border: '1px solid #E5E5E5',
  boxShadow: ' 0px 4px 4px 0px #00000040',
}))

export const Image = styled('img')(({ $disable }) => ({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  padding: '0px 16px',
  marginBottom: '8px',
  filter: `grayscale(${$disable ? '1' : '0'})`,
}))

export const BadgeName = styled(Typography)(() => ({
  textAlign: 'center',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
}))
