import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getEConfiguration } from 'src/services/eConfiguration'
import { objectList, numberFormat } from './event'
import { initialState } from './model'

const eConfiguration = createSlice({
  name: 'eConfiguration',
  initialState,
  reducers: {
    setInitialForm: (state, { payload }) => {
      state.data = payload
    },
    setTab: (state, { payload }) => {
      state.tab = payload
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.body, key, value)
    },
    setIsInit: (state, { payload }) => {
      state.isInit = payload
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEConfiguration.fulfilled, (state, { payload }) => {
      _.get(payload, 'data', []).map((config) => {
        if (config.key === 'CC_EMAIL_ANNOUNCEMENT_EXAMINATION') {
          const emailList = config.value.split(',')
          _.set(state.body, config.key, emailList)
        } else if (
          config.key === 'EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION'
        ) {
          const formatTime = numberFormat(config.value)
          _.set(state.body, config.key, formatTime)
        } else if (objectList.includes(config.key)) {
          const values = JSON.parse(config.value)
          _.set(state.body, config.key, values)
        } else {
          _.set(state.body, config.key, config.value ?? '')
        }
      })
      state.isLoading = false
    })
  },
})

export const {
  setInitialForm,
  setTab,
  setFieldValue,
  setIsInit,
  setIsLoading,
} = eConfiguration.actions

export default eConfiguration.reducer
