import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { FinishedFileButton } from '../../components/FloatingButtons'
import { handleHtmlLink } from '../../events'
import { mediaType } from '../../model'
import { MediaBoxStyled } from './Styled'
import ReactPlayer from './ReactPlayer'
import FileRender from './FileRender'
import LinkToWebsite from './LinkToWebsite'
import ETesting from './ETesting'
import EEvaluation from './EEvaluation'
import Scorm from './Scorm'

const MediaDisplay = () => {
  const { isLoading, selectedLesson } = useSelector(
    (state) => ({
      isLoading: state.eLearningLearning.isLoading,
      selectedLesson: state.eLearningLearning.selectedLesson,
    }),
    shallowEqual
  )
  const type = _.get(selectedLesson, 'type', '')
  const url = _.get(selectedLesson, 'url', '')

  if (isLoading) return <MediaBoxStyled />

  switch (type) {
    case mediaType.E_TESTING:
      return <ETesting selectedLesson={selectedLesson} />
    case mediaType.E_EVALUATION:
      return <EEvaluation selectedLesson={selectedLesson} />
    case mediaType.HTML:
    case mediaType.LINK: {
      const htmlLink = handleHtmlLink(type, url)
      const src = type === mediaType.LINK ? url : htmlLink
      return <ReactPlayer url={_.get(src.split('&'), 0, '')} />
    }
    case mediaType.UPLOAD_FILE:
      return (
        <>
          <FinishedFileButton isFixed />
          <FileRender selectedLesson={selectedLesson} />
        </>
      )
    case mediaType.LINK_TO:
      return <LinkToWebsite selectedLesson={selectedLesson} />
    case mediaType.SCORM:
      return <Scorm selectedLesson={selectedLesson} />
    default:
      return <MediaBoxStyled />
  }
}

export default MediaDisplay
