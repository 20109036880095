import { store } from 'src/App'
import _ from 'lodash'
import {
  setReduxValue,
  setUserFilterValue,
} from 'src/redux/slices/eExamination'

export const handleSelectAll = (check) => (dispatch) => {
  const state = store.getState()
  const examinerList = _.get(state.eExamination, 'examinerList', [])
  if (check) {
    dispatch(
      setReduxValue({ index: 'selectedExaminerList', value: examinerList })
    )
  } else {
    dispatch(setReduxValue({ index: 'selectedExaminerList', value: [] }))
  }
}

export const handleSelect =
  ({ check, value }) =>
  (dispatch) => {
    const state = store.getState()
    const selectedExaminerList = _.get(
      state.eExamination,
      'selectedExaminerList',
      []
    )
    if (check) {
      dispatch(
        setReduxValue({
          index: 'selectedExaminerList',
          value: [...selectedExaminerList, value],
        })
      )
    } else {
      const newSelected = _.filter(selectedExaminerList, (ele) => {
        return ele.uuid != value.uuid
      })
      dispatch(
        setReduxValue({
          index: 'selectedExaminerList',
          value: newSelected,
        })
      )
    }
  }

export const loadExaminationUserFilter = (props) => (dispatch) => {
  const { user, page, search } = props
  const unitNumber = _.get(user, 'unitNumber', '')
  dispatch(
    setUserFilterValue([
      { index: 'isFetch', value: true },
      { index: 'page', value: page },
      { index: 'unitNumber', value: unitNumber },
      { index: 'search', value: search },
    ])
  )
}

export const handleSearchExaminationUser = () => (dispatch) => {
  const user = JSON.parse(window.localStorage.getItem('user'))
  const state = store.getState()
  const examinerSearchText = _.get(state.eExamination, 'examinerSearchText', '')
  dispatch(
    loadExaminationUserFilter({ user, page: 1, search: examinerSearchText })
  )
}
