import styled from '@mui/system/styled'
import { Accordion, AccordionSummary } from '@mui/material'

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'unset',
  margin: 0,
  background: theme.palette.white.main,
  padding: '0 20px',
  paddingBottom: theme.spacing(2),
  '&.Mui-expanded': { margin: '0 !important' },
  '::before': { opacity: 0 },
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: '0',
  '& .MuiAccordionSummary-content': {
    margin: '16px 0',
  },
}))

export const StyledDivider = styled('div')(({ theme }) => ({
  width: 'calc(100% - 24px)',
  height: '1px',
  background: 'repeating-linear-gradient(90deg, #DBE4F1 0 7px, #0000 0 12px)',
}))
