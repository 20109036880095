import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import StepNumber from '../components/StepNumber'
import AlertInfo from '../components/AlertInfo'
import ExampleImage from '../components/ExampleImage'
import { StyledDashedBox } from '../components/ExampleImage/Styled'
import { SectionLabelIcon } from '../..'
import {
  StyledButton,
  StyledContainer,
  StyledPreview,
  StyledRowWrapper,
  StyledWrapper,
} from '../Styled'
import { handleOpenCameraDialog } from './events'

const FaceCaptureSection = () => {
  const { idCardImage, faceImage } = useSelector(
    (state) => ({
      idCardImage: state.eLearningEnroll.body.idCardImage,
      faceImage: state.eLearningEnroll.body.faceImage,
    }),
    shallowEqual
  )
  const disabled = _.isEmpty(idCardImage)
  return (
    <Column sx={{ alignItems: 'start', gap: 2 }}>
      <StyledRowWrapper>
        <StepNumber value={2} disabled={disabled} />
        <SectionLabelIcon
          isWidthAuto
          disabled={disabled}
          label="ถ่ายรูปหน้าตรง"
          icon="ic_file_person"
        />
      </StyledRowWrapper>

      <StyledWrapper>
        <AlertInfo disabled={disabled}>
          <li>จัดมุมถ่ายรูปที่สามารถเห็นเพียงใบหน้าของท่านเท่านั้น</li>
          <li>
            ตรวจสอบทรงผมและมุมมองการหันเข้าหากล้องเพื่อให้รูปของท่านใกล้เคียงรูปใบหน้าบนบัตรมากที่สุด
          </li>
          <li>จัดมุมที่ใช้ในการถ่ายรูปมีแสงตกกระทบบนใบหน้าเพียงพอ</li>
        </AlertInfo>

        <StyledContainer>
          {faceImage ? (
            <PreviewAndChangeImage />
          ) : (
            <ExampleAndInsertImage disabled={disabled} />
          )}
        </StyledContainer>
      </StyledWrapper>
    </Column>
  )
}

export default FaceCaptureSection

export const ExampleAndInsertImage = ({ disabled }) => {
  const isLg = isMediaWidthMoreThan('lg')
  return (
    <>
      <ExampleImage src="example_face.svg" disabled={disabled} />

      <StyledButton
        variant="contained"
        disabled={disabled}
        sx={{ mt: isLg ? 5 : 0 }}
        onClick={handleOpenCameraDialog}
      >
        เริ่มถ่ายรูป
      </StyledButton>
    </>
  )
}

export const PreviewAndChangeImage = () => {
  const faceImage = useSelector(
    (state) => state.eLearningEnroll.body.faceImage,
    shallowEqual
  )
  const imgSrc = `${window.__env__.REACT_APP_REKOGNITION_URL}${faceImage}`
  return (
    <StyledDashedBox>
      <StyledPreview src={imgSrc} />
      <Typography
        variant="body2"
        color="success.main"
        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
      >
        <img src="/icon/ic_check_success.svg" />
        ถ่ายรูปหน้าตรงแล้ว
      </Typography>
    </StyledDashedBox>
  )
}
