import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Template from 'src/components/Template'
import Header from './Header'
import Content from './Content'
import { fetchCollectionDetail } from './events'
import { StyledWrapper } from './styled'
import List from './List'

const Detail = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const data = useSelector(
    (state) => state.eLearningCollection.data,
    shallowEqual
  )
  const eLearningCollectionCourses = _.get(
    data,
    'eLearningCollectionCourses',
    []
  )
  useEffect(() => {
    dispatch(fetchCollectionDetail(id))
  }, [])
  return (
    <StyledWrapper>
      {data ? (
        <>
          <Header />
          <Template noMinHeight maxWidth={1280}>
            <Content />
          </Template>
          <List courses={eLearningCollectionCourses} />
        </>
      ) : (
        <Template />
      )}
    </StyledWrapper>
  )
}
export default Detail
