import React from 'react'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { Container, StyledHeaderText } from './Styled'

const Header = ({ title }) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <Container>
      <StyledHeaderText>
        <Typography
          id="title"
          variant={isNotSm ? 'h2' : 'h5'}
          color="text.white"
        >
          {title}
        </Typography>
      </StyledHeaderText>
    </Container>
  )
}

export default Header
