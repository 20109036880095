import React from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { getQueryParams } from 'src/utils/lib'
import { path } from 'src/constants/path'
import { StyledResultContent } from 'src/components/Preview/Result/Styled'
import { reStartETestingModule } from 'src/redux/slices/eLearning/learning'
import { setSectionTesting } from 'src/redux/slices/eTesting'
import { handleGetAnswer } from './events'

const BannerShowResult = () => {
  const { codeId, version, id: uuid } = useParams()
  const trainingPlan = getQueryParams('class')
  const dispatch = useDispatch()
  const pathStandalone = `${path.eTesting}/on-test/${codeId}/${version}/${uuid}`
  const pathOnTesting = `${path.eTesting}/on-test/${uuid}?class=${trainingPlan}`
  const testingPath = trainingPlan ? pathOnTesting : pathStandalone
  const isLoading = useSelector(
    (state) => state.loading.isLoading,
    shallowEqual
  )
  const eTesting = useSelector((state) => state.eTesting, shallowEqual)
  const isModule = _.get(eTesting, 'isModule', false)
  const result = _.get(eTesting, 'result', null)
  const isPass = _.get(result, 'isPass', false)
  const isShowScoreNow = _.get(result, 'isShowScoreNow', false)
  const isShowResult = _.get(eTesting, 'questionResult.isOpen', false)
  const showResult = _.get(eTesting, 'questionResult.result', 'LATER')
  const canReviewAnswer = isShowResult && showResult == 'LATER'
  const testCriteriaType = _.get(eTesting, 'testCriteriaType', '')
  const testRoundAt = _.get(eTesting, 'testRoundAt', 0)
  const roundUnlimit = _.get(eTesting, 'testRound.roundUnlimit', true)
  const round = _.get(eTesting, 'testRound.round', 1)
  const canRetry = roundUnlimit || round > testRoundAt

  if (_.isNil(result) || isLoading) return <CircularProgress></CircularProgress>

  return (
    <>
      <StyledResultContent>
        <ResultIcon result={result} />

        {(testCriteriaType === 'REQUIRED_PERCENT' || isShowScoreNow) && (
          <ResultScore result={result} />
        )}
      </StyledResultContent>

      {isPass && canReviewAnswer && (
        <Button
          data-testid="btn-result"
          variant="contained"
          size="l"
          sx={{ mb: 0.5 }}
          onClick={() =>
            dispatch(handleGetAnswer(uuid, trainingPlan, isModule))
          }
        >
          ดูเฉลย
        </Button>
      )}

      {!isPass && canRetry && (
        <Button
          data-testid="btn-test"
          variant="contained"
          size="l"
          onClick={() => {
            if (isModule) {
              dispatch(reStartETestingModule())
              dispatch(setSectionTesting())
            } else window.location.href = testingPath
          }}
        >
          สอบใหม่อีกครั้ง
        </Button>
      )}
    </>
  )
}

export default BannerShowResult

export const ResultIcon = ({ result }) => {
  const isPass = _.get(result, 'isPass', false)
  if (isPass) {
    return (
      <>
        <img src="/icon/main_success.svg" />
        <Typography variant="h3" color={'success.main'}>
          ผ่านการทดสอบ
        </Typography>
      </>
    )
  }

  return (
    <>
      <img src="/icon/main_fail.svg" />
      <Typography variant="h3" color={'error'}>
        ไม่ผ่านการทดสอบ
      </Typography>
    </>
  )
}

export const ResultScore = ({ result }) => {
  const isPass = _.get(result, 'isPass', false)
  const criteria = _.get(result, 'passScorePercent', 0)
  return (
    <>
      <Typography variant="h5" color="text.secondary">
        คุณได้คะแนน
      </Typography>
      <Typography variant="h2" color={isPass ? 'success.main' : 'error'}>
        {_.get(result, 'learnerScore', 0)} / {_.get(result, 'totalScore', 0)}
      </Typography>
      <Typography variant="h5" color="text.secondary">
        ( ได้คะแนน {_.get(result, 'learnerScorePercent', '0.00')}%
        {criteria && ` : เกณฑ์ผ่าน ${_.isNaN(criteria) ? 0 : criteria}%`} )
      </Typography>
    </>
  )
}
