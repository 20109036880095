import React from 'react'
import Upload from '@mui/icons-material/Upload'
import { StyledInputHidden } from 'src/modules/Document/DocumentInput/Styled'
import { StyledButton } from '../../Styled'
import { handleUploadFile } from './events'

const UploadFileInput = () => {
  return (
    <label htmlFor="upload-file">
      <StyledButton variant="contained" component="span" startIcon={<Upload />}>
        อัปโหลด
      </StyledButton>
      <StyledInputHidden
        data-testid="upload-file"
        id="upload-file"
        accept=".jpg, .jpeg, .png"
        type="file"
        onChange={(e) => handleUploadFile(e)}
      />
    </label>
  )
}

export default UploadFileInput
