import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledAnswerBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: theme.spacing(1),
  background: '#F3F3FF',
  '& *': { whiteSpace: 'normal' },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette?.blue?.blueBorder,
}))
