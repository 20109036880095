import _ from 'lodash'
import { setChangeState } from '../../../redux/slices/myProfile'

export const handleClick = (value) => (dispatch) => {
  dispatch(
    setChangeState({
      key: 'toggle',
      value,
    })
  )
}

export const calculateDate = (joinDate) => {
  if (!joinDate) return '-'
  var startDate = new Date(joinDate)
  var diffDate = new Date(new Date() - startDate)
  let result = ` ${diffDate.toISOString().slice(0, 4) - 1970
  } ปี ${diffDate.getMonth()} เดือน ${diffDate.getDate() - 1} วัน`

  if (diffDate.getDate() - 1 === 0) {
    result = `${diffDate.toISOString().slice(0, 4) - 1970
    } ปี ${diffDate.getMonth()} เดือน`
  }
  return result
}
export const checkEmpty = (details, key) => {
  const data = _.get(details, key, '')
  return !_.isEmpty(data) ? data : '-'
}
