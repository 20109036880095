import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { learnerCheckIn } from '../../utils/apiPath'

export const getLearnerCheckIn = createAsyncThunk(
  'learner/checkIn',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(learnerCheckIn, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)
