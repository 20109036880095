import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'

import Table from '../../../../../components/Table'
import { StyledDrawer, BoxButton, BoxHeader, BoxTableContainer } from './Styled'
import { setSearchText } from '../../../../../redux/slices/table'
import { fetchData, handleSubmit } from './events'

const Index = ({ isOpen, onCloseDrawer }) => {
  const dispatch = useDispatch()
  const [prospectDetail, setProspectDetail] = useLocalStorage('prospectDetail')

  const { prospects, course, prospectAddDrawerList } = useSelector(
    (state) => ({
      prospects: state.manageProspectEnrollment.prospects,
      course: state.manageProspectEnrollment.course,
      prospectAddDrawerList:
        state.manageProspectEnrollment.prospectAddDrawerList,
    }),
    shallowEqual
  )
  const { table } = useSelector((state) => state.table, shallowEqual)

  useEffect(() => {
    if (isOpen) dispatch(fetchData(prospects))
    else dispatch(setSearchText())
  }, [isOpen])

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <BoxHeader>
        <Typography variant="h5" color="primary">
          เพิ่มรายชื่อ
        </Typography>
        <Typography variant="body1">
          กรุณาเลือกรายชื่อเพิ่มรายชื่อจากหน้าโปรไฟล์ทีมของคุณ
        </Typography>
      </BoxHeader>
      <Box sx={{ px: '60px' }}>
        <BoxTableContainer>
          <Table />
        </BoxTableContainer>
      </Box>

      <BoxButton>
        <Button
          variant="outlined"
          size="l"
          sx={{ width: 250 }}
          onClick={onCloseDrawer}
        >
          ยกเลิกดำเนินการ
        </Button>
        <Button
          variant="contained"
          size="l"
          sx={{ width: 250 }}
          onClick={() => {
            dispatch(
              handleSubmit({
                course,
                prospects,
                selected: table.selected,
                prospectAddDrawerList,
                prospectDetail,
                setProspectDetail,
              })
            )
            onCloseDrawer()
          }}
        >
          ตกลง
        </Button>
      </BoxButton>
    </StyledDrawer>
  )
}

export default Index
