import React from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Close from '@mui/icons-material/Close'
import { HeadLabel, StyledCard, Row, StyledImage } from './Styled'
import { convertNumberWithComma } from '../../../../utils/lib'

const RedeemDialog = ({ row, open, setOpen }) => {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          minWidth: 750,
          maxWidth: 750,
        },
      }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <HeadLabel>
        <Box>
          <Typography variant="h5">รายการของรางวัลที่แลก</Typography>
          <IconButton
            data-testid="btn-close-history"
            color="primary"
            component="span"
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </HeadLabel>
      <StyledCard>
        {row.learningPointRedeemHistory.map((ele, ind) => {
          return (
            <Row
              key={ind}
              sx={{
                mb: 1.5,
                py: 1.5,
                alignItems: 'center',
                borderBottom: '1px solid #DBE4F1',
              }}
            >
              <StyledImage
                src={`${window.__env__.REACT_APP_FILE_URL}/get${ele.learningPointCatalog?.image?.key}`}
              />
              <Typography sx={{ mr: 3 }}>
                {ele.learningPointCatalog.name}
              </Typography>
              <Typography
                sx={{ width: 80, ml: 'auto', textAlign: 'right' }}
              >{`${convertNumberWithComma(ele.point)} คะแนน`}</Typography>
              <Typography
                sx={{ width: 48, ml: 12, textAlign: 'right' }}
              >{`x ${ele.quantity} ชิ้น`}</Typography>
            </Row>
          )
        })}

        <Row sx={{ justifyContent: 'space-between', mt: 2.5 }}>
          <Typography variant="body1b">จำนวนคะแนนที่ใช้แลกของรางวัล</Typography>
          <Row sx={{ gap: '15px' }}>
            <Typography variant="body1b">
              {convertNumberWithComma(row.point)}
            </Typography>
            <Typography variant="body1">คะแนน</Typography>
          </Row>
        </Row>
      </StyledCard>
    </Dialog>
  )
}

export default RedeemDialog
