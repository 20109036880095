import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledListHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(2)} 0`,
  borderBottom: `solid 1px ${theme.palette?.other?.divider}`,
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(3)} 0`,
    borderBottom: '0px',
  },
}))

export const StyledRightFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  '& #btn-filter': { display: 'none' },
  [theme.breakpoints.down('lg')]: {
    '& #btn-filter': { display: 'inline-flex' },
    gap: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'space-between',
  },
}))

export const StyledNotiDot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 5,
  top: 7,
  width: '6px !important',
  height: 6,
  background: theme.palette.secondary?.main,
  borderRadius: '100%',
}))
