export const initialState = {
  filterBody: {
    order: 'DESC',
    sortBy: 'POPULAR',
    courseType: [],
    courseStatus: [],
    myCourse: [],
    acquiredSkill: [],
    productType: [],
    courseName: '',
    courseHourFrom: 0,
    courseHourTo: 0,
    startDate: '',
    finishDate: '',
    page: 1,
    limit: 12,
  },
  courses: [],
  filterCourse: [],
  bannerSection: [],
  learningPaths: [],
  collections: [],
  totalCourses: 0,
  error: null,
}
