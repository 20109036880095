import styled from '@mui/system/styled'
import { Accordion, AccordionSummary } from '@mui/material'

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'unset',
  padding: theme.spacing(2),
  paddingTop: 0,
  background: theme.palette.background.default,
  margin: 0,
  marginBottom: theme.spacing(2),
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.main,
  },
}))
