import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import _ from 'lodash'
import { ContentBox } from './styled'
import { CONTENT_TYPE } from '../model'
import FactCard from './FactCard'
import Link from './Link'
import Text from './Text'
import Image from './Image'
import File from './File'

const Content = () => {
  const { contentData } = useSelector(
    (state) => ({
      contentData: state.eContent.contentData,
    }),
    shallowEqual
  )
  const handleContent = (data, index) => {
    switch (data.type) {
      case CONTENT_TYPE.TEXT:
        return <Text data={data} />
      case CONTENT_TYPE.LINK:
        return <Link data={data} />
      case CONTENT_TYPE.IMAGE:
        return <Image data={data} />
      case CONTENT_TYPE.FILE:
        return <File data={data} />
      case CONTENT_TYPE.KB:
        return <FactCard data={data} CardIndex={index} key={index} />
      default:
        return ''
    }
  }
  return (
    <ContentBox>
      {_.sortBy(contentData?.contents, ['sequence'])?.map((item, index) =>
        handleContent(item, index)
      )}
    </ContentBox>
  )
}

export default Content
