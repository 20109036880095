import IconButton from '@mui/material/IconButton'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Cancel from '@mui/icons-material/Cancel'
import DownloadingOutlined from '@mui/icons-material/DownloadingOutlined'
import styled from '@mui/system/styled'
import { Column, Row } from 'src/components/Layout/Styled'

export const StyledCourseCard = styled(Column)(({ theme, selected }) => {
  const border = selected
    ? `2px solid ${theme.palette?.primary?.main}`
    : 'unset'
  const shadowColor = selected
    ? 'rgba(73, 77, 244, 0.35)'
    : 'rgba(73, 118, 186, 0.35)'
  return {
    width: 168,
    minHeight: 180,
    border,
    borderRadius: theme.spacing(1),
    background: theme.palette?.background?.paper,
    boxShadow: `0px 4px 12px 1px ${shadowColor}`,
    cursor: 'pointer',
    img: {
      height: 100,
      borderRadius: '6px 6px 0 0 ',
      objectFit: 'cover',
    },
  }
})

export const StyledCarouselArrow = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isNext',
})(({ isNext }) => ({
  position: 'absolute',
  top: 0,
  left: isNext ? 'unset' : 0,
  right: isNext ? 0 : 'unset',
  zIndex: 1,
  width: 24,
  height: 212,
  borderRadius: 0,
  background: 'rgba(255, 255, 255, 0.7)',
}))

export const StyledIconWrapper = styled(Row)(({ theme }) => ({
  position: 'absolute',
  right: -8,
  top: -8,
  width: 19,
  height: 19,
  background: theme.palette?.text?.white,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
}))

export const StyledSuccessIcon = styled(
  (props) => <CheckCircle {...props} color="success" />,
  { shouldForwardProp: (prop) => prop !== 'isCard' }
)(({ isCard }) => ({ ...iconObj(isCard) }))

export const StylelFailIcon = styled(
  (props) => <Cancel {...props} color="error" />,
  { shouldForwardProp: (prop) => prop !== 'isCard' }
)(({ isCard }) => ({ ...iconObj(isCard) }))

export const StyledProgressIcon = styled(
  (props) => <DownloadingOutlined {...props} color="info" />,
  { shouldForwardProp: (prop) => prop !== 'isCard' }
)(({ isCard }) => ({ transform: 'rotate(270deg)', ...iconObj(isCard) }))

export const iconObj = (isCard) => {
  if (isCard) return { width: 24, height: 24 }
  return {
    width: 16,
    height: 16,
    position: 'relative',
    top: 0,
    right: 0,
  }
}
