import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { StyledCameraReadyBox } from './Styled'

const CameraReadyBox = ({ field, onOpenCamera }) => {
  const text = field === 'faceImage' ? 'รูปหน้าตรง' : 'บัตรประชาชน/ใบขับขี่'
  return (
    <StyledCameraReadyBox>
      <Typography
        sx={{ textAlign: 'center' }}
        variant="body1b"
        color="text.white"
      >
        ถ่ายภาพ{text}ของท่าน <br />
        กดปุ่ม “เปิดกล้อง” เพื่อเริ่มถ่าย{text}
      </Typography>
      <Button variant="contained" color="info" onClick={onOpenCamera}>
        เปิดกล้อง
      </Button>
    </StyledCameraReadyBox>
  )
}

export default CameraReadyBox
