import React from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Typography, Box } from '@mui/material'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import CardImageDialog from '../InformationForm/CardImageDialog'
import { openDialog } from 'src/redux/slices/alertDialog'

const Detail = ({ label, value, image, viewIcon = false }) => {
  const dispatch = useDispatch()
  return (
    <Grid container spacing={{ sm: 3, xs: 0 }}>
      <Grid item sm={2.5} xs={12}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item sm={9.5} xs={12}>
        { Array.isArray(value) ? (
          value.map((item, itemIndex) => {
            const imageName = item?.imageName ?? ''
            return (
              <Box key={itemIndex}>
                <Typography variant="body1b" sx={{ wordBreak: 'break-word' }}>
                  {imageName}
                </Typography>
                {viewIcon && (
                  <RemoveRedEyeTwoToneIcon
                    sx={{
                      fontSize: 20,
                      color: '#0000008A',
                      cursor: 'pointer',
                      marginLeft: 1,
                      verticalAlign: 'middle',
                    }}
                    onClick={() => {
                      if (!item?.cardImage) return
                      dispatch(
                        openDialog({
                          type: 'custom',
                          customWidth: 690,
                          content: (
                            <CardImageDialog
                              url={window.__env__.REACT_APP_FILE_URL + item?.cardImage}
                              title={`${imageName}`}
                              label={label}
                            />
                          ),
                        })
                      )
                    }}
                  />
                )}
              </Box>
            )
          })
        )
        : (
          <>
            <Typography variant="body1b" sx={{ wordBreak: 'break-word' }}>
              {value || '-'}
            </Typography>
            {viewIcon && (
              <RemoveRedEyeTwoToneIcon
                sx={{
                  fontSize: 20,
                  color: '#0000008A',
                  cursor: 'pointer',
                  marginLeft: 1,
                  verticalAlign: 'middle',
                }}
                onClick={() => {
                  if (!image) return
                  dispatch(
                    openDialog({
                      type: 'custom',
                      customWidth: 690,
                      content: (
                        <CardImageDialog
                          url={window.__env__.REACT_APP_FILE_URL + image}
                          title={value}
                        />
                      ),
                    })
                  )
                }}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default Detail
