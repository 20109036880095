import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  addExaminer,
  removeExaminer,
  setAddressOptions,
  setConsent,
  setDateFilter,
  setExaminerDetail,
  setFormNextButtonDisabled,
  setFilterValue,
  setProgress,
  setReduxValue,
  setSelectedRounds,
  setZip,
  setUserFilterValue,
  startDrawerLoading,
  stopDrawerLoading,
  removeIdcard,
  setImage,
  setOtherDocs,
  removeOtherDocs,
} from '.'
import {
  handleFormInput,
  handleFormNextStep,
  handleStep1Button,
  handleStep2Button,
  handleStep3Button,
} from './event'
import _ from 'lodash'
import {
  postScheduleFiter,
  getEExaminationUserFilter,
  getFieldOption,
  getAddress,
} from '../../../services/eExamination'
import { startLoading, stopLoading } from '../loading'
import { getGenderFromPrefix } from '../../../utils/lib'

export const listenerMiddlewareEExam = createListenerMiddleware()

listenerMiddlewareEExam.startListening({
  matcher: isAnyOf(
    setExaminerDetail,
    setZip,
    setImage,
    removeIdcard,
    setOtherDocs,
    removeOtherDocs
  ),
  effect: async (action, listenerApi) => {
    const { index, obj } = action.payload
    const currentState = listenerApi.getState().eExamination.examiners[index]
    const originalState = listenerApi.getOriginalState().eExamination
    if (obj === 'idCardNo' || obj === 'mobileNo') {
      listenerApi.dispatch(
        setReduxValue({ index: 'duplicatedError', value: [] })
      )
    }
    await handleFormInput(currentState, originalState, index, listenerApi)
    await handleFormNextStep(currentState, originalState, index, listenerApi)
  },
})

listenerMiddlewareEExam.startListening({
  actionCreator: setExaminerDetail,
  effect: async (action, listenerApi) => {
    const { index, obj } = action.payload
    if (obj == 'prefix') {
      const { prefix } = listenerApi.getState().eExamination.examiners[index]
      const { genderOptions } = listenerApi.getState().eExamination.options
      const gender = getGenderFromPrefix(prefix, genderOptions)
      listenerApi.dispatch(
        setExaminerDetail({ index, obj: 'gender', value: gender })
      )
    }
  },
})

listenerMiddlewareEExam.startListening({
  actionCreator: setProgress,
  effect: async (action, listenerApi) => {
    if (action.payload === 0) {
      handleStep1Button(listenerApi)
    } else if (action.payload === 1) {
      handleStep2Button(listenerApi)
    } else {
      handleStep3Button(listenerApi)
    }
  },
})

listenerMiddlewareEExam.startListening({
  matcher: isAnyOf(setSelectedRounds, addExaminer, removeExaminer),
  effect: async (action, listenerApi) => {
    handleStep1Button(listenerApi)
  },
})

listenerMiddlewareEExam.startListening({
  actionCreator: setFormNextButtonDisabled,
  effect: async (action, listenerApi) => {
    handleStep2Button(listenerApi)
  },
})

listenerMiddlewareEExam.startListening({
  actionCreator: setConsent,
  effect: async (action, listenerApi) => {
    handleStep3Button(listenerApi)
  },
})

listenerMiddlewareEExam.startListening({
  actionCreator: removeExaminer,
  effect: async (action, listenerApi) => {
    const { index } = action.payload
    const { examinersFormError, formNextButtonDisabled, formError } =
      listenerApi.getState().eExamination
    let newExaminerFormError = [...examinersFormError]
    newExaminerFormError.splice(index, 1)
    listenerApi.dispatch(
      setReduxValue({
        index: 'examinersFormError',
        value: newExaminerFormError,
      })
    )
    let newFormNextButtonDisabled = [...formNextButtonDisabled]
    newFormNextButtonDisabled.splice(index, 1)
    listenerApi.dispatch(
      setReduxValue({
        index: 'formNextButtonDisabled',
        value: newFormNextButtonDisabled,
      })
    )
    let newFormError = [...formError]
    newFormError.splice(index, 1)
    listenerApi.dispatch(
      setReduxValue({
        index: 'formError',
        value: newFormError,
      })
    )

    const { addressOptions, tambonOptions, amphureOptions, provinceOptions } =
      listenerApi.getState().eExamination.options
    let newAddressOptions = [...addressOptions]
    newAddressOptions.splice(index, 1)
    let newTambonOptions = [...tambonOptions]
    newTambonOptions.splice(index, 1)
    let newAmphureOptions = [...amphureOptions]
    newAmphureOptions.splice(index, 1)
    let newProvinceOptions = [...provinceOptions]
    newProvinceOptions.splice(index, 1)
    listenerApi.dispatch(
      setAddressOptions({
        addressOptions: newAddressOptions,
        tambonOptions: newTambonOptions,
        amphureOptions: newAmphureOptions,
        provinceOptions: newProvinceOptions,
      })
    )
  },
})

listenerMiddlewareEExam.startListening({
  actionCreator: addExaminer,
  effect: async (action, listenerApi) => {
    const {
      formError,
      examinersFormError,
      formNextButtonDisabled,
      selectedExaminerList,
      examiners,
    } = listenerApi.getState().eExamination
    const newExaminersFormError = new Array(selectedExaminerList.length).fill(
      true
    )
    listenerApi.dispatch(
      setReduxValue({
        index: 'examinersFormError',
        value: [...examinersFormError, ...newExaminersFormError],
      })
    )
    listenerApi.dispatch(
      setReduxValue({
        index: 'formNextButtonDisabled',
        value: [...formNextButtonDisabled, ...newExaminersFormError],
      })
    )
    const newFormError = new Array(selectedExaminerList.length).fill([])
    listenerApi.dispatch(
      setReduxValue({
        index: 'formError',
        value: [...formError, ...newFormError],
      })
    )

    const { addressOptions, tambonOptions, amphureOptions, provinceOptions } =
      listenerApi.getState().eExamination.options
    const initNewArray = new Array(selectedExaminerList.length).fill([])
    let newAddressOptions = [...addressOptions, ...initNewArray]
    let newTambonOptions = [...tambonOptions, ...initNewArray]
    let newAmphureOptions = [...amphureOptions, ...initNewArray]
    let newProvinceOptions = [...provinceOptions, ...initNewArray]
    listenerApi.dispatch(
      setAddressOptions({
        addressOptions: newAddressOptions,
        tambonOptions: newTambonOptions,
        amphureOptions: newAmphureOptions,
        provinceOptions: newProvinceOptions,
      })
    )
    selectedExaminerList.map(async (ele) => {
      const index = _.findIndex(
        examiners,
        (examiner) => examiner.uuid == ele.uuid
      )
      if (ele?.tambon?.zipCode) {
        listenerApi.dispatch(
          setZip({
            index: index,
            zipCode: ele?.tambon?.zipCode,
            tambonUuid: ele?.tambon?.uuid,
            tambonName: ele?.tambon?.tambonName,
            amphureUuid: ele?.amphure?.uuid,
            amphureName: ele?.amphure?.amphureName,
            provinceUuid: ele?.province?.uuid,
            provinceName: ele?.province?.provinceName,
          })
        )
        await listenerApi.dispatch(
          getAddress({ index: index, value: ele?.tambon?.zipCode })
        )
      }
    })
  },
})

listenerMiddlewareEExam.startListening({
  matcher: isAnyOf(setFilterValue, setDateFilter),
  effect: async (action, listenerApi) => {
    const width = listenerApi.getState().eExamination.windowWidth
    if (width > 375) {
      const { startDate, endDate, fields, type, page } =
        listenerApi.getState().eExamination.examinationFilter
      const { index } = action.payload
      const checkIndex = ['startDate', 'endDate', 'fields', 'type']
      if (
        (_.includes(checkIndex, index) ||
          action.type === 'eExamination/setDateFilter') &&
        ((startDate && endDate) || fields.length > 0 || type.length > 0)
      ) {
        const resultTable = document.getElementById('result-table')
        if (resultTable) {
          resultTable.scrollTop = 0
        }
        listenerApi.dispatch(startLoading())
        listenerApi.dispatch(setFilterValue({ index: 'isFetch', value: true }))
        await listenerApi.dispatch(
          postScheduleFiter({
            startDate: startDate,
            endDate: endDate,
            options: fields.map((ele) => ele.value),
            type: type,
            limit: 10,
            order: 'DESC',
            page: 1,
            sort: 'updatedAt',
          })
        )
        listenerApi.dispatch(stopLoading())
      } else if (index == 'page' && page > 1) {
        listenerApi.dispatch(setFilterValue({ index: 'isFetch', value: true }))
        await listenerApi.dispatch(
          postScheduleFiter({
            startDate: startDate,
            endDate: endDate,
            options: fields.map((ele) => ele.value),
            type: type,
            limit: 10,
            order: 'DESC',
            page: page,
            sort: 'updatedAt',
          })
        )
      } else if (
        !startDate &&
        !endDate &&
        fields.length == 0 &&
        type.length == 0
      ) {
        const resultTable = document.getElementById('result-table')
        if (resultTable) {
          resultTable.scrollTop = 0
        }
        listenerApi.dispatch(setReduxValue({ index: 'roundList', value: [] }))
      }
    }
  },
})

listenerMiddlewareEExam.startListening({
  matcher: isAnyOf(setUserFilterValue),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(startDrawerLoading())
    const { search, unitNumber, page, isFetch } =
      listenerApi.getState().eExamination.examinationUserFilter
    const body = {
      search: search,
      unitNumber: unitNumber,
      limit: 20,
      order: 'ASC',
      page: page,
      sort: 'firstNameTH',
    }
    if (isFetch) {
      await listenerApi.dispatch(getEExaminationUserFilter(body))
    }
    listenerApi.dispatch(stopDrawerLoading())
  },
})

listenerMiddlewareEExam.startListening({
  actionCreator: setFilterValue,
  effect: async (action, listenerApi) => {
    const { index } = action.payload
    if (index === 'type') {
      const { type } = listenerApi.getState().eExamination.examinationFilter
      if (type.length > 0) {
        const body = {
          type: type,
        }
        await listenerApi.dispatch(getFieldOption(body))
      }
    }
  },
})
