import React from 'react'
import _ from 'lodash'
import {
  openDialog,
  closeDialog,
  setEnableButton,
  setDisableButton,
} from '../../../redux/slices/alertDialog'
import UploadDialog, { TableUpload } from './'

import { downloadFileTypeGet } from '../../../utils/lib'
import { prospectDownloadTemplate } from '../../../utils/apiPath'
import {
  uploadTemplateProspect,
  submitUploadProspect,
  postProspectSentMail,
} from '../../../services/manageProspect'
import { ContentDialog } from '../Form'
import { findObjDialog, handleSubmitSentMail } from '../Form/events'

import * as slices from '../../../redux/slices/manageProspectUpload'
import { store } from 'src/App'
import { USER_TYPE } from 'src/modules/EExamination/constant'

export const handleChecked = (value, setChecked) => (dispatch) => {
  setChecked(value)
  value ? dispatch(setEnableButton()) : dispatch(setDisableButton())
}

export const alertDialogUpload =
  (setProspect, setProspectDetail) => (dispatch) => {
    dispatch(
      openDialog(
        dispatch(
          findObjDialogUpload({
            caseType: 'upload',
            setProspect,
            setProspectDetail,
          })
        )
      )
    )
  }

export const findObjDialogUpload =
  ({ caseType, result, status, setProspect, setProspectDetail }) =>
  (dispatch) => {
    switch (caseType) {
      case 'upload':
        return {
          isCloseDialog: false,
          isHiddenFooter: true,
          title: 'เพิ่มผู้เรียน',
          content: <UploadDialog />,
        }
      case 'uploadSuccess':
        return {
          isCloseDialog: false,
          disableButton: true,
          isHiddenButton: findUploadSuccess(result) <= 0,
          iconType: !status ? 'error' : 'success',
          title: 'กรุณาตรวจสอบรายชื่อ',
          customWidth: 640,
          message: [
            `ระบบสามารถนำเข้ารายชื่อได้เพียง <span style="color:#1CC54E;font-size: 22px;font-weight: 700;"> ${findUploadSuccess(
              result
            )} </span> รายชื่อ`,
            'จากไฟล์ที่คุณอัปโหลด และพบปัญหาดังต่อไปนี้',
          ],
          content: <TableUpload />,
          buttonLeft: {
            label: 'ยกเลิกดำเนินการ',
            handleClick: () => dispatch(handleLeft()),
          },
          buttonRight:
            findUploadSuccess(result) > 0
              ? {
                  handleClick: () => {
                    dispatch(
                      actionSubmitUpload(result, setProspect, setProspectDetail)
                    )
                  },
                }
              : {},
        }
      case 'uploadProfileErrorType':
        return {
          iconType: 'error',
          title: 'นามสกุลไฟล์ไม่ถูกต้อง',
          message: ['รองรับได้เฉพาะ File Excel (.xlsx) เท่านั้น'],
          buttonRight: {
            label: 'ตกลง',
          },
        }
      case 'uploadProfileErrorSize':
        return {
          iconType: 'error',
          title: 'ขนาดไฟล์ใหญ่เกินไป',
          message: ['ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB', 'กรุณาลองใหม่อีกครั้ง'],
          buttonRight: {
            label: 'ตกลง',
          },
        }
      case 'submitUploadSuccess':
        return {
          isCloseDialog: false,
          iconType: 'course',
          title: 'สมัครเข้าอบรม / สมัครสอบ',
          message: ['พร้อมจะสมัครเรียน/สอบให้แก่สมาชิกของท่านเลยหรือไม่?'],
          content: <ContentDialog />,

          buttonLeft: {
            label: 'ยกเลิก',
            handleClick: () => {
              dispatch(handleSubmitSentMail(result.prospect))
            },
          },
          buttonRight: {
            label: 'ตกลง',
            handleClick: () => {
              dispatch(
                handleClickEnroll(result, setProspect, setProspectDetail)
              )
            },
          },
        }
      case 'uploadError':
        return {
          isCloseDialog: false,
          iconType: 'error',
          title: 'กรุณาตรวจสอบรายชื่อ',
          message: ['เนื่องจากจำนวนรายชื่อเพิ่มได้ไม่เกิน 50 รายชื่อ/ครั้ง'],
          buttonRight: {
            label: 'ตกลง',
          },
        }
      case 'uploadErrorFormat':
        return {
          isCloseDialog: false,
          iconType: 'error',
          title: 'รูปแบบไฟล์ไม่ถูกต้อง',
          message: [
            'ไฟล์มีรูปแบบของเนื้อหาไม่ถูกต้อง กรุณาดาวน์โหลด',
            'Template ที่มีให้ และใช้งานตามที่กำหนดไว้',
          ],
          buttonRight: {
            label: 'ตกลง',
          },
        }
    }
  }

export const handleDownloadTemplate = (type) => (dispatch) => {
  let title = 'prospect_template.xlsx'

  if (type === 'CS') title = 'CSUser_template.xlsx'
  dispatch(
    downloadFileTypeGet({
      url: prospectDownloadTemplate,
      fileName: title,
    })
  )
}

export const checkTypeFile = (type) => {
  if (type === 'xlsx' || type === 'xls' || type === 'csv') return true
  return false
}

export const handleUploadTemplate = (event) => async (dispatch) => {
  const body = new FormData()

  let elementId = document.getElementById('btn-upload')
  if (event.target.files.length === 0) {
    elementId.value = null
    return
  }
  body.append('file', event.target.files[0])
  const getNameImage = _.get(event, 'target.files[0].name', undefined)
  if (!getNameImage) {
    elementId.value = null
    return
  }

  const sizeFile = _.get(event, 'target.files[0].size', 0)
  const getExtensionImage = getNameImage.split('.').pop()
  if (!checkTypeFile(getExtensionImage.toString().toLowerCase())) {
    elementId.value = null
    dispatch(
      openDialog(
        dispatch(
          findObjDialogUpload({
            caseType: 'uploadProfileErrorType',
          })
        )
      )
    )
    return
  }

  if (sizeFile > 2000000) {
    elementId.value = null
    dispatch(
      openDialog(
        dispatch(
          findObjDialogUpload({
            caseType: 'uploadProfileErrorSize',
          })
        )
      )
    )
    return
  }

  dispatch(
    slices.setChangeFile({
      files: body,
      fileName: getNameImage,
    })
  )
  elementId.value = null
}

export const actionUpload =
  (body, setProspect, setProspectDetail) => async (dispatch) => {
    const result = await dispatch(uploadTemplateProspect(body))
    const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
    const status = _.get(result, 'payload.status', 'ERROR')

    if (requestStatus == 'fulfilled') {
      let caseType = 'uploadSuccess'
      if (status === 'ERROR_MAX') caseType = 'uploadError'
      if (status === 'ERROR_FORMAT') caseType = 'uploadErrorFormat'

      dispatch(slices.setChangeUpload(_.get(result, 'payload', {})))
      dispatch(
        openDialog(
          dispatch(
            findObjDialogUpload({
              caseType,
              result: _.get(result, 'payload.result', []),
              status: status === 'ERROR' ? false : true,
              setProspect,
              setProspectDetail,
            })
          )
        )
      )
      document.getElementById('btn-upload').value = null
    }
  }

export const handleLeft = () => (dispatch) => {
  dispatch(slices.setClearUpload())
  dispatch(closeDialog())
}

export const handleRight =
  (body, setProspect, setProspectDetail) => (dispatch) => {
    dispatch(actionUpload(body, setProspect, setProspectDetail))
  }

export const findUploadSuccess = (list) => {
  if (!list.length) return 0
  let count = 0
  list.filter((item) => {
    if (item.status == 'SUCCESS') count++
  })
  return count
}

export const filerDataSuccess = (result) => {
  if (!result.length) return []
  let res = []
  result.filter((item) => {
    if (item.status === 'SUCCESS') res.push(item)
  })
  return res
}

export const actionSubmitUpload = (result) => async (dispatch) => {
  const res = await dispatch(
    submitUploadProspect({ tempProfile: filerDataSuccess(result) })
  )
  const requestStatus = _.get(res, 'meta.requestStatus', 'rejected')

  if (requestStatus == 'fulfilled') {
    await dispatch(
      postProspectSentMail({
        tempUuid: res.payload.result,
      })
    )
    dispatch(openDialog(dispatch(findObjDialog('createSuccess', ''))))
    dispatch(slices.setClearUpload())
  }
}

export const handleClickEnroll =
  (result, setProspect, setProspectDetail) => (dispatch) => {
    const state = store.getState()
    const selectActionType = _.get(
      state.manageProspect,
      'selectActionType',
      'enroll'
    )
    const user = JSON.parse(localStorage.getItem('user'))
    const prospectDetail = result.prospectDetail.map((ele, ind) => {
      const type = ele.type || 'TEMP'
      return {
        uuid: result.prospect[ind],
        type: type,
        unitName: user?.agentProfile?.agentUnitName || '',
        unitNumber: ele.unitNumber || '',
        image: '/logo/staff_profile_small.png',
        firstNameTH: ele?.firstNameTH || '',
        lastNameTH: ele?.lastNameTH || '',
        prefix: ele.prefixTH,
        mobileNo: ele?.mobileNo?.replace(/-/g, '') || '',
        email: ele?.email || '',
        idCardNo: '',
        isEdit: true,
        birthDate: '',
        institution: ele?.educational?.[0]?.institution || '',
        between: ele?.educational?.[0]?.between || '',
        qualification: '',
        branch: ele?.educational?.[0]?.branch || '',
        gpa: ele?.educational?.[0]?.GPA || '',
        editable: {
          isEditName: true,
          isEditSurname: true,
          isEditIdCardNo: type !== USER_TYPE.TEMP,
          isEditMobile: true,
          isEditBirthDate: type !== USER_TYPE.TEMP,
        },
      }
    })
    setProspectDetail(prospectDetail)
    if (selectActionType === 'enroll') {
      setProspect(result.prospect)
      window.location.href = 'manage-prospect/enrollment'
    } else {
      dispatch(handleSubmitSentMail(result.prospect, true))
    }
  }
