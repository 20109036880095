import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette?.background?.blue,
  gap: theme.spacing(5),
  marginTop: theme.spacing(9),
  [theme.breakpoints.down('lg')]: { gap: theme.spacing(4) },
  [theme.breakpoints.down('sm')]: { gap: theme.spacing(2) },
}))

export const StyledTypography = styled(Typography)(({ variant }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: variant === 'overline' ? '1' : '2',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
}))

export const LoadingCircular = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ theme, isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette?.primary?.main,
}))
