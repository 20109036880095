import _ from 'lodash'
import { store } from 'src/App'
import { fetchPathCourseDetail } from '../../events'

export const handleSelectCourse = (item) => async (dispatch) => {
  const selectedCourse = store.getState().eLearningLearningPath.selectedCourse
  const data = _.get(item, 'props.data', {})
  const uuid = _.get(data, 'course.uuid', '')
  const type = _.get(data, 'type', '')

  if (selectedCourse === uuid) return
  await dispatch(fetchPathCourseDetail({ type, uuid }))
}
