import _ from 'lodash'
import { MODULE_TYPE } from 'src/constants/eLearning'

export const handleModuleETesting = (lessons) => {
  const eTesting = []
  _.forEach(lessons, (lesson) => {
    _.forEach(_.get(lesson, 'eLearningLessonRefModule', []), (module) => {
      if (module.moduleType === MODULE_TYPE.E_TESTING) eTesting.push(module)
    })
  })
  return eTesting
}
