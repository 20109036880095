import { SECTION_KEY } from "../CourseCard/model"

export const BADGE_MODEL = {
  certificate: {
    th: 'มีประกาศนียบัตร',
    icon: 'ic_certificate_line.svg',
  },
  time: {
    th: 'ชั่วโมงเรียน',
    icon: 'time-linear.svg',
  },
}

export const COLLECTION_SECTION_KEY = {
  ELEARNING: SECTION_KEY.E_LEARNING,
  LEARNING_PATH: SECTION_KEY.LEARNING_PATH,
  COLLECTION: SECTION_KEY.COLLECTION,
}