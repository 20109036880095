import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import {
  CellContainer,
  Container,
  LeftCellContainer,
  StyledDivider,
} from './Styled'
import { isMediaWidthMoreThan, replaceProtocol } from 'src/utils/lib'

const TrainingCell = () => {
  const trainingRoom = useSelector(
    (state) => state.registration.data.trainingRoom,
    shallowEqual
  )

  const isWidthLg = isMediaWidthMoreThan('sm')
  return (
    <>
      {trainingRoom.map((item, index) => {
        const fullDate = `${item.dateDay} ${item.dateMonth} ${item.dateYear}`
        const fullTime = `${item.startTime} - ${item.endTime}`

        return (
          <Container key={index}>
            <LeftCellContainer>
              <Typography variant="body1" sx={{ width: '140px' }}>
                {fullDate}
              </Typography>
              <Typography
                variant="body1"
                sx={{ width: '200px' }}
                color="text.secondary"
              >
                {fullTime}
              </Typography>
            </LeftCellContainer>
            <CellContainer>
              <Typography
                variant="body1"
                sx={{ width: 'auto' }}
                color="text.secondary"
              >
                {item.roomName}
              </Typography>

              {item.roomType === 'ONLINE_ROOM' && (
                <>
                  {isWidthLg ? <StyledDivider /> : ''}
                  <Button
                    startIcon={<VideocamOutlinedIcon />}
                    onClick={() => window.open(`${replaceProtocol(item.link)}`, '_blank')}
                  >
                    เข้าเรียน
                  </Button>
                </>
              )}
            </CellContainer>
          </Container>
        )
      })}
    </>
  )
}

export default TrainingCell
