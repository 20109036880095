import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { handleDatePicker } from '../events'

const Index = ({ calendarRef }) => {
  const { currentDate, tapMenu } = useSelector(
    (state) => state.myProfile,
    shallowEqual
  )
  const dispatch = useDispatch()

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Custom input"
          value={currentDate}
          onChange={(value) =>
            dispatch(handleDatePicker({ value, calendarRef, tapMenu }))
          }
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box ref={inputRef}>
                <TextField sx={{ display: 'none' }} {...inputProps} />
              </Box>
              {InputProps?.endAdornment}
            </Box>
          )}
        />
      </LocalizationProvider>
    </>
  )
}

export default Index
