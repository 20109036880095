import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { StyledExam } from './Styled'
import Header from './Header'
import ExamRound from './ExamRound'
import ActionBar from './ActionBar'
import InformationForm from './InformationForm'
import { fetchInitials } from './events'
import Confirmation from './Confirmation'

const EExamination = () => {
  const dispatch = useDispatch()
  const { progress } = useSelector(
    (state) => ({
      progress: state.eExamination.progress,
    }),
    shallowEqual
  )
  useEffect(() => {
    dispatch(fetchInitials())
  }, [])

  const Content = () => {
    if (progress === 0) {
      return <ExamRound />
    } else if (progress === 1) {
      return <InformationForm />
    } else if (progress === 2) {
      return <Confirmation />
    }
  }
  return (
    <>
      <StyledExam>
        <Header />
        {Content()}
      </StyledExam>
      <ActionBar />
    </>
  )
}

export default EExamination
