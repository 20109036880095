import _ from 'lodash'
import {
  setChangeCamera,
  openDialog as openDetailDialog,
  closeDialog as closeDetailDialog,
  setScanAllow,
  setTime,
  setFailMaxTimes,
} from 'src/redux/slices/eLearning/detail'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  postEnrollRekognition,
  postVerifyFace,
} from 'src/services/eLearning/enroll'
import {
  ENROLL_REKOGNITION_STATUS,
  REKOGNITION_EVENT,
} from 'src/constants/eLearning'
import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import { postCourseLogLearner } from 'src/services/eLearning/learning'
import { setValidate } from 'src/redux/slices/eLearning/learning'
import {
  getCheckBatchFailScanCount,
  postBatchFailScanCount,
  putBatchFailScanCount,
} from 'src/services/eLearning/detail'
import { ERROR_OBJ, ERROR_TYPE } from 'src/components/FaceLiveness/model'
import { handleCalculateTimeLeft } from '../../../Enroll/Verification/events'
import { getDetailState } from '../../events'

export const handleCheckAllowScan = (isDialogOpen) => async (dispatch) => {
  if (!isDialogOpen) return

  const data = getDetailState().data
  const uuid = _.get(data, 'eLearningCourseBatchLearner.uuid', '')
  dispatch(startLoading())
  const response = await dispatch(getCheckBatchFailScanCount(uuid))
  dispatch(stopLoading())
  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  if (!_.isEmpty(expireDateTime)) {
    dispatch(handleDisallowScan(response))
  }
}

export const handleDisallowScan = (response) => (dispatch) => {
  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  const time = handleCalculateTimeLeft(expireDateTime)
  dispatch(setTime(time))
  const failTimes = _.get(response.payload.data, 'countRegister', '')
  dispatch(setFailMaxTimes(failTimes))
  dispatch(setScanAllow(false))
  dispatch(openDetailDialog())
}

export const handleOpenCamera = () => (dispatch) => {
  dispatch(setChangeCamera({ key: 'isOpen', value: true }))
  dispatch(setChangeCamera({ key: 'faceImage', value: null }))
}

export const handleDetectFace = (livenessData, history) => async (dispatch) => {
  const course = getDetailState().data
  const targetImage = _.get(
    course,
    'eLearningBatchLearnerRekog.faceImageKey',
    ''
  )
  const payload = { sessionId: livenessData.sessionId, body: { targetImage } }
  dispatch(startLoading())
  const response = await dispatch(postVerifyFace(payload))
  dispatch(setChangeCamera({ key: 'isOpen', value: false }))
  dispatch(setChangeCamera({ key: 'faceImage', value: null }))

  if (_.isEmpty(response.error)) {
    const idCardEnroll = _.get(course, 'eLearningIdCardEnroll', {})
    const eLearningIdCardEnroll = handleRelationObj(idCardEnroll)
    const eLearningCourseBatch = handleRelationObj(
      _.get(idCardEnroll, 'eLearningCourseBatch', {})
    )
    const data = _.get(response.payload, 'data', {})
    const faceImageKey = _.get(data, 'faceImageKey', '')
    const status = _.get(data, 'status', '')
    const body = {
      eLearningCourseBatch,
      eLearningIdCardEnroll,
      eLearningCourseBatchLearner: handleRelationObj(
        _.get(course, 'eLearningCourseBatchLearner', {})
      ),
      event: REKOGNITION_EVENT.ATTEND,
      faceImageKey,
      status,
      percent: _.get(data, 'percent', 0),
      times: 1,
    }

    const resEnroll = await dispatch(postEnrollRekognition(body))
    dispatch(stopLoading())

    if (
      _.isEmpty(resEnroll.error) &&
      status === ENROLL_REKOGNITION_STATUS.SUCCESS
    ) {
      dispatch(handleVerifySuccess(history))
    } else dispatch(handleVerifyFail(ERROR_TYPE.VERIFY))
  } else {
    dispatch(stopLoading())
    dispatch(handleVerifyFail(ERROR_TYPE.DETECT))
  }
}

export const handleVerifySuccess = (history) => (dispatch) => {
  dispatch(
    openDialog({
      iconType: 'success',
      title: 'ยืนยันตัวตนสำเร็จ',
      isCloseDialog: false,
      buttonRight: {
        handleClick: () => dispatch(handleRedirectToLearn(history)),
      },
    })
  )
}

export const handleRedirectToLearn = (history) => async (dispatch) => {
  const data = getDetailState().data
  const uuid = _.get(data, 'uuid', '')
  const eLearningCourse = handleRelationObj(data)
  const eLearningCourseBatch = handleRelationObj(
    _.get(data, 'eLearningIdCardEnroll.eLearningCourseBatch', {})
  )
  const payload = {
    eLearningCourse: {
      ...eLearningCourse,
      courseType: _.get(data, 'courseType', ''),
    },
    eLearningCourseBatch,
  }

  dispatch(startLoading())
  const response = await dispatch(postCourseLogLearner(payload))
  dispatch(stopLoading())
  dispatch(closeDialog())
  dispatch(closeDetailDialog())

  if (_.isEmpty(response.error)) {
    dispatch(setValidate())
    setTimeout(() => {
      const path = `/e-learning/course/learning/${uuid}?batch=${eLearningCourseBatch.uuid}`
      history.push(path)
    }, 500)
  }
}

export const handleVerifyFail = (type) => async (dispatch) => {
  const errorObj = _.get(ERROR_OBJ, type, ERROR_OBJ[ERROR_TYPE.SYSTEM])
  if (!errorObj.isCount) {
    dispatch(openScanErrorDialog(type))
    return
  }

  const { data, failLogUuid } = getDetailState()
  const eLearningCourseBatchLearner = handleRelationObj(
    _.get(data, 'eLearningCourseBatchLearner', {})
  )
  dispatch(startLoading())
  let response = {}
  if (_.isEmpty(failLogUuid)) {
    response = await dispatch(
      postBatchFailScanCount({ eLearningCourseBatchLearner })
    )
  } else {
    const payload = { uuid: failLogUuid, checkStatus: 'fail' }
    response = await dispatch(putBatchFailScanCount(payload))
  }
  dispatch(stopLoading())

  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  if (!_.isEmpty(expireDateTime)) {
    dispatch(closeDialog())
    dispatch(handleDisallowScan(response))
  } else {
    dispatch(openScanErrorDialog(type))
  }
}

export const openScanErrorDialog = (type) => (dispatch) => {
  const { title, message } = ERROR_OBJ[type]
  dispatch(
    openDialog({
      isCloseDialog: false,
      iconType: 'error',
      title,
      message: _.concat(message),
      buttonLeft: {
        handleClick: () => {
          dispatch(closeDialog())
          dispatch(closeDetailDialog())
        },
      },
      buttonRight: {
        label: 'ลองอีกครั้ง',
        handleClick: () => {
          dispatch(closeDialog())
          dispatch(setChangeCamera({ key: 'isOpen', value: false }))
          setTimeout(() => {
            dispatch(handleOpenCamera())
          }, 1000)
        },
      },
    })
  )
}

export const handleRelationObj = (obj) => {
  if (_.isEmpty(obj)) return null
  else return { id: obj.id, uuid: obj.uuid }
}
