import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Table, TableBody, TableContainer, Typography } from '@mui/material'
import { Column, Row } from 'src/components/Layout/Styled'
import TableBodyRowMobile from './TableBodyRowMobile'
import CircularProgress from '@mui/material/CircularProgress'
import { loadNextPage } from '../ResultTable/event'
import { StyledTableCell, StyledTableRow } from './Styled'

const ResultTableMobile = () => {
  const dispatch = useDispatch()
  const { roundList, hasMore, page, isFetch } = useSelector(
    (state) => ({
      roundList: state.eExamination.roundList,
      hasMore: state.eExamination.examinationFilter.hasMore,
      page: state.eExamination.examinationFilter.page,
      isFetch: state.eExamination.examinationFilter.isFetch,
    }),
    shallowEqual
  )
  return (
    <Column>
      <Row sx={{ background: '#7698CB1A', p: 2 }}>
        <Typography variant="body1b">ผลการค้นหา</Typography>
      </Row>
      <TableContainer
        id="result-table"
        sx={{ maxHeight: 600, textAlign: 'center' }}
        onScroll={(e) => {
          if (!hasMore || isFetch) return
          if (
            e.target.offsetHeight + e.target.scrollTop + 10 >
            e.target.scrollHeight
          ) {
            dispatch(loadNextPage(page + 1))
          }
        }}
      >
        <Table>
          <TableBody>
            {roundList.length > 0 ? (
              roundList.map((ele, ind) => {
                return <TableBodyRowMobile key={ind} index={ind} />
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={7} sx={{ textAlign: 'center' }}>
                  ไม่พบข้อมูล
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        {isFetch && page > 1 && (
          <CircularProgress size={20} sx={{ margin: '16px' }} />
        )}
      </TableContainer>
    </Column>
  )
}

export default ResultTableMobile
