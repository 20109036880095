import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { handleGoToELearningList, handleGoToMyClass } from './events'
import { StyledWrapper } from './Styled'

const SuccessStep = () => {
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <Column sx={{ alignItems: 'center', gap: 4, pb: 4 }}>
      <img src="/image/e_learning_register_success.svg" width={193} />
      <Column
        sx={{ alignItems: 'center', gap: 1, '*': { textAlign: 'center' } }}
      >
        <Typography variant={isNotSm ? 'h4' : 'h6'}>
          คุณลงทะเบียนสำเร็จ
        </Typography>
        <Typography variant={isNotSm ? 'h5' : 'body1b'} color="error">
          กรุณารอรับอีเมลประกาศสิทธิ์เข้าอบรมจากเจ้าหน้าที่อีกครั้ง
        </Typography>
      </Column>
      <StyledWrapper>
        <Button
          size={isNotSm ? 'l' : 'sm'}
          variant="outlined"
          onClick={handleGoToELearningList}
        >
          กลับไปหน้าหลักสูตรทั้งหมด
        </Button>
        <Button
          size={isNotSm ? 'l' : 'sm'}
          variant="contained"
          onClick={handleGoToMyClass}
        >
          คลาสของฉัน
        </Button>
      </StyledWrapper>
    </Column>
  )
}

export default SuccessStep
