import _ from 'lodash'
import { answerETestingObj } from './model'

export const handleInitialData = (state, payload) => {
  const data = _.get(payload, 'data', {})
  const randomQuestion = _.get(data, 'randomQuestion', [])
  const randomAnswer = _.get(data, 'randomAnswer', [])
  state = Object.assign(state, data)

  let questions = _.get(data, 'questions', [])
  if (randomQuestion) questions = _.shuffle(questions)
  questions = questions.map((item, index) => {
    let answers = item.answers
    if (randomAnswer || item.questionType === 'SEQUENCE') {
      answers = _.shuffle(answers)
    }
    if (randomAnswer && item.questionType === 'FILL_IN_BLANK_CHOICE') {
      answers = item.answers.map((ans) => ({
        ...ans,
        subAnswers: _.shuffle(ans.subAnswers),
      }))
    }
    return {
      ...item,
      answers,
      sequence: index + 1,
    }
  })
  state.questions = questions
  state.totalETesting = questions.length

  handleInitialAnswer(state, questions)
}

export const handleInitialAnswer = (state, questions) => {
  questions.forEach((question) => {
    if (isDuplicateAnswer(state, question)) return

    const answerObj = {
      ...answerETestingObj,
      questionUuid: question.uuid,
      type: question.questionType,
      key: question.sequence,
      isAnswered: question.questionType === 'SEQUENCE',
    }

    if (question.questionType === 'SEQUENCE') {
      _.set(answerObj, 'sequenceList', question.answers)
    }

    if (question.mediaType == 'VIDEO' && question.url != '') {
      _.set(answerObj, 'isVideoEnded', false)
    }

    state.answerETesting.push(answerObj)
  })
}

export const checkDisplayOneAndNow = (eTesting) => {
  if (!eTesting) return false
  return (
    eTesting.displayQuestion == 'ONE' &&
    eTesting.questionResult.isOpen &&
    eTesting.questionResult.result == 'NOW'
  )
}

export const isDuplicateAnswer = (state, question) => {
  const questionUuid = _.get(question, 'uuid', '')
  return state.answerETesting.some((item) => item.questionUuid == questionUuid)
}

export const handleAnswerType = (state, answerState, payload) => {
  const { type, value } = payload
  const questionUuid = _.get(payload, 'uuid', '')
  switch (type) {
    case 'SINGLE': {
      _.set(answerState, 'answerUuid', value)
      _.set(answerState, 'isAnswered', true)
      break
    }
    case 'FREE_TEXT': {
      _.set(answerState, 'freetextAnswer', value)
      _.set(answerState, 'isAnswered', !_.isEqual(value, ''))
      break
    }
    case 'MULTIPLE': {
      const prevAnswer = state.answerETesting.find(
        (item) => item.questionUuid === questionUuid
      )
      let newAnswer = state.answerETesting.filter(
        (item) =>
          item.questionUuid != questionUuid ||
          (item.questionUuid == questionUuid && item.answerUuid != '')
      )
      if (value.checked) {
        newAnswer.push({
          ...prevAnswer,
          answerUuid: value.value,
          isAnswered: true,
          questionUuid,
        })
      } else {
        newAnswer = newAnswer.filter((item) => item.answerUuid != value.value)
      }

      _.set(state, 'answerETesting', newAnswer)
      break
    }
    case 'SEQUENCE': {
      _.set(answerState, 'sequenceList', value)
      break
    }
    case 'MATCHING':
    case 'FILL_IN_BLANK_CHOICE':
    case 'FILL_IN_BLANK_DRAG': {
      const question = state.questions.find(
        (item) => item.uuid === questionUuid
      )
      const answers = _.get(question, 'answers', []).filter(
        (ans) => ans.isCorrect || !_.isEmpty(ans.target)
      )
      const key = type === 'MATCHING' ? 'matchingAns' : 'fillAns'
      _.set(answerState, key, value)
      _.set(
        answerState,
        'isAnswered',
        value.filter((item) => !_.isNil(item)).length === answers.length
      )
      break
    }
    case 'TRUE_FALSE': {
      const questionUuid = _.get(payload, 'uuid', '')
      const answerUuid = _.get(payload, 'answerUuid', '')
      let newAnswer = state.answerETesting
      const answerIndex = newAnswer.findIndex(
        (ans) =>
          ans.questionUuid === questionUuid &&
          (ans.answerUuid === '' || ans.answerUuid === answerUuid)
      )
      const answerObj = {
        ...answerETestingObj,
        questionUuid,
        answerUuid,
        isValueAns: value,
      }
      if (answerIndex === -1) newAnswer.push(answerObj)
      else newAnswer[answerIndex] = { ...newAnswer[answerIndex], ...answerObj }

      const trueFalseAns = newAnswer.filter(
        (ans) => ans.questionUuid === questionUuid
      )
      const trueFalseQue = state.questions.find(
        (que) => que.uuid === questionUuid
      )
      const isAnswered =
        _.get(trueFalseQue, 'answers', []).length === trueFalseAns.length
      newAnswer = newAnswer.map((item) => ({
        ...item,
        isAnswered:
          item.questionUuid === questionUuid ? isAnswered : item.isAnswered,
      }))

      _.set(state, 'answerETesting', newAnswer)
      break
    }
    default:
      break
  }
}
