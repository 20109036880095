import React from 'react'
import { TableHead, TableRow } from '@mui/material'
import ChairAltOutlinedIcon from '@mui/icons-material/ChairAltOutlined'
import EventIcon from '@mui/icons-material/Event'
import BusinessIcon from '@mui/icons-material/Business'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined'
import { StyledTableCell } from './Styled'

const TableHeadRow = () => {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell
          $available={true}
          $head
          align="center"
          sx={{ width: 50 }}
        ></StyledTableCell>
        <StyledTableCell
          $available={true}
          $head
          align="center"
          sx={{ width: 123 }}
        >
          <ChairAltOutlinedIcon
            sx={{
              marginRight: '5px',
              marginBottom: '-5px',
              color: '#0000008A',
              fontSize: 18,
            }}
          />
          จำนวนที่ว่าง
        </StyledTableCell>
        <StyledTableCell
          $available={true}
          $head
          align="center"
          sx={{ width: 148 }}
        >
          <EventIcon
            sx={{
              marginRight: '5px',
              marginBottom: '-5px',
              color: '#0000008A',
              fontSize: 18,
            }}
          />
          วันและเวลาที่สอบ
        </StyledTableCell>
        <StyledTableCell
          $available={true}
          $head
          align="center"
          sx={{ width: 112 }}
        >
          <BusinessIcon
            sx={{
              marginRight: '5px',
              marginBottom: '-5px',
              color: '#0000008A',
              fontSize: 18,
            }}
          />
          สนามสอบ
        </StyledTableCell>
        <StyledTableCell
          $available={true}
          $head
          align="center"
          sx={{ width: 120 }}
        >
          <LocationOnOutlinedIcon
            sx={{
              marginRight: '5px',
              marginBottom: '-5px',
              color: '#0000008A',
              fontSize: 18,
            }}
          />
          สถานที่สอบ
        </StyledTableCell>
        <StyledTableCell $available={true} $head align="center">
          <LocationCityOutlinedIcon
            sx={{
              marginRight: '5px',
              marginBottom: '-5px',
              color: '#0000008A',
              fontSize: 18,
            }}
          />
          อื่นๆ
        </StyledTableCell>
        <StyledTableCell
          $available={true}
          $head
          align="center"
          sx={{ width: 169 }}
        >
          <EventIcon
            sx={{
              marginRight: '5px',
              marginBottom: '-5px',
              color: '#0000008A',
              fontSize: 18,
            }}
          />
          วันที่เปิด-ปิดรับสมัคร
        </StyledTableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableHeadRow
