import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Template from 'src/components/Template'
import {
  StyledFooterWrapper,
  StyledStepButton,
  StyledRightButton,
  StyledWrapper,
} from './Styled'
import {
  handleClickBack,
  handleClickNext,
  handleDisableBtn,
  handleSubmitEnroll,
} from './events'

const Footer = ({ isVerification }) => {
  const activeStep = useSelector(
    (state) => state.eLearningEnroll.activeStep,
    shallowEqual
  )
  return (
    <StyledFooterWrapper>
      <Template noMinHeight>
        <StyledWrapper>
          {(isVerification || activeStep !== 0) && (
            <StyledStepButton
              activeStep={activeStep}
              onClick={() => handleClickBack(isVerification)}
            >
              ย้อนกลับ
            </StyledStepButton>
          )}
          {!isVerification && <RightButton />}
        </StyledWrapper>
      </Template>
    </StyledFooterWrapper>
  )
}

export default Footer

export const RightButton = () => {
  const { activeStep, isDisableSubmit, batch, idCardImage, faceImage } =
    useSelector(
      (state) => ({
        activeStep: state.eLearningEnroll.activeStep,
        isDisableSubmit: state.eLearningEnroll.isDisableSubmit,
        batch: state.eLearningEnroll.body.batch,
        idCardImage: state.eLearningEnroll.body.idCardImage,
        faceImage: state.eLearningEnroll.body.faceImage,
      }),
      shallowEqual
    )
  const disabled = useMemo(
    () => handleDisableBtn(),
    [activeStep, batch, idCardImage, faceImage]
  )

  if (activeStep === 1) {
    return (
      <StyledRightButton
        key="submit"
        endIcon={<ArrowForward />}
        disabled={isDisableSubmit}
        onClick={handleSubmitEnroll}
      >
        ลงทะเบียนเข้าอบรม
      </StyledRightButton>
    )
  }

  return (
    <StyledRightButton
      key="next"
      endIcon={<ArrowForward />}
      disabled={disabled}
      activeStep={activeStep}
      onClick={handleClickNext}
    >
      ถัดไป
    </StyledRightButton>
  )
}
