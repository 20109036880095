import _ from 'lodash'
import { store } from 'src/App'
import {
  setCourseType,
  setInitialPreview,
  startLoading,
  stopLoading,
  startCourseLoading,
  stopCourseLoading,
  setSelectedCourse,
} from 'src/redux/slices/eLearning/learningPath/detail'
import { getLearningPathDetail } from 'src/services/eLearning/learningPath'
import { fetchCourseDetail } from 'src/modules/Course/Detail/events'
import { postLikeLearningPath } from 'src/services/eLearning/learningPath'
import { fetchCourseDetail as fetchELearningCourseDetail } from '../../Course/Detail/events'
import { COURSE_TYPE } from './model'

export const handleInitialPreview = (id) => async (dispatch) => {
  dispatch(startLoading())
  const response = await dispatch(getLearningPathDetail(id))
  const data = _.get(response.payload, 'data', {})
  dispatch(stopLoading())
  dispatch(setInitialPreview(data))

  const firstCourse = _.get(data, 'eLearningLearningPathCourses[0]', {})
  const type = _.get(firstCourse, 'type', '')
  const uuid = _.get(firstCourse, 'course.uuid', '')
  await dispatch(fetchPathCourseDetail({ type, uuid }))
}

export const fetchPathCourseDetail = (course) => async (dispatch) => {
  const { type, uuid } = course
  dispatch(setCourseType(type))
  dispatch(setSelectedCourse(uuid))

  dispatch(startCourseLoading())
  if (type === COURSE_TYPE.E_LEARNING) {
    await dispatch(fetchELearningCourseDetail(uuid))
  } else {
    await dispatch(fetchCourseDetail(uuid))
  }
  dispatch(stopCourseLoading())
}

export const getDetailState = () => {
  return store.getState().eLearningLearningPath
}

export const handleLikeCourse = () => (dispatch) => {
  const { data } = getDetailState()
  const payload = {
    eLearningLearningPath: { id: data.id, uuid: data.uuid },
    isLike: !_.get(data, 'isLike', false),
  }
  dispatch(postLikeLearningPath(payload))
}
