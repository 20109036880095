import _ from 'lodash'
import { headCells } from 'src/constants/eLearning'
import {
  setInitialTable,
  startLoading,
  stopLoading,
} from 'src/redux/slices/table'
import { getCourseBatchLearnerLog } from 'src/services/class/e-learning-registration'
import {
  convertDateToThaiString,
  convertDurationToTimeRange,
} from 'src/utils/lib'

export const fetchAttendanceHistory = (table, uuid) => async (dispatch) => {
  const { limit, page } = table
  const realPage = page <= 0 ? 1 : +page
  const url = `?limit=${limit}&page=${realPage}&uuid=${uuid}`

  dispatch(startLoading())
  const response = await dispatch(getCourseBatchLearnerLog(url))
  dispatch(stopLoading())

  const data = _.get(response, 'payload.data', {})
  dispatch(
    setInitialTable({
      rows: _.get(data, 'result', []).map((row) => {
        const timeRange = convertDurationToTimeRange(row.duration, row.dateTime)
        return {
          date: convertDateToThaiString(row.dateTime),
          duration: `${timeRange.startTime} น. - ${timeRange.endTime} น.`,
        }
      }),
      allCount: _.get(data, 'totalCount', 0),
      headCells,
      isHideHeadCell: true,
      isCheckBox: false,
    })
  )
}
