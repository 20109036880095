import React from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import { TabContext, TabList } from '@mui/lab'
import { ECONTENT_TYPE, ECONTENT_TYPE_TH_TEXT } from '../../model'
import { handleChangeTab } from './events'
import TabContent from './TabContent'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './styled.css'

const RecommendArticleRow = ({ isMobile }) => {
  const dispatch = useDispatch()
  const { type } = useSelector(
    (state) => ({
      type: state.eContent.filter.type,
    }),
    shallowEqual
  )
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <TabContext value={type[0]}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(event, value) => dispatch(handleChangeTab(value))}
                aria-label="tab-list"
                indicatorColor="secondary"
              >
                <Tab
                  label={ECONTENT_TYPE_TH_TEXT.E_CONTENT}
                  value={ECONTENT_TYPE.E_CONTENT}
                />
                <Tab
                  label={ECONTENT_TYPE_TH_TEXT.KB}
                  value={ECONTENT_TYPE.KB}
                />
              </TabList>
            </Box>
          </Box>
          <TabContent isMobile={isMobile} />
        </TabContext>
      </Box>
    </>
  )
}

export default RecommendArticleRow
