import _ from 'lodash'

export const handleGetDocumentData = (state, { payload }) => {
  state.data = payload.data
  const documentList = _.get(payload, 'data.documentList', [])
  const courseUuid = _.get(payload, 'data.course.uuid', '')
  const trainingPlanUuid = _.get(payload, 'data.trainingPlan.uuid', '')
  const callbackUrl = `/course/${courseUuid}/class/${trainingPlanUuid}`
  state.isResend = _.get(payload, 'data.round', 1) > 1
  state.requestData = documentList
    .map((item, index) => ({ ...item, key: index }))
    .filter((item) => item.allow)
  state.callbackUrl = callbackUrl
}

export const handleServiceError = (state, { meta }) => {
  if (meta.response.status < 500) state.error = meta.response?.data
}
