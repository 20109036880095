import { store } from 'src/App'
import {
  setSelectedFile,
  setSelectedLesson,
  setVideoState,
  startLoading,
  stopLoading,
} from 'src/redux/slices/eLearning/learning'
import { mediaType } from '../../../../model'

export const handleSelectFile = ({
  type,
  isSelectedLesson,
  isCurrent,
  data,
  index,
}) => {
  store.dispatch(startLoading())
  store.dispatch(setVideoState({ playing: false }))
  setTimeout(() => {
    if (type !== mediaType.UPLOAD_FILE || !isSelectedLesson || !isCurrent) {
      store.dispatch(setSelectedLesson(data))
    }
    store.dispatch(setSelectedFile(index))
    store.dispatch(stopLoading())
  }, 1500)
}
