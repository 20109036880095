import _ from 'lodash'
import { setBatch } from 'src/redux/slices/eLearning/enroll'

export const handleBatchEvent = (state) => {
  return state.eLearningEnroll.batches.map((batch) => ({
    ...batch,
    start: batch.periodStart,
    end: batch.periodStart,
    display: 'background',
  }))
}

export const handleSelectEvent = (event, isSelected) => {
  if (!isSelected) return

  const dateEls = document.querySelectorAll('.fc-daygrid-day')
  dateEls.forEach((date) => {
    const periodStart = _.get(event, 'extendedProps.periodStart', '')
    const periodEnd = _.get(event, 'extendedProps.periodEnd', '')
    const dataDate = date.getAttribute('data-date')
    const dateElStyle = date.children[0].style

    if (dataDate === periodStart || dataDate === periodEnd) {
      dateElStyle.background = '#00008f'
      dateElStyle.color = '#ffffff'
      dateElStyle.fontWeight = 700
      if (!date.querySelector('.body3b')) {
        const span = document.createElement('span')
        span.className = 'body3b'
        span.innerHTML = dataDate === periodStart ? 'เริ่มอบรม' : 'จบอบรม'
        date.children[0].children[0].appendChild(span)
      }
    } else {
      const otherEvent = date.querySelector(`#e-${dataDate}`)
      if (dataDate > periodStart && dataDate < periodEnd) {
        dateElStyle.background = '#494DF414'
        if (otherEvent) otherEvent.style.background = '#f2f3fe'
      } else {
        dateElStyle.background = 'transparent'
        if (otherEvent) otherEvent.style.background = '#ffffff'
      }
      dateElStyle.color = '#333333'
      dateElStyle.fontWeight = 400
      date.querySelectorAll('.body3b')?.forEach((el) => el.remove())
    }
  })
}

export const handleSelectBatch = (event) => (dispatch) => {
  const uuid = _.get(event, 'extendedProps.uuid', '')
  dispatch(setBatch(uuid))
}
