import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledBackgroundLoading = styled(Box)(({ theme, isloading }) => ({
  backgroundColor: theme.palette.background?.blur,
  position: 'fixed',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  display: isloading ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
}))
