import {
  getAddress,
  getFieldOption,
  getGender,
  getQualification,
  postScheduleFiter,
  getEExaminationUserFilter,
  getMyExaminerProfile,
} from 'src/services/eExamination'
import _ from 'lodash'
import { USER_TYPE } from 'src/modules/EExamination/constant'
import { getGenderFromPrefix } from 'src/utils/lib'
import { manageOtherDocsName } from './event'

const { createSlice } = require('@reduxjs/toolkit')
const { initialState } = require('./model')

const eExamination = createSlice({
  name: 'eExamination',
  initialState: initialState,
  reducers: {
    setProgress: (state, { payload }) => {
      state.progress = payload
    },
    setExaminerDrawer: (state, { payload }) => {
      state.examinerDrawer = payload
    },
    setFilterValue: (state, { payload }) => {
      const { index, value } = payload
      state.examinationFilter[index] = value
    },
    setDateFilter: (state, { payload }) => {
      const { startDate, endDate } = payload
      state.examinationFilter.startDate = startDate
      state.examinationFilter.endDate = endDate
    },
    setReduxValue: (state, { payload }) => {
      const { index, value } = payload
      state[index] = value
    },
    setOptions: (state, { payload }) => {
      const { index, value } = payload
      state.options[index] = value
    },
    setAddressOptions: (state, { payload }) => {
      const { addressOptions, tambonOptions, amphureOptions, provinceOptions } =
        payload
      state.options.addressOptions = addressOptions
      state.options.tambonOptions = tambonOptions
      state.options.amphureOptions = amphureOptions
      state.options.provinceOptions = provinceOptions
    },
    addExaminer: (state) => {
      const { examiners, selectedExaminerList } = state
      state.examiners = [...examiners, ...selectedExaminerList]
      state.examinerDrawer = false
    },
    removeExaminer: (state, { payload }) => {
      const { examiners } = state
      const { index } = payload
      let newExaminer = [...examiners]
      newExaminer.splice(index, 1)
      state.examiners = newExaminer
    },
    setInitialAddExaminerDrawer: (state) => {
      state.examinerSearchText = ''
      state.examinerList = []
      state.selectedExaminerList = []
    },
    setSelectedRounds: (state, { payload }) => {
      const { value } = payload
      state.selectedRounds = value
    },
    setExaminerDetail: (state, { payload }) => {
      const { index, obj, value } = payload
      state.examiners[index][obj] = value
    },
    setImage: (state, { payload }) => {
      const { index, cardImage, imageName, fileName, key, fileSize, fileType } =
        payload
      const imgObj = {
        cardImage,
        imageName,
        fileName,
        key,
        fileSize,
        fileType,
      }
      state.examiners[index].cardImage = imgObj
      state.examiners[index].imageName = imageName
    },
    setExaminersFormError: (state, { payload }) => {
      const { index, value } = payload
      state.examinersFormError[index] = value
    },
    setFormNextButtonDisabled: (state, { payload }) => {
      const { index, value } = payload
      state.formNextButtonDisabled[index] = value
    },
    setFormError: (state, { payload }) => {
      const { index, value } = payload
      state.formError[index] = value
    },
    setZip: (state, { payload }) => {
      const {
        index,
        zipCode,
        tambonUuid,
        tambonName,
        amphureUuid,
        amphureName,
        provinceUuid,
        provinceName,
      } = payload
      state.examiners[index].zipCode = zipCode
      state.examiners[index].tambon = tambonUuid
      state.examiners[index].amphure = amphureUuid
      state.examiners[index].province = provinceUuid
      state.options.tambonOptions[index] = [
        { label: tambonName, value: tambonUuid },
      ]
      state.options.amphureOptions[index] = [
        { label: amphureName, value: amphureUuid },
      ]
      state.options.provinceOptions[index] = [
        {
          label: provinceName,
          value: provinceUuid,
        },
      ]
    },
    clearAddress: (state, { payload }) => {
      const { index, value } = payload
      state.examiners[index].zipCode = value
      state.examiners[index].tambon = ''
      state.examiners[index].amphure = ''
      state.examiners[index].province = ''
      state.options.addressOptions[index] = []
      state.options.tambonOptions[index] = []
      state.options.amphureOptions[index] = []
      state.options.provinceOptions[index] = []
    },
    setConsent: (state, { payload }) => {
      state.consent = payload
    },
    setUserFilterValue: (state, { payload }) => {
      payload.map((item) => {
        const { index, value } = item
        state.examinationUserFilter[index] = value
      })
    },
    removeIdcard: (state, { payload }) => {
      const { index } = payload
      state.examiners[index].cardImage = ''
      state.examiners[index].imageName = ''
    },
    setWidth: (state, { payload }) => {
      state.windowWidth = payload
    },
    setSideBarDrawer: (state, { payload }) => {
      state.sideBarDrawer = payload
    },
    startDrawerLoading: (state) => {
      state.drawerIsLoading = true
    },
    stopDrawerLoading: (state) => {
      state.drawerIsLoading = false
    },
    setOtherDocConfig: (state, { payload }) => {
      state.otherDocConfig = payload
    },
    setOtherDocs: (state, { payload }) => {
      const {
        index,
        cardImage,
        imageName,
        documentName,
        fileName,
        key,
        fileSize,
        fileType,
      } = payload
      if (!state.examiners[index].otherDocs) {
        state.examiners[index].otherDocs = []
      }
      const otherDocs = manageOtherDocsName(state.examiners[index].otherDocs, {
        cardImage: cardImage,
        imageName: imageName,
        documentName: documentName,
        fileName,
        key,
        fileSize,
        fileType,
      })
      state.examiners[index].otherDocs = otherDocs
    },
    removeOtherDocs: (state, { payload }) => {
      const { index, imageIndex } = payload
      const otherDocs = manageOtherDocsName(
        state.examiners[index].otherDocs.filter(
          (item, itemIndex) => itemIndex !== imageIndex
        ),
        null
      )
      state.examiners[index].otherDocs = otherDocs
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGender.fulfilled, (state, { payload }) => {
        state.options.genderOptions = payload
      })
      .addCase(getGender.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getQualification.fulfilled, (state, { payload }) => {
        state.options.qualificationOptions = payload
      })
      .addCase(getQualification.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getAddress.fulfilled, (state, { payload }) => {
        const { options, index } = payload
        state.options.addressOptions[index] = options
      })
      .addCase(getAddress.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getFieldOption.fulfilled, (state, { payload }) => {
        state.options.fieldOptions = payload
        if (payload.length == 0) {
          state.examinationFilter.fields = []
        }
      })
      .addCase(getFieldOption.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postScheduleFiter.fulfilled, (state, { payload }) => {
        const { result, totalCount, page } = payload
        if (page == 1) {
          state.roundList = _.uniqBy(
            [...state.selectedRounds, ...result],
            'uuid'
          )
        } else {
          state.roundList = state.roundList.concat(result)
        }
        state.examinationFilter.page = page
        state.examinationFilter.hasMore = totalCount > state.roundList.length
        state.examinationFilter.isFetch = false
      })
      .addCase(postScheduleFiter.rejected, (state, action) => {
        state.error = action.meta?.message
        state.examinationFilter.isFetch = false
      })
      .addCase(getEExaminationUserFilter.fulfilled, (state, { payload }) => {
        const { data } = payload
        const {
          examinerList,
          options: { genderOptions },
        } = state
        const newExaminerList = _.get(data, 'result', []).map((examiner) => {
          const userImageUrl = examiner.userImage
            ? `${window.__env__.REACT_APP_FILE_URL}${examiner.userImage}`
            : '/logo/staff_profile_small.png'
          const type = examiner?.type || 'TEMP'
          return {
            uuid: examiner?.uuid || '',
            type: type,
            unitName: examiner?.unitName || '',
            unitNumber: examiner?.unitNumber || '',
            image: userImageUrl,
            firstNameTH: examiner?.firstNameTH || '',
            lastNameTH: examiner?.lastNameTH || '',
            prefix: examiner?.prefixTH || '',
            mobileNo: examiner?.mobileNo?.replace(/-/g, '') || '',
            email: examiner?.email || '',
            idCardNo: examiner?.idCardNo || '',
            isEdit: examiner?.isEdit || false,
            birthDate: examiner?.dateOfBirth || '',
            gender: getGenderFromPrefix(examiner?.prefixTH, genderOptions),
            institution: examiner?.institution || '',
            between: examiner?.between || '',
            qualification: examiner?.qualification || '',
            branch: examiner?.branch || '',
            gpa: examiner?.gpa || '',
            tambon: examiner?.tambon || {},
            amphure: examiner?.amphure || {},
            province: examiner?.province || {},
            address: examiner?.address || '',
            editable: {
              isEditName: type != USER_TYPE.AGENT,
              isEditSurname: type != USER_TYPE.AGENT,
              isEditIdCardNo: !(
                type == USER_TYPE.AGENT || type == USER_TYPE.TEMP
              ),
              isEditMobile: type != USER_TYPE.AGENT,
              isEditBirthDate: !(
                type == USER_TYPE.AGENT || type == USER_TYPE.TEMP
              ),
            },
          }
        })
        if (state.examinationUserFilter.page > 1) {
          const mergeExaminerList = [...examinerList, ...newExaminerList]
          state.examinerList = mergeExaminerList
        } else {
          state.examinerList = newExaminerList
        }
        state.examinationUserFilter.isFetch = false
        state.examinationUserFilter.hasMore =
          state.examinerList.length < data.totalCount
      })
      .addCase(getEExaminationUserFilter.rejected, (state, action) => {
        state.error = action.meta?.message
        state.examinationUserFilter.isFetch = false
      })
      .addCase(getMyExaminerProfile.fulfilled, (state, { payload }) => {
        const {
          options: { genderOptions },
        } = state
        const userImageUrl = payload.userImage
          ? `${window.__env__.REACT_APP_FILE_URL}${payload.userImage}`
          : '/logo/staff_profile_small.png'
        const type = payload?.type || 'TEMP'
        const data = [
          {
            uuid: payload?.uuid || '',
            type: type,
            unitName: payload?.unitName || '',
            unitNumber: payload?.unitNumber || '',
            image: userImageUrl,
            firstNameTH: payload?.firstNameTH || '',
            lastNameTH: payload?.lastNameTH || '',
            prefix: payload?.prefixTH || '',
            mobileNo: payload?.mobileNo?.replace(/-/g, '') || '',
            email: payload?.email || '',
            idCardNo: payload?.idCardNo || '',
            isEdit: payload?.isEdit || false,
            birthDate: payload?.dateOfBirth || '',
            gender: getGenderFromPrefix(payload?.prefixTH, genderOptions),
            institution: payload?.institution || '',
            between: payload?.between || '',
            qualification: payload?.qualification || '',
            branch: payload?.branch || '',
            gpa: payload?.gpa || '',
            zipCode: payload?.tambon?.zipCode || '',
            tambon: payload?.tambon?.uuid || '',
            amphure: payload?.amphure?.uuid || '',
            province: payload?.province?.uuid || '',
            address: payload?.address || '',
            editable: {
              isEditName: type != USER_TYPE.AGENT,
              isEditSurname: type != USER_TYPE.AGENT,
              isEditIdCardNo: !(
                type == USER_TYPE.AGENT || type == USER_TYPE.TEMP
              ),
              isEditMobile: type != USER_TYPE.AGENT,
              isEditBirthDate: !(
                type == USER_TYPE.AGENT || type == USER_TYPE.TEMP
              ),
            },
          },
        ]
        state.examiners = data
        state.options.addressOptions = [[]]
        if (payload?.tambon?.zipCode) {
          state.options.tambonOptions = [
            [
              {
                label: payload?.tambon?.tambonName,
                value: payload?.tambon?.uuid,
              },
            ],
          ]
          state.options.amphureOptions = [
            [
              {
                label: payload?.amphure?.amphureName,
                value: payload?.amphure?.uuid,
              },
            ],
          ]
          state.options.provinceOptions = [
            [
              {
                label: payload?.province?.provinceName,
                value: payload?.province?.uuid,
              },
            ],
          ]
        } else {
          state.options.tambonOptions = [[]]
          state.options.amphureOptions = [[]]
          state.options.provinceOptions = [[]]
        }
        state.formError = [[]]
        state.examinersFormError = [true]
        state.formNextButtonDisabled = [true]
      })
      .addCase(getMyExaminerProfile.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  setProgress,
  setExaminerDrawer,
  setFilterValue,
  setDateFilter,
  setReduxValue,
  setOptions,
  setAddressOptions,
  addExaminer,
  removeExaminer,
  setInitialAddExaminerDrawer,
  setSelectedRounds,
  setExaminerDetail,
  setImage,
  setExaminersFormError,
  setFormNextButtonDisabled,
  setFormError,
  setZip,
  clearAddress,
  setConsent,
  setUserFilterValue,
  removeIdcard,
  setWidth,
  setSideBarDrawer,
  startDrawerLoading,
  stopDrawerLoading,
  setOtherDocConfig,
  setOtherDocs,
  removeOtherDocs,
} = eExamination.actions

export default eExamination.reducer
