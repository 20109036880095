import Divider from '@mui/material/Divider'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const StyledWrapper = styled(Column)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    overflow: 'auto',
  },
}))
