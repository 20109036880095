import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import StepLabel from '@mui/material/StepLabel'
import MuiStepper from '@mui/material/Stepper'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { steps } from './model'
import { StyledConnector, StyledStep, StyledStepIcon } from './Styled'

const Stepper = ({ activeStep }) => {
  return (
    <Box id="stepper">
      <MuiStepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StyledConnector />}
      >
        {steps.map((label, index) => (
          <StyledStep id={`step-${index}`} key={label}>
            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
          </StyledStep>
        ))}
      </MuiStepper>
    </Box>
  )
}

export default Stepper

export const StepIcon = (props) => {
  const { active, completed, className } = props
  const isNotSm = isMediaWidthMoreThan('sm')
  return (
    <StyledStepIcon ownerState={{ completed, active }} className={className}>
      {completed && (
        <img className="done" src="/icon/ic_check_fill_success.svg" />
      )}
      <Typography variant={isNotSm ? 'h5' : 'body1b'}>{props.icon}</Typography>
    </StyledStepIcon>
  )
}
