import React from 'react'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { StyledDialog } from 'src/components/AlertDialog/Styled'
import { closeDialog } from 'src/redux/slices/eLearning/enroll'

const AlertDialog = () => {
  const { isOpen, canClickOutside, content } = useSelector(
    (state) => state.eLearningEnroll.dialog
  )
  const dispatch = useDispatch()

  return (
    <Box>
      <StyledDialog
        maxWidth="sm"
        fullWidth={true}
        customWidth={576}
        sx={{ zIndex: '1298 !important' }}
        open={isOpen}
        onClose={() => canClickOutside && dispatch(closeDialog())}
      >
        {content}
      </StyledDialog>
    </Box>
  )
}

export default AlertDialog
