import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column, Row } from 'src/components/Layout/Styled'
import { dialogIcon } from 'src/components/AlertDialog/model'
import { StyledButton } from './Styled'
import { handleCloseDialog } from './events'

const DialogContent = ({ children }) => {
  const {
    iconType,
    title,
    message,
    isLoading,
    disableButton,
    buttonLeft,
    buttonRight,
    buttonCenter,
    isHiddenFooter,
  } = useSelector((state) => state.alertDialog, shallowEqual)
  return (
    <Column sx={{ p: '18px', gap: 1, alignItems: 'center' }}>
      {iconType && (
        <img alt="icon" src={dialogIcon[iconType] || '/icon/main_error.svg'} />
      )}

      <Column sx={{ gap: 0.5, alignItems: 'center' }}>
        <Typography variant="h6" textAlign="center">
          {title}
        </Typography>
        {message.map((text, i) => (
          <Typography
            key={i}
            variant="h6"
            color="primary"
            textAlign="center"
            sx={{ fontWeight: 400 }}
          >
            {text}
          </Typography>
        ))}
        {children}
      </Column>

      {!isHiddenFooter && (
        <Row sx={{ mt: '10px', gap: '18px' }}>
          {!_.isEmpty(buttonLeft) && (
            <DialogButton
              disabled={isLoading}
              button={buttonLeft}
              variant={_.get(buttonLeft, 'variant', 'outlined')}
            />
          )}
          {!_.isEmpty(buttonCenter) && (
            <DialogButton
              disabled={isLoading}
              button={buttonCenter}
              variant={_.get(buttonCenter, 'variant', 'outlined')}
            />
          )}
          <DialogButton
            loading={isLoading}
            disabled={disableButton}
            button={buttonRight}
            variant={_.get(buttonRight, 'variant', 'contained')}
          />
        </Row>
      )}
    </Column>
  )
}

export default DialogContent

export const DialogButton = ({ button, variant, loading, disabled }) => {
  return (
    <StyledButton
      data-testid="btn-agree"
      size="l"
      loading={loading}
      disabled={disabled}
      variant={variant}
      color={_.get(button, 'color', 'primary')}
      sx={_.get(button, 'style', {})}
      onClick={_.get(button, 'handleClick', handleCloseDialog)}
      startIcon={_.get(button, 'startIcon', null)}
      endIcon={_.get(button, 'endIcon', null)}
    >
      {_.get(button, 'label', 'ตกลง')}
    </StyledButton>
  )
}
