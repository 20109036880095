import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const StyledContainer = styled(Column)(({ theme }) => ({
  padding: `${theme.spacing(10)} 0`,
  marginTop: theme.spacing(9),
  gap: theme.spacing(5),
  alignItems: 'center',
  '& > .MuiBox-root:not(first-of-type)': { maxWidth: 640 },
  '& > .MuiBox-root:first-of-type': { maxWidth: 680 },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(4),
    padding: `${theme.spacing(5)} ${theme.spacing(2.5)}`,
    '& > .MuiBox-root': { maxWidth: '100%' },
  },
}))

export const StyledHeadContent = styled(Column)(({ theme }) => ({
  gap: theme.spacing(1),
  textAlign: 'center',
  span: { color: theme.palette.error?.main },
}))
