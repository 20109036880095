import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getAddtionalConsent,
  getAddtionalConsentAndDocs,
  getLearnerDocument,
  getRegisterDocument,
  postRegisterDocument,
  putLearnerDocument,
  uploadRegisterDocument,
} from 'src/services/document'
import { handleGetDocumentData, handleServiceError } from './events'
import { initialState } from './model'
import { postEnrollClassQueue } from 'src/services/class/detail'

const document = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setUploadDocument: (state, { payload }) => {
      const { key } = payload
      const targetIndex = state.requestData.findIndex((item) => item.key == key)
      state.requestData[targetIndex] = payload
    },
    setErrorText: (state, { payload }) => {
      const { key } = payload
      const errorIndex = state.errorText.findIndex((item) => item.key == key)
      if (errorIndex == -1) {
        state.errorText.push(payload)
      } else {
        state.errorText[errorIndex] = payload
      }
      if (_.isNil(payload.text)) {
        const index = state.errorText.findIndex((item) => item.key == key)
        state.errorText.splice(index, 1)
      }
    },
    setClearFile: (state, { payload }) => {
      state.requestData[payload] = {
        ...state.requestData[payload],
        fileName: undefined,
      }
    },
    setClearErrorText: (state) => {
      state.errorText = []
    },
    setIdCardNo: (state, { payload }) => {
      state.idCardNo = payload
    },
    setDateOfBirth: (state, { payload }) => {
      state.dateOfBirth = payload
    },
    setData: (state, { payload }) => {
      state.data = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLearnerDocument.fulfilled, handleGetDocumentData)
      .addCase(getLearnerDocument.rejected, handleServiceError)
      .addCase(getRegisterDocument.fulfilled, handleGetDocumentData)
      .addCase(getRegisterDocument.rejected, handleServiceError)
      .addCase(putLearnerDocument.fulfilled, (state) => {
        state.isSuccess = true
      })
      .addCase(putLearnerDocument.rejected, handleServiceError)
      .addCase(postRegisterDocument.fulfilled, (state) => {
        state.isSuccess = true
      })
      .addCase(postRegisterDocument.rejected, handleServiceError)
      .addCase(postEnrollClassQueue.fulfilled, (state) => {
        state.isSuccess = true
      })
      .addCase(postEnrollClassQueue.rejected, handleServiceError)
      .addCase(getAddtionalConsent.fulfilled, handleGetDocumentData)
      .addCase(getAddtionalConsent.rejected, handleServiceError)
      .addCase(getAddtionalConsentAndDocs.fulfilled, handleGetDocumentData)
      .addCase(getAddtionalConsentAndDocs.rejected, handleServiceError)
      .addCase(uploadRegisterDocument.fulfilled, (state, { meta, payload }) => {
        const key = _.get(meta.arg, 'key', 0)
        const fileName = _.get(payload, 'key', '')
        const targetIndex = state.requestData.findIndex(
          (item) => item.key == key
        )
        _.set(state.requestData[targetIndex], 'fileName', fileName)
        _.set(
          state.requestData[targetIndex],
          'displayName',
          _.get(payload, 'name', '')
        )
      })
      .addCase(uploadRegisterDocument.rejected, (state, { meta }) => {
        const key = _.get(meta.arg, 'key', 0)
        console.log(key)
      })
  },
})

export const {
  setUploadDocument,
  setErrorText,
  setClearFile,
  setClearErrorText,
  setIdCardNo,
  setDateOfBirth,
  setData,
} = document.actions

export default document.reducer
