import _ from 'lodash'
import { startLoading, stopLoading } from '../../../redux/slices/loading'
import { getRegistrationDetail } from '../../../services/class/registration'
import { setRegistrationData } from '../../../redux/slices/class/registration'

export const fetchDataRegistration =
  (uuid, classLearnerUuid) => async (dispatch) => {
    dispatch(startLoading())
    const result = await dispatch(
      getRegistrationDetail({ uuid, classLearnerUuid })
    )
    dispatch(setRegistrationData(_.get(result, 'payload.data', {})))
    dispatch(stopLoading())
  }
