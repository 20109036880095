import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Column } from 'src/components/Layout/Styled'
import { Typography } from '@mui/material'
import SingleForm from './SingleForm'
import MultipleForm from './MultipleForm'

const InformationForm = () => {
  const { selfRegister, examiners } = useSelector(
    (state) => ({
      selfRegister: state.eExamination.selfRegister,
      examiners: state.eExamination.examiners,
    }),
    shallowEqual
  )
  return (
    <Column>
      <Typography variant="h6" sx={{ mb: 4 }}>
        กรอกข้อมูลส่วนตัว
        {!selfRegister && ` (${examiners.length})`}
      </Typography>
      {selfRegister ? <SingleForm /> : <MultipleForm />}
    </Column>
  )
}

export default InformationForm
