import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Slider from 'react-slick'
import {
  ArrowBackIosOutlined,
  ArrowForwardIos,
  Download,
} from '@mui/icons-material'
import './styled.css'
import CircularProgress from '@mui/material/CircularProgress'
import {
  FlexCenterBox,
  ImageBox,
  DownloadText,
  DownloadTextSize,
} from './styled'
import { handleClickDownloadFile } from '../events'
import { startDownload } from 'src/redux/slices/eContent'

const File = ({ data }) => {
  const dispatch = useDispatch()
  const { downloadLoading } = useSelector(
    (state) => ({
      downloadLoading: state.eContent.downloadLoading,
    }),
    shallowEqual
  )
  var settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  function NextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <ArrowForwardIos />
      </div>
    )
  }
  function PrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <ArrowBackIosOutlined />
      </div>
    )
  }
  return (
    <>
      {data.content.files.map((file) => {
        return (
          <>
            <Slider {...settings} className="noPrint document">
              {file.fileName?.map((item, index) => {
                return (
                  <ImageBox
                    src={`${window.__env__.REACT_APP_FILE_URL}${item}`}
                    key={index}
                    alt="image"
                  />
                )
              })}
            </Slider>
            {file.downloadable && (
              <FlexCenterBox
                onClick={async () => {
                  if (downloadLoading) return
                  dispatch(startDownload())
                  dispatch(handleClickDownloadFile(file.file, file.displayName))
                }}
              >
                {downloadLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Download />
                )}
                <DownloadText>ดาวน์โหลดเฉพาะเอกสารนี้</DownloadText>
                <DownloadTextSize>{`ขนาด ${file.fileSize} MB`}</DownloadTextSize>
              </FlexCenterBox>
            )}
          </>
        )
      })}
    </>
  )
}

export default File
