import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import Close from '@mui/icons-material/Close'
import { closeDialog } from 'src/redux/slices/alertDialog'
import { StyledImage } from './Styled'
import _ from 'lodash'
import { handleDownload } from './event'

const CardImageDialog = ({ url, title, label }) => {
  const dispatch = useDispatch()
  const { progress } = useSelector(
    (state) => ({
      progress: state.eExamination.progress,
    }),
    shallowEqual
  )
  const type = _.get(url.split('.'), url.split('.').length - 1, 'png')
  return (
    <Box sx={{ maxHeight: 580 }}>
      <Box
        sx={{
          height: 60,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Close
          sx={{ mx: 3, mt: 2, cursor: 'pointer' }}
          data-testid="btn-close"
          color="action"
          onClick={() => dispatch(closeDialog())}
        />
      </Box>
      <Box sx={{ px: '60px', pb: '60px', textAlign: 'center' }}>
        <Typography variant="h5" color="primary.main">
          { label ? label : 'รูปหน้าบัตรประชาชน' }
        </Typography>
        {type === 'pdf' ? (
          <Box sx={{ width: '100%', my: 3 }}>
            <iframe src={url} width="100%" height="580px"></iframe>
          </Box>
        ) : (
          <StyledImage src={url} />
        )}
        <Button
          size="l"
          variant="outlined"
          sx={{ width: '100%' }}
          onClick={() => {
            if (progress == 1) handleDownload(title, url, type)
            else dispatch(closeDialog())
          }}
        >
          <Typography variant="button">
            {progress == 1 ? 'บันทึกรูปภาพ' : 'ตกลง'}
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default CardImageDialog
