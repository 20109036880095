import React from 'react'
import { Typography } from '@mui/material'
import { Column } from 'src/components/Layout/Styled'
import DateRange from './DateRange'
import FieldType from './FieldType'
import FieldSelect from './FieldSelect'

const Filter = () => {
  return (
    <Column sx={{ background: '#FAFAFA', mb: 3, py: 1, px: 2, rowGap: 2 }}>
      <Typography variant="h6" color="primary.main">
        ตัวกรอง
      </Typography>
      <DateRange />
      <FieldType />
      <FieldSelect />
    </Column>
  )
}

export default Filter
