import { WebStorageStateStore } from 'oidc-client-ts'

export const keycloakConfig = (type) => {
  const oidConfig = {
    authority: `${window.__env__.REACT_APP_KEY_URL}/auth/realms/${window.__env__.REACT_APP_KEY_REALM_AMS}`,
    client_id: window.__env__.REACT_APP_KEY_CLIENT_ID_AMS,
    redirect_uri: window.__env__.REACT_APP_APP_URL + '/authentication/callback',
    post_logout_redirect_uri: window.__env__.REACT_APP_LOGOUT_URL_AMS,
    userStore: new WebStorageStateStore({
      store: window.localStorage,
    }),
    automaticSilentRenew: false,
    scope: 'openid offline_access',
  }

  if (type === 'Agent') {
    oidConfig.authority = `${window.__env__.REACT_APP_KEY_URL}/auth/realms/${window.__env__.REACT_APP_KEY_REALM_B2A}`
    oidConfig.client_id = window.__env__.REACT_APP_KEY_CLIENT_ID_B2A
    oidConfig.client_secret = window.__env__.REACT_APP_KEY_CLIENT_SECRET_B2A
    oidConfig.post_logout_redirect_uri = window.__env__.REACT_APP_LOGOUT_URL_B2A
  } else if (type === 'Staff') {
    oidConfig.authority = `${window.__env__.REACT_APP_KEY_URL}/auth/realms/${window.__env__.REACT_APP_KEY_REALM_B2E}`
    oidConfig.client_id = window.__env__.REACT_APP_KEY_CLIENT_ID_B2E
    oidConfig.post_logout_redirect_uri = window.__env__.REACT_APP_LOGOUT_URL_B2E
    oidConfig.client_secret = window.__env__.REACT_APP_KEY_CLIENT_SECRET_B2E
  }

  return oidConfig
}
export default keycloakConfig
