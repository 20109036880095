import _ from 'lodash'
import {
  resetRedirectPage,
  setAnswerEEvaluation,
  setAssignSubQuestion,
  setNextQuestion,
  setRedirectPage,
  setSectionResult,
} from 'src/redux/slices/eEvaluation'

export const handleSelector = (state, keyProps) => {
  const { keyQuestion, keySub } = keyProps
  const question = state.eEvaluation.questions.find(
    (item) => item.key == keyQuestion
  )

  if (!keySub) return question

  const choice = question.choices.find(
    (item) =>
      item.nextQuestionType === 'NEXT_SUB' &&
      item.nextSubQuestionSequence == keySub
  )
  const subQuestion = _.get(choice, 'subQuestions', {})

  return subQuestion
}

export const handleDefaultValue = (eEvaluation, uuid) => {
  return _.get(eEvaluation, 'answerEEvaluation', []).filter(
    (item) => item.questionUuid == uuid
  )
}

export const handleOtherChange =
  (e, uuid, choice, keyQuestion) => (dispatch) => {
    const value = _.get(e, 'target.value', null)
    const name = _.get(e, 'target.name', null)
    dispatch(setAnswerEEvaluation({ type: 'OTHER', uuid, value, name }))

    if (value != '') {
      if (choice.nextQuestionType == 'NEXT_SUB') {
        setTimeout(() => {
          dispatch(setNextQuestion())
        }, 500)
      }
      if (choice.nextQuestionType == 'SUMMARY') {
        setTimeout(() => {
          dispatch(setSectionResult())
        }, 500)
      }
      if (choice.nextQuestionType == 'NEXT_TO') {
        setTimeout(() => {
          dispatch(
            setRedirectPage({
              from: keyQuestion,
              to: choice.nextQuestionSequence,
            })
          )
        }, 500)
      }
    }
  }

export const handleFreeTextChange = (e, uuid) => (dispatch) => {
  const value = _.get(e, 'target.value', null)
  dispatch(setAnswerEEvaluation({ type: 'FREE_TEXT', uuid, value }))
}

export const handleSingleChange = (e, keyProps, eventProps) => (dispatch) => {
  const { keyQuestion, keySub } = keyProps
  const { question, answers } = eventProps
  const choices = _.get(question, 'choices', [])
  const uuid = _.get(question, 'uuid', '')
  const name = _.get(e, 'target.name', null)
  const value = _.get(e, 'target.value', null)

  dispatch(setAnswerEEvaluation({ type: 'SINGLE', uuid, value, name }))

  if (!keySub) dispatch(setAssignSubQuestion({ keyQuestion, value }))

  if (name != 'OTHER') {
    const choice = choices.find((item) => item.uuid === value)
    const nextQuestionType = _.get(choice, 'nextQuestionType', 'NEXT')
    const nextQuestionSequence = _.get(choice, 'nextQuestionSequence', null)
    const isNoNextQuestion =
      nextQuestionSequence === 0 || _.isNil(nextQuestionSequence)

    if (nextQuestionType === 'NEXT' || isNoNextQuestion) {
      const answerUuid = _.get(answers, '[0].answerUuid', '')
      const oldAnswer = choices.find((item) => item.uuid == answerUuid)
      if (oldAnswer && oldAnswer.nextQuestionType == 'NEXT_TO') {
        dispatch(resetRedirectPage(keyQuestion))
      }
    } else if (nextQuestionType === 'NEXT_SUB') {
      setTimeout(() => {
        dispatch(setNextQuestion())
      }, 500)
    } else if (nextQuestionType === 'SUMMARY') {
      setTimeout(() => {
        dispatch(setSectionResult())
      }, 500)
    } else if (nextQuestionType === 'NEXT_TO') {
      setTimeout(() => {
        dispatch(
          setRedirectPage({
            from: keyQuestion,
            to: nextQuestionSequence,
          })
        )
      }, 500)
    }
  }
}

export const handleMultipleChange = (e, uuid, key) => (dispatch) => {
  const name = _.get(e, 'target.name', null)
  const value = { checked: e.target.checked, value: e.target.value }
  dispatch(setAnswerEEvaluation({ type: 'MULTIPLE', uuid, value, name, key }))
}

export const isOtherInputShow = (choice, answers) => {
  if (choice.answerType != 'OTHER') return false
  const thisAnswer = answers.find((item) => item.answerUuid == choice.uuid)
  const answerUuid = _.get(thisAnswer, 'answerUuid', null)
  return choice.title == 'อื่นๆ' && answerUuid == choice.uuid
}

export const handleDisableCheckbox = (question, value, answers) => {
  const { setTypeOfAnswers, typeOfAnswers, numberOfAnswers } = question
  const answered = answers.filter((item) => item.isAnswered)

  if (!setTypeOfAnswers) return false

  if (typeOfAnswers != 'EQUAL' && typeOfAnswers != 'MAXIMUM') return false

  const index = answers.findIndex((item) => item.answerUuid == value)
  return answered.length >= numberOfAnswers && index == -1
}
