import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import DateRangeInput from '../../../../components/DateRangeInput'
import { StyledDrawer } from '../../Calendar/Header/DrawerFilter/Styled'
import { setToggleFilterDrawer } from '../../../../redux/slices/table'
import { toDoType } from '../../../../constants/toDo'
import { getQueryParams } from '../../../../utils/lib'
import { defaultFilter, arrayFilter, arrayFilterStandalone } from '../model'
import { StyledGroupBox, StyledFooter } from './Styled'
import {
  handleChange,
  handleResetPeriod,
  handleSelectDate,
  onFilterClick,
} from './events'

import { setDefaultFilter } from '../events'

export const ListCheckbox = ({ stateFilter, arrayFilter, setFilter }) => {
  return (
    <>
      {arrayFilter.map((item, index) => {
        const { label, nameCheckBox, nameInput, placeholder, typeFilter } = item

        return (
          <StyledGroupBox key={index}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{
                      'data-testid': `check-${nameCheckBox}`,
                    }}
                    name={nameCheckBox}
                    checked={stateFilter[nameCheckBox]}
                    onChange={(e) => {
                      handleChange({
                        value: e.target.checked,
                        key: e.target.name,
                        stateFilter,
                        setFilter,
                      })
                    }}
                  />
                }
                label={label}
                sx={!stateFilter[nameCheckBox] ? { marginBottom: -1 } : {}}
              />

              {stateFilter[nameCheckBox] && (
                <>
                  {typeFilter == 'default' && (
                    <InputBase
                      sx={{ borderRadius: 0 }}
                      type="basic"
                      inputProps={{ 'data-testid': `input-${nameInput}` }}
                      name={nameInput}
                      disabled={!stateFilter[nameCheckBox]}
                      placeholder={placeholder}
                      value={stateFilter[nameInput]}
                      onChange={(e) => {
                        handleChange({
                          key: e.target.name,
                          value: e.target.value,
                          stateFilter,
                          setFilter,
                        })
                      }}
                    />
                  )}
                  {typeFilter == 'date' && (
                    <DateRangeInput
                      placeholder={placeholder}
                      dateState={{
                        startDate: stateFilter[item.startDate],
                        endDate: stateFilter[item.endDate],
                        display: stateFilter[item.display],
                        key: 'selection',
                      }}
                      onChange={(selectedDates) => {
                        handleSelectDate({
                          item,
                          selectedDates,
                          stateFilter,
                          setFilter,
                        })
                      }}
                      onReset={() =>
                        handleResetPeriod({
                          item,
                          stateFilter,
                          setFilter,
                        })
                      }
                    />
                  )}
                  {(typeFilter == 'type' || typeFilter == 'status') && (
                    <>
                      {item.list.map((itemList, indexList) => {
                        return (
                          <FormControlLabel
                            key={indexList}
                            label={itemList.label}
                            control={
                              <Checkbox
                                sx={{ mx: 2 }}
                                inputProps={{
                                  'data-testid': `check-${itemList.name}`,
                                }}
                                name={itemList.name}
                                checked={stateFilter[typeFilter][itemList.name]}
                                onChange={(e) => {
                                  handleChange({
                                    value: e.target.checked,
                                    key: itemList.name,
                                    listKey: typeFilter,
                                    stateFilter,
                                    setFilter,
                                  })
                                }}
                              />
                            }
                          />
                        )
                      })}
                    </>
                  )}
                </>
              )}
            </FormGroup>
          </StyledGroupBox>
        )
      })}
    </>
  )
}

const Index = ({ open }) => {
  const dispatch = useDispatch()
  const { type } = useParams()
  const standaloneType = getQueryParams('type')
  const [filter, setFilter] = useState(setDefaultFilter(type, standaloneType))
  const { table } = useSelector((state) => state.table)

  const [filterMemo, setFilterMemo] = useState()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(setDefaultFilter(type, standaloneType))
  }, [open])

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(setToggleFilterDrawer(false))}
    >
      <Box id="header">
        <Typography variant="h5">ตัวกรอง</Typography>
        <IconButton
          data-testid="btn-close"
          color="primary"
          onClick={() => dispatch(setToggleFilterDrawer(false))}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider id="divider" />
      <Box id="body">
        <ListCheckbox
          stateFilter={filter}
          arrayFilter={
            type === toDoType.standalone ? arrayFilterStandalone : arrayFilter
          }
          setFilter={setFilter}
        />
      </Box>
      <StyledFooter>
        <Button
          sx={{ width: 144 }}
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => setFilter(defaultFilter)}
        >
          ล้าง
        </Button>
        <Button
          sx={{ width: 144 }}
          data-testid="btn-submit"
          onClick={() => {
            dispatch(onFilterClick(filter, type, table))
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default Index
