import * as yup from 'yup'

export const schama = yup.object().shape({
  firstName: yup.string().max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร'),
  lastName: yup.string().max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร'),
  firstNameTH: yup.string().max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร'),
  lastNameTH: yup.string().max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร'),
  idCardNo: yup.string().when('isAgent', {
    is: false, // ตรวจสอบเฉพาะเมื่อ isAgent เป็น false
    then: yup
      .string()
      .required('กรุณากรอกเลขประจำตัวประชาชน 13 หลัก')
      .matches(/^[0-9]{13}$/, 'กรุณากรอกเลขประจำตัวประชาชน 13 หลัก')
      .test('check-idCardNo', 'รูปแบบเลขประจำตัวประชาชนไม่ถูกต้อง', (value) => {
        if (!value || value.length !== 13) {
          return false
        }
        let sum = 0
        for (let i = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i)
        }
        const checkDigit = (11 - (sum % 11)) % 10
        return checkDigit === parseInt(value.charAt(12))
      }),
    otherwise: yup.string(), // ถ้าเป็น isAgent ให้ idCardNo เป็น optional และไม่ตรวจสอบฟอร์แมต
  }),
})
