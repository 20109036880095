export const SECTION_KEY = {
  E_LEARNING: 'E_LEARNING',
  BANNER: 'BANNER',
  COURSE: 'COURSE',
  LEARNING_PATH: 'LEARNING_PATH',
  COLLECTION: 'COLLECTION',
}

export const SECTION_OBJ = {
  [SECTION_KEY.COURSE]: {
    isCourse: true,
    keyField: 'courses',
    keyValue: 'course',
    link: '/e-learning/course',
    listTitle: 'หลักสูตรทั้งหมด',
    listUnit: 'หลักสูตร',
  },
  [SECTION_KEY.LEARNING_PATH]: {
    keyField: 'learningPaths',
    keyValue: 'learningPath',
    link: '/e-learning/learning-path',
    listTitle: 'เส้นทางการเรียนรู้ทั้งหมด',
    listUnit: 'เส้นทางการเรียนรู้',
  },
  [SECTION_KEY.COLLECTION]: {
    keyField: 'collections',
    keyValue: 'collection',
    link: '/e-learning/collection',
    listTitle: 'Collection ทั้งหมด',
    listUnit: 'Collection',
  },
}

export const filterBody = {
  order: 'DESC',
  sortBy: 'POPULAR',
  courseType: [],
  courseStatus: [],
  myCourse: ['INPROGRESS'],
  acquiredSkill: [],
  productType: [],
  courseName: '',
  courseHourFrom: 0,
  courseHourTo: 0,
  startDate: '',
  finishDate: '',
  page: 1,
  limit: 12,
}

export const filterLikeBody = {
  order: 'DESC',
  sortBy: 'POPULAR',
  courseType: [],
  courseStatus: [],
  myCourse: ['LIKE'],
  acquiredSkill: [],
  productType: [],
  courseName: '',
  courseHourFrom: 0,
  courseHourTo: 0,
  startDate: '',
  finishDate: '',
  page: 1,
  limit: 12,
}
