import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { postELearningLearnerList } from 'src/services/eLearning/list'
import { handleFilterBody } from './events'
import { initialState } from './model'

const classLearnerList = createSlice({
  name: 'classLearnerList',
  initialState,
  reducers: {
    startLoadMore: (state) => {
      state.isLoadMore = true
    },
    stopLoadMore: (state) => {
      state.isLoadMore = false
    },
    setTab: (state, { payload }) => {
      state.filter = []
      state.filterBody = { ...initialState.filterBody }
      handleFilterBody(state, payload)
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
      state.filterBody.page = 1
      if (payload.length == 0) handleFilterBody(state, state.tab)
      else state.filterBody.classStatus = payload
    },
    setSearch: (state, { payload }) => {
      state.filterBody.name = payload
      state.filterBody.page = 1
    },
    setPeriod: (state, { payload }) => {
      const { startDate, finishDate } = payload
      state.filterBody.startDate = startDate
      state.filterBody.finishDate = finishDate
      state.filterBody.page = 1
    },
    setLoadMore: (state) => {
      const nextPage = state.filterBody.page + 1
      const maxPage = _.ceil(state.totalClasses / 15)
      if (nextPage <= maxPage) state.filterBody.page = nextPage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postELearningLearnerList.fulfilled, (state, action) => {
        const { meta, payload } = action
        const batchs = _.get(payload.data, 'courseBatchs', [])
        const isFilter = _.get(meta?.arg, 'page', 1) == 1
        if (isFilter) state.batchs = batchs
        else state.batchs = _.concat(state.batchs, batchs)
        state.totalClasses = _.get(payload.data, 'totalCourseBatch', 0)
      })
      .addCase(postELearningLearnerList.pending, (state, { meta }) => {
        const isFilter = _.get(meta?.arg, 'page', 1) == 1
        state.classes = isFilter ? [] : state.classes
      })
      .addCase(postELearningLearnerList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const {
  startLoadMore,
  stopLoadMore,
  setTab,
  setFilter,
  setSearch,
  setPeriod,
  setLoadMore,
} = classLearnerList.actions

export default classLearnerList.reducer
