import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import { Column, Row } from 'src/components/Layout/Styled'
import SummaryItem from './SummaryItem'

const Summary = () => {
  const { selectedRounds } = useSelector(
    (state) => ({
      selectedRounds: state.eExamination.selectedRounds,
    }),
    shallowEqual
  )
  return (
    <Column sx={{ background: '#FAFAFA', mb: 2, p: 3, rowGap: 2 }}>
      <Row>
        <InsertInvitationIcon sx={{ mr: 1, fontSzie: 24, color: '#00008F' }} />
        <Typography variant="h6" color="primary.main">
          {`รายละเอียดวันสอบ (${selectedRounds.length})`}
        </Typography>
      </Row>
      {selectedRounds.map((ele, ind) => {
        return <SummaryItem key={ind} index={ind} />
      })}
    </Column>
  )
}

export default Summary
