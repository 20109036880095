export const initialVideo = {
  playing: false,
  muted: false,
  volume: 0.5,
  played: 0,
  playedSeconds: 0,
  playbackRate: 1, // 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2
  seeking: false,
  isFinished: false,
}

export const initialState = {
  error: null,
  isValidate: false,
  isLoading: false,
  isLessonLoading: false,
  isVideoLoading: false,
  isExpanded: true,
  isCourseCompleted: false,
  isFullScreen: false,
  isSkipAllowed: false,
  progressPercent: 0,
  course: null,
  lessons: [],
  lessonsTotal: 0,
  selectedLesson: {},
  eLearningCourseBatchLearner: {},
  eLearningCourseBatch: {},
  eLearningIdCardEnroll: null,
  eLearningBatchLearnerRekog: null,
  verifiedTimes: 1,
  camera: { isOpen: false, faceImage: null },
  time: { mins: 0, secs: 0 },
  verifyDialog: {
    isDialogOpen: false,
    randomTime: [],
    round: 0,
    verifyRound: 0,
    resetMins: 30, // 30 minutes
    randomDateTime: null,
  },
  clickDialog: {
    isDialogOpen: false,
    round: 0,
    randomDateTime: null,
  },
  questions: [],
  failMaxTimes: 0,
  failTime: { mins: 0, secs: 0 },
  failLogUuid: null,
}
