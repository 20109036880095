import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import DateRangeInput from 'src/components/DateRangeInput'
import { Column } from 'src/components/Layout/Styled'
import { setDateFilter } from 'src/redux/slices/eExamination'
import dayjs from 'dayjs'

const DateRange = () => {
  const dispatch = useDispatch()
  const { startDate, endDate } = useSelector(
    (state) => ({
      startDate: state.eExamination.examinationFilter.startDate,
      endDate: state.eExamination.examinationFilter.endDate,
    }),
    shallowEqual
  )
  return (
    <Column>
      <Typography variant="body1b">ช่วงวันที่สอบ</Typography>
      <DateRangeInput
        placeholder="เลือกวันที่"
        dateState={{
          startDate: startDate ? new Date(startDate) : new Date(),
          endDate: endDate ? new Date(endDate) : new Date(),
          display: startDate != '',
          key: 'selection',
        }}
        onChange={(selecteDates, setOpen) => {
          dispatch(
            setDateFilter({
              startDate: selecteDates.startDate
                ? dayjs(selecteDates.startDate).format('YYYY-MM-DD')
                : '',
              endDate: selecteDates.endDate
                ? dayjs(selecteDates.endDate).format('YYYY-MM-DD')
                : '',
            })
          )
          if (selecteDates.startDate !== selecteDates.endDate) setOpen(false)
        }}
        onReset={() => dispatch(setDateFilter({ startDate: '', endDate: '' }))}
      />
    </Column>
  )
}

export default DateRange
