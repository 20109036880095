import React from 'react'
import { Column } from 'src/components/Layout/Styled'
import InformationItem from './InformationItem'
import { Typography } from '@mui/material'

const SingleForm = () => {
  return (
    <Column>
      <InformationItem index={0} />
      <Typography variant="body2" color="primary.main" sx={{ mt: 5 }}>
        หากต้องการเปลี่ยนแปลงข้อมูลกรุณาติดต่อต้นสังกัด
      </Typography>
    </Column>
  )
}

export default SingleForm
