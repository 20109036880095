import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useSelector, shallowEqual } from 'react-redux'
import { ContentTooltip } from '../'
import { CustomTooltip } from '../Styled'
import { mapEventTypeCalendar } from '../events'

const Index = ({ calendarRef }) => {
  const calendar = useSelector(
    (state) => state.myProfile.calendar,
    shallowEqual
  )
  const events = mapEventTypeCalendar(calendar)

  return (
    <Box
      id="my-profile-calendar"
      onMouseDown={(evt) => {
        evt.stopPropagation()
        evt.preventDefault()
      }}
    >
      <FullCalendar
        ref={calendarRef}
        timeZone={'GMT+7'}
        weekends={true}
        height={536}
        firstDay={1}
        headerToolbar={false}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        displayEventTime={false}
        showNonCurrentDates={false}
        fixedWeekCount={false}
        eventLimit={true}
        dayMaxEventRows={2}
        eventContent={(info) => {
          return (
            <CustomTooltip
              enterTouchDelay={0}
              title={<ContentTooltip event={info.event} />}
            >
              {handleMouseEnter(info)}
            </CustomTooltip>
          )
        }}
      />
    </Box>
  )
}

export const handleMouseEnter = (innerProps) => {
  const status = innerProps.event.extendedProps.status

  return (
    <Box
      className={`fc-event-main-frame event-main-box event-status-${status.toLowerCase()}`}
    >
      <Box className="fc-event-title-container box-group-month">
        <Box className="fc-event-title fc-sticky event-calendar-view">
          <Typography variant="tooltip">{innerProps.event.title}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Index
