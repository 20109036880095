import React from 'react'
import NameCheckTable from './NameCheckTable'
import TestTable from './TestTable'
import TrainingTable from './TrainingTable'

const ClassTable = () => {
  return (
    <>
      <TrainingTable />
      <NameCheckTable />
      <TestTable />
    </>
  )
}

export default ClassTable
