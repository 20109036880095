import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Grid, Typography, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { StyledInputHidden } from './Styled'
import { Row } from 'src/components/Layout/Styled'
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import { openDialog } from 'src/redux/slices/alertDialog'
import CardImageDialog from './CardImageDialog'
import { handleUploadIdCard } from './event'
import { removeIdcard } from 'src/redux/slices/eExamination'

const UploadFileInput = ({ index }) => {
  const dispatch = useDispatch()
  const { value, imageName } = useSelector(
    (state) => ({
      value: state.eExamination.examiners[index].cardImage,
      imageName: state.eExamination.examiners[index].imageName,
    }),
    shallowEqual
  )
  return (
    <Grid item sm={6} xs={12}>
      <Row>
        <Typography variant="body1">อัปโหลดรูปหน้าบัตรประชาชน</Typography>
        <Typography sx={{ lineHeight: 1, ml: 0.5 }} color="error">
          *
        </Typography>
      </Row>
      <Row sx={{ my: 1 }}>
        <label htmlFor={`upload-file-${index}`}>
          <Row
            sx={{
              background: '#E5E5E5',
              border: '1px solid #CCCCCC',
              padding: '10.5px 20px',
              cursor: 'pointer',
            }}
          >
            <Typography variant="body1" color="text.primary">
              เลือกไฟล์
            </Typography>
            <StyledInputHidden
              accept=".jpg, .jpeg, .png, .pdf"
              type="file"
              id={`upload-file-${index}`}
              onChange={(e) => {
                dispatch(
                  handleUploadIdCard({ index: index, value: e.target.files[0] })
                )
              }}
            />
          </Row>
        </label>
        <Row
          sx={{
            border: '1px solid #CCCCCC',
            padding: '10.5px 20px',
            height: 45,
            width: imageName ? 'calc(100% - 126px)' : 'calc(100% - 94px)',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="body1"
            color={value ? 'text.primary' : 'text.silver'}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {imageName || 'ยังไม่เลือกไฟล์'}
          </Typography>
          <RemoveRedEyeTwoToneIcon
            sx={{ fontSize: 20, color: '#0000008A', cursor: 'pointer' }}
            onClick={() => {
              if (!value?.cardImage) return
              dispatch(
                openDialog({
                  type: 'custom',
                  customWidth: 900,
                  content: (
                    <CardImageDialog
                      url={window.__env__.REACT_APP_FILE_URL + value?.cardImage}
                      title={imageName}
                    />
                  ),
                })
              )
            }}
          />
        </Row>
        {imageName && (
          <IconButton
            sx={{ ml: 1, p: 0 }}
            onClick={() => {
              document.querySelector(`#upload-file-${index}`).value = ''
              dispatch(removeIdcard({ index: index }))
            }}
          >
            <CloseIcon sx={{ color: '#00008F' }} />
          </IconButton>
        )}
      </Row>
      <Typography variant="body2" color="text.gray">
        ไฟล์ที่รองรับ .jpg, .jpeg, .png, .pdf ขนาดของไฟล์ที่แนะนำไม่เกิน 2 MB
      </Typography>
    </Grid>
  )
}

export default UploadFileInput
