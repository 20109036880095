import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const ThumbImageBox = styled('img')(({ boxWidth, theme }) => ({
  width: boxWidth,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const VideoThumbnailBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: '8px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))
