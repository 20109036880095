import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setFetchData,
} from '../../redux/slices/checkIn'
import { getLearnerCheckIn } from '../../services/checkIn'

export const mapStatus = (status, errorCase) => {
  if (status === 'SUCCESS') {
    return {
      label: 'เช็คชื่อสำเร็จ',
      icon: '/icon/success.svg',
      color: 'success.main',
    }
  } else {
    return {
      label:
        errorCase === 'ATTENDED' ? 'คุณเคยเช็คชื่อแล้ว' : 'เช็คชื่อไม่สำเร็จ',
      icon: '/icon/fail.svg',
      color: 'error.main',
    }
  }
}

export const fetchCheckIn = (body) => async (dispatch) => {
  dispatch(startLoading())
  const result = await dispatch(getLearnerCheckIn(body))

  const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
  const response = _.get(result, 'payload.data', {})

  if (requestStatus == 'fulfilled') {
    const errorCase = _.get(response, 'errorCase', 'ERROR')
    let textError = ''
    let lastCheckedBy = `เช็คชื่อครั้งล่าสุดโดย คุณ ${_.get(
      response,
      'course.lastCheckedBy',
      ''
    )}`
    const timeoutLastChecked = 'เช็คชื่อครั้งล่าสุดโดย...'
    if (errorCase === 'ERROR') {
      textError =
        errorCase === 'TIME_OUT'
          ? 'QR Code หมดอายุการใช้งาน'
          : 'คุณเคยเช็คชื่อแล้ว'
      lastCheckedBy =
        errorCase === 'TIME_OUT' ? timeoutLastChecked : lastCheckedBy
    }

    if (_.isEmpty(response.course.lastCheckedBy)) {
      lastCheckedBy = timeoutLastChecked
    }

    dispatch(
      setFetchData({
        ...response,
        textError,
        course: { ..._.get(response, 'course', {}), lastCheckedBy },
        isShowError: errorCase === 'TIME_OUT' ? true : false,
      })
    )
  } else {
    const dataErrors = {
      status: 'ERROR',
      errorCase: 'ERROR500',
      isShowError: true,
      textError: 'ระบบขัดข้อง',
    }
    dispatch(setFetchData(dataErrors))
  }

  dispatch(stopLoading())
}
