import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Row } from 'src/components/Layout/Styled'
import { setHeight, setMonth } from 'src/redux/slices/eLearning/enroll'
import { MONTH_TH } from '../model'
import { handleChangeMonth } from './events'
import { convertDateToThaiString } from 'src/utils/lib'

const Toolbar = ({ calendarRef }) => {
  const { label, flag } = useSelector(
    (state) => ({
      label: state.eLearningEnroll.month.split(' '),
      flag: state.eLearningEnroll.flag,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const el = document.getElementsByClassName('fc-daygrid-body').item(0)
    dispatch(setHeight(el.clientHeight))

    if (calendarRef.current) {
      const monthTitle = calendarRef.current.getApi().currentData.viewTitle
      dispatch(setMonth(monthTitle))
    }
  }, [flag])

  return (
    <Row sx={{ justifyContent: 'space-between' }}>
      <Typography variant="h5">
        {MONTH_TH[label[0]] || ''}{' '}
        {label[1] ? convertDateToThaiString(label[1], 'year') : ''}
      </Typography>
      <Row sx={{ width: 'auto', gap: 0.5 }}>
        <IconButton
          sx={{ p: 0.5 }}
          onClick={() => dispatch(handleChangeMonth(calendarRef, false))}
        >
          <KeyboardArrowLeft color="primary" />
        </IconButton>
        <IconButton
          sx={{ p: 0.5 }}
          onClick={() => dispatch(handleChangeMonth(calendarRef, true))}
        >
          <KeyboardArrowRight color="primary" />
        </IconButton>
      </Row>
    </Row>
  )
}

export default Toolbar
